import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
    StepComponent,
    _,
    isNotNullOrUndefined,
    ModalService,
    unitMMFormatter,
    ConfiguratorOptions,
    StartedChangingStepValue,
    StepsService,
} from '@icc/configurator/shared';
import { DoorFacade } from '../+state/door.facade';
import { WindowFacade, ProfilesModalService, BrowserProfilesService } from '@icc/configurator/window';
import {
    APP_CONFIG,
    AppConfigFactory,
    EventBusService,
    ConfigurationsService,
    WindowActiveConfiguration,
    ParametersService,
    ShapeService,
    core,
    SizeRangeService,
} from '@icc/common';
import {
    FormBuilder,
    FormGroup,
    Validators,
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';
import { ResizeService } from '@icc/legacy/configurator/layout/resize.service';
import { FillingValidationService } from '@icc/legacy/configurator/steps/window/glazings/filling-validation.service';
import { SchemasService } from '@icc/legacy/configurator/steps/roller_shutter/schemas.service';
import { UnitConverterService } from '@icc/configurator/ui';
import { DimensionsService } from '@icc/legacy/configurator/steps/window/dimensions/dimensions.service';
import { DoorSizes, Shape } from '@icc/window';
import { filter, take } from 'rxjs/operators';
import { SizesService } from './sizes.service';
import { SetSizesService } from './set-sizes.service';
import { IccDoorSize, IccDoorLightsSize } from '@icc/common/data-types/DoorSize';
import { ThresholdsService } from '@icc/legacy/configurator/layout/thresholds.service';
import { ExtensionsPageComponent } from '../extensions-page/extensions-page.component';
import { PriceService } from '@icc/price';
import { LayoutService } from '@icc/legacy/configurator/layout/layout.service';
import { MeasurementsService } from '@icc/legacy/configurator/steps/window/dimensions/measurements.service';
import { ExtensionsService } from '@icc/legacy/configurator/layout/extensions.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { HIGH_CONTRAST_MODE_ACTIVE_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';
import { ProfileInfoComponent } from 'libs/configurator/window/src/lib/profile-info/profile-info.component';
import { DoorPortalsService } from 'libs/configurator/window/src/lib/door-portals/door-portals.service';
import { CasingsService } from 'libs/configurator/window/src/lib/profiles/casings.service';

@Component({
    selector: 'icc-sizes',
    templateUrl: './sizes.component.html',
    styleUrls: ['./sizes.component.scss'],
})
export class SizesComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Rozmiary');
    static stepIcon = {
        ligature: 'settings_overscan',
    };
    public configurator = 'door';
    public stepId = 'sizes';
    public title = _('DOOR|Uzupełnij rozmiary drzwi');

    private subscriptions: any[] = [];

    public doorSizes$ = this.doorFacade.doorSizes$;
    public shape$ = this.windowFacade.shape$;
    public selectedFrame$ = this.doorFacade.selectedFrame$;
    public selectedThreshold$ = this.doorFacade.selectedThreshold$;
    public seperateFrameAndSashShortening$ = this.doorFacade.seperateFrameAndSashShortening$;

    public dimensionsForm: FormGroup = this.fb.group({});
    public doorSizes: DoorSizes | null = null;
    public shape: Partial<Shape> | null = null;

    public fields: {
        type: string;
        options: Array<any>;
        name: string;
        field?: keyof DoorSizes;
        highlight?: keyof DoorSizes;
        value?: number;
        disabled?: boolean;
        hintParams: {
            min: string,
            max: string,
            step: string
        };
    }[] = [];

    private dimensionsFields: {
        type: string;
        name: string;
        field?: keyof DoorSizes;
        highlight?: keyof DoorSizes;
        options: Array<any>;
        show: (shape?: Partial<DoorSizes>) => boolean;
        value?: (shape?: Partial<DoorSizes>) => number;
        focus?: boolean;
    }[] = [
        {
            type: 'select',
            name: 'sashSize',
            field: 'sashSizeId',
            options: [],
            show: () => true,
            focus: true,
        },
        {
            type: 'select',
            name: 'passiveSashSize',
            field: 'passiveSashSizeId',
            options: [],
            show: () =>
                this.configurationsService.conf.Current.Sashes.some(sash =>
                    ['DRP', 'DOP'].includes(sash.type.type)
                ),
            focus: true,
        },
        {
            type: 'checkbox',
            name: 'standardHeight',
            field: 'standardHeight',
            options: [],
            show: () => this.isStandardHeightCheckboxAvailable()
        },
        {
            type: 'number',
            name: 'customWidth',
            field: 'customWidth',
            options: [],
            show: () => this.isCustomWidthAvailable()
        },
        {
            type: 'number',
            name: 'customHeight',
            field: 'customHeight',
            options: [],
            show: () => this.isCustomHeightAvailable()
        },
        {
            type: 'select',
            options: [],
            name: 'shortening',
            field: 'shortening',
            show: () => this.shorteningAvailable(),
        },
        {
            type: 'select',
            options: [],
            name: 'frameShortening',
            field: 'frameShortening',
            show: () => this.frameShorteningAvailable(),
        },
        {
            type: 'number',
            name: 'leftLightSize',
            field: 'leftLightSize',
            options: [],
            show: () =>
                this.sideLightsSizes.length === 1
                && this.sideLightsSizes[0].size_from !== this.sideLightsSizes[0].size_to
                && this.doorSizes.leftLightSize > 0,
        },
        {
            type: 'select',
            name: 'leftLightSize',
            field: 'leftLightSizeId',
            options: [],
            show: () =>
                (this.sideLightsSizes.length > 1
                    || (this.sideLightsSizes.length === 1
                        && this.sideLightsSizes[0].size_from === this.sideLightsSizes[0].size_to))
                && this.doorSizes.leftLightSize > 0,
        },
        {
            type: 'number',
            name: 'rightLightSize',
            field: 'rightLightSize',
            options: [],
            show: () =>
                this.sideLightsSizes.length === 1
                && this.sideLightsSizes[0].size_from !== this.sideLightsSizes[0].size_to
                && this.doorSizes.rightLightSize > 0,
        },
        {
            type: 'select',
            name: 'rightLightSize',
            field: 'rightLightSizeId',
            options: [],
            show: () =>
                (this.sideLightsSizes.length > 1
                    || (this.sideLightsSizes.length === 1
                        && this.sideLightsSizes[0].size_from === this.sideLightsSizes[0].size_to))
                && this.doorSizes.rightLightSize > 0,
        },
        {
            type: 'number',
            name: 'topLightSize',
            field: 'topLightSize',
            options: [],
            show: () =>
                this.topLightsSizes.length === 1
                && this.topLightsSizes[0].size_from !== this.topLightsSizes[0].size_to
                && this.doorSizes.topLightSize > 0,
        },
        {
            type: 'select',
            name: 'topLightSize',
            field: 'topLightSizeId',
            options: [],
            show: () =>
                (this.topLightsSizes.length > 1
                    || (this.topLightsSizes.length === 1
                        && this.topLightsSizes[0].size_from === this.topLightsSizes[0].size_to))
                && this.doorSizes.topLightSize > 0,
        },
    ];

    dimensionUnit = this.unitConverterService.getUnit();
    sashSizes: IccDoorSize[] = [];
    passiveSashSizes: IccDoorSize[] = [];
    sideLightsSizes: IccDoorLightsSize[] = [];
    topLightsSizes: IccDoorLightsSize[] = [];

    hiddenSelectionsWithoutChange = false;

    static stepEnable = (conf: WindowActiveConfiguration, options: ConfiguratorOptions) =>
        conf.System && Boolean(conf.System.door_type);

    constructor(
        private doorFacade: DoorFacade,
        private windowFacade: WindowFacade,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private fb: FormBuilder,
        private dimensionsService: DimensionsService,
        private stepsService: StepsService,
        private resizeService: ResizeService,
        private fillingValidationService: FillingValidationService,
        private configurationsService: ConfigurationsService<'door'>,
        private priceService: PriceService,
        private parametersService: ParametersService,
        private layoutService: LayoutService,
        private schemasService: SchemasService,
        private unitConverterService: UnitConverterService,
        private measurementsService: MeasurementsService,
        private extensionsService: ExtensionsService,
        private shapeService: ShapeService,
        private sizesService: SizesService,
        private setSizesService: SetSizesService,
        private profilesModalService: ProfilesModalService,
        private thresholdsService: ThresholdsService,
        private modalService: ModalService,
        private profilesService: BrowserProfilesService,
        private eventBusService: EventBusService,
        private sizeRangeService: SizeRangeService,
        private doorPortalsService: DoorPortalsService,
        private casingsService: CasingsService
    ) {
        super();
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.subscriptions.push(
            this.eventBusService.subscribe<StartedChangingStepValue>('startedChangingStep', data => {
                if (
                    data.value.nextStep.code !== 'sizes'
                    && data.value.nextStep.code !== data.value.prevStep.code
                    && data.value.prevStep.code === 'sizes'
                ) {
                    if (!this.dimensionsForm.valid || !this.dimensionsService.valid(this.shape)) {
                        data.value.cancel();
                    }
                }
            })
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    private init() {
        const conf = this.configurationsService.conf.Current;
        this.sashSizes = this.sizesService.getNotPassiveSashesSizes();
        this.passiveSashSizes = this.sizesService.getPassiveSashSizes(conf);
        this.sideLightsSizes = this.sizesService.getSideLightsSizes();
        this.topLightsSizes = this.sizesService.getTopLightsSizes();
        this.subscriptions.push(
            this.shape$.pipe(filter(isNotNullOrUndefined)).subscribe(shape => {
                this.shape = shape;
            })
        );
        this.hiddenSelectionsWithoutChange = this.config().IccConfig.Configurators.door.hideSelectionsWithoutChange;
        this.subscriptions.push(
            this.doorSizes$.pipe(filter(isNotNullOrUndefined), take(1)).subscribe(doorSizes => {
                if (!this.fields || this.fields.length === 0) {
                    this.generateFields(doorSizes);
                }
            })
        );

    }

    private generateFields(doorSizes: DoorSizes, conf = this.configurationsService.conf.Current) {
        this.doorSizes = doorSizes;

        const steps = {
            height: doorSizes?.sizeIntervalHeight || 1,
            width: doorSizes?.sizeIntervalCustomWidth || 1,
        }

        const group = this.fb.group({});
        const fields = this.dimensionsFields
            .filter(f => f.show())
            // eslint-disable-next-line max-statements
            .map(f => {
                let options = f.options;
                let validators = [];
                let hintParams = null;
                let step = 1;
                let disabled = false;
                if (f.field === 'shortening') {
                    options = this.buildShorteningOptions();
                } else if (f.field === 'frameShortening') {
                    options = this.buildFrameShorteningOptions(doorSizes.shortening);
                    disabled = options.length < 2;
                } else if (f.field === 'sashSizeId') {
                    options = this.sashSizes.map(v => ({
                        name: v.name,
                        value: v.id,
                    }));
                } else if (f.field === 'passiveSashSizeId') {
                    options = this.passiveSashSizes.map(v => ({
                        name: v.name,
                        value: v.id,
                    }));
                } else if (f.field === 'leftLightSizeId') {
                    options = this.sideLightsSizes.map(v => ({
                        name: v.name,
                        value: v.id,
                    }));
                } else if (f.field === 'leftLightSize') {
                    validators = [
                        Validators.min(this.sideLightsSizes[0].size_from),
                        Validators.max(this.sideLightsSizes[0].size_to),
                        SizesComponent.step(this.sideLightsSizes[0].size_interval || 1),
                    ];
                    hintParams = {
                        min: this.formatUnit(this.sideLightsSizes[0].size_from),
                        max: this.formatUnit(this.sideLightsSizes[0].size_to),
                        step: this.formatUnit(this.sideLightsSizes[0].size_interval),
                    };
                    step = this.sideLightsSizes[0].size_interval;
                } else if (f.field === 'rightLightSizeId') {
                    options = this.sideLightsSizes.map(v => ({
                        name: v.name,
                        value: v.id,
                    }));
                } else if (f.field === 'rightLightSize') {
                    validators = [
                        Validators.min(this.sideLightsSizes[0].size_from),
                        Validators.max(this.sideLightsSizes[0].size_to),
                        SizesComponent.step(this.sideLightsSizes[0].size_interval || 1),
                    ];
                    hintParams = {
                        min: this.formatUnit(this.sideLightsSizes[0].size_from),
                        max: this.formatUnit(this.sideLightsSizes[0].size_to),
                        step: this.formatUnit(this.sideLightsSizes[0].size_interval),
                    };
                    step = this.sideLightsSizes[0].size_interval;
                } else if (f.field === 'topLightSizeId') {
                    options = this.topLightsSizes.map(v => ({
                        name: v.name,
                        value: v.id,
                    }));
                } else if (f.field === 'topLightSize') {
                    validators = [
                        Validators.min(this.topLightsSizes[0].size_from),
                        Validators.max(this.topLightsSizes[0].size_to),
                        SizesComponent.step(this.topLightsSizes[0].size_interval || 1),
                    ];
                    hintParams = {
                        min: this.formatUnit(this.topLightsSizes[0].size_from),
                        max: this.formatUnit(this.topLightsSizes[0].size_to),
                        step: this.formatUnit(this.topLightsSizes[0].size_interval),
                    };
                    step = this.topLightsSizes[0].size_interval;
                } else if (f.field === 'customWidth') {
                    const rangeId = this.sizesService.getSizeRangeId(this.doorSizes);
                    const passiveSash = conf.Sashes.find(sash => sash.type.passive);
                    const width = conf?.Width - (conf?.doorSizes?.leftLightSize || 0) - (conf?.doorSizes?.rightLightSize || 0) - (passiveSash?.rWidth || 0);
                    const sizeRanges = this.sizeRangeService.getSizeRange(rangeId, width, conf?.Height, true, steps);
                    validators = [];
                    if (sizeRanges?.width){
                        hintParams = {
                            min: this.formatUnit(sizeRanges?.width?.minX),
                            max: this.formatUnit(sizeRanges?.width?.maxX),
                            step: this.formatUnit(steps.width),
                        };
                        step = steps.width;
                    }
                } else if (f.field === 'customHeight') {
                    const rangeId = this.sizesService.getSizeRangeId(this.doorSizes);
                    const passiveSash = conf.Sashes.find(sash => sash.type.passive);
                    const width = conf?.Width - (conf?.doorSizes?.leftLightSize || 0) - (conf?.doorSizes?.rightLightSize || 0) - (passiveSash?.rWidth || 0);
                    const sizeRanges = this.sizeRangeService.getSizeRange(rangeId, width, conf?.Height, true, steps);
                    validators = [];
                    hintParams = {
                        min: this.formatUnit(sizeRanges?.height?.minY),
                        max: this.formatUnit(sizeRanges?.height?.maxY),
                        step: this.formatUnit(steps.height),
                    };
                    step = steps.height;
                }
                return {
                    type: f.type,
                    options,
                    disabled,
                    name: f.name,
                    field: f.field,
                    step,
                    validators,
                    hintParams,
                    highlight: f.highlight || f.field,
                };
            })
            .filter(field => field.field && (field.options?.length === 0 || !this.hiddenSelectionsWithoutChange || field.options?.length > 1));

        fields.forEach(field => {
            group.addControl(
                field.field,
                this.fb.control(doorSizes[field.field], {
                    updateOn: 'blur',
                    validators: field.validators
                })
            );
        });
        if (this.doorSizes.customSize) {
            group.setValidators(this.sizeRangeMatchValidator().bind(this));
        }
        this.dimensionsForm = group;

        this.subscriptions.push(
            this.dimensionsForm.valueChanges.subscribe((value: DoorSizes) => {
                const newDoorSizes: DoorSizes = {
                    ...doorSizes,
                    ...value,
                };
                this.setTempShapeDimensions(
                    newDoorSizes,
                    value.leftLightSizeId
                    && value.leftLightSizeId !== doorSizes.leftLightSizeId,
                    value.rightLightSizeId
                    && value.rightLightSizeId !== doorSizes.rightLightSizeId,
                    value.topLightSizeId && value.topLightSizeId !== doorSizes.topLightSizeId,
                    value.sashSizeId && value.sashSizeId !== doorSizes.sashSizeId,
                );
                if (this.dimensionsForm.valid && this.dimensionsService.valid(this.shape)) {
                    this.resizeLayout();
                    this.fillingValidationService.valid();
                    this.eventBusService.post({
                        key: 'icc-redraw',
                        value: {},
                    });
                    this.generateFields(conf.doorSizes);
                } else {
                    this.setErrorsForDoorLights();
                }
            }),
        );

        const frameShorteningControl = this.dimensionsForm.get('frameShortening');
        if (frameShorteningControl) {
            this.subscriptions.push(
                this.dimensionsForm.get('shortening').valueChanges.subscribe(v => {
                    this.rebuildFrameShorteningOptions(v);
                })
            )
        }

        this.fields = fields;
    }

    resizeLayout() {
        const conf = this.configurationsService.conf.Current;
        const oldWidth = conf.Width;
        const oldHeight = conf.Height;

        this.setSizesService.setSizes(this.doorSizes, conf, false);
        this.dimensionsService.setDimensions(this.shape);
        this.resizeService.resizeLayout(conf.Width - oldWidth, conf.Height - oldHeight, conf);
        this.eventBusService.post({
            key: 'changedDoorSizes',
            value: {},
        });
        this.dimensionsService.validDoorModelDimensions(this.shape);
        this.profilesService.setDefaultFrames(conf);
        if (!this.config().IccConfig.Configurators.roller_shutter.extraDimensionsOptions) {
            this.schemasService.setShuttersBySchema({
                width: this.shape.width,
                height: this.shape.height,
                nextStep: false,
            });
        }

        if (this.config().IccConfig.Configurators.dependencies) {
            this.eventBusService.post({ key: 'processDependencies', value: null });
        }
        conf.ChangedDimensions = false;
    }

    buildShorteningOptions() {
        const shorteningRange = this.sizesService.getSashesShorteningRange();
        return shorteningRange.map(i => ({ name: i,value: i }));
    }

    buildFrameShorteningOptions(shortening?: number) {
        const options = [];
        if (shortening > 0) {
            options.push(shortening);
        }
        options.push(0)
        return options
            .map(i => ({
                name: i,
                value: i,
            }));
    }

    rebuildFrameShorteningOptions(shortening?: number) {
        const options = this.buildFrameShorteningOptions(shortening);
        const frameShorteningField = this.fields.find(f => f.name === 'frameShortening');
        if (frameShorteningField) {
            frameShorteningField.options = options;
            frameShorteningField.disabled = options.length < 2; // Disable field
            this.dimensionsForm.get('frameShortening').setValue(options[0].value);
        }
    }

    // eslint-disable-next-line max-statements
    setTempShapeDimensions(
        doorSizes: DoorSizes,
        changedLeftLightSizeId: boolean,
        changedRightLightSizeId: boolean,
        changedTopLightSizeId: boolean,
        changedSashSizeId: boolean,
        conf = this.configurationsService.conf.Current
    ) {
        if (!conf.System.seperate_frame_and_sash_shortening && doorSizes.shortening) {
            doorSizes.frameShortening = doorSizes.shortening;
        }

        const invalidLeftLightSizeAfterChangedSash = (changedSashSizeId && this.dimensionsForm.get('leftLightSize')?.status === 'INVALID');
        if (changedLeftLightSizeId && doorSizes.leftLightSizeId || invalidLeftLightSizeAfterChangedSash) {
            const size = this.sideLightsSizes.find(s => s.id === doorSizes.leftLightSizeId);
            if (doorSizes.leftLightSize || invalidLeftLightSizeAfterChangedSash) {
                doorSizes.leftLightSize = size.size_from;
            }
        }

        const invalidRightLightSizeAfterChangedSash = (changedSashSizeId && this.dimensionsForm.get('rightLightSize')?.status === 'INVALID');
        if (changedRightLightSizeId && doorSizes.rightLightSizeId || invalidRightLightSizeAfterChangedSash) {
            const size = this.sideLightsSizes.find(s => s.id === doorSizes.rightLightSizeId);
            if (doorSizes.rightLightSize || invalidRightLightSizeAfterChangedSash) {
                doorSizes.rightLightSize = size.size_from;
            }
        }

        const invalidTopLightSizeAfterChangedSash = (changedSashSizeId && this.dimensionsForm.get('topLightSize')?.status === 'INVALID');
        if (changedTopLightSizeId && doorSizes.topLightSizeId || invalidTopLightSizeAfterChangedSash) {
            const size = this.topLightsSizes.find(s => s.id === doorSizes.topLightSizeId);
            if (doorSizes.topLightSize || invalidTopLightSizeAfterChangedSash) {
                doorSizes.topLightSize = size.size_from;
            }
        }

        if (changedSashSizeId && doorSizes.sashSizeId) {
            const size = this.sashSizes.find(s => s.id === doorSizes.sashSizeId);
            const oldSize = this.sashSizes.find(s => s.id === conf.doorSizes.sashSizeId);
            doorSizes.sizeIntervalCustomWidth = size.size_interval_custom_width || 1;
            doorSizes.sizeIntervalHeight = size.size_interval_height || 1;
            if (size?.custom_height_availability) {
                doorSizes.customSize = true;
            } else {
                doorSizes.customSize = size.custom;
            }
            if (size.sizes[conf.ProfileSet.sash]?.sash_width > 0) {
                doorSizes.customWidth = size.sizes[conf.ProfileSet.sash].sash_width || 0
            } else {
                doorSizes.customWidth = size.default_width || 0;
            }

            if (size?.custom_height_availability && size?.standard_default_height) {
                if(doorSizes.isStandardHeightSelected || oldSize.default_height === doorSizes.customHeight) {
                    doorSizes.customHeight = this.getStandardHeightSize(size);
                }
            } else {
                const isStandardHeightSelected = this.sizesService.isStandardHeightSelected(size, conf);

                const sashHeight = size.sizes[conf.ProfileSet.sash].sash_height || 0;
                if (size?.custom &&  sashHeight > 0) {
                    if (isStandardHeightSelected) {
                        doorSizes.customHeight = this.sizesService.getSashHeightInRebate(sashHeight, conf);
                    } else {
                        doorSizes.customHeight = sashHeight;
                    }
                } else if (
                    doorSizes.customSize &&
                    sashHeight > 0 &&
                    isStandardHeightSelected
                ) {
                    if (isStandardHeightSelected) {
                        doorSizes.customHeight = this.sizesService.getSashHeightInRebate(sashHeight, conf);
                    } else {
                        doorSizes.customHeight = sashHeight;
                    }
                } else {
                    doorSizes.customHeight = size?.default_height || 0;
                }
            }
            this.generateFields(doorSizes);
        }
        this.doorSizes = doorSizes;
        this.shape = this.sizesService.getShapeFromDoorSizes(
            doorSizes,
            this.configurationsService.conf.Current
        );
        this.dimensionsService.changedDimensions();
        this.setErrorsForDoorLights();
    }

    private setErrorsForDoorLights() {
        ['rightLightSize', 'leftLightSize', 'topLightSize'].forEach(size => {
            const hintParams = this.fields.find(k => k.name === size)?.hintParams;

            if (hintParams) {
                const min = Number(hintParams.min.replace(/\D/g, ''));
                const max = Number(hintParams.max.replace(/\D/g, ''));
                const step = Number(hintParams.step.replace(/\D/g, ''));

                if (!core.isValueInRangeWithIncludedStepValue(min, max, step, this.dimensionsForm.get(size).value)) {
                    this.dimensionsForm.get(size).setErrors({
                        range: {
                            min: this.formatUnit(min),
                            max: this.formatUnit(max),
                            step: this.formatUnit(step),
                        },
                    });
                }
            }
        });
    }

    getStandardHeightSize(size: IccDoorSize, conf = this.configurationsService.conf.Current) {
        return size.sizes[conf.ProfileSet.sash].sash_height || 0;
    }

    changeFrame() {
        const conf = this.configurationsService.conf.Current;
        const defaultConf = this.configurationsService.conf.Default;
        const frame = conf.Frames.find(f => f.lowThreshold) || conf.Frames[0];
        this.profilesModalService.changeProfile(conf, defaultConf, 'frame', {
            position: 'allFrames',
            frame,
        });
    }

    changeThreshold() {
        const conf = this.configurationsService.conf.Current;
        const threshold = this.profilesService.getProfile(this.profilesService.getUsedThresholdId(conf));
        this.profilesModalService
            .openProfilesModal(
                conf,
                'threshold',
                {
                    and: [],
                    not: [],
                },
                threshold
            )
            .then(profile => {
                if (profile) {
                    this.thresholdsService.changeLowThreshold(
                        profile.id,
                        this.configurationsService.conf.Current
                    );
                }
            });
    }

    changeExtensions() {
        this.modalService.open({
            pageComponent: ExtensionsPageComponent,
        });
    }

    anyAvailableExtensions(): boolean {
        const profiles = this.profilesService.getFilteredProfiles(this.configurationsService.conf.Current, ['extension', 'sandwich'], [{}], false);
        return profiles && Array.isArray(profiles) && profiles.length > 0;
    }

    private formatUnit(value: number) {
        return unitMMFormatter(value, this.config().IccConfig, 1, 0, this.dimensionUnit.unit);
    }

    static step(step: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null || step == null) {
                return null; // don't validate empty values to allow optional controls
            }
            const value = parseFloat(control.value);
            // Controls with NaN values after parsing should be treated as not having a
            // minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
            return !isNaN(value) && value % step !== 0
                ? { step: { step: step, actual: control.value } }
                : null;
        };
    }

    sizeRangeMatchValidator(conf = this.configurationsService.conf.Current): ValidatorFn {
        return (form: FormGroup): ValidationErrors | null => {
            const heightControl = form.get('customHeight');
            const widthControl = form.get('customWidth');
            const selectedSashSizeId = form.get('sashSizeId')?.value || conf.doorSizes.sashSizeId;
            const id = this.sizesService.getSizeRangeId(this.doorSizes);
            const doorSizes = this.sizesService.getSashSizeById(id);
            if(Number(selectedSashSizeId) !== Number(id)) {
                const currentSashSize = this.sashSizes.find(sashSize => Number(sashSize.id) === Number(this.doorSizes.sashSizeId));
                const selectedSashSize = this.sashSizes.find(sashSize => Number(sashSize.id) === Number(selectedSashSizeId));
                if(currentSashSize.custom && !selectedSashSize.custom && this.isStandardHeightCheckboxAvailable(selectedSashSizeId)) {
                    conf.doorSizes.isStandardHeightSelected = true

                }
            }
            const steps = {
                height: this.doorSizes?.sizeIntervalHeight || 1,
                width: this.doorSizes?.sizeIntervalCustomWidth || 1,
            }
            const passiveSash = conf.Sashes.find(sash => sash.type.passive);
            const constructionWidth = conf?.Width - (conf?.doorSizes?.leftLightSize || 0) - (conf?.doorSizes?.rightLightSize || 0) - (passiveSash?.rWidth || 0);

            const width = widthControl?.value ?? constructionWidth;

            const height =
                conf?.type === 'door' && conf?.doorSizes?.isStandardHeightSelected
                    ? Number(
                          doorSizes.sizes.reduce((prev, curr) =>
                              prev.height < curr.height ? prev : curr
                          ).height
                      )
                    : heightControl?.value ?? conf?.Height;

            const sizeRanges = this.sizeRangeService.getSizeRange(id, width, height, false, steps);

            if (
                (sizeRanges && !sizeRanges?.isValid) ||
                (heightControl && 'value' in heightControl && heightControl.value === null) ||
                (widthControl && 'value' in widthControl && widthControl.value === null)
            ) {
                if (sizeRanges?.width && !conf?.doorSizes?.isStandardWidthSelected) {
                    widthControl?.setErrors({
                        range: {
                            min: this.formatUnit(sizeRanges?.width?.minX),
                            max: this.formatUnit(sizeRanges?.width?.maxX),
                            step: this.formatUnit(steps.width),
                        },
                    });
                }

                if (sizeRanges?.height && !conf?.doorSizes?.isStandardHeightSelected) {
                    heightControl?.setErrors({
                        range: {
                            min: this.formatUnit(sizeRanges?.height?.minY),
                            max: this.formatUnit(sizeRanges?.height?.maxY),
                            step: this.formatUnit(steps.height),
                        },
                    });
                }

                if (
                    (sizeRanges?.width && !conf?.doorSizes?.isStandardWidthSelected) ||
                    (sizeRanges?.height && !conf?.doorSizes?.isStandardHeightSelected)
                ) {
                    return { range: true };
                }
            }

            heightControl?.setErrors(null);
            widthControl?.setErrors(null);
            return null;
        };
    }

    round(value: number, step: number) {
        return Math.ceil(value / step) * step;
    }

    toggleStandardHeightField(event: MatCheckbox, conf = this.configurationsService.conf.Current) {
        if (event.checked) {
            conf.doorSizes.isStandardHeightSelected = true;
            const size = this.sashSizes.find((s) => s.id === conf.doorSizes.sashSizeId);
            if (size?.custom_height_availability) {
                const sashHeight = size?.sizes[conf.ProfileSet.sash].sash_height || 0;
                if (sashHeight === 0) {
                    conf.doorSizes.customHeight = size?.default_height;
                } else {
                    if (conf.doorSizes.isStandardHeightSelected) {
                        conf.doorSizes.customHeight = this.sizesService.getSashHeightInRebate(sashHeight, conf);
                    } else {
                        conf.doorSizes.customHeight = sashHeight;
                    }
                }
            }
            this.doorSizes = conf.doorSizes;
            this.shape = this.sizesService.getShapeFromDoorSizes(
                conf.doorSizes,
                this.configurationsService.conf.Current
            );
            this.dimensionsService.changedDimensions();
            this.resizeLayout();
            this.fillingValidationService.valid();
            this.eventBusService.post({
                key: 'icc-redraw',
                value: {},
            });
            this.generateFields(conf.doorSizes);

        } else {
            this.doorSizes.customHeight = this.sashSizes.find(s => s.id === conf.doorSizes?.sashSizeId)?.default_height || 0;
            conf.doorSizes.isStandardHeightSelected = false;
            this.resizeLayout();
            this.generateFields(conf.doorSizes);
        }
    }

    getMin(field) {
        const min = field?.hintParams?.min;

        if (!min) return;

        return this.getNumber(min);
    }

    getMax(field) {
        const max = field?.hintParams?.max;

        if (!max) return;

        return this.getNumber(max);
    }

    getNumber(input) {
        if (!input) {
            return;
        }
        const value = input.match(/[0-9]+/g);
        if(!value || value.length === 0 || isNaN(value[0])) {
            return
        }
        return input.match(/[0-9]+/g)[0];
    }

    isCustomHeightAvailable() {
        const sash = this.sashSizes.find((s) => s.id === this.doorSizes.sashSizeId);
        if (sash?.custom_height_availability) {
            return (
                !this.doorSizes.isStandardHeightSelected ||
                (sash?.custom_height_availability &&
                    !sash?.standard_default_height &&
                    !sash?.default_height_availability)
            );
        }
    }

    isStandardHeightCheckboxAvailable(sashSizeId = null) {
        const sash = sashSizeId ?
                    this.sashSizes.find((s) => Number(s.id) === Number(sashSizeId))
                    : this.sashSizes.find((s) => s.id === this.doorSizes.sashSizeId);
        return sash?.custom_height_availability && (sash?.standard_default_height || sash.default_height_availability);
    }


    isStandardHeightSelected() {
        return this.doorSizes.isStandardHeightSelected;
    }

    isCustomWidthAvailable(conf = this.configurationsService.conf.Current) {
        if (this.doorSizes.sashSizeId) {
            const size = this.sashSizes.find((s) => s.id === this.doorSizes.sashSizeId);
            if (size?.custom) {
                this.setSizesService.setStandardWidthSelected(!size.custom, conf);
                this.doorSizes.isStandardWidthSelected = !size.custom;
                return size.custom;
            }
        }
        return false;
    }

    isWysiwygEditorContentAvailable(conf = this.configurationsService.conf.Current) {
        const profile = conf.UsedProfiles.find(p => p.type === 'frame');
        return profile && Boolean(profile?.wysiwygEditorContent?.length)
    }

    clickShowInfoButton(conf = this.configurationsService.conf.Current) {
        const profile = conf.UsedProfiles.find(p => p.type === 'frame');
        profile && this.modalService
            .open({
                pageComponent: ProfileInfoComponent,
                resolve: {
                    profile: () => profile,
                    button: () => this.isSelectProfileButtonInShowInfoButtonAvailable(Number(profile?.id))
                },
            });
    }

    isSelectProfileButtonInShowInfoButtonAvailable(profileId: number) {
        const isPortalAvailableInFrame = Boolean(this.profilesService.matchProfileToFrame(profileId, 'portal')?.length)
        const isCasingAvailableInFrame = Boolean(this.profilesService.matchProfileToFrame(profileId, 'casing')?.length)

        return !(isCasingAvailableInFrame || isPortalAvailableInFrame);
    }

    isFrameButton1Disabled(conf = this.configurationsService.conf.Current): boolean {
        const [type, options] = this.profilesModalService.getOptions(conf, 'frame', {
            position: 1,
            frame: conf.Frames,
            sash: null,
            mullion: null,
            extension: null,
            coupling: null
        });
        const frames = this.profilesService.getFilteredProfiles(conf, 'frame', options, false)

        const portals = this.doorPortalsService.getPortalsInAvailableFrames(conf);
        const casings = this.casingsService.getAvailableFramesWithCasings(conf);

        return (frames?.length <= 1) && (portals?.length <= 0) && (casings?.length <= 0);
    }

    isThresholdButton1Disabled(conf = this.configurationsService.conf.Current): boolean {
        const thresholds = this.profilesService.getFilteredProfiles(conf, 'threshold');
        return (thresholds?.length <= 1);
    }

    getSelectedFrame(conf = this.configurationsService.conf.Current) {
        const position = 1;
        const frame = conf.Frames[0];
        const profileId = frame.frame[position].profileId;
        return this.profilesService.getProfile(profileId);
    }

    shorteningAvailable(conf = this.configurationsService.conf.Current): boolean {
        const size = (this.sashSizes || []).find(s => s.id === conf.doorSizes.sashSizeId);
        return conf.System.shortening.length > 0 && conf.doorSizes.isStandardHeightSelected && Boolean(size?.shortening_available);
    }

    frameShorteningAvailable(conf = this.configurationsService.conf.Current): boolean {
        return conf.System.seperate_frame_and_sash_shortening && this.shorteningAvailable(conf);
    }

}
