import { ActiveConfiguration } from './ActiveConfiguration';
import { ComplementaryGoodsConfiguration } from './ComplementaryGoodsConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { TimeLimitSegment } from '@icc/common/time-limit/time-limit-functions';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { Sill, Cassonetto, SeparateFilling, SeparateProfile } from './parts/complementary_goods';
import { core } from '@icc/common/helpers';

import { ActiveAccessory } from './parts/common';

export class ComplementaryGoodsActiveConfiguration implements ActiveConfiguration {
    public static is(configuration): configuration is ComplementaryGoodsActiveConfiguration {
        return (
            configuration instanceof ComplementaryGoodsActiveConfiguration
            || !configuration.$version && configuration.type === 'complementary_goods'
        );
    }
    type: 'complementary_goods' = 'complementary_goods';
    Name: string = '';
    Price: number = 0;
    ListPrice: number = 0;
    PriceNoMargin: number = 0;
    ListPriceNoMargin: number = 0;
    PriceAfterDiscountsInCurrency: number = 0;
    ListPriceAfterDiscountsInCurrency: number = 0;
    PriceBeforePromotions: number = 0;
    ListPriceBeforePromotions: number = 0;
    PriceGross: number = 0;
    ListPriceGross: number = 0;
    PriceGrossAfterDiscounts: number = 0;
    ListPriceGrossAfterDiscounts: number = 0;
    PriceGrossBeforePromotions: number = 0;
    ListPriceGrossBeforePromotions: number = 0;
    Quantity: number = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    ListPriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    ListPriceSegmentsNoMargin: PriceSegment[] = [];
    PriceSegmentsBeforePromotions: PriceSegment[] = [];
    ListPriceSegmentsBeforePromotions: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    ListPriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    ListPricePartsNoMargin: PricePart[] = [];
    PricePartsBeforePromotions: PricePart[] = [];
    ListPricePartsBeforePromotions: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    Discount: number = 0;
    ListDiscount: number = 0;
    timeLimit: number = 0;
    timeLimitsStack: TimeLimitSegment[] = [];
    Title = '';
    Description = '';
    Attachments = [];
    valid: {} = {};
    PriceValid = false;
    drawData: any;

    ComplementaryGoods: {
        windowsill: any[],
        cassonetto: any[],
        glass: any[],
        accessory: ActiveAccessory[],
        profile: any[],
    } = {
        windowsill: [],
        cassonetto: [],
        glass: [],
        accessory: [],
        profile: [],
    };

    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (ComplementaryGoodsActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (ComplementaryGoodsConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.ListPrice = configuration.listPrice;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.ListPriceNoMargin = configuration.listPriceNoMargin;
                    this.PriceAfterDiscountsInCurrency = configuration.priceAfterDiscountsInCurrency;
                    this.ListPriceAfterDiscountsInCurrency = configuration.listPriceAfterDiscountsInCurrency;
                    this.PriceGross = configuration.priceGross;
                    this.ListPriceGross = configuration.listPriceGross;
                    this.PriceGrossAfterDiscounts = configuration.priceGrossAfterDiscounts;
                    this.ListPriceGrossAfterDiscounts = configuration.listPriceGrossAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.ListPriceSegments = configuration.listPriceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.ListPriceSegmentsNoMargin = configuration.listPriceSegmentsNoMargin;
                    this.PriceSegmentsBeforePromotions = configuration.priceSegmentsBeforePromotions;
                    this.ListPriceSegmentsBeforePromotions = configuration.listPriceSegmentsBeforePromotions;
                    this.PriceParts = configuration.priceParts;
                    this.ListPriceParts = configuration.listPriceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.ListPricePartsNoMargin = configuration.listPricePartsNoMargin;
                    this.PricePartsBeforePromotions = configuration.pricePartsBeforePromotions;
                    this.ListPricePartsBeforePromotions = configuration.listPricePartsBeforePromotions;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.Description = configuration.description;
                    this.valid = configuration.valid;
                    this.timeLimit = configuration.timeLimit;
                    this.timeLimitsStack = configuration.timeLimitsStack;
                    this.attributes = configuration.attributes;

                    this.ComplementaryGoods.windowsill = configuration.windowSills.map(
                        this.mapSill.bind(this)
                    );
                    this.ComplementaryGoods.cassonetto = configuration.cassonettos.map(
                        this.mapCassonetto.bind(this)
                    );
                    this.ComplementaryGoods.glass = configuration.fillings.map(
                        this.mapFilling.bind(this)
                    );
                    this.ComplementaryGoods.accessory = configuration.accessories.map(
                        accessory => new ActiveAccessory(accessory, null, configuratorsDataService)
                    );
                    this.ComplementaryGoods.profile = configuration.profiles.map(
                        this.mapProfile.bind(this)
                    );
                }
            }
        }
        this.configuratorsDataService = null;
    }

    private mapSill(sill: Sill) {
        const newSill = {
            count: sill.count,
            amount: sill.length,
            weight: sill.weight,
            comment: sill.comment,
            color: null,
            colorName: '',
            accessory: this.configuratorsDataService.get('windowSillsGoods', sill.id),
        };
        newSill.accessory.selectedColor = sill.color.id;
        newSill.accessory.currentWidth = sill.width;
        newSill.accessory.currentLength = sill.length;
        newSill.accessory.price = sill.price;
        if (sill.plugs) {
            newSill.accessory.plugs = Number(sill.plugs);
        }
        return newSill;
    }

    private mapCassonetto(cassonetto: Cassonetto) {
        const newCassonetto = {
            count: cassonetto.count,
            amount: cassonetto.width,
            weight: cassonetto.weight,
            color: cassonetto.color.id + '',
            colorName: cassonetto.color.name,
            accessory: this.configuratorsDataService.get('cassonettos', cassonetto.id),
        };
        newCassonetto.accessory.currentWidth = cassonetto.width;
        newCassonetto.accessory.currentWidthX = cassonetto.width;
        newCassonetto.accessory.currentHeight = cassonetto.height;
        if (cassonetto.p1) {
            newCassonetto.accessory.currentP1 = cassonetto.p1;
        }
        if (cassonetto.p2) {
            newCassonetto.accessory.currentP2 = cassonetto.p2;
        }
        if (cassonetto.l1) {
            newCassonetto.accessory.currentL1 = cassonetto.l1;
        }
        newCassonetto.accessory.colors = this.getCassonettoColors(newCassonetto.accessory);
        return newCassonetto;
    }

    /**
     * Ustawia dostępne kolory dla kasonetki
     *
     * Funkcja analogiczna do funkcji z ComplementaryGoodsService.js
     * @param {Object} cassonetto Kasonetka
     * @return {Array} Lista kolorów
     */
    private getCassonettoColors(cassonetto) {
        const windowColorsAll = this.configuratorsDataService.data.windowColorsAll;
        const colorGroupIds =
            cassonetto.pricelists && cassonetto.pricelists.map(a => a.window_color_group_id);
        return (
            (colorGroupIds
                && windowColorsAll.filter(e =>
                    colorGroupIds.find(
                        search =>
                            e.groups
                            && typeof e.groups.find(
                                windowColorGroup => Number(windowColorGroup) === Number(search)
                            ) !== 'undefined'
                    )
                ))
            || []
        );
    }

    private mapFilling(filling: SeparateFilling) {
        const newFilling = {
            count: filling.count,
            amount: filling.width,
            comment: filling.comment,
            weight: filling.filling.weight,
            color: null,
            colorName: '',
            accessory:
                core.copy(
                    this.configuratorsDataService.data.fillings.find(
                        e => e.id === filling.filling.id
                    )
                )
                || core.copy(
                    this.configuratorsDataService.data.fillings.find(
                        e => e.id.split('.')[0] === filling.filling.id
                    )
                ),
        };
        newFilling.accessory.currentWidth = filling.width;
        newFilling.accessory.sel_width = filling.width;
        newFilling.accessory.currentHeight = filling.height;
        newFilling.accessory.sel_height = filling.height;
        newFilling.accessory.warmEdge = filling.glazingSpacer.id;
        newFilling.accessory.warmEdgeName = filling.glazingSpacer.name;
        newFilling.accessory.price = filling.price;
        newFilling.accessory.comment = filling.comment;

        return newFilling;
    }

    private mapProfile(profile: SeparateProfile) {
        const newProfile = {
            count: profile.count,
            amount: profile.length,
            color: null,
            colorName: '',
            accessory: this.configuratorsDataService.get('profiles', profile.profile.id),
            comment: profile.comment,
        };
        newProfile.accessory.currentLength = profile.length;
        newProfile.accessory.price = profile.price;

        newProfile.accessory.selectedColor = {
            outer: {},
            inner: {},
            core: {},
        };

        ['outer', 'inner', 'core'].forEach(side => {
            if (profile.color.profile[side] && profile.color.profile[side].id) {
                newProfile.accessory.selectedColor[side] =
                this.configuratorsDataService.get(
                    ['outer', 'inner'].includes(side) ? 'windowColorsAll' : 'colors',
                    profile.color.profile[side].id
                );
                if(newProfile.accessory.selectedColor[side]) {
                    newProfile.accessory.selectedColor[side].isDefault =
                        profile.color.profile[side].isDefault;
                }
            }
        });
        if (profile.wood) {
            newProfile.accessory.selectedWood = this.configuratorsDataService.get(
                'woodTypes',
                profile.wood.id
            );
        }

        return newProfile;
    }
}



export type IComplementaryGoodsActiveConfiguration = Pick<
    ComplementaryGoodsActiveConfiguration,
    keyof ComplementaryGoodsActiveConfiguration
>;

