import { PricePart } from './PricePart';
import { PriceSegment } from './PriceSegment';
import * as hash from 'object-hash';
import { core } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { currencyExchange } from '@icc/helpers';

/**
 * Funkcja zwracająca cenę z segmentów
 * @param  {array}  prices  Tablica segmentów cen
 * @param  {string} type    Typ cen
 * @param  {number} sashId  Id skrzydła
 * @param  {number} isashId Id pola
 * @param  {number} divId   Id słupka
 * @return {number}         cena
 */
export function priceFromSegments(
    prices: PriceSegment[] = [],
    priceParts: PricePart[] = [],
    currency: any,
    {
        value,
        percent,
    }: {
        value?: {
            type: string;
            data?: any;
        };
        percent?: {
            type: string;
            data?: any;
        };
    },
    context: any = {},
    source = '',
    listPrice = false
) {
    if (source === 'generateProductCodes') {
        changeUnitPriceForExtensionsWhenLengthOptionEnabled(priceParts, prices, context, listPrice);
    }

    const valueSegs = prices.filter(
        seg =>
            seg
            && (value && seg.type === value.type)
            && (!value
                || !value.data
                || Object.keys(value.data).every(key => {
                    return (
                        seg.data
                        && (core.deepFind(seg.data, key) == value.data[key]
                            || (Common.isObject(core.deepFind(seg.data, key))
                                && (Common.isObject(value.data[key])
                                && hash(core.deepFind(seg.data, key))
                                    === hash(value.data[key])))
                            || Common.isArray(value.data[key])
                                && value.data[key].map(String).includes(String(core.deepFind(seg.data, key)))
                            || Common.isArray(value.data[key])
                                && Common.isArray(core.deepFind(seg.data, key))
                                && value.data[key].map(String).some(el => core.deepFind(seg.data, key).map(String).includes(el))
                        )
                    );
                }))
    );
    const percentSegs = prices.filter(
        seg =>
            seg
            && percent
            && seg.type === percent.type
            && (!percent.data
                || Object.keys(percent.data).every(
                    key => seg.data && core.deepFind(seg.data, key) === percent.data[key]
                ))
    );
    const parts = priceParts.filter(
        pp =>
            ((valueSegs.length > 0 && valueSegs.map(seg => seg.id).indexOf(pp.valueId) > -1)
                || (!value || value.type === '*'))
            && ((percentSegs.length > 0
                && percentSegs.map(seg => seg.id).indexOf(pp.percentId) > -1)
                || (!percent || percent.type === '*')
                || (percent.type === pp.percentType && percent.type === null))
    );
    return currencyExchange(parts.reduce((prev, curr) => prev + curr.value, 0), currency);
}


function changeUnitPriceForExtensionsWhenLengthOptionEnabled(
    priceParts: PricePart[],
    prices: PriceSegment[],
    context: any,
    listPrice = false
) {
    if (
        !(
            Array.isArray(priceParts) &&
            priceParts.length &&
            Array.isArray(prices) &&
            prices.length &&
            Object.keys(context)?.length
        )
    ) {
        return;
    }

    const sideProfilesPriceParts = priceParts.filter((k) => k.valueType === 'sideProfile');
    prices.forEach((price) => {
        if (
            price.type === 'sideProfile' &&
            context.configuratorsData.profiles.find((k) => k.id === price.data.profileId)?.length
        ) {
            const sideProfile = context.conf.sideProfiles.find(
                (k) => (Number(k.profileId) === Number(price.data.profileId))
            );

            const pricePiece = listPrice && sideProfile?.listPricePiece ? sideProfile?.listPricePiece : sideProfile.pricePiece;
            if (pricePiece) {
                price.baseValue = pricePiece;
                price.value = pricePiece;

                sideProfilesPriceParts.forEach((pricePart) => {
                    if (pricePart.valueId === price.id) {
                        pricePart.baseValue = pricePiece;
                        pricePart.value = pricePiece;
                    }
                });
            }
        }
    });
}

export function findInSummary(arr, value, kind: 'percent' | 'value' = 'percent') {
    if (!Array.isArray(arr)) {
        return false;
    }
    const index = kind === 'value' ? 0 : 1;

    for (const el of arr) {
        if (el[index] === value) {
            return true;
        }

        if (Array.isArray(el[index])) {
            if (el[index].includes(value)) {
                return true;
            }
        }
    }

    return false;
}
