<div class="hardware-list">
    <icc-select-box
        [title]="selectedType?.name"
        [description]="selectedType?.description"
        [imageUrl]="selectedTypeImg"
    ></icc-select-box>

    <mat-card class="mat-elevation-2" *ngIf="colors.length > 0">
        <div>
            <p>{{ 'COLOR|Wybierz kolor:' | translate }}</p>
            <icc-list [items]="colors" itemTemplate="itemColor" [scrollable]="false" [selected]="selColor" (select)="selectColor($event)">
            </icc-list>
        </div>
    </mat-card>


    <form *ngIf="cylinderKeysForm" [formGroup]="cylinderKeysForm">
        <mat-card class="mat-elevation-2" *ngIf="selectedType?.type === 'cylinder' && maxKeyQuantity > 0">
            <p *ngIf="!hiddenPrice">{{ 'DOOR|Cena za klucz dodatkowy:' | translate}} <s *ngIf="listKeyPrice && listKeyPrice !== keyPrice">{{ listKeyPrice | currency: currency }}</s> {{ keyPrice | currency: currency }}</p>
            <mat-form-field>
                <input type="number" matInput [placeholder]="'INTERFACE|Ilość kluczy dodatkowych' | translate" formControlName="key">
                <mat-error *ngIf="cylinderKeysForm.get('key').hasError('min')">
                    {{ 'DOOR|Wprowadzona wartość jest nieprawidłowa' | translate}}
                </mat-error>
                <mat-error *ngIf="cylinderKeysForm.get('key').hasError('max')">
                    {{ 'DOOR|Maksymalna ilość kluczy: ' | translate}} {{maxKeyQuantity}}
                </mat-error>
                <mat-hint>{{ 'INTERFACE|Maksymalna liczba kluczy dostępna dla wkładki:' | translate}} {{maxKeyQuantity}} </mat-hint>
            </mat-form-field>
        </mat-card>
    </form>
</div>

<div class="selected-summary mat-elevation-z4">
    <img [src]="selectedTypeImg" defaultImg>
    <p>{{ selectedType?.name }} {{getColorName()}}</p>
    <button
        [disabled]="selectedType?.type === 'cylinder' && !cylinderKeysForm.valid"
        mat-raised-button (click)="save()" color="primary"
    >
    {{ 'INTERFACE|Wybierz' | translate}}
    </button>
</div>
