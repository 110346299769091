import { Inject, Injectable } from '@angular/core';
import { CustomPricesService }  from '@icc/common/custom-price/custom-prices.service';
import { core } from '@icc/common/helpers';
import { StateService } from '@icc/common/state.service';
import { IccColor, IccProfileSideColors, ProfilesPrices } from '@icc/common/data-types';
import { ColorsDefaultsService } from './colors/colors-defaults.service';
import {APP_CONFIG, AppConfigFactory} from '@icc/common/config';
import { ProfileSideColors } from './configurations/parts/common/Colors';

@Injectable()
export class ProfilesPriceService {
    constructor(
        private colorsDefaultsService: ColorsDefaultsService,
        private stateService: StateService,
        private customPricesService: CustomPricesService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    // eslint-disable-next-line max-params
    // eslint-disable-next-line max-statements
    getProfilePrice(
        profileId: number,
        type: string,
        system: { id: string | number; type: string } | null,
        colors: IccProfileSideColors,
        prices: ProfilesPrices,
        customPrice?: any,
        pricesForLength?: any,
        profileLength?: number,
        colorFactor?: number
    ) {
        if (
            !prices
            || !prices[profileId]
            || !((system && prices[profileId][system.id]) || prices[profileId].default)
            || !((system && prices[profileId][system.id]) || prices[profileId].default)[type]
            || (system && !system.id)
            || !colors
        ) {
            return null;
        }

        if (!customPrice) {
            const offer = this.stateService.getCurrentOffer();
            const dealerId = offer ? offer.dealer_id : null;
            const customPrices = this.customPricesService.get(dealerId);
            customPrice = customPrices && customPrices.Profile ? customPrices.Profile : null;
        }
        const profileLengthPrices =
            this.config().IccConfig.Configurators.extensionPricesForLength &&
            profileLength > 0 &&
            pricesForLength[profileId]?.find(
                (prices) => profileLength >= prices.length_from && profileLength <= prices.length_to
            )?.prices;
        const profilePrices = (profileLengthPrices ||
            (system && prices[profileId][system.id]) ||
            prices[profileId].default)[type];
        let colorGroups = [];
        let colorGroupsOut = [];
        let side = 'double';

        if (colors.inner?.id && !colors.inner?.isCore && colors.outer?.isCore) {
            colorGroups = colors.inner.groups.map(Number);
            side = 'inner';
        } else if (colors.outer?.id && !colors.outer?.isCore && colors.inner?.isCore) {
            colorGroups = colors.outer.groups.map(Number);
            side = 'outer';
        } else if (colors.inner?.groups && colors.outer?.groups && colors.inner.id === colors.outer.id) {
            colorGroups = colors.inner.groups.map(Number);
        } else if (colors.inner?.groups && colors.outer?.groups && colors.inner.id !== colors.outer.id) {
            colorGroups = colors.inner.groups.map(Number);
            colorGroupsOut = colors.outer.groups.map(Number);
            side = 'bicolor';
        } else {
            return null;
        }

        let profilePrice = profilePrices
            .map((p, index) => {
                p.id = index;
                return p;
            })
            .filter(
                (p) =>
                    colorGroups.indexOf(Number(p.colorGroup)) > -1 &&
                    (side !== 'bicolor' ||
                        (side === 'bicolor' &&
                            colorGroupsOut.indexOf(Number(p.colorGroupOut)) > -1)) &&
                    (p.side === side ||
                        (p.side === 'single' && (['outer', 'inner'] as any).includes(side)))
            )[0];
        if (profilePrice) {
            profilePrice = {
                ...profilePrice,
            };
        }
        if (profilePrice && customPrice && customPrice[profileId]) {
            const customPriceProfile = customPrice[profileId].fields
                .map((c) => {
                    c.json = core.parseJson(c.json);
                    ['price_construction', 'price_length', 'price_piece'].forEach((field) => {
                        if (c.json[field] != null) {
                            c.json[field] = c.price;
                        }
                    });
                    return c;
                })
                .filter(
                    (c) =>
                        c.json.colorGroup === profilePrice.colorGroup &&
                        c.json.colorGroupOut === profilePrice.colorGroupOut &&
                        c.json.side === profilePrice.side &&
                        c.json.profileType === type
                );
            const defaultCustomPrices = customPriceProfile.filter(
                (c) => c.field === 'default_prices' && c.relation === null
            );
            const systemCustomPrices = system
                ? customPriceProfile.filter(
                      (c) => c.field === 'WindowLine' && c.relation === Number(system.id)
                  )
                : [];
            defaultCustomPrices.forEach((c) => {
                ['price_construction', 'price_length', 'price_piece'].forEach((field) => {
                    if (c.json[field] != null) {
                        profilePrice[field] = c.json[field];
                    }
                });
            });
            systemCustomPrices.forEach((c) => {
                ['price_construction', 'price_length', 'price_piece'].forEach((field) => {
                    if (c.json[field] != null) {
                        profilePrice[field] = c.json[field];
                    }
                });
            });
        }

        if (profilePrice) {
            const pricesFields = [
                'price_construction',
                'price_piece',
                'price_length',
                'price_field',
                'price_percent',
                'price_sash',
                'price_two_sashes',
                'price_area',
                'list_price_construction',
                'list_price_piece',
                'list_price_length',
                'list_price_field',
                'list_price_percent',
                'list_price_sash',
                'list_price_two_sashes',
                'list_price_area',
            ];
            Object.keys(profilePrice).forEach((key) => {
                if (pricesFields.includes(key) && profilePrice[key] != null) {
                    profilePrice[key] = parseFloat(profilePrice[key]);

                    if (colorFactor) {
                        profilePrice[key] = profilePrice[key] * (colorFactor / 100 + 1);
                    }
                }
            });

            return profilePrice;
        }
        return null;
    }

    getProfileAlushellPrice(profileId, type, hasAlushell, alushells, colors: IccProfileSideColors, prices, customPrice) {
        if (!alushells || alushells.length === 0) {
            return null;
        }
        if (!hasAlushell) {
            return {
                price_length: 0,
                price_piece: 0,
            };
        }
        const alushell = alushells[0];
        if (
            !prices
            || !prices[profileId]
            || !prices[profileId][alushell.id]
            || !prices[profileId][alushell.id][type]
            || !colors
            || !colors.alushell
            || !colors.alushell.colorGroups?.length
            || !alushell
            || !alushell.id
        ) {
            return null;
        }
        const profilePrices = prices[profileId][alushell.id][type];
        let colorGroups = [];
        colorGroups = colors.alushell.colorGroups.map(Number);
        const side = 'outer';
        const profilePrice = profilePrices
            .map((p, index) => {
                p.id = index;
                return p;
            })
            .filter((p) => colorGroups.indexOf(Number(p.colorGroup)) > -1 && p.side === side)[0];

        if (profilePrice && customPrice && customPrice[profileId]) {
            const customPriceProfile = customPrice[profileId].fields
                .map((c) => {
                    c.json = core.parseJson(c.json);
                    ['price_length', 'price_piece'].forEach((field) => {
                        if (c.json[field] != null) {
                            c.json[field] = c.price;
                        }
                    });
                    return c;
                })
                .filter(
                    (c) =>
                        c.json.colorGroup === profilePrice.colorGroup &&
                        c.json.colorGroupOut === profilePrice.colorGroupOut &&
                        c.json.side === profilePrice.side &&
                        c.json.profileType === type
                );
            const alushellCustomPrices = customPriceProfile.filter(
                (c) => c.field === 'Alushell' && c.relation === Number(alushell.id)
            );
            alushellCustomPrices.forEach((c) => {
                ['price_length', 'price_piece'].forEach((field) => {
                    if (c.json[field] != null) {
                        profilePrice[field] = c.json[field];
                    }
                });
            });
        }

        if (profilePrice) {
            const pricesFields = [
                'price_construction',
                'price_piece',
                'price_length',
                'price_field',
                'price_percent',
                'price_sash',
                'price_two_sashes',
                'list_price_construction',
                'list_price_piece',
                'list_price_length',
                'list_price_field',
                'list_price_percent',
                'list_price_sash',
                'list_price_two_sashes',
            ];
            Object.keys(profilePrice).map((key) =>
                pricesFields.includes(key)
                    ? (profilePrice[key] = parseFloat(profilePrice[key]) || 0)
                    : null
            );

            return profilePrice;
        }
        return null;
    }

    getThresholdPrice(
        profileId,
        type,
        system,
        prices,
        customPrice?,
        pricesForLength?,
        thresholdLength?: number
    ) {
        if (
            !prices[profileId] ||
            !prices[profileId][system.id] ||
            !prices[profileId][system.id][type] ||
            !system ||
            !system.id
        ) {
            return null;
        }

        const thresholdPricesForLength =
            pricesForLength &&
            Array.isArray(pricesForLength[profileId]) &&
            pricesForLength[profileId].find(
                (prices) =>
                    thresholdLength >= prices.length_from && thresholdLength <= prices.length_to
            )?.prices;
        const profilePrice = (thresholdPricesForLength
            ? thresholdPricesForLength
            : prices[profileId][system.id])[type][0];

        if (profilePrice && customPrice && customPrice[profileId]) {
            const customPriceProfile = customPrice[profileId].fields
                .map((c) => {
                    c.json = core.parseJson(c.json);
                    ['price_construction', 'price_length', 'price_piece'].forEach((field) => {
                        if (c.json[field] != null) {
                            c.json[field] = c.price;
                        }
                    });
                    return c;
                })
                .filter((c) => c.json.side === profilePrice.side && c.json.profileType === type);
            const defaultCustomPrices = customPriceProfile.filter(
                (c) => c.field === 'default_prices' && c.relation === null
            );
            const systemCustomPrices = customPriceProfile.filter(
                (c) => c.field === 'WindowLine' && c.relation === Number(system.id)
            );
            defaultCustomPrices.forEach((c) => {
                ['price_construction', 'price_length', 'price_piece'].forEach((field) => {
                    if (c.json[field] != null) {
                        profilePrice[field] = c.json[field];
                    }
                });
            });
            systemCustomPrices.forEach((c) => {
                ['price_construction', 'price_length', 'price_piece'].forEach((field) => {
                    if (c.json[field] != null) {
                        profilePrice[field] = c.json[field];
                    }
                });
            });
        }

        if (profilePrice) {
            const pricesFields = [
                'price_construction',
                'price_piece',
                'price_length',
                'price_field',
                'price_percent',
                'price_sash',
                'price_two_sashes',
                'list_price_construction',
                'list_price_piece',
                'list_price_length',
                'list_price_field',
                'list_price_percent',
                'list_price_sash',
                'list_price_two_sashes',
            ];
            Object.keys(profilePrice).map((key) =>
                pricesFields.includes(key)
                    ? (profilePrice[key] = parseFloat(profilePrice[key]) || 0)
                    : null
            );

            return profilePrice;
        }
        return null;
    }

    // eslint-disable-next-line max-statements
    getLippingProfilePrice(profileId, system, colors: {inner: IccColor, outer: IccColor}, prices) {
        if (
            !prices
            || !prices[profileId]
            || !((system && prices[profileId][system.id]) || prices[profileId].default)
            || !((system && prices[profileId][system.id]) || prices[profileId].default).lipping
            || (system && !system.id)
            || !colors.inner
        ) {
            return null;
        }

        const profilePrices = ((system && prices[profileId][system.id]) || prices[profileId].default).lipping;
        let colorGroups = [];
        let colorGroupsOut = [];
        let side = 'double';

        if (colors.inner?.id && !colors.inner?.isCore && colors.outer?.isCore) {
            colorGroups = colors.inner.colorGroups.map(Number);
            side = 'inner';
        } else if (colors.outer?.id && !colors.outer?.isCore && colors.inner?.isCore) {
            colorGroups = colors.outer.colorGroups.map(Number);
            side = 'outer';
        } else if (colors.inner?.colorGroups && colors.outer?.colorGroups && colors.inner.id === colors.outer.id) {
            colorGroups = colors.inner.colorGroups.map(Number);
        } else if (colors.inner?.colorGroups && colors.outer?.colorGroups && colors.inner.id !== colors.outer.id) {
            colorGroups = colors.inner.colorGroups.map(Number);
            colorGroupsOut = colors.outer.colorGroups.map(Number);
            side = 'bicolor';
        } else {
            return null;
        }

        let profilePrice = profilePrices
            .map((p, index) => {
                p.id = index;
                return p;
            })
            .filter(
                (p) =>
                    colorGroups.indexOf(Number(p.colorGroup)) > -1 &&
                    (side !== 'bicolor' ||
                        (side === 'bicolor' &&
                            colorGroupsOut.indexOf(Number(p.colorGroupOut)) > -1)) &&
                    (p.side === side ||
                        (p.side === 'single' && (['outer', 'inner'] as any).includes(side)))
            )[0];
        if (profilePrice) {
            profilePrice = {
                ...profilePrice,
            };
        }

        if (profilePrice) {
            const pricesFields = [
                'price_construction',
                'list_price_construction',
            ];
            Object.keys(profilePrice).forEach((key) => {
                if (pricesFields.includes(key) && profilePrice[key] != null) {
                    profilePrice[key] = parseFloat(profilePrice[key]);
                }
            });

            return profilePrice;
        }
        return null;
    }

    getRollerGuidePrice(profileId) {
        return null;
    }
}
