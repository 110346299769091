import { PriceSegment } from '@icc/price/b2b';
import { Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { Common } from '@icc/common/Common';
import { Promotion } from '@icc/common/data-types';
import { core } from '@icc/common/helpers';

@Injectable()
export abstract class DiscountsAndMultipliersService {
    public loaded = false;
    public multipliers: any;
    protected offerDiscounts: any;
    protected buyDiscounts: any;
    protected saleDiscounts: any;
    protected unavailableSystems: any = [];
    protected config: AppConfigFactory;

    abstract load();

    abstract reload();

    async getOfferDiscountAsync() {
        if (!this.loaded) {
            await this.load();
        }
        return this.offerDiscounts;
    }

    getOfferDiscountSync() {
        return this.offerDiscounts;
    }

    getMultipliers() {
        return this.multipliers;
    }

    getBuyDiscounts() {
        return this.buyDiscounts;
    }

    getSaleDiscounts() {
        return this.saleDiscounts;
    }

    getUnavailableSystems() {
        return this.unavailableSystems;
    }

    getDiscountGroups(priceStack: PriceSegment[]) {
        const systemSeg = priceStack.find(s => s && s.type === 'system');
        const discountGroups = priceStack
            .filter(segment => segment && segment.valueType === 'value')
            .reduce((prev, segment) => {
                if (segment.type === 'coupledWindow') {
                    const coupledPriceStack = segment.data.priceStack;
                    const coupledDiscountGroups = this.getDiscountGroups(coupledPriceStack);
                    Object.entries(coupledDiscountGroups).forEach(group => {
                        if (Common.isUndefined(prev[group[0]])) {
                            prev[group[0]] = 0;
                        }
                        if (prev[group[0]] !== null && !isNaN(parseFloat(group[1] as any))) {
                            prev[group[0]] += parseFloat(group[1] as any);
                        } else {
                            prev[group[0]] = null;
                        }
                    });
                } else {
                    this.segmentToDiscountGroup(segment, systemSeg, prev);
                }
                return prev;
            }, {});
        return discountGroups;
    }

    suppMultipliers(
        priceStack: PriceSegment[],
        type: string,
        extendId,
        multipliers = this.multipliers,
        dealerId?
    ) {
        multipliers = multipliers || this.multipliers;
        if (
            (this.config().IccConfig.Dealer.systemMultipliers
                || this.config().IccConfig.Dealer.systemMultipliersAndSystemDiscounts)
            && Common.isObject(multipliers)
            && !Common.isArray(multipliers)
        ) {
            const multipliersSource = multipliers[dealerId] || multipliers;
            if (this.config().IccConfig.Dealer.surchargeMultipliers) {
                if (type === 'accessory' || type === 'complementary_goods') {
                    priceStack.push(
                        ...extendId([
                            {
                                type: 'commodityMultiplier',
                                baseValue:
                                    (multipliersSource.Commodity && multipliersSource.Commodity[0])
                                    || 1,
                                value:
                                    (multipliersSource.Commodity && multipliersSource.Commodity[0])
                                    || 1,
                                valueType: 'multiplier',
                                data: {},
                            },
                        ])
                    );
                } else {
                    const systemSeg = priceStack.filter(s => s && s.type === 'system');
                    if (systemSeg.length > 0 && systemSeg[0].data) {
                        priceStack.push(
                            ...extendId([
                                {
                                    type: 'systemMultiplier',
                                    baseValue:
                                        (multipliersSource.WindowLine
                                            && multipliersSource.WindowLine[systemSeg[0].data.id])
                                        || 1,
                                    value:
                                        (multipliersSource.WindowLine
                                            && multipliersSource.WindowLine[systemSeg[0].data.id])
                                        || 1,
                                    valueType: 'multiplier',
                                    data: {},
                                },
                            ])
                        );
                    }

                    priceStack.push(
                        ...extendId([
                            {
                                type: 'supplementMultiplier',
                                baseValue:
                                    (multipliersSource.Supplement
                                        && multipliersSource.Supplement[0])
                                    || 1,
                                value:
                                    (multipliersSource.Supplement
                                        && multipliersSource.Supplement[0])
                                    || 1,
                                valueType: 'multiplier',
                                data: {},
                            },
                        ])
                    );
                }
            }
            const rollerSeg = priceStack.filter(s => s && s.type === 'rollerBoxes');
            if (rollerSeg.length > 0 && rollerSeg[0].data) {
                priceStack.push(
                    ...extendId([
                        {
                            type: 'rollerMultiplier',
                            baseValue:
                                (multipliersSource.WindowLine
                                    && multipliersSource.WindowLine[rollerSeg[0].data.type])
                                || 1,
                            value:
                                (multipliersSource.WindowLine
                                    && multipliersSource.WindowLine[rollerSeg[0].data.type])
                                || 1,
                            valueType: 'multiplier',
                            data: {},
                        },
                    ])
                );
            }

            const mosquitoSegments = priceStack.filter(s => s && s.type.includes('mosquito'));
            const mosquitoSystemsIds = mosquitoSegments.map(el => el?.data?.systemId).filter((v, i, a) => v && a.indexOf(v) === i);
            if (mosquitoSystemsIds.length) {
                mosquitoSystemsIds.forEach(systemId => {
                    priceStack.push(
                        ...extendId([
                            {
                                type: 'mosquitoMultiplier',
                                baseValue:
                                    (multipliersSource.WindowLine
                                        && multipliersSource.WindowLine[systemId])
                                    || 1,
                                value:
                                    (multipliersSource.WindowLine
                                        && multipliersSource.WindowLine[systemId])
                                    || 1,
                                valueType: 'multiplier',
                                data: {
                                    systemId
                                },
                                to: mosquitoSegments.filter(el => el?.data?.systemId === systemId).map(el => el.id)
                            },
                        ])
                    );
                })
            }
        }
    }

    suppPromotions(priceStack: PriceSegment[], extendId, promotions: Promotion[]) {
        const systemSeg = priceStack.find(s => s && s.type === 'system' && s.data?.id);

        if (!systemSeg?.data?.id) {
            return;
        }

        const newFillingsPrices: Record<number, number> = {};

        for (const promotion of promotions) {
            const dateFrom = new Date(promotion.date_from);
            dateFrom.setHours(0, 0, 0);
            const dateTo = new Date(promotion.date_to);
            dateTo.setHours(23, 59, 59);
            const now = new Date();

            if (now < dateFrom || now > dateTo) {
                continue;
            }

            if (promotion.model === 'WindowLine') {
                const sashSegs = priceStack.filter(s => s && s.type === 'sashes');
                if (sashSegs.length > 0 && promotion.record && promotion.record.includes(Number(systemSeg.data.id))) {
                    priceStack.push(
                        ...extendId([
                            {
                                type: 'promotionRaster',
                                baseValue: (100 - Number(promotion.value) ) / 100,
                                value: (100 - Number(promotion.value)) / 100,
                                valueType: 'percent',
                                data: {
                                    name: promotion?.names[this.config().CurLang] ?? promotion.name,
                                    promotionId: promotion.id,
                                    systemId: systemSeg.data.id,
                                    promotionModel: promotion.model,
                                    promotionType: promotion.type,
                                    promotionValue: promotion.value,
                                },
                                to: sashSegs.map(el => el.id),
                            },
                        ])
                    );
                }
            } else if (promotion.model === 'WindowPrice') {
                const sashSegs = priceStack.filter(s => s && s.type === 'sashes');
                if (sashSegs.length > 0) {
                    sashSegs.forEach(sashSeg => {
                        if (sashSeg.data && promotion.record && promotion.record.includes(Number(sashSeg.data.priceId))) {
                            priceStack.push(
                                ...extendId([
                                    {
                                        type: 'promotionRaster',
                                        baseValue: (100 - Number(promotion.value) ) / 100,
                                        value: (100 - Number(promotion.value)) / 100,
                                        valueType: 'percent',
                                        data: {
                                            name: promotion?.names[this.config().CurLang] ?? promotion.name,
                                            promotionId: promotion.id,
                                            priceId: sashSeg.data.priceId,
                                            promotionModel: promotion.model,
                                            promotionType: promotion.type,
                                            promotionValue: promotion.value,
                                        },
                                        to: [sashSeg.id],
                                    },
                                ])
                            );
                        }
                    });
                }
            } else if (promotion.model === 'Filling') {
                const fillingSegs = priceStack.filter(s => s && s.type === 'filling');
                if (fillingSegs.length) {
                    fillingSegs.forEach(fillingSeg => {
                        if (!newFillingsPrices[fillingSeg.id]) {
                            newFillingsPrices[fillingSeg.id] = fillingSeg.data.glazingPrice;
                        }
                        if (fillingSeg.data && promotion.record && promotion.record.includes(Number(fillingSeg.data.id?.split('.')[0]))
                            && (promotion.field !== 'WindowLine' || !promotion.relation?.length || promotion.relation.includes(Number(systemSeg.data.id)))
                        ) {
                            let promotionValue = promotion.value;
                            if (promotion.type === 'value_by') {
                                promotionValue = -(promotionValue * fillingSeg.data.area);
                            } else if (promotion.type === 'value_to') {
                                promotionValue = (promotionValue - newFillingsPrices[fillingSeg.id]) * fillingSeg.data.area;
                            } else if (promotion.type === 'percent') {
                                promotionValue = -1 * core.roundPrice(Number(promotion.value) / 100 * newFillingsPrices[fillingSeg.id]) * fillingSeg.data.area;
                            }
                            newFillingsPrices[fillingSeg.id] += promotionValue;

                            priceStack.push(
                                ...extendId([
                                    {
                                        type: 'promotionFilling',
                                        baseValue: promotionValue,
                                        value: promotionValue,
                                        valueType: 'value',
                                        data: {
                                            name: promotion.names[this.config().CurLang] ?? promotion.name,
                                            promotionId: promotion.id,
                                            fillingId: fillingSeg.data.id,
                                            systemId: systemSeg.data.id,
                                            promotionModel: promotion.model,
                                            promotionType: promotion.type,
                                            promotionValue: promotion.value,
                                            sashId: fillingSeg.data.sashId,
                                        },
                                        to: [fillingSeg.id],
                                    },
                                ])
                            );
                        }
                    });
                }
            }
        }
    }

    protected getDiscountGroupForSegmentType(segment: PriceSegment, systemSegment: PriceSegment) {
        let group = 'system';
        if (systemSegment && !~~this.config().IccConfig.Dealer.oneProductDiscount) {
            group = 'system' + systemSegment.data.id;
        }
        if (
            ['accessories', 'sashesHardware', 'sideAccessories'].indexOf(segment.type) > -1
            && this.config().IccConfig.Dealer.discountPerAllAccessoriesCategory
        ) {
            group = 'accessory' + (segment.data.categoryId || '');
        } else if (['complementaryGoods'].indexOf(segment.type) > -1) {
            group = 'accessory';
            if (
                this.config().IccConfig.Dealer.displayDiscountsForAddons
                && ['accessory', 'cassonetto', 'glass', 'windowsill'].includes(segment.data.good)
            ) {
                group = segment.data.good;
                if (segment.data.good === 'glass') {
                    group = 'glazing';
                }
            }
        } else if (
            ['sideProfile', 'alignment', 'couplings'].indexOf(segment.type) > -1
            && this.config().IccConfig.Dealer.discountPerAllAccessoriesCategory
        ) {
            group = 'priceLevel' + (segment.data.priceLevelId || '');
        } else if (
            [
                'rollerColors',
                'rollerColor',
                'rollerDrive',
                'rollerDriveManual',
                'rollerDriveElems',
                'rollerAssembly',
                'rollerBoxSize',
                'rollerShutterMosquito',
                'rollerConsole',
                'rollerConcealedStrip',
                'rollerBoxes',
                'rollerSlatDivision',
                'rollerGrayPolystyrene'
            ].indexOf(segment.type) > -1
        ) {
            group = 'roller' + (segment.data.typeId || '');
        }
        if (Common.isObject(this.config().IccConfig.Offer.discountGroups)) {
            for (const key in this.config().IccConfig.Offer.discountGroups) {
                if (
                    this.config().IccConfig.Offer.discountGroups.hasOwnProperty(key)
                    && Common.isArray(this.config().IccConfig.Offer.discountGroups[key])
                    && this.config().IccConfig.Offer.discountGroups[key].indexOf(segment.type) > -1
                ) {
                    group = key;
                }
            }
        }
        return group;
    }

    getMontageMultiplier() {
        const serviceMultiplier = this.getMultipliers().Service && this.getMultipliers().Service[0] || 1;
        const montageMultiplier = this.getBuyDiscounts().Montages && this.getBuyDiscounts().Montages[0] || 0;
        const multiplier =
            (this.loaded
                && (this.config().IccConfig.Dealer.surchargeMultipliers
                    ? serviceMultiplier
                    : (100 - montageMultiplier) / 100))
            || 1;
        return multiplier;
    }

    private segmentToDiscountGroup(segment: PriceSegment, systemSeg: PriceSegment, prev: {}) {
        const group = this.getDiscountGroupForSegmentType(segment, systemSeg);
        if (Common.isUndefined(prev[group])) {
            prev[group] = 0;
        }
        if (prev[group] !== null && !isNaN(parseFloat(segment.value as any))) {
            prev[group] += parseFloat(segment.value as any);
        } else {
            prev[group] = null;
        }
    }
}
