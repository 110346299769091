<icc-select-box
                *ngIf="(selectedConfiguratorType$ | async) !== 'external_blind'"
                [title]="'ROLLERSHUTTER|Typ pancerza:' | translate"
                [description]="(profile$ | async)?.name"
                [imageUrl]="'/files/profile/' + ((profile$ | async)?.img || '')"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="changeProfile()"
></icc-select-box>
<div class="box-form mat-elevation-z3">
    <form>
        <mat-form-field *ngIf="availableRoundReel && ((selectedConfiguratorType$ | async) !== 'external_blind')">
            <mat-label>{{ 'ROLLERSHUTTER|Rura nawojowa:' | translate }}</mat-label>
            <mat-select  name="roundReel" [value]="roundReel$ | async" (selectionChange)="changeRoundReel($event.value)">
                <mat-option *ngFor="let reel of roundReels" [value]="reel.id">{{ reel.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'ROLLERSHUTTER|Zmień wysokość skrzynki:' | translate }}</mat-label>
            <mat-select  name="roundReel" [value]="boxHeight$ | async" (selectionChange)="onBoxHeightChange($event.value)">
                <mat-option *ngFor="let size of boxSizes" [value]="size">{{ size | unitmm:0:0 }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>
<div class="box-form mat-elevation-z3">
    <h4>{{ 'ROLLERSHUTTER|Zmiana prowadnic i listwy końcowej:' | translate }}</h4>
    <div id="shutter-layout-draw" #parent>
        <icc-draw [details]="drawService.details" [options]="drawOptions" fitTo="width" iccDrawResize [drawWidth]="width$ | async" [drawHeight]="height$ | async" [parentElement]="parent"></icc-draw>
    </div>
</div>
