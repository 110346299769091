import { Common } from '../Common';
import { IIccConfig } from '@icc/config';
import { core } from '../helpers';

function isValidURI(data: string): boolean {
    try {
        decodeURIComponent(escape(data));
        return true;
    } catch (e) {
        return false;
    }
}

export function base64Decoder(data, $, location?: string, changeImagePaths=false) {
    if (Common.isDefined(data) && location) {
        if (!isValidURI(data)) {
           return data;
        }
        let decodedData;
        try {
            decodedData = decodeURIComponent(escape(data));
        } catch (e) {
            return data
        }
        const str = $(decodedData);
        if (changeImagePaths) {
            str.find("pattern > image").each(function(el) {
                let attr = $(this).attr('href');
                attr = location + attr;
                $(this).attr('href', attr);
            });
        }
        str.find("rect[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("rect[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("polygon[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("polygon[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("circle[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("circle[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("path[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("path[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("line[style*='marker-start: url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('style', attr);
        });
        str.find("line[style*='marker: url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('style', attr);
        });
        str.find("*[mask*='url']").each(function(el) {
            let attr = $(this).attr('mask');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('mask', attr);
        });
        str.find("*[fill*='url']").each(function(el) {
            let attr = $(this).attr('fill');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('fill', attr);
        });
        str.find("*[stroke*='url']").each(function(el) {
            let attr = $(this).attr('stroke');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('stroke', attr);
        });
        return str[0].outerHTML || str.parent().html();
    } else {
        return data;
    }
}

export function svgImageFilter(data, IccConfig: IIccConfig, $, location?: string, keepUrl?: boolean, changeImagePaths=false, isPdf=false, conf=null, baseDimensionUnit = 'mm', targetDimensionUnit = 'mm') {
    data = base64Decoder(data, $, location, changeImagePaths);

    if (!keepUrl) {
        data = data.replace(/url\(\'(https?|icc-res)\:\/\/.*?\#/g, `url('#`);
        data = data.replace(/url\((https?|icc-res)\:\/\/.*?\#/g, `url(#`);
    }

    data = data.replace(/cdvfile:\/\/localhost\/persistent\/files/g, ``);

    data = data.replace(
        /class="windowSvg[a-zA-Z0-9-\s]*"/g,
        '$0 height="'
            + IccConfig.Drawing.pdf.height
            + '" width="'
            + IccConfig.Drawing.pdf.width
            + '"'
    );
    data = data.replace(/about:blank/g, '');

    data = data.replace(/<\/?icc-draw[^>]*>/g, '');
    data = data.replace(/_ngcontent-[\w-]*(="")?/g, '');
    data = data.replace(/_nghost-[\w-]*(="")?/g, '');

    if (IccConfig.Drawing.pdf.blackWhite) {
        data = data.replace(/url\(\'?#iccDraw\-muntins\-[a-zA-Z0-9-\s]*\'?\)/g, `#000`);
        data = data.replace(/url\(\'?#iccDraw\-[a-z]*\-[a-z0-9\-]{36}\'?\)/g, `#fff`);
        data = data.replace(/stroke\=\"\#bbb\"/g, `stroke="#000"`);
    }

    if (IccConfig.Drawing.pdf.muntins == 'black') {
        data = data.replace(/url\(\'?#iccDraw\-muntins\-[a-zA-Z0-9-\s]*\'?\)/g, `#000`);
    }

    data = data.replace(/href/g,'xlink:href');
    data = data.replace(/xlink:xlink:href/g,'xlink:href');
    data = data.replace(/class="preview/g,'class="smallWindowPreview smallWindowPreviewX');
    if(isPdf) {
        const containerWidth = conf.type === 'coupled_window' ? 540 : 350;
        const containerHeight = conf.type === 'coupled_window' ? 412.23  : 270.7;
        const baseFontSize = 20;

        let viewBoxDimension = data.match(/viewBox="[+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)?"/g);
        viewBoxDimension = viewBoxDimension[0].slice(9, -1);
        viewBoxDimension = viewBoxDimension.split(" ");
        const dimensionNodeScale = parseFloat(viewBoxDimension[2]) < parseFloat(viewBoxDimension[3]) ? parseFloat(viewBoxDimension[3]) / containerHeight : parseFloat(viewBoxDimension[2]) / containerWidth;
        let fontSizeDif = 0;
        data = data.replace(/class="DimensionNode([\s\S]*?)g>/g, (match) =>  {
            match = match.replace(/font-size="\d+(\.\d+)?"/g, (match2) => {
                const fontSize = match2.slice(11, -1)
                const newFontSize = dimensionNodeScale * baseFontSize;
                fontSizeDif = newFontSize - fontSize;
                return 'font-size="' + newFontSize + '"';
            });
            let y = null;
            let x = null;
            match = match.replace(/x="-?\d+(\.\d+)?" y="-?\d+(\.\d+)?"/g, (match2) => {
                const toChange = match2.split(" ");
                const xToChange = toChange[0].slice(3, -1)
                const yToChange = parseFloat(toChange[1].slice(3, -1))
                y = y ? y : yToChange;
                x = x ? x : xToChange;
                const newY = y === yToChange && x === xToChange ? (yToChange + fontSizeDif * 0.5) : (yToChange - fontSizeDif * 0.5);

                return 'x="' + xToChange + '" y="' + newY  + '"';
            });

            return match;
        });

        data = data.replace(/viewBox="[+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)?"/g, (match) => {
            const vieBoxSize = match.slice(9, -1).split(" ");
            const yIndex =  vieBoxSize[1] * 2;
            const width = parseFloat(vieBoxSize[2]) + fontSizeDif;
            const height = parseFloat(vieBoxSize[3]) + fontSizeDif - yIndex;
            return 'viewBox="' + vieBoxSize[0] + " " + yIndex + " " +  width + " " + height + '"';
        });
    }

    if (baseDimensionUnit !== targetDimensionUnit) {
        const data$ = $(data);
        const dimensionTexts = data$.find('.DimensionNode').find('text');
        dimensionTexts.each(function() {
            if (targetDimensionUnit === 'inch' && baseDimensionUnit === 'mm') {
                $(this).text(core.formatNumber(parseFloat($(this).text()) / IccConfig.Configurators.inchToMilimeter, 2, 100));
            } else if (targetDimensionUnit === 'mm' && baseDimensionUnit === 'inch') {
                $(this).text(core.formatNumber(parseFloat($(this).text()) * IccConfig.Configurators.inchToMilimeter, 2, 100));
            }
        });
        data = data$[0].outerHTML || data$.parent().html();
    }

    return data;
}
