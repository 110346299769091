<svg [attr.viewBox]="viewBox">
  <defs>
      <marker id="markerLine" markerWidth="2" markerHeight="5" refX="0.5" refY="2.5" markerUnits="strokeWidth" orient="auto">
          <path d="M0,0 L0,5" style="fill: #000000;" stroke-width="1.5" stroke="#f00"/>
      </marker>
      <marker id="markerLineSmall" markerWidth="1" markerHeight="5" refX="0.5" refY="2.5" markerUnits="strokeWidth" orient="auto">
          <path d="M0,0 l0,5" style="fill: #000000;" stroke-width="1" stroke="#f00"/>
      </marker>
  </defs>

  <svg:path [attr.d]="path" class="path" [attr.stroke-width]="strokeWidth"></svg:path>
  <svg:path [attr.d]="path2" class="path2" *ngIf="!fieldPrefix"></svg:path>
  <svg:g *ngFor="let dimension of dimensions">
      <svg:text *ngIf="highlight === 'all'"  [attr.dominant-baseline]="dimension.baseline" [attr.x]="dimension.center.x" [attr.y]="dimension.center.y"  [ngClass]="dimension.vertical ? 'vertical' : 'x'" [style.text-anchor]="dimension.textAnchor">{{dimension.title}}</svg:text>
      <svg:path  [attr.d]="dimension.path" [attr.stroke-width]="strokeWidth" stroke="#f00" [attr.marker-start]="fieldPrefix ? 'url(#markerLineSmall)' : 'url(#markerLine)'" [attr.marker-end]="fieldPrefix ? 'url(#markerLineSmall)' : 'url(#markerLine)'"></svg:path>
  </svg:g>
</svg>
