import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA, ModalService, InfoService } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { Profile, ProfileType, Reinforcement } from '@icc/window';
import { BrowserProfilesService } from '../profiles.service';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { IccProfileSideColors, IccSideColors } from '@icc/common/data-types';
import { Wood } from '@icc/common/configurations/parts/common';
import { Common, core, AppConfigFactory, APP_CONFIG, TranslateService, ConfigurationsService, ConfiguratorsDataService, EventBusService,  } from '@icc/common';
import { iccListItem, iccListTab, UnitConverterService } from '@icc/configurator/ui';
import { PriceBaseService, PriceColorsService } from '@icc/price/b2b';
import { ProfileColorsService } from '../profile-colors.service';
import { ProfileOptionsPageComponent } from '../profile-options-page/profile-options-page.component';
import { ReinforcementsModalService } from '../../reinforcements/reinforcements-modal.service';
import { BehaviorSubject, Subscription} from 'rxjs';
import { AccessoriesService } from '@icc/legacy/configurator/steps/window/accessories/accessories.service';
import { CasingsService } from '../casings.service';
import { DoorPortalsService } from '../../door-portals/door-portals.service';
import { ProfileInfoComponent } from '../../profile-info/profile-info.component';
import { DependenciesService } from '@icc/legacy/dependencies/dependencies.service';
import { SizesService } from 'libs/configurator/door/src/lib/sizes/sizes.service';

interface iccProfileListItem extends iccListItem {
    profileCategoryId: string | number;
    price: {
        price_field: any;
        price_length: any;
        price_piece: any;
        price_construction: any;
        price_sash: any;
        price_area: any;
        price_percent: any;
    };
    type: ProfileType;
    selectedColor: Profile['selectedColor'];
    selectedWood: Profile['selectedWood'];
    width: Profile['width'];
    maxHeight: Profile['maxHeight'];
    minHeight: Profile['minHeight'];
}

interface BlockFrameProfile {
    ids: number[],
    conditionsType: (string | undefined)[],
    dependencyId: number
}

@Component({
    selector: 'icc-profiles-list-page',
    templateUrl: './profiles-list-page.component.html',
    styleUrls: ['./profiles-list-page.component.scss'],
})
export class ProfilesListPageComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Wybierz profil');
    public options = [];
    private subscriptions: Subscription[] = [];

    showTabs = false;
    showColorsWhenCoupling = this.CurConfService.conf === 'coupled_window';
    profiles: iccProfileListItem[] = [];
    selectedTypeTab = 'fixed_mullion';
    currency: any = null;
    selectedProfileId?: number;
    profileCategories: iccListTab[] = [];
    hiddenPrice = false;
    blockedExtensions: { types: any; options: any; }[] = [];
    blockedFrameProfiles: BlockFrameProfile[] = []
    selectedColors$: BehaviorSubject<IccProfileSideColors> = new BehaviorSubject( {
        core: null,
        outer: null,
        inner: null,
        alushell: null,
    });

    tabs: iccListTab[] = [
        {
            id: 'fixed_mullion',
            name: this.translateService.instant('WINDOW|Słupki')
        },
        {
            id: 'false_mullion',
            name: this.translateService.instant('WINDOW|Słupki ruchome')
        },
        {
            id: 'sash',
            name: this.translateService.instant('WINDOW|Skrzydła')
        },
        {
            id: 'false_mullion_sash',
            name: this.translateService.instant('WINDOW|Skrzydła przymykowe')
        },
        {
            id: 'coupling',
            name: this.translateService.instant('WINDOW|Łączniki')
        },
        {
            id: 'extension',
            name: this.translateService.instant('WINDOW|Poszerzenie')
        },
        {
            id: 'other',
            name: this.translateService.instant('WINDOW|Inny profil')
        },
    ]

    dimensionUnit = this.unitConverterService.getUnit();
    private dependencies: {
        blockedExtension?: boolean; id: number; conditions: { type?: string, value?: any; }[];
    }[] = [];
    listPriceEnable = this.config().IccConfig.Offer.listPrice;
    listPriceEnableInMarket = this.config().listPriceEnableInMarket;

    constructor(
        private sharedFacade: SharedFacade,
        private profilesService: BrowserProfilesService,
        private profilesPriceService: ProfilesPriceService,
        private priceBaseService: PriceBaseService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        private CurConfService: CurrentConfiguratorService,
        private configurationsService: ConfigurationsService<'door'>,
        private profileColorsService: ProfileColorsService,
        private modalService: ModalService,
        private reinforcementsModalService: ReinforcementsModalService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            profiles: Profile[];
            profilesPrices: any[];
            selectedProfile: Profile;
            system: any | null;
            type: ProfileType;
            currency: any;
            color?: IccProfileSideColors;
            wood?: Wood;
            reinforcement?: Reinforcement;
            profileCategories?: iccListTab[];
            hiddenPrice?: boolean;
        },
        private unitConverterService: UnitConverterService,
        private infoService: InfoService,
        private accessoriesService: AccessoriesService,
        private casingsService: CasingsService,
        private doorPortalsService: DoorPortalsService,
        private configuratorsDataService: ConfiguratorsDataService,
        private eventBusService: EventBusService,
        private dependenciesService: DependenciesService,
        private sizesService: SizesService,
        private priceColorsService: PriceColorsService,
    ) {
        super();
    }

    ngOnInit() {
        this.dependencies = this.configuratorsDataService.data.dependencies || [];
        const types: ProfileType[] = [];
        this.subscriptions.push(
            this.sharedFacade.currency$.subscribe(currency => {
                this.currency = currency;
            })
        );
        this.hiddenPrice =
            (this.pageData.hiddenPrice ?? false) ||
            (this.config().preset === 'b2c' && this.config().hidePricesInB2cConfigurator);
        this.pageData.profiles.forEach(profile => {
            if (this.pageData.color) {
                if (
                    this.pageData.selectedProfile
                    && this.pageData.selectedProfile.id === profile.id
                ) {
                    profile.selectedColor = this.pageData.color;
                    profile.selectedWood = this.pageData.wood;
                    this.selectedColors$.next(profile.selectedColor);
                } else  if (!profile.selectedColor || !profile.selectedColor.core) {
                    this.profileColorsService.setDefaultProfileColorSet(
                        this.configurationsService.conf.Current,
                        profile,
                        this.pageData.color,
                    );
                }
            }
            if (
                this.pageData.reinforcement
                && this.pageData.selectedProfile
                && this.pageData.selectedProfile.id === profile.id
            ) {
                profile.reinforcement = this.pageData.reinforcement;
            }

            if (!types.includes(profile.type)) {
                types.push(profile.type);
            }
        });

        const tabsAsCategories = this.pageData.profileCategories
            && types.length === 1;
        if (tabsAsCategories) {
            this.tabs = this.pageData.profileCategories?.map((category: any) => {
                return {id: category.id, name: category.name};
            }) || [];
        }

        this.profiles = this.pageData.profiles.map(profile => ({
            id: Number(profile.id),
            side: profile.side,
            profileCategoryId: profile.profileCategoryId,
            title: profile.name || profile.code,
            imageUrl: `/files/profile/${profile.img}`,
            description: `${profile.name}`,
            price: profile.price,
            type: profile.type,
            selectedColor: profile.selectedColor,
            selectedWood: profile.selectedWood,
            width: profile.width,
            maxHeight: profile.maxHeight,
            minHeight: profile.minHeight,
            reinforcement: profile.reinforcement,
            tabs: tabsAsCategories ? [profile.profileCategoryId] : [profile.type],
        }));
        this.selectedProfileId = this.pageData.selectedProfile && Number(this.pageData.selectedProfile.id);
        if (types.length > 1 || tabsAsCategories) {
            this.showTabs = true;
        }

        const blockedItems = this.configurationsService.conf.Current.Dependencies?.filter(
            (p) => p.type === 'blockade_to_configuration'
        ) || [];

        this.dependencies.forEach((dependency) => {
            blockedItems.forEach(blocked => {
                if (dependency && blocked && dependency.id === Number(String(blocked.id).split('-')[0])) {
                    dependency.conditions.forEach((condition) => {
                        if (condition.type === 'extension' && dependency.blockedExtension) {
                            this.blockedExtensions.push(
                                {
                                    types: condition.value.extensionTypes,
                                    options: condition.value?.extensionOptions
                                }
                            )
                        }
                    });
                }
            })
            if(dependency.conditions.some(condition => condition.type === 'frameProfile')) {
                this.blockedFrameProfiles.push({
                    ids: dependency.conditions.find(condition => condition.type === 'frameProfile')?.value || [],
                    conditionsType: dependency.conditions.map(condition => condition.type),
                    dependencyId: dependency.id
                });
            }
        })

        this.markAsDisabled();
    }

    markAsDisabled(conf = this.configurationsService.conf.Current) {
        this.profiles.forEach(profile => {
            if (['extension', 'frame'].indexOf(profile.type) === -1 && !profile.profileCategoryId) return;

            const category = this.pageData.profileCategories?.find(
                (c) =>
                    c?.quantity_restriction &&
                    c?.max_quantity &&
                    profile.profileCategoryId === c?.id
            );
                if (category && !this.blockedExtensions.length && !this.blockedFrameProfiles.length) {
                    return;
                } else {
                    if (this.isExtensionAvailable(Number(profile.id), profile.side)) {
                        profile.disabled = false;
                    } else {
                        profile.disabled = true;
                    }
                }
            }
        );
    }

    isExtensionAvailable(profileId: number, side?: 'left' | 'right' | 'top' | 'bottom', conf = this.configurationsService.conf.Current) {
        const handlePosition = conf.Sashes?.find((k) => k.type?.handle_position)?.type?.handle_position;

        let isExtensionViable = true;
        if(!(Array.isArray(this.blockedExtensions) && this.blockedExtensions.length)) {
            this.blockedExtensions.forEach((e) => {
                const fromHingeSide = e.options?.includes('from_hinge_side');
                e.types.forEach((t: { id: number | "ALL_EXTENSIONS" }) => {
                    if (fromHingeSide && (t.id === profileId || t.id === "ALL_EXTENSIONS")) {
                        if (handlePosition === 'L' && side === 'right') {
                            isExtensionViable = false;
                        } else if (handlePosition === 'R' && side === 'left') {
                            isExtensionViable = false;
                        }
                    } else if (t.id === profileId || t.id === "ALL_EXTENSIONS") {
                        isExtensionViable = false;
                    }
                });
            });
        }

        if(isExtensionViable && this.blockedFrameProfiles.length) {
            this.blockedFrameProfiles.forEach(blockedExtension => {
                if(blockedExtension.ids.includes(profileId) && this.profileIsBlockByDependency(conf, profileId, blockedExtension)) {
                    isExtensionViable = false;
                }
            });
        }

        return isExtensionViable;
    }

    profileIsBlockByDependency(conf: any, profileId: number, blockedExtension: BlockFrameProfile) {
        if(blockedExtension.conditionsType.includes('doorWidth') || blockedExtension.conditionsType.includes('doorHeight')) {
            const copyCof = core.copy(conf);
            const profile = this.profilesService.getProfile(profileId);
            copyCof.UsedProfiles = copyCof.UsedProfiles.filter((p: any) => p.type !== 'frame')
            copyCof.UsedProfiles.push(profile)
            copyCof.ProfileSet.frameSide = profileId;
            const doorSizes = copyCof.doorSizes;
            const { width, height } = this.sizesService.getConstructionDimensions(doorSizes, copyCof);
            copyCof.Width = width;
            copyCof.Height = height;
            this.dependenciesService.process(copyCof);
            if(copyCof.Dependencies.some((dependency: any) => Number(String(dependency.id).split('-')[0]) === blockedExtension.dependencyId)) {
                return true;
            }
        }

        return false;
    }

    hasZeroPrice(
        price: {
            price_field: any;
            price_length: any;
            price_piece: any;
            price_construction: any;
            price_sash: any;
            price_area: any;
            price_percent: any;
        },
        type: ProfileType | null = null
    ) {
        if (!price) {
            return false;
        }
        let requiredFields = [price.price_field, price.price_length, price.price_piece];
        if (
            (Common.isArray(this.pageData.type) && type === 'frame')
            || this.pageData.type === 'frame'
        ) {
            requiredFields.push(price.price_percent);
            requiredFields.push(price.price_construction);
        }
        if (
            (Common.isArray(this.pageData.type) && type === 'threshold')
            || this.pageData.type === 'threshold'
        ) {
            requiredFields = [price.price_construction, price.price_sash, price.price_length];
        } else if (
            !this.pageData.type
            || (Common.isArray(this.pageData.type)
                && type
                && ['coupling', 'extension', 'alignment', 'other'].includes(type))
            || ['coupling', 'extension', 'alignment', 'other'].includes(this.pageData.type)
        ) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (
            (Common.isArray(this.pageData.type) && type === 'sandwich')
            || this.pageData.type === 'sandwich'
        ) {
            requiredFields = [price.price_area];
        }
        return requiredFields.every(p => p === 0);
    }

    hasNoPrice(
        price: {
            price_field: any;
            price_length: any;
            price_piece: any;
            price_construction: any;
            price_sash: any;
            price_area: any;
            price_percent: any;
        },
        type: ProfileType | null = null
    ) {
        if (!price) {
            return true;
        }
        let requiredFields = [price.price_field, price.price_length, price.price_piece];
        if (
            (Common.isArray(this.pageData.type) && type === 'frame')
            || this.pageData.type === 'frame'
        ) {
            requiredFields.push(price.price_percent);
            requiredFields.push(price.price_construction);
        }
        if (
            (Common.isArray(this.pageData.type) && type === 'threshold')
            || this.pageData.type === 'threshold'
        ) {
            requiredFields = [price.price_construction, price.price_sash, price.price_length];
        } else if (
            !this.pageData.type
            || (Common.isArray(this.pageData.type)
                && type
                && ['coupling', 'extension', 'alignment', 'other'].includes(type))
            || ['coupling', 'extension', 'alignment', 'other'].includes(this.pageData.type)
        ) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (
            (Common.isArray(this.pageData.type) && type === 'sandwich')
            || this.pageData.type === 'sandwich'
        ) {
            requiredFields = [price.price_area];
        }
        return requiredFields.some(p => isNaN(Number(p)) || p === null);
    }

    selectProfile(profileItem: iccProfileListItem) {
        this.changeProfile(profileItem);
    }

    showInvalidAccessoriesForFrameInfo(invalidAccessories: string[], profileItem: iccProfileListItem) {
        this.infoService.openConfirmModal(
            this.translateService.instant('WINDOW|Niedostępne dodatki dla profilu ramy'),
            this.translateService.instant(`WINDOW|Po zmianie profilu następujące dodatki zostaną usunięte: `) + invalidAccessories.filter(name => ' ' + name),
            [
                {
                    name: this.translateService.instant('INTERFACE|Anuluj'),
                    callback: () => {},
                    accent: true,
                },
                {
                    name: this.translateService.instant('INTERFACE|Kontynuuj'),
                    callback: () => {
                        this.accessoriesService.removeInvalidFrameDependentAccessories(profileItem.id);
                        this.selectProfile(profileItem);
                    },
                },
            ]
        );
    }

    changeProfile(profileItem: iccProfileListItem) {
        let invalidAccessoriesToFrameProfile;
        const profile = core.copy(this.pageData.profiles.find((p) => Number(p.id) === Number(profileItem.id)));

        if (profile?.type ==='frame') {
            invalidAccessoriesToFrameProfile = this.checkIfAccessoryMatchFrameProfile(profileItem);
        }

        if (invalidAccessoriesToFrameProfile && invalidAccessoriesToFrameProfile.length && profile?.type === 'frame' ) {
            this.showInvalidAccessoriesForFrameInfo(invalidAccessoriesToFrameProfile, profileItem);
        } else {
            if (profile) {
                const matchedCasingsToFrame = this.profilesService.matchProfileToFrame(profile.id, 'casing');
                const matchedPortalsToFrame = this.profilesService.matchProfileToFrame(profile.id, 'portal');
                profile.width = profileItem.width || profile.width;
                if (
                    (profile.type === 'frame' &&
                        (matchedCasingsToFrame.length > 0 ||
                            matchedPortalsToFrame.find((p) => this.doorPortalsService.isPortalValid(p?.id)
                            ))) ||
                    profile.type === 'extension' ||
                    profile.type === 'sandwich' ||
                    profile.type === 'coupling' && this.showColorsWhenCoupling ||
                    this.CurConfService.conf === 'complementary_goods'
                ) {
                    this.selectedColors$.next(profile.selectedColor);
                    this.modalService
                        .open({
                            pageComponent: ProfileOptionsPageComponent,
                            resolve: {
                                profile,
                                profilesPrices: this.pageData.profilesPrices,
                                profile$: new BehaviorSubject({
                                    count: 1,
                                    currentLength: 0,
                                }),
                                selectedColors$: this.selectedColors$,
                                colorGroups: this.configuratorsDataService.data.windowColorGroups,
                            },
                        })
                        .result.then((result) => {
                            this.sharedFacade.closePage(result);
                        });
                } else {
                    this.sharedFacade.closePage(profile);
                }
            }
        }
    }

    private checkIfAccessoryMatchFrameProfile(profileItem: iccProfileListItem) {
        if (this.CurConfService.conf !== 'complementary_goods' && this.CurConfService.conf !== 'coupled_window' && this.CurConfService.conf !== 'roller_shutter') {
            return this.profilesService.getNonMatchingAccessoriesToTheFrame(Number(profileItem.id));
        } else {
            return [];
        }
    }

    openModalColors(profile: Profile) {
        this.profileColorsService
            .openProfileColorsModal(profile, this.pageData.selectedProfile, this.pageData.profilesPrices, this.selectedColors$)
            .then(selection => {
                const colorFactor = this.priceColorsService.getSideProfileColorFactor({
                    profile,
                    profileColors: selection ? selection.colors : profile.selectedColor,
                    wood: profile.selectedWood,
                    system: this.configurationsService.conf.Current.System,
                    colorGroups: this.configuratorsDataService.data.windowColorGroups || [],
                });

                profile.price = this.profilesPriceService.getProfilePrice(
                    profile.id,
                    Common.isArray(this.pageData.type) ? profile.type : this.pageData.type,
                    this.pageData.system,
                    selection ? selection.colors : profile.selectedColor,
                    this.pageData.profilesPrices,
                    undefined,
                    undefined,
                    undefined,
                    colorFactor,
                );

                profile.selectedColor = core.copy(
                    selection ? selection.colors : profile.selectedColor
                );
                profile.selectedWood = core.copy(selection ? selection.wood : profile.selectedWood);
            });
    }

    openModalReinforcements(profile: Profile) {
        this.reinforcementsModalService.openReinforcementsModal(
            profile.id,
            profile.reinforcement ? profile.reinforcement : null,
            this.currency,
        ).then((reinforcement: Reinforcement | false) => {
            if (reinforcement) {
                const profileTmp = this.pageData.profiles.find(el => el.id === profile.id);
                if (profileTmp) {
                    profileTmp.reinforcement = reinforcement.id === null ? null : core.copy(reinforcement);
                }
            }
        });

    }

    priceMarket(price: number) {
        return this.priceBaseService.addMarginMarketFactor(price);
    }

    clickShowInfoButton(item: iccListItem) {
        const profile = this.profilesService.getProfile(Number(item?.id));
        profile && this.modalService
            .open({
                pageComponent: ProfileInfoComponent,
                resolve: {
                    profile: () => profile,
                    button: () => this.isSelectProfileButtonInShowInfoButtonAvailable(Number(item?.id))
                },
            });
    }

    isSelectProfileButtonInShowInfoButtonAvailable(profileId: number) {
        const isPortalAvailableInFrame = Boolean(this.profilesService.matchProfileToFrame(profileId, 'portal')?.length)
        const isCasingAvailableInFrame = Boolean(this.profilesService.matchProfileToFrame(profileId, 'casing')?.length)

        return !(isCasingAvailableInFrame || isPortalAvailableInFrame);
    }

    isWysiwygEditorContentNotEmpty(item: iccListItem) {
        const profile = this.profilesService.getProfile(Number(item?.id));

        return profile && Boolean(profile?.wysiwygEditorContent?.length);
    }
}
