import { Injectable, Inject } from '@angular/core';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { EventBusService } from '@icc/common/event-bus.service';
import { BrowserFramesService } from '@icc/legacy/configurator/layout/frames.service';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { StateService } from '@icc/common/state.service';
import { Common } from '@icc/common/Common';
import { AlignmentsService } from '@icc/legacy/configurator/layout/alignments.service';
import { ExtensionsService } from '@icc/legacy/configurator/layout/extensions.service';
import { ProfileType, Profile, SideProfileActive, Reinforcement } from '@icc/window';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { CoupledWindowActiveConfiguration } from '@icc/common/configurations/CoupledWindowActiveConfiguration';
import { WindowConfiguration } from '@icc/common/configurations/WindowConfiguration';
import { activeSideColorsToSideColors } from '@icc/common/configurations/converters/window/colors';
import { IccProfileSideColors } from '@icc/common/data-types';
import { ModalService } from '@icc/helpers';
import { BrowserProfilesService } from './profiles.service';
import { ProfilesListPageComponent } from './profiles-list-page/profiles-list-page.component';
import { ConfiguratorsDataService, core } from '@icc/common';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { MullionsService } from '@icc/common/profiles/mullions.service';
import { PriceColorsService } from '@icc/price/b2b';
import { ProfileColorsService } from './profile-colors.service';

@Injectable()
export class ProfilesModalService {
    constructor(
        private eventBusService: EventBusService,
        private framesService: BrowserFramesService,
        private profilesService: BrowserProfilesService,
        private profilesPriceService: ProfilesPriceService,
        private stateService: StateService,
        private alignmentsService: AlignmentsService,
        private extensionsService: ExtensionsService,
        protected mullionsService: MullionsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private modalService: ModalService,
        private configuratorsDataService: ConfiguratorsDataService,
        private priceColorsService: PriceColorsService,
        private profileColorsService: ProfileColorsService
    ) {}

    async openProfilesModal(
        conf: WindowActiveConfiguration,
        type: ProfileType | ProfileType[] | null,
        options: { and?: string[]; not?: string[] } | { and?: string[]; not?: string[] }[],
        selectedProfile: Profile | null,
        color?: IccProfileSideColors,
        wood?: SideProfileActive['wood'],
        reinforcement?: Reinforcement,
        overwritePrice: boolean = true,
        side?: 'left' | 'right' | 'top' | 'bottom'
    ): Promise<Profile> {
        const systemId = conf ? Number(conf.System.id) : null;
        const pricesForLength = this.profilesService.profilesPricesForLength;
        const profiles = this.profilesService
            .getFilteredProfiles(conf, type, options, false)
            .map((profile) => {
                if (side && profile.type === 'extension') {
                    profile.side = side;
                }
                if (profile.type !== 'threshold' && color) {
                    this.profileColorsService.setProfileDefaultColors(
                        conf,
                        profile,
                        selectedProfile,
                        color,
                        wood ?? null
                    );
                }
                let profileOptions: {
                    and?: string[];
                    not?: string[];
                } = {
                    and: [],
                    not: [],
                };
                if (Common.isArray(options) && Common.isArray(type)) {
                    profileOptions = options[type.indexOf(profile.type)];
                } else if (!Common.isArray(options) && !Common.isArray(type)) {
                    profileOptions = options;
                }
                const priceType = this.profilesService.getProfilePriceType(
                    profile.type,
                    profileOptions
                );
                if (profile.type !== 'threshold') {
                    let extensionLength;
                    if (
                        profile.type === 'extension' &&
                        this.config().IccConfig.Configurators.extensionPricesForLength
                    ) {
                        const extensionSide =
                            (Array.isArray(options) &&
                                options[0]?.and?.every((o) => o === 'side')) ||
                            (!Array.isArray(options) &&
                                Array.isArray(options.and) &&
                                options.and.every((o) => o === 'side'))
                                ? 'vertical'
                                : 'horizontal';
                        extensionLength = this.profilesService.getExtensionLength(extensionSide);
                    }
                    if (overwritePrice) {
                        const frameColorTypes = [
                            'frame',
                            'fixed_mullion',
                            'structured_muntin_frame',
                            'false_mullion',
                            'threshold',
                        ];
                        const colorFrom = frameColorTypes.includes(profile.type) ? 'frame' : 'sash';

                        const colorFactor = this.priceColorsService.getSideProfileColorFactor({
                            profile,
                            profileColors:
                                profile.selectedColor && profile.selectedColor
                                    ? profile.selectedColor
                                    : conf.Colors[colorFrom],
                            wood: profile.selectedWood ?? conf.Wood,
                            system: conf.System,
                            colorGroups: this.configuratorsDataService.data.windowColorGroups || [],
                        });

                        profile.price = systemId
                            ? this.profilesPriceService.getProfilePrice(
                                  profile.id,
                                  priceType,
                                  conf.System,
                                  profile.selectedColor
                                      ? profile.selectedColor
                                      : conf.Colors[colorFrom],
                                  this.profilesService.profilesPrices,
                                  null,
                                  pricesForLength,
                                  extensionLength,
                                  colorFactor
                              )
                            : null;
                    }
                } else {
                    const thresholdLength = this.profilesService.getThresholdLength();
                    profile.price = systemId
                        ? this.profilesPriceService.getThresholdPrice(
                              profile.id,
                              'threshold',
                              conf.System,
                              this.profilesService.profilesPrices,
                              null,
                              pricesForLength,
                              thresholdLength
                          )
                        : null;
                }
                return profile;
            });

        const modalInstance = this.modalService.open({
            component: 'profilesModal',
            pageComponent: ProfilesListPageComponent,
            resolve: {
                profiles: () => profiles,
                profilesPrices: () => this.profilesService.profilesPrices,
                system: () => conf.System,
                type: () => type,
                selectedProfile: () => selectedProfile,
                currency: () =>
                    this.config().currency || this.stateService.state.offers[0].doc.currency,
                color: () => color,
                wood: () => wood,
                hideCouplings: () => conf.SashesType !== 'Fix',
                reinforcement: () => reinforcement,
                profileCategories: () => this.profilesService.getProfileCategories(),
            },
        });

        return modalInstance.result;
    }

    selectAlignmentAndPut(
        field: ActiveSash,
        conf: WindowActiveConfiguration,
        side: 'top' | 'bottom' | 'left' | 'right'
    ) {
        let alignmentType = 'alignment_frame';
        const frame =
            field.frameId != null ? BrowserFramesService.getFrameById(field.frameId, conf) : null;
        if (Common.isNumber(field.parentId)) {
            alignmentType = 'alignment_sash';
        } else if (
            side === 'bottom' &&
            field.nearAlignments.bottom === -1 &&
            field.nearAlignments.left === -1 &&
            field.nearAlignments.right === -1 &&
            field.nearMullions.bottom === -1 &&
            frame &&
            frame.lowThreshold
        ) {
            alignmentType = 'alignment_threshold';
        }
        const options = {
            and: [alignmentType],
            not: [],
        };

        this.openProfilesModal(conf, null, options, null, conf.Colors.frame).then(
            (selectedProfile) => {
                if (selectedProfile) {
                    this.alignmentsService.putAlignmentInField(selectedProfile, field, conf, side);
                    if (this.config().IccConfig.Configurators.dependencies) {
                        this.eventBusService.post({ key: 'processDependencies', value: null });
                    }
                }
            }
        );
    }

    selectExtensionAndPut(
        conf: WindowActiveConfiguration,
        side: 'top' | 'bottom' | 'left' | 'right'
    ) {
        const options = [
            {
                and: [side === 'left' || side === 'right' ? 'side' : side],
                not: [],
            },
            {
                and: [],
                not: [],
            },
        ];

        this.openProfilesModal(
            conf,
            ['extension', 'sandwich'],
            options,
            null,
            conf.Colors.frame,
            undefined,
            undefined,
            true,
            side
        ).then((profile) => {
            if (profile) {
                this.extensionsService.putExtensionOnSide(conf, profile, side);
                if (this.config().IccConfig.Configurators.dependencies) {
                    this.eventBusService.post({ key: 'processDependencies', value: null });
                }

                this.eventBusService.post({
                    key: 'putExtensionOnSide',
                    value: {},
                });
            }
        });
    }

    async selectProfileAndPut(
        conf: WindowActiveConfiguration,
        type: ProfileType,
        selected: Profile | null = null,
        selectedColor: IccProfileSideColors | null = null
    ) {
        const options = [
            {
                and: [],
                not: [],
            },
            {
                and: [],
                not: [],
            },
        ];
        const frameColorTypes = [
            'frame',
            'fixed_mullion',
            'structured_muntin_frame',
            'false_mullion',
            'threshold',
            'coupling',
            'extension',
        ];
        const colorFrom = frameColorTypes.includes(type) ? 'frame' : 'sash';
        const selectedProfile = await this.openProfilesModal(
            conf,
            [type],
            options,
            selected,
            selectedColor ?? conf.Colors[colorFrom]
        );
        return selectedProfile;
    }

    // eslint-disable-next-line max-statements
    changeProfile(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        type: any,
        {
            position = null,
            frame = null,
            sash = null,
            mullion = null,
            extension = null,
            coupling = null,
        }: {
            position?: any;
            frame?: any;
            sash?: any;
            mullion?: any;
            extension?: any;
            coupling?: any;
        }
    ) {
        frame = frame && conf.Frames.find((el) => el.id === frame.id);
        const changeAllProfiles =
            position === 'all' && (type !== 'frame' || (frame && !frame.lowThreshold));
        const changeProfilesWithoutThreshold =
            (position === 'all' || position === 'allFrames') &&
            type === 'frame' &&
            frame &&
            frame.lowThreshold;
        const changeAllFrames = position === 'allFrames' && type === 'frame';
        let color;
        let wood;
        let profileId;
        let options;
        let reinforcement;
        let altFrame = false;
        const drawPosition = position;
        if (type === 'mullion') {
            mullion = this.getMullion(conf, mullion.id);
            profileId = mullion.profileId;
        } else if (type === 'sashFrame') {
            sash = this.getSash(conf, sash.id);
            const sashFrameData = conf.drawData.sashFrame.find((s: any) => s.sashId === sash.id);
            const side =
                (sashFrameData &&
                    sashFrameData.sides[position] &&
                    sashFrameData.sides[position].outerEdge.side) ||
                'bottom';
            position = changeAllProfiles ? 'bottom' : side;
            profileId = sash.frame[position].profileId;
            color = conf.Colors.sash;
        } else if (type === 'frame') {
            const frameData =
                conf.drawData &&
                conf.drawData.frame &&
                (conf.drawData.frame.find((f: any) => f.frameId === frame.id) ??
                    conf.drawData.frame[0]);
            if (typeof position === 'number') {
                const positionMap = this.profilesService.parseFrameDrawDataSides(frameData.sides)
                    .positionMap;
                altFrame = frameData.sides[position].poly.alt;
                position = positionMap[position];
            }
            position = changeProfilesWithoutThreshold ? 1 : changeAllProfiles ? 0 : position;
            profileId = frame.frame[position].profileId;
            if (altFrame && frame.frame[position].alt?.profileId) {
                profileId = frame.frame[position].alt.profileId;
            }
        } else if (type === 'extension') {
            extension = conf.SideProfiles.find((o) => o.id === extension.id) || {};
            profileId = extension.profileId;
            color = extension.color;
            wood = extension.wood;
            reinforcement = extension.reinforcement ? core.copy(extension.reinforcement) : null;
        } else if (type === 'coupling') {
            coupling = conf.couplings.find((c) => c.id === coupling.id);
            profileId = coupling.profileId;
        }
        const profile = this.profilesService.getProfile(profileId);
        if (!color) {
            color = conf.Colors.frame;
        }
        if (type === 'extension' && profile && profile.type === 'sandwich') {
            profile.width = extension.width;
        }
        [type, options] = this.getOptions(conf, type, {
            position,
            frame,
            sash,
            mullion,
            extension,
            coupling,
            altFrame,
        });
        if (type !== 'no_mullion') {
            this.openProfilesModal(conf, type, options, profile, color, wood, reinforcement).then(
                (selectedProfile) => {
                    if (selectedProfile) {
                        const pauseId = this.eventBusService.pause([
                            'setBondedGlazingInSash',
                            'setMullionProfile',
                            'setSashProfile',
                        ]);
                        try {
                            if (changeAllProfiles && type !== 'mullion') {
                                Object.keys(type === 'frame' ? frame.frame : sash.frame).map(
                                    (pos) => {
                                        position = type === 'frame' ? Number(pos) : pos;
                                        const canBeChanged =
                                            (type !== 'sash' && type !== 'false_mullion_sash') ||
                                            this.canBeChangedSashProfile(conf, position, sash, selectedProfile);
                                        if (canBeChanged) {
                                            this.setProfile(
                                                conf,
                                                defaultConf,
                                                type,
                                                selectedProfile,
                                                {
                                                    position,
                                                    frame,
                                                    sash,
                                                    mullion,
                                                    extension,
                                                }
                                            );
                                        }
                                    }
                                );
                            } else if (changeProfilesWithoutThreshold && type === 'frame') {
                                if (changeAllFrames) {
                                    this.setFramesProfileSet(conf, defaultConf, selectedProfile);
                                } else {
                                    const frameData =
                                        conf.drawData &&
                                        conf.drawData.frame &&
                                        (conf.drawData.frame.find(
                                            (f: any) => f.frameId === frame.id
                                        ) ??
                                            conf.drawData.frame[0]);
                                    Object.keys(frame.frame).map((pos) => {
                                        position = Number(pos);
                                        let frameProfile = this.profilesService.getProfile(
                                            frame.frame[pos].profileId
                                        );
                                        if (frame.frame[pos].alt?.profileId) {
                                            frameProfile = this.profilesService.getProfile(
                                                frame.frame[pos].alt?.profileId
                                            );
                                        }
                                        if (frameProfile.type !== 'threshold') {
                                            this.setProfile(
                                                conf,
                                                defaultConf,
                                                type,
                                                selectedProfile,
                                                {
                                                    position,
                                                    frame,
                                                    sash,
                                                    mullion,
                                                    extension,
                                                    altFrame: frame.frame[pos].alt != null,
                                                    drawPosition:
                                                        position > 0
                                                            ? position +
                                                              (frameData.sides.length -
                                                                  Object.keys(frame.frame).length)
                                                            : position,
                                                }
                                            );
                                        }
                                    });
                                }
                            } else if (selectedProfile.type === 'coupling' && mullion) {
                                this.framesService.splitFrame(selectedProfile, mullion, conf);
                            } else if (selectedProfile.type === 'fixed_mullion' && coupling) {
                                this.framesService.joinFrame(selectedProfile, coupling, conf);
                            } else {
                                this.setProfile(
                                    conf,
                                    defaultConf,
                                    selectedProfile.type,
                                    selectedProfile,
                                    {
                                        position,
                                        frame,
                                        sash,
                                        mullion,
                                        extension,
                                        coupling,
                                        altFrame,
                                        drawPosition,
                                    }
                                );
                            }
                        } finally {
                            this.eventBusService.resume(
                                ['setBondedGlazingInSash', 'setMullionProfile', 'setSashProfile'],
                                pauseId
                            );
                        }
                        if (this.config().IccConfig.Configurators.dependencies) {
                            this.eventBusService.post({ key: 'processDependencies', value: null });
                        }
                    }
                }
            );
        }
    }

    private setProfile(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        type: ProfileType,
        profile: Profile,
        {
            position = null,
            frame = null,
            sash = null,
            mullion = null,
            extension = null,
            coupling = null,
            altFrame = false,
            drawPosition = null,
        }: {
            position?: 'bottom' | 'top' | 'left' | 'right' | number | null;
            frame?: any | null;
            sash?: any | null;
            mullion?: any | null;
            extension?: any | null;
            coupling?: any | null;
            altFrame?: boolean;
            drawPosition?: number | string | null;
        }
    ) {
        if ((type === 'sash' || type === 'false_mullion_sash') && typeof position === 'string') {
            this.profilesService.setSashProfile(conf, defaultConf, sash, profile, position, {});
        } else if (type.indexOf('mullion') > -1) {
            this.profilesService.setMullionProfile(conf, mullion, profile, {});
        } else if (type === 'frame' && typeof position === 'number') {
            if (!drawPosition) {
                drawPosition = position;
            }
            const frameData = conf.drawData && conf.drawData.frame && conf.drawData.frame[0];
            const side =
                (frameData &&
                    drawPosition &&
                    frameData.sides[drawPosition] &&
                    frameData.sides[drawPosition].outerEdge.side) ||
                'bottom';
            const mainProfile =
                altFrame && frame?.splitBottom
                    ? this.profilesService.getProfile(frame?.frame[position].profileId)
                    : profile;
            const altProfile =
                frame?.frame[position]?.alt &&
                (altFrame && frame?.splitBottom
                    ? profile
                    : this.profilesService.getProfile(frame?.frame[position]?.alt?.profileId));
            this.profilesService.setFrameProfile(conf, mainProfile, frame, position, {
                side,
                finWidth:
                    altProfile && side === 'bottom'
                        ? altProfile.finWidth || 0
                        : profile && (side !== 'top' || (side === 'top' && !conf.hasRoller))
                        ? profile.finWidth || 0
                        : 0,
                altProfile,
            });
        } else if (type === 'extension' || type === 'sandwich') {
            this.profilesService.setExtensionProfile(extension, profile);
        } else if (type === 'coupling') {
            this.framesService.changeCouplingProfile(conf, coupling, profile, {});
        }
    }

    setFramesProfileSet(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        profile: Profile
    ) {
        const profileSet = core.copy(conf.ProfileSet);
        profileSet.frameTop = profile.id;
        profileSet.frameSide = profile.id;
        profileSet.frameBottom = profile.id;
        conf.ProfileSet = core.copy(profileSet);
        conf.ProfileSet.isDefault = false;
        defaultConf.ProfileSet = core.copy(profileSet);
        defaultConf.ProfileSet.isDefault = false;
        this.eventBusService.post({
            key: 'setProfileSet',
            value: {
                profileSet,
            },
            conf,
            defaultConf,
        });
    }

    private getMullion(conf: WindowActiveConfiguration, mullionId: number) {
        for (const mullion of conf.Mullions) {
            if (mullion.id === mullionId) {
                return mullion;
            }
        }
        for (const sash of conf.Sashes) {
            for (const mullion of sash.intMullions) {
                if (mullion.id === mullionId) {
                    return mullion;
                }
            }
        }
    }

    private getSash(conf: WindowActiveConfiguration, sashId: number) {
        for (const sash of conf.Sashes) {
            if (sash.id === sashId) {
                return sash;
            }
        }
    }

    getOptions(
        conf: WindowActiveConfiguration,
        type: any,
        {
            position = null,
            frame = null,
            sash = null,
            mullion = null,
            extension = null,
            coupling = null,
            altFrame = false,
        }: {
            position?: 'bottom' | 'top' | 'left' | 'right' | 1 | null;
            frame?: any;
            sash?: any;
            mullion?: any;
            extension?: any;
            coupling?: any;
            altFrame?: boolean;
        }
    ) {
        let options:
            | {
                  and: string[];
                  not: string[];
                  filter?: (
                      profile: Profile,
                      conf: WindowActiveConfiguration | WindowConfiguration | null
                  ) => boolean;
              }
            | {
                  and: string[];
                  not: string[];
                  filter?: (
                      profile: Profile,
                      conf: WindowActiveConfiguration | WindowConfiguration | null
                  ) => boolean;
              }[] = {
            and: [],
            not: [],
        };

        let positionMap: {
            bottom: string;
            right: string;
            top: string;
            left: string;
            0?: string;
            1?: string;
            2?: string;
            3?: string;
        } = {
            0: 'bottom',
            1: 'side',
            2: 'top',
            3: 'side',
            bottom: 'bottom',
            right: 'side',
            top: 'top',
            left: 'side',
        };

        if (position !== null) {
            if (type === 'sashFrame' && sash) {
                const sashFrameData =
                    conf.drawData &&
                    conf.drawData.sashFrame &&
                    conf.drawData.sashFrame.find((s) => s.sashId === sash.id);

                if (sashFrameData) {
                    sashFrameData.sides.forEach((side, index) => {
                        positionMap[index] = side.outerEdge.side || 'bottom';
                    });
                }
                if (sash.nearMullions.bottom > -1) {
                    const bottomMulion = conf.Mullions.find(
                        (el) => el.id === sash.nearMullions.bottom
                    );
                    const bottomSashes = (bottomMulion && bottomMulion.multiAlignBottom) || [];

                    if (
                        sash.type.type === 'SD' ||
                        bottomSashes.some((el) => el.type && el.type.type === 'SU')
                    ) {
                        positionMap = {
                            top: 'top_in_top_sash',
                            bottom: 'bottom_in_top_sash',
                            left: 'side_in_top_sash',
                            right: 'side_in_top_sash',
                        };
                    }
                } else if (sash.nearMullions.top > -1) {
                    const topMullion = conf.Mullions.find((el) => el.id === sash.nearMullions.top);
                    const topSashes = (topMullion && topMullion.multiAlignTop) || [];

                    if (
                        sash.type.type === 'SU' ||
                        topSashes.some((el) => el.type && el.type.type === 'SD')
                    ) {
                        positionMap = {
                            top: 'top_in_bottom_sash',
                            bottom: 'bottom_in_bottom_sash',
                            left: 'side_in_bottom_sash',
                            right: 'side_in_bottom_sash',
                        };
                    }
                }
            }
            options.and.push(positionMap[position]);

            if (type === 'sashFrame' && sash) {
                if (
                    (position === 'right' || position === 'left') &&
                    sash.nearMullions[position] > -1 &&
                    sash.type.handle_position === (position === 'right' ? 'R' : 'L')
                ) {
                    const nearbyMullion = conf.Mullions.find(
                        (el) => el.id === sash.nearMullions[position]
                    );
                    const adjacentSashes = nearbyMullion?.[position === 'right' ? 'multiAlignRight' : 'multiAlignLeft'] || [];
                    if (
                        !sash.type.passive &&
                        nearbyMullion?.type === 'false_mullion' &&
                        adjacentSashes.length > 0
                    ) {
                        const passiveSashProfile = this.profilesService.getProfile(
                            adjacentSashes[0]?.frame?.[position === 'right' ? 'left' : 'right']?.profileId
                        );
                        if (passiveSashProfile?.compatibleActiveSashProfileId) {
                            options.filter = (profile, conf) => (
                                +passiveSashProfile?.compatibleActiveSashProfileId ===
                                    profile.id
                            );
                        }
                    }

                    if (!sash.type.passive) {
                        options.not.push('passive_sash');
                    }
                } else {
                    options.not.push('passive_sash');
                }
            }
        }

        if (type === 'sashFrame' && sash) {
            type = 'sash';
            options[sash.type.out_open ? 'and' : 'not'].push('outward_opening');
            if (
                sash.type.passive &&
                ((sash.type.handle_position === 'R' && position === 'right') ||
                    (sash.type.handle_position === 'L' && position === 'left'))
            ) {
                type = ['sash', 'false_mullion_sash'];
                options = [
                    options,
                    {
                        and: [],
                        not: [],
                    },
                ];
                options[1][sash.type.out_open ? 'and' : 'not'].push('outward_opening');
            }
        } else if (type === 'mullion' && mullion) {
            type = mullion.type;
            if (type === 'fixed_mullion') {
                options.and.push(
                    mullion.direction + (mullion.parentSash != null ? '_sash' : '_frame'),
                    this.mullionsService.getMullionGlazingOrHardwareOptions(mullion)
                );
                const frame =
                    mullion.frameId != null
                        ? BrowserFramesService.getFrameById(mullion.frameId, conf)
                        : null;
                if (
                    !mullion.parentSash &&
                    ((mullion.direction === 'vertical' &&
                        (mullion.rHeight === conf.Height ||
                            (frame && mullion.rHeight === frame.height))) ||
                        (mullion.direction === 'horizontal' &&
                            (mullion.rWidth === conf.Width ||
                                (frame && mullion.rWidth === frame.width)))) &&
                    this.config().IccConfig.Configurators.coupledFrames &&
                    conf.SashesType === 'Fix'
                ) {
                    type = [type, 'coupling'];
                    options = [
                        options,
                        {
                            and: [],
                            not: [],
                        },
                    ];
                }
            } else {
                const centralHandleOption = this.mullionsService.hasCentralHandleFalseMullion(mullion);
                if (centralHandleOption) {
                    options.and.push('central_handle');
                } else {
                    options.not.push('central_handle');
                }
            }
        } else if (type === 'extension') {
            type = ['extension', 'sandwich'];
            options = [
                options,
                {
                    and: [],
                    not: [],
                },
            ];
        } else if (
            type === 'coupling' &&
            coupling &&
            (conf.SashesType === 'Fix' || !mullion.parentSash)
        ) {
            if (coupling.framesId.length === 1 && coupling.otherFramesId.length === 1) {
                type = ['fixed_mullion', 'coupling'];
                options = [
                    {
                        and: [coupling.direction + '_frame'],
                        not: [],
                    },
                    {
                        and: [],
                        not: [],
                    },
                ];
            }
        } else if (type === 'frame') {
            if (frame.lowThreshold && !altFrame && positionMap[position] === 'bottom') {
                type = 'threshold';
            }
            options = {
                and: [],
                not: [],
                filter: (profile, conf) => {
                    return (
                        (conf && this.profilesService.matchDoorLightSize(profile, conf)) || false
                    );
                },
            };

            if (type === 'frame') {
                const hasOutOpenSash = conf.Sashes.some(
                    (s) => s.frameId === frame.id && s.type?.out_open
                );
                if (hasOutOpenSash) {
                    options.and.push('for_outward_opening_window');
                } else {
                    options.and.push('for_inward_opening_window');
                }
            }
        }

        return [type, options];
    }

    canBeChangedSashProfile(
        conf: WindowActiveConfiguration,
        position: 'left' | 'right' | 'top' | 'bottom',
        sash: ActiveSash,
        selectedProfile: Profile,
    ) {
        const nearMullion = sash.nearMullions[position];
        if (nearMullion > -1) {
            const mullion = this.getMullion(conf, nearMullion);
            if (mullion?.type === 'false_mullion') {
                if (
                    sash.type?.passive &&
                    sash.type?.handle_position === (position === 'right' ? 'R' : 'L') &&
                    !this.profilesService.isProfileCompatibleWithProfile(mullion.profileId, selectedProfile.id)
                ) {
                    return false;
                }
                const nearSash =
                    position === 'right' ? mullion.multiAlignRight[0] : mullion.multiAlignLeft[0];
                if (
                    nearSash &&
                    nearSash.type?.passive &&
                    nearSash.type?.handle_position === (position === 'right' ? 'L' : 'R')
                ) {
                    const nearSashProfile = this.profilesService.getProfile(
                        nearSash.frame[position === 'right' ? 'left' : 'right'].profileId
                    );
                    if (nearSashProfile.compatibleActiveSashProfileId) {
                        return nearSashProfile.compatibleActiveSashProfileId === selectedProfile.id;
                    }
                }
            }
        }
        return true;
    }
}
