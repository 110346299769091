/* eslint-disable max-statements */
import { Common } from '@icc/common/Common';
import {
    ConfigurationsService,
    AppConfigFactory,
    APP_CONFIG,
    WindowActiveConfiguration,
} from '@icc/common';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { core } from '@icc/common/helpers';
import { Injectable, Inject } from '@angular/core';
import { HandlesService } from './handles.service';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { TranslateService } from '@icc/common/translate.service';

@Injectable()
export class FittingService {
    private isDoorVersion2: any;

    fittings: any[] = [];
    handles: any[] = [];
    loadedData = false;

    showInnerLeverBox = (conf: DoorActiveConfiguration | WindowActiveConfiguration) =>
        conf.HandleType === 'DoubleLever'
        || conf.HandleType === 'LeverPull'
        || conf.HandleType === 'InnerLever';
    showInnerPullBox = (conf: DoorActiveConfiguration | WindowActiveConfiguration) =>
        conf.HandleType === 'DoublePull';
    showOuterPullBox = (conf: DoorActiveConfiguration | WindowActiveConfiguration) =>
        conf.HandleType === 'DoublePull' || conf.HandleType === 'LeverPull';
    showWindowLeverBox = (conf: DoorActiveConfiguration | WindowActiveConfiguration) =>
        conf.OwnedSashesTypes.window
        && conf.Sashes.some(
            el => !['F', 'FF', 'OFF', 'DRA', 'DRP', 'DOA', 'DOP'].includes(el.type.type)
        )
        && conf.HasHandle
        && this.isDoorVersion2;

    constructor(
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    init() {
        this.isDoorVersion2 =
            this.config().IccConfig.Configurators.door.version === 2
            && this.configurationsService.conf.Current.type === 'door';
        this.fittings = this.configuratorsDataService.data.windowFittings;
        this.loadedData = true;
    }

    innerLeverBox = (conf: DoorActiveConfiguration | WindowActiveConfiguration) => {
        let img = '/files/windowaccessory/' + conf.Handle.img;

        if (
            Common.isObject(conf.Handle.colors_imgs)
            && Common.isString(conf.Handle.colors_imgs[conf.HandleColor.id])
            && conf.Handle.colors_imgs[conf.HandleColor.id] !== ''
        ) {
            img =
                '/files/windowhandlescolorswindowaccessory/'
                + conf.Handle.colors_imgs[conf.HandleColor.id];
        }

        let name = conf.OneHandle ? conf.Handle.name :  this.translateService.instant('WINDOW|Różne klamki w kwaterach');
        let color = conf.OneHandle ? conf.HandleColor.name : '';
        let colorOuter = conf.HandleOuterColor.name;
        let colorId = conf.HandleColor.id;
        let colorOuterId = conf.HandleOuterColor.id;
        let selectType = 'default';
        let oneHandle = conf.Handle && conf.OneHandle && conf.HasHandle;
        let varHandle = !conf.OneHandle && conf.HasHandle;
        let hasHandle = conf.HasHandle;
        const isOuterHandler = this.config().IccConfig.Configurators.sliding_door.outerHandles;
        const hasSecondColor = ((['window', 'sliding_door'].indexOf(conf.type) === -1 || !isOuterHandler) && conf.HandleType === 'DoubleLever')
            || (
                conf.type === 'sliding_door'
                && isOuterHandler
                && conf.Handle.outer_handles_id?.length === 0
                && conf.HandleType === 'DoubleLever'
            );

        if (
            this.isDoorVersion2
            && conf.OwnedSashesTypes.window
            && conf.Sashes.some(
                el =>
                    el.type
                    && !['F', 'FF', 'OFF', 'DRA', 'DRP', 'DOA', 'DOP'].includes(el.type.type)
            )
            && conf.HasHandle
        ) {
            name = conf.Handle.name;
            selectType = 'door';

            if (Common.isObject(conf.OneHandleSash.door)) {
                name = conf.OneHandleSash.door.name;
                color = conf.OneHandleSash.doorColor.name;
                colorOuter = conf.OneHandleOuterSash.doorColor.name;
                colorId = conf.OneHandleSash.doorColor.id;
                colorOuterId = conf.OneHandleOuterSash.doorColor.id;
                oneHandle = conf.OneHandleSash.door && conf.HasHandle;
                varHandle = !conf.OneHandleSash.door && conf.HasHandle;
                hasHandle = conf.HasHandle;
            }
        }
        return {
            img,
            name,
            color,
            colorOuter,
            colorId,
            colorOuterId,
            selectType,
            oneHandle,
            varHandle,
            hasHandle,
            hasSecondColor
        };
    };

    outerLeverBox = (conf: DoorActiveConfiguration | WindowActiveConfiguration) => {
        let img = '/files/windowaccessory/' + conf.HandleOuter.img;

        if (
            Common.isObject(conf.HandleOuter.colors_imgs)
            && Common.isString(conf.HandleOuter.colors_imgs[conf.HandleOuterColor.id])
            && conf.HandleOuter.colors_imgs[conf.HandleOuterColor.id] !== ''
        ) {
            img =
                '/files/windowhandlescolorswindowaccessory/'
                + conf.HandleOuter.colors_imgs[conf.HandleOuterColor.id];
        }

        const name = conf.HandleOuter.name;
        const color = conf.HandleColor.name;
        const colorOuter = conf.HandleOuterColor.name;
        const colorId = conf.HandleColor.id;
        const colorOuterId = conf.HandleOuterColor.id;
        const selectType = 'default';
        const oneHandle = false;
        const varHandle = !conf.OneHandle && conf.HasHandle;
        const hasHandle = conf.HasHandle;

        const hasSecondColor = false;
        return {
            img,
            name,
            color,
            colorOuter,
            colorId,
            colorOuterId,
            selectType,
            oneHandle,
            varHandle,
            hasHandle,
            hasSecondColor
        };
    };

    innerPullBox = (conf: DoorActiveConfiguration | WindowActiveConfiguration) => {
        let img = '/files/windowaccessory/' + conf.Handle.img;

        if (
            Common.isObject(conf.Handle.colors_imgs)
            && Common.isString(conf.Handle.colors_imgs[conf.HandleColor.id])
            && conf.Handle.colors_imgs[conf.HandleColor.id] !== ''
        ) {
            img =
                '/files/windowhandlescolorswindowaccessory/'
                + conf.Handle.colors_imgs[conf.HandleColor.id];
        }

        const name = conf.Handle.name;
        const color = conf.HandleColor.name;
        const colorOuter = conf.HandleOuterColor.name;
        const colorId = conf.HandleColor.id;
        const colorOuterId = conf.HandleOuterColor.id;
        const selectType = 'door';
        const description = conf.Handle.description;
        const oneHandle = conf.Handle && conf.OneHandle && conf.HasHandle;
        const varHandle = !conf.OneHandle && conf.HasHandle;
        const hasHandle = conf.HasHandle;
        return {
            img,
            name,
            color,
            colorOuter,
            colorId,
            colorOuterId,
            selectType,
            description,
            oneHandle,
            varHandle,
            hasHandle,
            hasSecondColor: false
        };
    };

    outerPullBox = (conf: DoorActiveConfiguration | WindowActiveConfiguration) => {
        let img = '/files/windowaccessory/' + conf.HandleOuter.img;

        if (
            Common.isObject(conf.HandleOuter.colors_imgs)
            && Common.isString(conf.HandleOuter.colors_imgs[conf.HandleOuterColor.id])
            && conf.HandleOuter.colors_imgs[conf.HandleOuterColor.id] !== ''
        ) {
            img =
                '/files/windowhandlescolorswindowaccessory/'
                + conf.HandleOuter.colors_imgs[conf.HandleOuterColor.id];
        }

        const name = conf.HandleOuter.name;
        const color = conf.HandleColor.name;
        const colorOuter = conf.HandleOuterColor.name;
        const colorId = conf.HandleColor.id;
        const colorOuterId = conf.HandleOuterColor.id;
        const selectType = 'door';
        const description = conf.HandleOuter.description;
        const oneHandle = conf.HandleOuter && conf.OneHandle && conf.HasHandle;
        const varHandle = !conf.HandleOuter && conf.OneHandle && conf.HasHandle;
        const hasHandle = conf.HasHandle;
        return {
            img,
            name,
            color: colorOuter,
            colorOuter: color,
            colorId: colorOuterId,
            colorOuterId: colorId,
            selectType,
            description,
            oneHandle,
            varHandle,
            hasHandle,
            hasSecondColor: false
        };
    };
    hingeBoxSelect = (conf: DoorActiveConfiguration | WindowActiveConfiguration) => {
        let img = conf.Hinge ? '/files/windowaccessory/' + conf.Hinge.img : '';

        const name = conf.Hinge ? conf.Hinge.name : '';
        const color = conf.Hinge ? conf.HingeColor.name : '';
        const colorId = conf.Hinge ? conf.HingeColor.id : '';
        const selectType = 'window';
        const description = conf.Hinge ? conf.Hinge.description : '';
        return {
            img,
            name,
            color,
            colorId,
            selectType,
            description,
            hasSecondColor: false
        };
    };

    windowLeverBox = (conf: DoorActiveConfiguration | WindowActiveConfiguration) => {
        let img = '/files/windowaccessory/' + (conf.OneHandleSash.window?.img || '');

        if (
            Common.isObject(conf.OneHandleSash.window)
            && Common.isObject(conf.OneHandleSash.window.colors_imgs)
            && Common.isString(
                conf.OneHandleSash.window.colors_imgs[conf.OneHandleOuterSash.windowColor.id]
            )
            && conf.OneHandleSash.window.colors_imgs[conf.OneHandleOuterSash.windowColor.id] !== ''
        ) {
            img =
                '/files/windowhandlescolorswindowaccessory/'
                + conf.OneHandleSash.window.colors_imgs[conf.HandleOuterColor.id];
        }

        const name = conf.OneHandleSash.window.name;
        const color = conf.OneHandleSash.windowColor.name;
        const colorOuter = conf.OneHandleOuterSash.windowColor.name;
        const colorId = conf.HandleColor.id;
        const colorOuterId = conf.HandleOuterColor.id;
        const selectType = 'window';
        const description = conf.OneHandleSash.window.description;
        const oneHandle = Common.isObject(conf.OneHandleSash.window);
        const varHandle = !oneHandle;
        const hasHandle = this.showWindowLeverBox(conf);
        return {
            img,
            name,
            color,
            colorOuter,
            colorId,
            colorOuterId,
            selectType,
            description,
            oneHandle,
            varHandle,
            hasHandle,
            hasSecondColor: false
        };
    };
}
