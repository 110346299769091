<mat-card *ngFor="let product of products; index as i;">
  <h2>{{product.name}}</h2>
  <p><b>{{ 'DOOR|Indeks:' | translate }} </b>{{product.code}}</p>
  <p><b>{{ 'DOOR|Indeks rozszerzony:' | translate }} </b>{{product.codeExt}}</p>
  <p *ngIf="this.curLang">
    <b>{{ 'DOOR|Nazwa:' | translate }} </b>{{product.localizedNames[this.curLang] ? product.localizedNames[this.curLang] : ''}}
  </p>
  <p *ngIf="!hiddenPrice">
    <b>{{ 'DOOR|Cena:' | translate }} </b><s *ngIf="product.listUnitPrice && product.listUnitPrice != '0' && product.listUnitPrice !== product.unitPrice">{{product.listUnitPrice}}</s> {{product.unitPrice}}
  </p>
  <p><b>{{ 'DOOR|Ilość:' | translate }} </b>{{product.quantity}}</p>
  <p *ngIf="product.altProductionTime !== undefined"><b>{{ 'DOOR|Czas realizacji:' | translate }} </b>{{product.altProductionTime}}</p>
  <p *ngIf="product.configuration && product.configuration.length > 0"><b>{{ 'DOOR|Cechy:' | translate }} </b></p>
  <ul *ngIf="product.configuration && product.configuration.length > 0">
    <li *ngFor="let feature of product.configuration">
      {{feature.field}}: {{feature.value}}
    </li>
  </ul>
  <br>
</mat-card>
