import { core } from '@icc/common/helpers';
import { IWindowConfiguration } from '../../WindowConfiguration';
import { DoorConfiguration } from '../../DoorConfiguration';
import { isNumber } from '@icc/helpers';
import { WindowConfigurationLegacy14 } from '../../legacy/WindowConfigurationLegacy14';
import { DoorPortal, DoorPortalLegacy, IccColor, IccConstructColor } from '@icc/common/data-types';
import { iccColorToColorId } from './colors';
import { Sash, SashLegacy14 } from '@icc/window';
import { Accessory, AccessoryLegacy, AccessoryLegacy14, ConstructionColor } from '../../parts/common';
import { ColorLegacy14 } from '../../legacy/parts/common/Colors';
import { IWindowConfigurationLegacy15 } from '../../legacy/WindowConfigurationLegacy15';

// eslint-disable-next-line max-statements
export function changesInVersion15(
    oldConfiguration: WindowConfigurationLegacy14,
    dataRequiredToUpdate: {
        langCode: string;
        /**
         * Kolory
         */
        colors: Record<IccColor['id'], IccColor>;
        /**
         * Kolor konstrukcji
         */
        windowColorsAll?: IccConstructColor[] | undefined;
    }
): IWindowConfigurationLegacy15 {
    const { glazingBeadColors, ...restDictionary } = oldConfiguration.dictionary;
    const configuration: IWindowConfigurationLegacy15 = {
        ...core.copy(oldConfiguration),
        priceAfterDiscountsInCurrency: oldConfiguration.priceAfterDiscounts,
        priceBeforePromotions: oldConfiguration.price,
        priceGrossBeforePromotions: oldConfiguration.priceGross,
        priceSegmentsBeforePromotions: oldConfiguration.priceSegments,
        pricePartsBeforePromotions: oldConfiguration.priceParts,
        dictionary: {
            ...restDictionary,
            constrColors: {},
            colors: {},
            sealColors: {},
            siliconeColors: {},
            accessoryColors: {},
        },
        doorPortal: oldConfiguration.doorPortal ? convertDoorPortal(oldConfiguration.doorPortal, dataRequiredToUpdate) : undefined,
        issues: [],
    };
    for (const [key] of Object.entries(oldConfiguration.dictionary.colors)) {
        const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === Number(key.split('+')[0]));
        if(constrColor?.id)  {
            configuration.dictionary.constrColors[constrColor.id] = {
                ...constrColor,
                name: {
                    [dataRequiredToUpdate.langCode]: constrColor.name,
                },
            };
        } else {
            const constrColorId = Number(key.split('+')[0]);
            configuration.dictionary.constrColors[constrColorId] = getConstructColorFromColors(oldConfiguration.dictionary.colors[key], dataRequiredToUpdate.colors, constrColorId, dataRequiredToUpdate.langCode);
        }
    }

    (['frame', 'sash'] as const).forEach(part => {
        let coreConstColor: IccConstructColor | undefined;
        (['core', 'alushell'] as const).forEach(side => {
            if (oldConfiguration.colors[part][side]?.id) {
                const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +oldConfiguration.colors[part][side]?.id);
                if(constrColor?.id) {
                    const color = dataRequiredToUpdate.colors[constrColor.colorId];
                    if(color?.id) {
                        configuration.dictionary.colors[
                            Number(color.id)
                        ] = {
                            id: Number(color.id),
                            name: {
                                [dataRequiredToUpdate.langCode]: color.name,
                            },
                            code: color.code,
                            color: color.color,
                            colorImg: color.colorImg,
                            woodTypeId: +color.woodTypeId,
                            colorsPaletteId: +color.colorsPaletteId,
                            visualization: color.visualization,
                            isCore: color.isCore,
                            isRal: color.isRal,
                            colorGroups: color.colorGroups,
                            constructColors: color.constructColors,
                            coreColors: color.coreColors,
                            systems: color.systems
                        };
                    }
                    configuration.colors[part][side] = {
                        id: Number(color.id),
                        isDefault: oldConfiguration.colors[part][side].isDefault,
                    };
                }

                if (side === 'core') {
                    coreConstColor = constrColor;
                }
            }
        });
        (['outer', 'inner'] as const).forEach(side => {
            if (oldConfiguration.colors[part][side]?.id) {
                const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +oldConfiguration.colors[part][side]?.id);
                if(constrColor?.id) {
                    configuration.dictionary.constrColors[
                        Number(oldConfiguration.colors[part][side].id)
                    ] = {
                        ...constrColor,
                        name: {
                            [dataRequiredToUpdate.langCode]: constrColor.name,
                        },
                    };
                }
                configuration.colors[part][side] = {
                    id: Number(oldConfiguration.colors[part][side].id),
                    isDefault: oldConfiguration.colors[part][side].isDefault,
                };
            } else if (coreConstColor?.id) {
                configuration.dictionary.constrColors[
                    Number(coreConstColor.id)
                ] = {
                    ...coreConstColor,
                    name: {
                        [dataRequiredToUpdate.langCode]: coreConstColor.name,
                    },
                };
                configuration.colors[part][side] = {
                    id: Number(coreConstColor.id),
                    isDefault: oldConfiguration.colors[part].core.isDefault,
                };
            }
        });
    });

    configuration.sideProfiles = configuration.sideProfiles.map(c => {
        let coreColor: IccColor | undefined;
        let coreConstColor: IccConstructColor | undefined;
        if (c.color?.core) {
            const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +c.color.core.id);
            if (constrColor?.colorId) {
                const color = dataRequiredToUpdate.colors[constrColor.colorId];
                coreColor = color;
                coreConstColor = constrColor;
                configuration.dictionary.colors[color.id] = {
                    ...color,
                    name: {
                        [dataRequiredToUpdate.langCode]: color.name,
                    },
                };
            }
        }
        if (c.color.outer?.id) {
            const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +c.color.outer.id);
            if(constrColor?.id) {
                configuration.dictionary.constrColors[constrColor.id] = {
                    ...constrColor,
                    name: {
                        [dataRequiredToUpdate.langCode]: constrColor.name,
                    },
                };
            }
        } else if (coreConstColor?.id) {
            configuration.dictionary.constrColors[coreConstColor.id] = {
                ...coreConstColor,
                name: {
                    [dataRequiredToUpdate.langCode]: coreConstColor.name,
                },
            };
            c.color.outer = {
                id: coreConstColor.id,
                isDefault: c.color.core.isDefault,
            };
        }
        if (c.color.inner?.id) {
            const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +c.color.inner.id);
            if(constrColor?.id) {
                configuration.dictionary.constrColors[constrColor.id] = {
                    ...constrColor,
                    name: {
                        [dataRequiredToUpdate.langCode]: constrColor.name,
                    },
                };
            }
        } else if (coreConstColor?.id) {
            configuration.dictionary.constrColors[coreConstColor.id] = {
                ...coreConstColor,
                name: {
                    [dataRequiredToUpdate.langCode]: coreConstColor.name,
                },
            };
            c.color.inner = {
                id: coreConstColor.id,
                isDefault: c.color.core.isDefault,
            };
        }
        let alushellColor: IccColor | undefined;
        if (c.color.alushell) {
            const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +c.color.alushell.id);
            if(constrColor?.colorId) {
                const color = dataRequiredToUpdate.colors[constrColor.colorId];
                alushellColor = color;
                if(color?.id) {
                    configuration.dictionary.colors[color.id] = {
                        ...color,
                        name: {
                            [dataRequiredToUpdate.langCode]: color.name,
                        },
                    };
                }
            }
        }

        return {
            ...c,
            color: {
                inner: c.color.inner,
                outer: c.color.outer,
                core: iccColorToColorId(coreColor),
                alushell: iccColorToColorId(alushellColor),
            }
        };
    });

    if (oldConfiguration.sealColor?.id && oldConfiguration.dictionary.sealColors[oldConfiguration.sealColor.id]) {
        configuration.dictionary.sealColors[oldConfiguration.sealColor.id] = {
            ...oldConfiguration.dictionary.sealColors[oldConfiguration.sealColor.id],
            extId: String(oldConfiguration.dictionary.sealColors[oldConfiguration.sealColor.id].extId),
        };
    }
    if (oldConfiguration.siliconeColor?.id && oldConfiguration.dictionary.siliconeColors[oldConfiguration.siliconeColor.id]) {
        configuration.dictionary.siliconeColors[oldConfiguration.siliconeColor.id] = {
            ...oldConfiguration.dictionary.siliconeColors[oldConfiguration.siliconeColor.id],
            extId: String(oldConfiguration.dictionary.siliconeColors[oldConfiguration.siliconeColor.id].extId),
        };
    }

    if (oldConfiguration.dictionary.accessoryColors) {
        for (const [key, value] of Object.entries(oldConfiguration.dictionary.accessoryColors)) {
            configuration.dictionary.accessoryColors[key] = {
                ...value,
                extId: String(value.extId),
            };
        }
    }

    if (oldConfiguration.muntins) {
        if (oldConfiguration.muntins.colorInner?.id) {
            const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +oldConfiguration.muntins.colorInner?.id);
            if(constrColor?.colorId) {
                const color = dataRequiredToUpdate.colors[constrColor.colorId];
                if(color?.id) {
                    configuration.dictionary.colors[color.id] = {
                        ...color,
                        name: {
                            [dataRequiredToUpdate.langCode]: color.name,
                        },
                    };
                    configuration.muntins.colorInner = {
                        id: color.id,
                        isDefault: oldConfiguration.muntins.colorInner.isDefault,
                    };
                }
            }
        }
        if (oldConfiguration.muntins.colorOuter?.id) {
            const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +oldConfiguration.muntins.colorOuter?.id);
            if(constrColor?.colorId) {
                const color = dataRequiredToUpdate.colors[constrColor.colorId];
                if(color?.id) {
                    configuration.dictionary.colors[color.id] = {
                        ...color,
                        name: {
                            [dataRequiredToUpdate.langCode]: color.name,
                        },
                    };
                    configuration.muntins.colorOuter = {
                        id: color.id,
                        isDefault: oldConfiguration.muntins.colorOuter.isDefault,
                    };
                }
            }
        }
    }

    configuration.couplings = configuration.couplings.map(c => {
        let coreColor: IccColor | undefined;
        let coreConstColor: IccConstructColor | undefined;
        if (c.color?.core) {
            const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +c.color.core.id);
            if(constrColor?.colorId) {
                const color = dataRequiredToUpdate.colors[constrColor.colorId];
                coreColor = color;
                coreConstColor = constrColor;
                if(color?.id) {
                    configuration.dictionary.colors[color.id] = {
                        ...color,
                        name: {
                            [dataRequiredToUpdate.langCode]: color.name,
                        },
                    };
                }
            }
        }
        if (c.color?.outer?.id) {
            const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +c.color.outer.id);
            if(constrColor?.id) {
                configuration.dictionary.constrColors[constrColor.id] = {
                    ...constrColor,
                    name: {
                        [dataRequiredToUpdate.langCode]: constrColor.name,
                    },
                };
            }
        } else if (coreConstColor?.id) {
            configuration.dictionary.constrColors[coreConstColor.id] = {
                ...coreConstColor,
                name: {
                    [dataRequiredToUpdate.langCode]: coreConstColor.name,
                },
            };
            c.color.outer = {
                id: coreConstColor.id,
                isDefault: c.color.core.isDefault,
            };
        }
        if (c.color?.inner?.id) {
            const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +c.color.inner.id);
            if(constrColor?.id) {
                configuration.dictionary.constrColors[constrColor.id] = {
                    ...constrColor,
                    name: {
                        [dataRequiredToUpdate.langCode]: constrColor.name,
                    },
                };
            }
        } else if (coreConstColor?.id) {
            configuration.dictionary.constrColors[coreConstColor.id] = {
                ...coreConstColor,
                name: {
                    [dataRequiredToUpdate.langCode]: coreConstColor.name,
                },
            };
            c.color.inner = {
                id: coreConstColor.id,
                isDefault: c.color.core.isDefault,
            };
        }
        let alushellColor: IccColor | undefined;
        if (c.color?.alushell) {
            const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +c.color.alushell.id);
            if(constrColor?.colorId) {
                const color = dataRequiredToUpdate.colors[constrColor.colorId];
                alushellColor = color;
                if(color?.id) {
                    configuration.dictionary.colors[color.id] = {
                        ...color,
                        name: {
                            [dataRequiredToUpdate.langCode]: color.name,
                        },
                    };
                }
            }
        }

        return {
            ...c,
            color: {
                inner: c.color?.inner,
                outer: c.color?.outer,
                core: iccColorToColorId(coreColor),
                alushell: iccColorToColorId(alushellColor),
            }
        };
    });

    if (configuration.thresholdColor?.id) {
        const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +configuration.thresholdColor?.id);
        if(constrColor?.colorId) {
            const color = dataRequiredToUpdate.colors[constrColor.colorId];
            if(color?.id) {
                configuration.dictionary.colors[color.id] = {
                    ...color,
                    name: {
                        [dataRequiredToUpdate.langCode]: color.name,
                    },
                };
                configuration.thresholdColor = {
                    id: color.id,
                    isDefault: configuration.thresholdColor.isDefault,
                };
            }
        }
    }

    if (configuration.lippingColor?.id) {
        const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +configuration.lippingColor?.id);
        if(constrColor?.colorId) {
            const color = dataRequiredToUpdate.colors[constrColor.colorId];
            if(color?.id) {
                configuration.dictionary.colors[color.id] = {
                    ...color,
                    name: {
                        [dataRequiredToUpdate.langCode]: color.name,
                    },
                };
                configuration.lippingColor = {
                    id: color.id,
                    isDefault: configuration.lippingColor.isDefault,
                };
            }
        }
    }

    if (configuration.innerLippingColor?.id) {
        const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +configuration.innerLippingColor?.id);
        if(constrColor?.colorId) {
            const color = dataRequiredToUpdate.colors[constrColor.colorId];
            if(color?.id) {
                configuration.dictionary.colors[color.id] = {
                    ...color,
                    name: {
                        [dataRequiredToUpdate.langCode]: color.name,
                    },
                };
                configuration.innerLippingColor = {
                    id: color.id,
                    isDefault: configuration.innerLippingColor.isDefault,
                };
            }
        }
    }

    configuration.sashes = configuration.sashes.map(sash => convertSash(sash, configuration, dataRequiredToUpdate));

    configuration.accessories = oldConfiguration.accessories.map(
        accessory => convertAccessory(accessory, dataRequiredToUpdate)
    );
    configuration.BlockedAccessories = [];
    configuration.sideAccessories = {
        top: oldConfiguration.sideAccessories.top.map(
            accessory => convertAccessory(accessory, dataRequiredToUpdate)
        ),
        bottom: oldConfiguration.sideAccessories.bottom.map(
            accessory => convertAccessory(accessory, dataRequiredToUpdate)
        ),
        left: oldConfiguration.sideAccessories.left.map(
            accessory => convertAccessory(accessory, dataRequiredToUpdate)
        ),
        right: oldConfiguration.sideAccessories.right.map(
            accessory => convertAccessory(accessory, dataRequiredToUpdate)
        ),
    };

    return configuration;
}


function convertSash(oldSash: SashLegacy14, configuration: IWindowConfigurationLegacy15, dataRequiredToUpdate: {
    langCode: string;
    /**
     * Kolory
     */
    colors: Record<IccColor['id'], IccColor>;
    /**
     * Kolor konstrukcji
     */
    windowColorsAll?: IccConstructColor[] | undefined;

}): Sash {
    const sash: Sash = {
        ...oldSash,
    };

    (['filling', 'panelInner'] as const).forEach(part => {
        (['color', 'overlayColor'] as const).forEach(colorField => {
            if (sash[part]?.[colorField]) {
                (['outer', 'inner'] as const).forEach(side => {
                    if (sash[part][colorField][side]?.id) {
                        const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +sash[part][colorField][side]?.id);
                        if(constrColor?.colorId) {
                            const color = dataRequiredToUpdate.colors[constrColor.colorId];
                            if(color?.id) {
                                configuration.dictionary.colors[color.id] = {
                                    ...color,
                                    name: {
                                        [dataRequiredToUpdate.langCode]: color.name,
                                    },
                                };
                                sash[part][colorField][side] = {
                                    id: color.id,
                                    isDefault: sash[part][colorField][side].isDefault,
                                };
                            }
                        }
                    }
                });
            }
        });
    });

    sash.intSashes = oldSash.intSashes?.map(s => convertSash(s, configuration, dataRequiredToUpdate));

    sash.hardware = oldSash.hardware?.map(h => convertAccessory(h, dataRequiredToUpdate));

    return sash;
}
function convertDoorPortal(doorPortal: DoorPortalLegacy, dataRequiredToUpdate: {
    langCode: string;
    /**
     * Kolory
     */
    colors: Record<IccColor['id'], IccColor>;
    /**
     * Kolor konstrukcji
     */
    windowColorsAll?: IccConstructColor[] | undefined;
}): DoorPortal {
    const doorPortalConstrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +doorPortal.selectedColor?.id);
    const color = dataRequiredToUpdate.colors[doorPortalConstrColor.colorId];

    const convertedDoorPortal: DoorPortal = {
        ...doorPortal,
        selectedColor: {
            ...color,
            isDefault: doorPortal.selectedColor?.isDefault,
        },
    };

    return convertedDoorPortal;
}

function convertAccessory(accessory: AccessoryLegacy14, dataRequiredToUpdate: {
    langCode: string;
    /**
     * Kolory
     */
    colors: Record<IccColor['id'], IccColor>;
    /**
     * Kolor konstrukcji
     */
    windowColorsAll?: IccConstructColor[] | undefined;
}): Accessory {
    if (!accessory.color?.profile) {
        return accessory;
    }

    let coreConstrColor: IccConstructColor | undefined;
    if (accessory.color.profile.core?.id) {
        const constrColor = dataRequiredToUpdate.windowColorsAll.find(color => +color.id === +accessory.color.profile.core?.id);
        if(constrColor?.colorId) {
            const color = dataRequiredToUpdate.colors[constrColor.colorId];
            coreConstrColor = constrColor;
            if(color?.id) {
                accessory.color.profile.core = {
                    id: color.id,
                    isDefault: accessory.color.profile.core.isDefault,
                };
            }
        }
    }

    if (!accessory.color.profile.outer?.id && coreConstrColor) {
        accessory.color.profile.outer = {
            id: coreConstrColor.id,
            isDefault: accessory.color.profile.core.isDefault,
        };
    }

    if (!accessory.color.profile.inner?.id && coreConstrColor) {
        accessory.color.profile.inner = {
            id: coreConstrColor.id,
            isDefault: accessory.color.profile.core.isDefault,
        };
    }

    return accessory;
}

function getConstructColorFromColors(color: ColorLegacy14, colors: Record<number, IccColor>, constructColorId: number, langCode: string) : ConstructionColor {
    let selectedColor: IccColor;
    for (const [key, c] of Object.entries(colors)) {
        if(c.code === color.code) {
            selectedColor = c;
            break;
        }
    }
    const constrColor: ConstructionColor = {
        id: constructColorId,
        colorId: selectedColor?.id ?? 0,
        coreColorId: 0,
        isCore: selectedColor?.isCore ?? false,
        isRal: color?.RAL ?? false,
        group: color?.group ?? '',
        color: color?.color ?? '',
        color_img: color?.color_img ?? null,
        wood_type: null,
        name: color?.name,
        code: color?.code ?? '',
        type: color?.type === 'RAL' ? 'other' : color?.type,
        groups: color?.groups ?? [],
        sides: [],
        order: '1',
        alfa: null,
        locale: langCode,
        systems: [],
        sealColorsIds: [],
        defaultSealColorId: 0,
        visualization: color?.visualization ?? selectedColor?.visualization,
        inInsulation: false
    }
    return constrColor;
}

