import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '../translation-helper';
import { SharedFacade } from '../+state/shared.facade';
import { Subscription } from 'rxjs';
import { TranslateService, ConfigurationsService, AppConfigFactory, APP_CONFIG, ConfiguratorsDataService } from '@icc/common';
import { Product, Sandbox } from '@icc/product-codes-generator';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'icc-product-features',
  templateUrl: './product-features.component.html',
  styleUrls: ['./product-features.component.scss']
})
export class ProductFeaturesComponent extends PageComponent implements OnInit {
  public title = _('INTERFACE|Cechy produktów');

  private subscriptions: Subscription[] = [];

  products!: Product[];
  hiddenPrice = false;
  curLang = this.config()?.CurLang || 'en';
  constructor(
    @Inject(APP_CONFIG) public config: AppConfigFactory,
    private configurationsService: ConfigurationsService<'window' | 'door'>,
    private httpClient: HttpClient
  ) {
    super();
  }

  ngOnInit() {
    this.hiddenPrice = this.config().preset === 'b2c' && this.config().hidePricesInB2cConfigurator;
    this.getAllFeatures();
  }

  async getAllFeatures() {
    const details = this.configurationsService.createSimpleConfiguration(this.configurationsService.conf.Current);
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = String(urlParams.get('key'));
    const confType = urlParams.get('type') || 'window';
    const confMarketId = urlParams.get('marketId') || '1';
    const confLang = urlParams.get('lang') || 'pl';
    const params: {
      type: string,
      marketId: string,
      lang: string
  } = {
      type: confType,
      marketId: confMarketId,
      lang: confLang
  };
    const response = await this.httpClient
        .post<{ products: Product[]}>(
            (this.config().EnvConfig?.remoteHost || window.location.origin)
            + '/api/configurator/product_features',
            {
                configuration: details
            },
            {
                headers: new HttpHeaders({
                    key: myParam,
                }),
                params
            }
        )
        .pipe(map(data => data.products))
        .toPromise();
      this.products = response;
      
  }
}
