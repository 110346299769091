import { Injectable } from '@angular/core';
import { core } from '../helpers';
import { ProfilesService } from '../profiles.service';
import { DoorActiveConfiguration } from '../configurations/DoorActiveConfiguration';
import { WindowActiveConfiguration } from '../configurations/WindowActiveConfiguration';

@Injectable()
export class ColorRestrictionService {
    constructor(
        private profilesService: ProfilesService,
    ) { }

    isColorGroupNotRestricted(colorGroup) {
        return !(
            colorGroup?.max_sheet_metal_height ||
            colorGroup?.min_sheet_metal_height ||
            colorGroup?.max_sheet_metal_width ||
            colorGroup?.min_sheet_metal_width
        );
    }

    isColorGroupBetweenMinAndMaxMetalSheetHeight(colorGroup, sashHeight: number) {
        const min = colorGroup?.min_sheet_metal_height ?? 0;
        const max = colorGroup?.max_sheet_metal_height ?? Number.MAX_VALUE;

        return core.isNumberInRange(sashHeight, Number(min), Number(max));
    }

    isColorGroupBetweenMinAndMaxMetalSheetWidth(colorGroup, sashWidth: number) {
        const min = colorGroup?.min_sheet_metal_width ?? 0;
        const max = colorGroup?.max_sheet_metal_width ?? Number.MAX_VALUE;

        return core.isNumberInRange(sashWidth, Number(min), Number(max));
    }

    getSashWidthInRebateBasedOnConstructionWidth(conf: DoorActiveConfiguration | WindowActiveConfiguration, passiveDoorSash = false) {
        const frameProfile = conf.UsedProfiles.find(p => p.type === 'frame') || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        const mullionProfile = conf.UsedProfiles.find(p => p.type === 'false_mullion') || this.profilesService.getProfile(conf.ProfileSet.falseMullion);
        const leftLightSize = (conf.doorSizes && conf.doorSizes.leftLightSize) || 0;
        const rightLightSize = (conf.doorSizes && conf.doorSizes.rightLightSize) || 0;
        const isPassiveSash = conf.Sashes.some(el => el.type.type === 'DOP' || el.type.type === 'DRP');
        if(passiveDoorSash && isPassiveSash) {
            const passiveSash = conf.Sashes.find(el => el.type.type === 'DOP' || el.type.type === 'DRP');
            return passiveSash.rWidth - (frameProfile?.spaceSash || 0) + (this.getBiggerRebateValueFromVirtualDoorSash(conf) * 2) - (mullionProfile?.spaceSash || 0);
        } else if (isPassiveSash) {
            const activeSash = conf.Sashes.find(el => el.type.type === 'DRA' || el.type.type === 'DOA');
            return activeSash.rWidth - (frameProfile?.spaceSash || 0) + (this.getBiggerRebateValueFromVirtualDoorSash(conf) * 2) - (mullionProfile?.spaceSash || 0);
        }

        return conf.Width - leftLightSize - rightLightSize - (2 * frameProfile?.spaceSash || 0) + (this.getBiggerRebateValueFromVirtualDoorSash(conf) * 2);
    }

    getSashHeightInRebateBasedOnConstructionHeight(conf: DoorActiveConfiguration | WindowActiveConfiguration) {
        const frame = conf.UsedProfiles.find(p => p.type === 'frame') || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        const threshold = conf.UsedProfiles.find(p => p.type === 'threshold') || this.profilesService.getProfile(conf.ProfileSet.threshold);
        const topLight = (conf.doorSizes && conf.doorSizes.topLightSize) || 0;

        return conf.Height - topLight - (frame?.spaceSash || 0) + (threshold?.spaceSash || 0) + this.getBiggerRebateValueFromVirtualDoorSash(conf);
    }

    getBiggerRebateValueFromVirtualDoorSash(conf: DoorActiveConfiguration | WindowActiveConfiguration) {
        const virtualDoorSash = conf.UsedProfiles.find(p => p.type === 'virtual_door_sash');

        if (virtualDoorSash) {
            if (virtualDoorSash.rebateWidth && virtualDoorSash.rebateWidthOut) {
                return Math.max(virtualDoorSash.rebateWidth, virtualDoorSash.rebateWidthOut)
            } else if (virtualDoorSash.rebateWidth && !virtualDoorSash.rebateWidthOut) {
                return virtualDoorSash.rebateWidth;
            } else if (!virtualDoorSash.rebateWidth && virtualDoorSash.rebateWidthOut) {
                return virtualDoorSash.rebateWidthOut
            } else {
                return 0;
            }
        }
    }
}
