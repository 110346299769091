import { Injectable } from '@angular/core';
import {
    ConfiguratorsDataService,
    ConfigurationsService,
    EventBusService,
    ProfilesService,
    WindowActiveConfiguration,
} from '@icc/common';
import { DoorPortal } from '@icc/common/data-types';
import { Profile } from '@icc/window';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DoorPortalsService {
    allPortals: Profile[] = [];
    selectedPortalOptions$ = new BehaviorSubject<PortalOptions>({});
    portalsLoaded = false;

    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'window'>,
        private eventBusService: EventBusService,
        private profilesService: ProfilesService,
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration(
            ['loadedConfiguratorsData'],
            () => {
                this.init();
            }
        );

        this.eventBusService.subscribe<unknown[]>(['changedFrames'], (data) => {
            this.afterFrameChanged(data.activeConfiguration as WindowActiveConfiguration);
        })
        this.eventBusService.subscribe<unknown[]>(['setFrameProfile'], (data) => {
            this.validateAndFixIssues(data.activeConfiguration as WindowActiveConfiguration);
            this.afterFrameChanged(data.activeConfiguration as WindowActiveConfiguration);
        });
    }

    init() {
        this.allPortals = this.profilesService.getProfilesByType('portal');
        this.portalsLoaded = true;
    }

    getPortalById(id: number | null) {
        return this.allPortals.find((p) => Number(p.id) === id);
    }

    matchPortalsToFrame(profileId: number) {
        const profilesPortalsIds = this.profilesService.getProfilesCompatibleProfiles();
        const portalsRelatedToProfile = profilesPortalsIds.filter(
            (c) => Number(c.profile_id) === profileId
        );

        const portals = this.profilesService.getProfilesByType('portal');

        return portals.filter((c) =>
            portalsRelatedToProfile.some((p) => c.id === Number(p.compatible_profile_id))
        );
    }

    getPortalPlateTypes(portals: DoorPortal[]) {
        const portalTypes = [];

        for (const p of portals) {
            if (p.plateType) {
                portalTypes.push(p.plateType);
            }
        }

        return portalTypes;
    }

    getPortalSizes(portals: DoorPortal[]) {
        const leftSideWidths: number[] = [];
        const rightSideWidths: number[] = [];
        const upperHeights: number[] = [];

        for (const p of portals) {
            if (p.leftPortal) {
                leftSideWidths.push(Number(p.portalSizes));
            }
            if (p.rightPortal) {
                rightSideWidths.push(Number(p.portalSizes));
            }
            if (p.upperPortal) {
                upperHeights.push(Number(p.portalSizes));
            }
        }

        leftSideWidths.sort((a, b) => a - b);
        rightSideWidths.sort((a, b) => a - b);
        upperHeights.sort((a, b) => a - b);

        return { leftSideWidths, rightSideWidths, upperHeights };
    }

    setPortal(portal: DoorPortal, conf = this.configurationsService.conf.Current) {
        if (!portal) {
            this.unsetPortal(conf);
        } else if (portal) {
            conf.doorPortal = portal.selectedPortal;
            if (conf.doorPortal) {
                conf.doorPortal.plateType = portal.plateType;
                conf.doorPortal.depth = portal.depth;
                conf.doorPortal.wallThickness = portal.wallThickness;
                conf.doorPortal.leftWidth = portal.leftWidth;
                conf.doorPortal.rightWidth = portal.rightWidth;
                conf.doorPortal.upperHeight = portal.upperHeight;
                conf.doorPortal.surcharge =
                    conf.doorPortal.plateType && conf.doorPortal.portalSurcharges &&
                    this.getSurchargeBasedOnPlateType(conf.doorPortal.plateType, conf.doorPortal.portalSurcharges);
                conf.doorPortal.prices = conf.doorPortal.pricesForLength ? this.getPricesForLength(conf.doorPortal.pricesForLength) : '';
            }
            this.eventBusService.post({ key: 'setDoorPortal', value: {} });
        }
    }

    afterFrameChanged(conf: WindowActiveConfiguration) {
        if (conf.doorPortal && conf.doorPortal?.id) {
            conf.doorPortal.upperWidth = this.getUpperWidth(conf);
        }
    }

    getUpperWidth(conf = this.configurationsService.conf.Current) {
        return conf.Frames[0].width + (conf.doorPortal?.leftWidth || 0) + (conf.doorPortal?.rightWidth || 0);
    }

    unsetPortal(conf = this.configurationsService.conf.Current) {
        conf.doorPortal = null;
    }

    getSurchargeBasedOnPlateType(plateType: string, portalSurcharges: string) {
        const portalSurchages = JSON.parse(portalSurcharges);
        return portalSurchages.find((p) => p.plateType === plateType).surcharges;
    }

    getPricesForLength(pricesForLength: string) {
        const prices = JSON.parse(pricesForLength);
        const pricesArr: Prices[] = [];

        for (const p of prices) {
            p.prices.portal.map((x) =>
                pricesArr.push(this.mapPrices(p.length || p.depth, x.left, x?.list_left, x.upper, x?.list_upper, x.right, x?.list_right, x?.depth, x?.list_depth))
            );
        }

        return pricesArr;
    }
    // eslint-disable-next-line max-params
    private mapPrices(length: number, left: number, listLeft: number, upper: number, listUpper: number, right: number, listRight: number, depth: number, listDepth: number) {
        if (depth) {
            return { length, left, list_left: listLeft, upper, list_upper: listUpper, right, list_right: listRight, depth, list_depth: listDepth };
        } else {
            return { length, left, list_left: listLeft, upper, list_upper: listUpper, right, list_right: listRight };
        }
    }

    getPortalWallDepth(portalId: number) {
        const selectedPortal = this.getPortalById(portalId);
        const minimumPortalDepth = selectedPortal?.minimumPortalDepth || 0;
        const maximumPortalDepth = selectedPortal?.maximumPortalDepth || 0;

        return { minimumPortalDepth, maximumPortalDepth };
    }

    calculatePortalWallThickness(
        additionalWallThickness: number,
        minimumPortalDepth: number,
        maximumPortalDepth: number
    ) {
        const minimumWallThickness = minimumPortalDepth + additionalWallThickness;
        const maximumWallThickness = maximumPortalDepth + additionalWallThickness;

        return { minimumWallThickness, maximumWallThickness };
    }

    getPortalBasedOnWallThickness(
        wallWidth: number,
        frameAdditionalWallWidth: number,
        portals: Profile[]
    ) {
        const width = wallWidth - frameAdditionalWallWidth;

        return portals.find((p) => width >= p.minimumPortalDepth && width <= p.maximumPortalDepth);
    }

    getPortalSizesForPortalSides(portal: Profile) {
        const availablePortalSizes: DoorPortal[] = [];
        const availableSizes = portal.portalSizes ? JSON.parse(portal.portalSizes) : [];
        availableSizes.forEach((s: DoorPortal) => availablePortalSizes.push(s));

        const { leftSideWidths, rightSideWidths, upperHeights } = this.getPortalSizes(availablePortalSizes);

        return { leftSideWidths, rightSideWidths, upperHeights };
    }

    getPortalPlateType(portal: Profile) {
        const portalSurcharges = portal.portalSurcharges ? JSON.parse(portal.portalSurcharges) : [];
        const portalPlateTypes = this.getPortalPlateTypes(portalSurcharges);

        return portalPlateTypes;
    }

    getAvailableSelectedPortal(conf = this.configurationsService.conf.Current, availablePortals: Profile[]) {
        return availablePortals.find(p => p.id === conf.doorPortal?.id) || availablePortals[0];
    }

    getAvailableFramesWithPortals(conf = this.configurationsService.conf.Current) {
        const availableFrames = this.profilesService.getFilteredProfiles(conf, 'frame');

        const frames: Profile[] = [];
        availableFrames.forEach(frame => {
            if (this.profilesService.matchProfileToFrame(frame.id, 'portal').length > 0) {
                    frames.push(frame);
                }
            }
        );

        return frames;
    }

    getAvailablePortalsInSelectedFrame(conf = this.configurationsService.conf.Current) {
        const framesWithPortals = this.getAvailableFramesWithPortals(conf);
        const selectedFrame = conf.UsedProfiles.find(p => p.type === "frame");

        if (selectedFrame) {
            return framesWithPortals.filter(p => p.id === selectedFrame.id);
        } else {
            return [];
        }
    }

    isPortalValid(portalId: number, conf = this.configurationsService.conf.Current) {
        return this.isPortalAvailableInDoorLights(conf) && this.isPortalAvailableInSelectedOpeningType(portalId);
    }

    getPortalsInAvailableFrames(conf = this.configurationsService.conf.Current) {
        return this.getAvailableFramesWithPortals(conf).filter((p) => {
            const portals = this.matchPortalsToFrame(Number(p.id));
            return portals.find((k) => this.isPortalValid(Number(k.id)));
        });
    }

    isPortalAvailableInDoorLights(conf = this.configurationsService.conf.Current) {
        if (conf.OwnedSashesTypes?.doorTopLight || conf.OwnedSashesTypes?.doorLeftLight || conf.OwnedSashesTypes?.doorRightLight) {
            return false;
        } else {
            return true;
        }
    }

    isPortalAvailableInSelectedOpeningType(id: number, conf = this.configurationsService.conf.Current) {
        const portal = this.getPortalById(id);
        const openingType = this.getOpeningType(conf);
        if (portal && portal.outwardOpening && openingType === "outward") {
            return true;
        }
        if (portal && portal.inwardOpening && openingType === "inward") {
            return true;
        }
        return false;
    }

    getOpeningType(conf = this.configurationsService.conf.Current) {
        let openingType: 'inward' | 'outward' | null = null;
        if (conf.Sashes && conf.Sashes.length) {
            openingType = conf.Sashes.some(
                s => s.type && s.type.type === 'DOA'
            )
                ? 'outward'
                : 'inward';

            return openingType;
        }
    }

    setSelectedPortalOptions(value: PortalOptions) {
        this.selectedPortalOptions$.next(value);
    }

    get selectedPortalOptions() {
        return this.selectedPortalOptions$.getValue();
    }

    validateAndFixIssues(conf = this.configurationsService.conf.Current) {
        if(this.portalsLoaded) {
            const pauseId = this.eventBusService.pause(['setFrameProfile']);
            try {
                const selectedFrameId = conf.UsedProfiles.find(p => p.type === "frame")?.id;
                const availablesPortals = this.matchPortalsToFrame(Number(selectedFrameId));
                if (availablesPortals.length === 0 || (conf.doorPortal?.id && !this.isPortalValid(conf.doorPortal?.id))) {
                    this.unsetPortal(conf);
                }
            } finally {
                this.eventBusService.resume(['setFrameProfile'], pauseId);
            }
        }
    }
}

type Prices = {
    length: number;
    left: number;
    right: number;
    upper: number;
};

export type PortalOptions = {
    isPortalSelected?: boolean;
    leftWidth?: number,
    rightWidth?: number,
    upperHeight?: number,
    wallThickness?: number,
    plateType?: string
}
