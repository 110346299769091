const snakeCase = require('snake-case');
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfigFactory } from '../config';
import { ConfiguratorsDataService } from './configurators-data.service';
import { IccColorGroup } from '../data-types/ColorGroup';
import { Common } from '../Common';


@Injectable({
    providedIn: 'root',
})
export class ColorsGroupsService {

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configuratorsDataService: ConfiguratorsDataService,
    ) {
    }

    /**
     * Funkcja znajdujaca grupe kolorow
     * @param  {object} color Kolor
     * @param  {object} conf  Konfiguracja
     */
    findGroupForColor(color, conf, windowColorGroups, prop) {
        let gr: IccColorGroup[] = [];
        if (!Common.isObject(color)) {
            return gr;
        }
        if (
            Common.isObject(conf.RollerShutter.system)
            && Common.isObject(conf.RollerShutter.profile)
        ) {
            gr = windowColorGroups.filter(
                el =>
                    Common.isArray(el.roller_systems)
                    && el.roller_systems.indexOf(conf.RollerShutter.system?.id) > -1
                    && (color.groups && color.groups.indexOf(+el.id) > -1)
                    && Common.isArray(el.roller_element)
                    && el.roller_element.indexOf(snakeCase(prop)) > -1
            );

            if (prop === 'profile') {
                gr = gr.filter(el => Common.isArray(el.profiles)
                    && el.profiles.indexOf(conf.RollerShutter.profile.id) > -1
                );
            } else if (snakeCase(prop) === 'guideRail'
                && this.config().IccConfig.Configurators.roller_shutter.guides
            ) {
                const guideRailsIds = this.getGuideRailsIds(conf);
                if (guideRailsIds.length > 0) {
                    gr = gr.filter(el =>
                        el.profiles && el.profiles.some(r => guideRailsIds.includes(Number(r)))
                    );
                }
            } else if (prop === 'endslat'
                && this.config().IccConfig.Configurators.roller_shutter.slats
            ) {
                const slatsIds = this.getSlatsIds(conf);
                if (slatsIds.length > 0) {
                    gr = gr.filter(el =>
                        el.profiles && el.profiles.some(r => slatsIds.includes(Number(r)))
                    );
                }
            }
        }
        return gr;
    }

    private getGuideRailsIds(conf) {
        const guideRailsIds = [];
        if (conf.RollerShutter.guideRails && conf.RollerShutter.guideRails.length) {
            for (let i = 0; i < conf.RollerShutter.guideRails.length; i++) {
                if (!guideRailsIds.includes(Number(conf.RollerShutter.guideRails[i].id))
                    && conf.RollerShutter.guideRails[i].id !== null) {
                    guideRailsIds.push(Number(conf.RollerShutter.guideRails[i].id));
                }
            }
        }
        return guideRailsIds;
    }

    private getSlatsIds(conf) {
        const slatsIds = [];
        if (conf.RollerShutter.slats && conf.RollerShutter.slats.length) {
            for (let i = 0; i < conf.RollerShutter.slats.length; i++) {
                if (!slatsIds.includes(Number(conf.RollerShutter.slats[i].id))
                    && conf.RollerShutter.slats[i].id !== null) {
                    slatsIds.push(Number(conf.RollerShutter.slats[i].id));
                }
            }
        }
        return slatsIds;
    }
}
