import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { ConfigurationsService, core, WindowActiveConfiguration } from '@icc/common';
import {
    IccAccessoryAccessory,
    IccColor,
    IccMaterial,
    IccProfileSideColors,
} from '@icc/common/data-types';
import { BehaviorSubject, SubscriptionLike } from 'rxjs';
import {
    ColorControl,
    ColorControlDef,
} from 'libs/configurator/window/src/lib/colors/color-control';
import { NewColorsService } from 'libs/configurator/window/src/lib/colors/new-colors.service';
import {
    ColoredElements,
    ColoredSides,
    ProfileColoredSides,
} from 'libs/configurator/window/src/lib/colors/colored-elements';
import { WoodService } from 'libs/configurator/window/src/lib/construction/wood.service';

@Component({
    selector: 'icc-accessories-colors-page',
    templateUrl: './accessories-colors-page.component.html',
    styleUrls: ['./accessories-colors-page.component.scss'],
})
export class AccessoriesColorsPageComponent extends PageComponent implements OnInit, OnDestroy {
    public title = _('WINDOW|Wybierz kolor dodatku');
    public options = [];

    showCore = false;
    agreeButton = false;

    controls: ColorControl[] = [];
    controlsDef: ColorControlDef[] = [
        {
            title: _('COLOR|Kolor zewnętrzny'),
            element: 'accessory',
            side: 'outer',
        },
        {
            title: _('COLOR|Kolor wewnętrzny '),
            element: 'accessory',
            side: 'inner',
            whiteSwitch: true,
        },
        {
            title: _('WINDOW|Kolor bazy'),
            element: 'accessory',
            side: 'core',
        },
    ];

    private subscriptions: SubscriptionLike[] = [];

    constructor(
        private sharedFacade: SharedFacade,
        private newColorsService: NewColorsService,
        private woodService: WoodService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            accessory: IccAccessoryAccessory;
            type: 'accessory';
            colors$: BehaviorSubject<string | IccProfileSideColors>;
            material?: IccMaterial;
        },
        private configurationsService: ConfigurationsService<'window'>
    ) {
        super();
        this.updateControls(this.configurationsService.conf.Current);
    }

    ngOnInit() {
        this.subscriptions.push(
            this.configurationsService.configuration$.subscribe((conf) => {
                this.updateControls(conf);
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    updateControls(conf = this.configurationsService.conf.Current) {
        const controls: ColorControl[] = [];
        for (const controlDef of this.controlsDef) {
            controlDef.accessory = this.pageData.accessory;

            if (this.shouldSkipControl(conf, controlDef)) {
                continue;
            }

            const colorValue = this.newColorsService.getElementColor(conf, controlDef);
            const availableColors = this.newColorsService.getMatchingColorsFor(conf, controlDef);
            const switchVisibility = this.getSwitchVisibility(availableColors, controlDef, conf);
            const control = this.createControl(controlDef, switchVisibility, colorValue);
            controls.push(control);
        }
        this.controls = controls;
    }

    shouldSkipControl(conf = this.configurationsService.conf.Current, controlDef: ColorControlDef) {
        if (controlDef.hide && controlDef.hide()) {
            return true;
        }
        if (controlDef.side === 'core' && !this.hasCoreColorSelect(conf, controlDef)) {
            return true;
        }
        return false;
    }

    getSwitchVisibility(
        availableColors: IccColor[],
        controlDef: ColorControlDef,
        conf: WindowActiveConfiguration
    ) {
        let isWhiteSwitchVisible = false;
        let isWhiteSwitchDisabled = true;
        let isBilateralSwitchVisible = false;
        let isMoreColorsSwitchVisible = false;

        const disabled = !availableColors?.length || availableColors?.length <= 1;

        if (controlDef.side === 'inner' && controlDef.accessory?.material === 'pvc') {
            const switchVisibility = this.isWhiteSwitchVisible(controlDef, conf, availableColors);
            isWhiteSwitchVisible = switchVisibility.whiteSwitchVisible ?? false;
            isBilateralSwitchVisible = switchVisibility.bilateralSwitchVisible ?? false;
            isMoreColorsSwitchVisible = !disabled;

            isWhiteSwitchDisabled = this.isWhiteColorButtonDisabled(
                controlDef.element,
                controlDef.side,
                controlDef.accessory,
                conf
            );
        }
        return {
            isWhiteSwitchVisible,
            isBilateralSwitchVisible,
            isMoreColorsSwitchVisible,
            isWhiteSwitchDisabled,
            disabled,
        };
    }

    createControl(
        controlDef: ColorControlDef,
        switchVisibility: {
            isWhiteSwitchVisible: boolean;
            isBilateralSwitchVisible: boolean;
            isMoreColorsSwitchVisible: boolean;
            isWhiteSwitchDisabled: boolean;
            disabled: boolean;
        },
        colorValue: IccColor,
    ) {
        return {
            title: typeof controlDef.title === 'function' ? controlDef.title() : controlDef.title,
            element: controlDef.element,
            side: controlDef.side,
            accessory: this.pageData.accessory,
            alt: controlDef.alt,
            whiteSwitchVisible: switchVisibility.isWhiteSwitchVisible,
            bilateralSwitchVisible: switchVisibility.isBilateralSwitchVisible,
            moreColorsSwitchVisible: switchVisibility.isMoreColorsSwitchVisible,

            whiteSwitchDisabled: switchVisibility.isWhiteSwitchDisabled,
            disabled: switchVisibility.disabled,
            name: colorValue?.name || '',
            color: colorValue?.color || '',
            colorImg: '/files/color/' + (colorValue?.colorImg || ''),
        };
    }

    isWhiteSwitchVisible(
        colorControl: ColorControlDef,
        conf = this.configurationsService.conf.Current,
        availableColors: IccColor[] = []
    ) {
        if (colorControl.side !== 'inner') {
            return {
                whiteSwitchVisible: false,
                bilateralSwitchVisible: false,
            };
        }
        if (colorControl.accessory?.material !== 'pvc') {
            return {
                whiteSwitchVisible: false,
                bilateralSwitchVisible: false,
            };
        }
        const outerColor = this.newColorsService.getElementColor(conf, {
            element: colorControl.element,
            side: 'outer',
            accessory: colorControl.accessory,
            alt: colorControl.alt,
        });
        const innerColor = this.newColorsService.getElementColor(conf, {
            element: colorControl.element,
            side: 'inner',
            accessory: colorControl.accessory,
            alt: colorControl.alt,
        });

        const availableWhiteColor = this.newColorsService.getWhiteElementColor(conf, colorControl);

        if (!availableWhiteColor) {
            return {
                whiteSwitchVisible: false,
                bilateralSwitchVisible:
                    outerColor?.id !== innerColor?.id &&
                    availableColors?.some((c) => c.id === innerColor?.id),
            };
        }

        if (colorControl.element !== 'accessory') {
            return {
                whiteSwitchVisible:
                    (outerColor?.id === innerColor?.id &&
                        innerColor?.coreColors.every(
                            (c) => !innerColor.constructColors[c].some((el) => el.type === 'white')
                        )) ||
                    outerColor?.coreColors.some((c) =>
                        outerColor.constructColors[c].some((el) => el.type === 'white')
                    ),
                bilateralSwitchVisible:
                    (outerColor?.id !== innerColor?.id &&
                        innerColor?.coreColors.some((c) =>
                            innerColor.constructColors[c].some((el) => el.type === 'white')
                        )) ||
                    (outerColor?.coreColors.some((c) =>
                        outerColor.constructColors[c].some((el) => el.type === 'white')
                    ) &&
                        availableColors?.some((c) => c.id === outerColor?.id)),
            };
        } else {
            const outerConstrColor = this.newColorsService.getElementConstrColor(
                conf,
                colorControl.element,
                'outer',
                null,
                colorControl.accessory
            );
            const innerConstrColor = this.newColorsService.getElementConstrColor(
                conf,
                colorControl.element,
                'inner',
                null,
                colorControl.accessory
            );

            // Wyświetlamy przycisk jeśli:
            // 1. Kolor zewnętrzny jest inny niż wewnętrzny
            // 2. Kolor zewnętrzny jest biały - po to, żeby użytkownik był świadomy, że może zmienić kolor
            // 3. Kolor wewnętrzny nie jest biały - po to, żeby użytkownik mógł zmienić kolor
            return {
                whiteSwitchVisible:
                    (outerColor?.id === innerColor?.id && innerConstrColor?.type !== 'white') ||
                    outerConstrColor?.type === 'white',
                bilateralSwitchVisible:
                    outerColor?.id !== innerColor?.id &&
                    (innerConstrColor?.type === 'white' || outerConstrColor?.type === 'white') &&
                    availableColors?.some((c) => c.id === outerColor?.id),
            };
        }
    }

    isWhiteColorButtonDisabled(
        element: ColoredElements,
        side?: ProfileColoredSides | ColoredSides,
        accessory?: IccAccessoryAccessory,
        conf = this.configurationsService.conf.Current
    ) {
        if (element === 'accessory' && side === 'inner') {
            const innerConstrColor = this.newColorsService.getElementConstrColor(
                conf,
                element,
                'inner',
                null,
                accessory
            );
            return innerConstrColor?.type === 'white';
        }

        return false;
    }

    hasCoreColorSelect(
        conf = this.configurationsService.conf.Current,
        controlDef: ColorControlDef
    ) {
        if (this.pageData.accessory?.material !== 'pvc') {
            return false;
        }
        const outerColor = this.newColorsService.getElementColor(conf, {
            element: controlDef.element,
            side: 'outer',
            accessory: controlDef.accessory,
            alt: controlDef.alt,
        });

        const innerColor = this.newColorsService.getElementColor(conf, {
            element: controlDef.element,
            side: 'inner',
            accessory: controlDef.accessory,
            alt: controlDef.alt,
        });

        return !outerColor?.isCore && !innerColor?.isCore;
    }

    async openModalWood() {
        const wood = await this.woodService.openWoodPicker(this.pageData.accessory.selectedWood);
        if (wood) {
            this.pageData.accessory.selectedWood = core.copy(wood);
        }
    }

    openModalColor(colorControl: ColorControl) {
        this.newColorsService.openModalColorPicker(
            this.configurationsService.conf.Current,
            colorControl,
            colorControl.title
        );
    }

    setBilateralColor(colorControl: ColorControl) {
        if (colorControl.side === 'inner') {
            this.newColorsService.setBilateralColor(
                this.configurationsService.conf.Current,
                colorControl
            );
        }
    }

    setWhiteColor(colorControl: ColorControl) {
        this.newColorsService.setWhiteColor(this.configurationsService.conf.Current, colorControl);
    }

    close() {
        this.pageData.colors$.next(this.pageData.accessory.selectedColor);
        this.sharedFacade.closePage({
            colors: this.pageData.accessory.selectedColor,
            wood: this.pageData.accessory.selectedWood,
        });
    }
}
