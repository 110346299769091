import { ExternalBlindActiveConfiguration } from '@icc/common/configurations/ExternalBlindActiveConfiguration';
import { AppConfigFactory } from '@icc/common/app-config';
import { APP_CONFIG } from '@icc/common/config';
import { Injectable, Inject } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ShutterPartialState } from './shutter.reducer';
import { shutterQuery } from './shutter.selectors';
import { LoadShutter } from './shutter.actions';
import { SharedFacade } from '@icc/configurator/shared';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { map } from 'rxjs/operators';
import { combineLatest, BehaviorSubject } from 'rxjs';

@Injectable()
export class ShutterFacade {

    selectedConfiguratorType$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.type)
    );

    selectedType$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.type)
    );

    selectedSystem$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.system)
    );

    selectedSchemaId$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.schemaId)
    );

    shutters$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.shutters)
    );

    hasBox$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.system.hasBox)
    );

    availableCrankSlopes$ = this.configurationsService.configuration$.pipe(
        map(conf => [45, 90])
    )

    selectedCrankSlope$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            return conf instanceof ExternalBlindActiveConfiguration && conf.RollerShutter.crankSlope ? conf.RollerShutter.crankSlope : null;
        })
    )

    selectedMountingLength$ = this.configurationsService.configuration$.pipe(
        map(conf => {
                return conf instanceof ExternalBlindActiveConfiguration ? conf.RollerShutter.mountingLength : null;
            })
    )

    hasPrices$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.hasPrices)
    );

    height$ = this.configurationsService.configuration$.pipe(map(conf => conf.Height));
    fullHeight$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Height + conf.RollerShutter.realBoxHeight)
    );

    width$ = this.configurationsService.configuration$.pipe(map(conf => conf.Width));

    guideType$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.guideType)
    );

    profile$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.profile)
    );

    roundReel$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.roundReel)
    );

    boxHeight$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.realBoxHeight)
    );

    driveSide$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.driveSide)
    );

    driveOutputSide$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.driveOutputSide)
    );

    hanger$ = this.configurationsService.configuration$.pipe(
        map(conf => (conf.RollerShutter.hanger ? conf.RollerShutter.hanger : null))
    );

    railsBottomCut$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.railsBottomCut)
    );

    concealedStrip$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.concealedStrip)
    );

    inInsulation$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.inInsulation)
    );

    boxInnerColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.colors.boxInner)
    );
    boxGuideOuterColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.colors.boxGuideOuter)
    );
    boxColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.colors.box)
    );
    guideRailColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.colors.guideRail)
    );
    profileColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.colors.profile)
    );
    endslatColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.colors.endslat)
    );
    revisionColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.colors.revision)
    );
    boxSideSurfaceColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.colors.boxSideSurface)
    );

    boxInnerColorShow$ = combineLatest(this.selectedConfiguratorType$, this.selectedType$, this.selectedSystem$, this.inInsulation$).pipe(
        map(([confType, type, system, inInsulation]) => confType === 'roller_shutter' && system.box_color_type === 'bicolor' && !inInsulation)
    );
    boxGuideOuterColorShow$ = combineLatest(this.selectedConfiguratorType$, this.selectedType$, this.selectedSystem$, this.inInsulation$).pipe(
        map(([confType, type, system, inInsulation]) => confType === 'roller_shutter' && system.box_color_type === 'bicolor' && !inInsulation)
    );
    boxColorShow$ = combineLatest(this.selectedConfiguratorType$, this.selectedType$, this.selectedSystem$, this.hasBox$).pipe(map(([confType, type, system, hasBox]) => system.box_color_type === 'bilateral' || (confType === 'external_blind' && hasBox)));
    guideRailColorShow$ = combineLatest(this.sharedFacade.config$, this.selectedType$).pipe(
        map(([config, type]) => type.type!=='N' || config.Configurators.roller_shutter.boxColorGuideColor)
    );
    profileColorShow$ = new BehaviorSubject(true);
    endslatColorShow$ = this.selectedConfiguratorType$.pipe(map(confType => {
        return confType === 'roller_shutter' ? true : false;
    }));
    revisionColorShow$ = combineLatest(
        this.selectedConfiguratorType$,
        this.selectedType$,
        this.selectedSystem$,
        this.sharedFacade.config$
    ).pipe(
        map(
            ([confType, type, system, config]) =>
                confType === 'roller_shutter'
                &&  ((type.type === 'N'
                        && config.Configurators.roller_shutter.additionalColors
                        && system.available_revision_color)
                    || (type.type === 'Z'
                        && config.Configurators.roller_shutter.revisionColorInZ
                        && system.available_revision_color)
                    || type.type === 'I')
        )
    );
    boxSideSurfaceColorShow$ = combineLatest(
        this.selectedConfiguratorType$,
        this.sharedFacade.config$
    ).pipe(
        map(
            ([confType, config]) =>
                confType === 'roller_shutter'
                && config.Configurators.roller_shutter.additionalColors
                && config.Configurators.roller_shutter.boxSideSurfaceColor
        )
    );

    mosquito$ = combineLatest(
        new BehaviorSubject(this.config().IccConfig.Configurators.roller_shutter.mosquito),
        this.selectedConfiguratorType$
    ).pipe(
        map(([conf, confType]) => {
            return conf && confType === 'roller_shutter' ? true : false;
        })
    );

    drive$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.drive)
    );

    driveOverride$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.driveType)
    );

    driveElements$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.driveElements)
    );

    driveManual$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.driveManual)
    );

    openingLock$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.openingLock)
    );

    grayPolystyrene$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.RollerShutter.grayPolystyrene)
    );

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private store: Store<ShutterPartialState>,
        private sharedFacade: SharedFacade,
        private dataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind'>
    ) {}

    loadAll() {
        this.store.dispatch(new LoadShutter());
    }
}
