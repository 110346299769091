import { Injectable, Inject } from '@angular/core';
import { core } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { EventBusService } from '@icc/common/event-bus.service';
import { IssuesService, IssueLevel } from '@icc/helpers';
import { ValidationService } from '@icc/common/configurators/validation.service';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { SideProfileActive, SideProfile } from '@icc/window';
import { CoupledWindowActiveConfiguration } from '@icc/common/configurations/CoupledWindowActiveConfiguration';
import { IWindowConfiguration, WindowConfiguration } from '@icc/common/configurations/WindowConfiguration';
import { BrowserProfilesService } from 'libs/configurator/window/src/lib/profiles/profiles.service';
import { ConfigurationsService, TranslateService } from '@icc/common';
import { HandlesService } from '../steps/window/handles/handles.service';
import { ProfileColorsService } from 'libs/configurator/window/src/lib/profiles/profile-colors.service';

@Injectable()
export class ExtensionsService {
    constructor(
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService<'window'>,
        private profilesService: BrowserProfilesService,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private translateService: TranslateService,
        private handlesService: HandlesService,
        private profileColorsService: ProfileColorsService,
    ) {
        this.eventBusService.subscribe(
            ['setSystem', 'putExtensionOnSide', 'setExtensionProfile', 'setFrameProfile', 'changedDoorSizes'],
            data => {
                this.validateExtensionsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                );
            }
        );
        this.eventBusService.subscribe(
            ['removeExtensionBySide'],
            (data) => {
                if (data?.value && Array.isArray(data.value) && data.value.length) {
                    data.value.forEach(side => this.removeExtensionBySide(side, data.activeConfiguration as WindowActiveConfiguration));
                }
            }
        )
    }

    getFoundationExtensions(conf: WindowActiveConfiguration) {
        return this.profilesService.getFilteredProfiles(conf, 'sandwich');
    }

    setFoundationProfile(conf: WindowActiveConfiguration, foundation: {
        isFoundationProfile: boolean;
        foundationProfile: null | number;
        foundationProfileHeight: number;
    }) {
        if (foundation.isFoundationProfile) {
            conf.foundationProfile = true;
            const existingProfile = this.getFoundationProfile(conf);
            if (existingProfile) {
                existingProfile.width = existingProfile.widthOut = foundation.foundationProfileHeight;
                if (foundation.foundationProfile){
                    existingProfile.profileId = foundation.foundationProfile;
                }
            } else {
                const profile = this.profilesService.getProfile(foundation.foundationProfile);
                profile.width = foundation.foundationProfileHeight;
                this.profileColorsService.setProfileDefaultColors(conf, profile, null, conf.Colors.frame, conf.Wood);
                this.putExtensionOnSide(conf, profile, 'bottom');
            }
        } else {
            conf.foundationProfile = false;
            this.removeExtensionFromSide(conf, 'bottom');
        }
    }

    getFoundationProfile(conf: WindowActiveConfiguration) {
        const sides = this.profilesService.getFrameSidesOnEdge(conf);
        const windowSide = sides.find(s => s.side === 'bottom');
        const sideProfileOnSide = windowSide && this.getLastSideProfileByFrameIndex(windowSide.frameEdges, conf);
        return sideProfileOnSide;
    }

    putExtensionOnSide(conf: WindowActiveConfiguration, profile, side) {
        if (profile.type === 'sandwich') {
            profile.widthOut = profile.width;
        }
        const sides = this.profilesService.getFrameSidesOnEdge(conf);
        const windowSide = sides.find(s => s.side === side);
        const sideProfileOnSide = this.getLastSideProfileByFrameIndex(windowSide.frameEdges, conf);

        const newSideProfile = new SideProfileActive({
            id: this.getIdForNew(conf),
            profileId: profile.id,
            profileCategoryId: profile.profileCategoryId,
            name: profile.name || profile.nameCms,
            code: profile.code,
            pricePiece: profile?.price?.price_piece,
            listPricePiece: profile?.price?.list_price_piece,
            length: 0,
            width: profile.width,
            widthOut: profile.widthOut,
            count: this.config().IccConfig.Configurators.hs.doubleExtensions && conf.System?.confType === 'hs' ? 2 : 1,
            color: profile.selectedColor ? core.copy(profile.selectedColor) : {},
            wood: profile.selectedWood ? core.copy(profile.selectedWood) : {},
            adjacentSideProfileId: sideProfileOnSide ? [sideProfileOnSide.id] : [],
            reinforcement: profile.reinforcement,
            framesId: !sideProfileOnSide
                ? windowSide.frameEdges.map(fE => ({
                      id: fE.frameId,
                      edges: [fE.frameEdgeIndex],
                  }))
                : [],
            side,
        });
        conf.SideProfiles.push(newSideProfile);
        if (sideProfileOnSide) {
            sideProfileOnSide.adjacentOtherSideProfileId.push(newSideProfile.id);
        }

        this.eventBusService.post({
            key: 'putExtensionOnSide',
            value: {
                profile,
                side,
            },
        });
        if (profile.type === 'sandwich') {
            profile.width = null;
            profile.widthOut = null;
        }
    }

    removeExtensionFromSide(conf: WindowActiveConfiguration, side) {
        const sides = this.profilesService.getFrameSidesOnEdge(conf);
        const windowSide = sides.find(s => s.side === side);
        const sideProfileOnSide = this.getLastSideProfileByFrameIndex(windowSide.frameEdges, conf);
        if (sideProfileOnSide) {
            conf.SideProfiles.splice(conf.SideProfiles.indexOf(sideProfileOnSide as SideProfileActive), 1);
            conf.SideProfiles.forEach(sp => {
                sp.adjacentOtherSideProfileId = sp.adjacentOtherSideProfileId.filter(
                    spId => spId !== sideProfileOnSide.id
                );
                sp.adjacentSideProfileId = sp.adjacentSideProfileId.filter(
                    spId => spId !== sideProfileOnSide.id
                );
            });

            this.setExtensionTotalQuantity(conf);

            this.eventBusService.post({
                key: 'removedExtensionFromSide',
                value: {
                    side,
                },
            });
            this.eventBusService.post({
                key: 'processDependencies',
                value: null
            });
            return true;
        } else {
            return false;
        }
    }

    removeAllExtensionsFromSide(conf: WindowActiveConfiguration, side) {
        while(this.removeExtensionFromSide(conf, side));
    }

    removeAllExtensions(conf: WindowActiveConfiguration) {
        ['top', 'bottom', 'left', 'right'].forEach(side => this.removeExtensionBySide(side, conf));
    }

    removeExtensionBySide(side, conf: WindowActiveConfiguration) {
        if (side && conf?.SideProfiles) {
            conf.SideProfiles.forEach(s => s.side === side && this.removeAllExtensionsFromSide(conf, side))
        }
    }

    validateExtensionsAndFixIssues(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'extensionsSystems');
        if (conf.SideProfiles) {
            if (this.isDifferentSashAndFrameShortening(conf)) {
                this.removeAllExtensionsFromSide(conf, 'bottom');
            }
            if (!this.validSystemsAndConnections(conf)) {
                this.validationService.invalid(conf, 'extensionsSystems');
                this.issuesService.simpleRegister(
                    'invalid-extensions',
                    'Niepoprawne profile poszerzeń',
                    this.translateService.instant('WINDOW|Niepoprawne profile poszerzeń'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.NONE,
                        blockStepsAfter: null,
                    }
                );
                if (this.config().IccConfig.Configurators.removeExtensionsIfInvalid) {
                    this.removeAllExtensions(conf);
                }
            } else {
                Object.assign(conf, this.validationService.valid(conf, 'extensionsSystems'));
                this.issuesService.unregister('invalid-extensions', conf);
            }
        }

        this.setExtensionTotalQuantity(conf);
    }

    setExtensionTotalQuantity(conf: WindowActiveConfiguration) {
        const extensions = conf.SideProfiles.map(p => p.profileId).reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {})

        if (core.isEmptyObject(extensions)) {
            return;
        }

        conf.SideProfiles.forEach(element => {
            if (Object.keys(extensions).find(p => Number(p) === Number(element.profileId))) {
                element.totalQuantity = extensions[Number(element.profileId)];
            }
        });
    }

    validSystemsAndConnections(conf: WindowActiveConfiguration) {
        return Object.keys(conf.SideProfiles).every(side =>
            conf.SideProfiles.every(extension => {
                const profile = conf.UsedProfiles.find(el => el.id === extension.profileId);
                return (
                    profile && profile.systems && profile.systems.includes(Number(conf.System.id))
                    && this.profilesService.filterProfileByConnection(profile, conf)
                );
            })
        );
    }

    getFirstSideProfileById(sideProfileId: number, conf: CoupledWindowActiveConfiguration) {
        const lastProfiles = conf.sideProfiles.filter(s => s.id === sideProfileId);
        const profiles = lastProfiles
            .map(s =>
                s.adjacentSideProfileId.length > 0 ? this.getBackProfiles(s, conf.sideProfiles) : s
            )
            .reduce<SideProfileActive[]>((acc, val) => acc.concat(val), []);
        return profiles[0];
    }

    getSideProfilesWidthById(sideProfileId: number, conf: CoupledWindowActiveConfiguration) {
        const lastProfiles = conf.sideProfiles.filter(s => s.id === sideProfileId);
        const profilesWidth =
            lastProfiles
                .map(s =>
                    s.adjacentSideProfileId.length > 0
                        ? this.getBackProfiles(s, conf.sideProfiles)
                        : []
                )
                .reduce<number>((acc, val) => acc + val.reduce((p, c) => p + c.width, 0), 0)
            + lastProfiles.reduce((p, c) => p + c.width, 0);
        return profilesWidth;
    }

    getIdForNew(conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration) {
        const sideProfiles = CoupledWindowActiveConfiguration.is(conf)
            ? conf.sideProfiles
            : conf.SideProfiles;
        const intProfilesId = Object.keys(sideProfiles).reduce((prev, side) => {
            const maxId = sideProfiles.reduce(
                (intPrev, profile) => (profile.id > intPrev ? profile.id : intPrev),
                0
            );
            return maxId > prev ? maxId : prev;
        }, -1);
        return intProfilesId + 1;
    }

    getLastSideProfileByFrameIndex<
        T extends WindowActiveConfiguration | CoupledWindowActiveConfiguration | WindowConfiguration,
        S extends T extends WindowConfiguration ? SideProfile : SideProfileActive
    >(
        frameEdges: {
            frameId: number;
            frameEdgeIndex: number;
            positionId?: string;
        }[],
        conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration | WindowConfiguration
    ) {
        const firstProfiles = ((conf.type === 'coupled_window' || WindowConfiguration.is(conf)
            ? conf.sideProfiles
            : conf.SideProfiles
        ) as S[]).filter(s =>
            s.framesId.some(f =>
                frameEdges.some(
                    fE =>
                        (conf?.System && conf.System?.door_type || f.id === fE.frameId)
                        && f.edges.includes(fE.frameEdgeIndex)
                        && f.positionId === fE.positionId
                )
            )
        );
        const profiles = firstProfiles
            .map((s: S) =>
                s.adjacentOtherSideProfileId.length > 0
                    ? this.getNextProfiles(
                          s,
                          ((conf.type === 'coupled_window' || WindowConfiguration.is(conf)
                              ? conf.sideProfiles
                              : conf.SideProfiles) as S[])
                      )
                    : s
            )
            .reduce<S[]>((acc, val) => acc.concat(val), []);
        return profiles[0];
    }

    private getBackProfiles<
        T extends SideProfileActive | SideProfile,
        S extends T extends SideProfileActive ? SideProfileActive : SideProfile
    >(baseProfile: S, sideProfiles: S[]): S[] {
        const backProfiles = sideProfiles.filter(s =>
            baseProfile.adjacentSideProfileId.includes(s.id)
        );
        const allBackProfiles = backProfiles
            .map(s =>
                s.adjacentSideProfileId.length > 0 ? this.getBackProfiles(s, sideProfiles) : s
            )
            .reduce<S[]>((acc, val) => acc.concat(val), []);
        return allBackProfiles;
    }

    private getNextProfiles<
        T extends SideProfileActive | SideProfile,
        S extends T extends SideProfileActive ? SideProfileActive : SideProfile
    >(baseProfile: S, sideProfiles: S[]): S[] {
        const nextProfiles = sideProfiles.filter(s =>
            baseProfile.adjacentOtherSideProfileId.includes(s.id)
        );
        const allNextProfiles = nextProfiles
            .map(s =>
                s.adjacentOtherSideProfileId.length > 0 ? this.getNextProfiles(s, sideProfiles) : s
            )
            .reduce<S[]>((acc, val) => acc.concat(val), []);
        return allNextProfiles;
    }

    isDifferentSashAndFrameShortening(conf: WindowActiveConfiguration): boolean {
        return conf.type === 'door'
                && conf.System.seperate_frame_and_sash_shortening
                && conf.doorSizes
                && conf.doorSizes.frameShortening != conf.doorSizes.shortening
    }

    isExtensionAvailable(side: 'bottom' | 'top' | 'left' | 'right', conf = this.configurationsService.conf.Current) {
        if (conf.SideProfiles.length <= 0) {
            return true;
        }

        const extensions = this.profilesService
            .getFilteredProfiles(conf, 'extension')
            .filter((k) => k.options.includes(['left', 'right'].includes(side) ? 'side' : side));
        const categories = extensions.map((k) => k.profileCategoryId);
        const categoriesWithRestrictedQuantity = this.profilesService.profileCategories.filter(
            (k) => k.quantity_restriction && categories.some((c) => c === k.id)
        );
        const categoriesWithRestrictedQuantityPerSide = this.profilesService.profileCategories.filter(
            (k) => k.quantity_restriction_per_side && categories.some((c) => c === k.id)
        );
        const categoriesWithoutRestrictedQuantity = this.profilesService.profileCategories.filter(
            (k) => !k.quantity_restriction && categories.some((c) => c === k.id)
        );
        const categoriesWithoutRestrictedQuantityPerSide = this.profilesService.profileCategories.filter(
            (k) => !k.quantity_restriction_per_side && categories.some((c) => c === k.id)
        );

        const available = !categoriesWithRestrictedQuantity ? true : this.isAnyExtensionAvailable(categoriesWithRestrictedQuantity, categoriesWithoutRestrictedQuantity, conf);
        const availablePerSide = !categoriesWithRestrictedQuantityPerSide ? true : this.isAnyExtensionAvailablePerSide(categoriesWithRestrictedQuantityPerSide, categoriesWithoutRestrictedQuantityPerSide, side, conf);

        return available && availablePerSide;
    }

    private isAnyExtensionAvailable(
            categoriesWithRestrictedQuantity,
            categoriesWithoutRestrictedQuantity,
            conf = this.configurationsService.conf.Current
    ) {
        let isAnyExtensionAvailable = true;
        if (this.areAllExtensionsRestricted(categoriesWithRestrictedQuantity, conf)) {
            isAnyExtensionAvailable = false;
        }
        if (categoriesWithoutRestrictedQuantity.length) {
            isAnyExtensionAvailable = true;
        }

        return isAnyExtensionAvailable;
    }

    private areAllExtensionsRestricted(categoriesWithRestrictedQuantity, conf = this.configurationsService.conf.Current) {
        const sideProfilesCategoryIds = conf.SideProfiles.map(sp => sp.profileCategoryId);
        const occurrences = sideProfilesCategoryIds.reduce((acc, curr) => (acc[curr] ? ++acc[curr] : acc[curr] = 1, acc), {});
        return categoriesWithRestrictedQuantity.every(category => (occurrences[category.id] >= category.max_quantity));
    }

    private isAnyExtensionAvailablePerSide(
        categoriesWithRestrictedQuantityPerSide,
        categoriesWithoutRestrictedQuantityPerSide,
        side: 'bottom' | 'top' | 'left' | 'right',
        conf = this.configurationsService.conf.Current,
        ) {
            let isAnyExtensionAvailable = true;
            if (this.areAllExtensionsRestrictedPerSide(categoriesWithRestrictedQuantityPerSide, conf, side)) {
                isAnyExtensionAvailable = false;
            }
            if (categoriesWithoutRestrictedQuantityPerSide.length) {
                isAnyExtensionAvailable = true;
            }

            return isAnyExtensionAvailable;
        }

    private areAllExtensionsRestrictedPerSide(categoriesWithRestrictedQuantity, conf = this.configurationsService.conf.Current, side: 'bottom' | 'top' | 'left' | 'right') {
        const sideProfilesCategoryIds = conf.SideProfiles.filter(profile => profile.side === side).map(sp => sp.profileCategoryId);
        const occurrences = sideProfilesCategoryIds.reduce((acc, curr) => (acc[curr] ? ++acc[curr] : acc[curr] = 1, acc), {});
        return categoriesWithRestrictedQuantity.every(category => (occurrences[category.id] >= category.max_quantity_per_side));
    }
}
