import { WindowConfigurationLegacy17 } from '../../legacy/WindowConfigurationLegacy17';
import { IWindowConfiguration } from '../../WindowConfiguration';


// eslint-disable-next-line max-statements
export function changesInVersion18(
    oldConfiguration: WindowConfigurationLegacy17,

): IWindowConfiguration {
    const configuration: IWindowConfiguration = {
        ...oldConfiguration,
        lipping: null
    };
    return configuration;
}
