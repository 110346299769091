<mat-card>
    <div class="card-header-container">
        <div class="card-header-container__img">
            <img
                [src]="'/files/windowaccessory/' + accessory?.img"
                [alt]="accessory?.name"
                defaultImg>
        </div>
        <mat-card-title class="card-header-container__box-title">{{accessory?.name}}</mat-card-title>
        <mat-card-subtitle class="card-header-container__box-description" [innerHtml]="accessory?.description"></mat-card-subtitle>
    </div>
    <mat-card-content class="accessory-box-content">
        <form [formGroup]="form">
            <div *ngIf="accessory.price_source == 'confColors'">
                <button mat-raised-button *ngIf="accessory.show_colors"
                        (click)="openModalColors(accessory)">{{ 'INTERFACE|Kolory' | translate }}</button>
                <span *ngIf="accessory.price_source == 'confColors' && accessory.selectedWood?.name">{{ 'WINDOW|Rodzaj drewna' | translate }}:
                    <b
                       *ngIf="accessory.price_source == 'confColors'">{{ accessory.selectedWood?.name }}</b>
                    <br />
                </span>
                <span
                        *ngIf="accessory.price_source == 'confColors' && accessory.selectedColor.outer?.name">{{ 'COLOR|Kolor zewn.' | translate }}:
                    <b
                        *ngIf="accessory.price_source == 'confColors'">{{ accessory.selectedColor.outer?.name }}</b>
                    <br />
                </span>
                <span
                        *ngIf="accessory.price_source == 'confColors' && accessory.selectedColor.inner?.name">{{ 'COLOR|Kolor wewn.' | translate }}:
                    <b
                        *ngIf="accessory.price_source == 'confColors'">{{ accessory.selectedColor.inner?.name }}</b>
                    <br />
                </span>
                <span
                        *ngIf="accessory.price_source == 'confColors' && accessory.selectedColor.core?.name && showCoreColorInAdditionalProfilesAndAccessories">{{ 'WINDOW|Kolor bazy' | translate }}:
                    <b
                        *ngIf="accessory.price_source == 'confColors'">{{ accessory.selectedColor.core?.name }}</b>
                    <br />
                </span>
            </div>

            <div *ngIf="accessory.price_source == 'colors'">
                <mat-form-field>
                    <mat-label>{{ 'INTERFACE|Kolor' | translate }}</mat-label>
                    <mat-select [disabled]="isAccessoryColorFieldDisabled()" formControlName="selectedColor">
                        <mat-option *ngFor="let color of getAccessoryColors(accessory)" (onSelectionChange)="setAccessoryColorPrice(color, $event)"
                                    [value]="color.id">
                            {{color.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div
                 *ngIf="accessory.show_colors && accessory.price_source == 'table'">
                <mat-select formControlName="colorOptions" (selectionChange)="setColorOptionsPrice(accessory)">
                    <mat-option value="white"
                                *ngIf="accessory.no_price || (!accessory.no_price && accessory.price_white)">
                        {{ 'COLOR|Biały' | translate }}
                    </mat-option>
                    <mat-option value="color-white"
                                *ngIf="accessory.no_price || (!accessory.no_price && accessory.price_white_colour)">
                        {{ 'COLOR|Kolor' | translate }}/{{ 'COLOR|Biały' | translate }}
                    </mat-option>
                    <mat-option value="color-color"
                                *ngIf="accessory.no_price || (!accessory.no_price && accessory.price_colour_colour)">
                        {{ 'COLOR|Kolor' | translate }}/{{ 'COLOR|Kolor' | translate }}
                    </mat-option>
                    <mat-option value="ral"
                                *ngIf="accessory.no_price || (!accessory.no_price && accessory.price_ral)">
                        {{ 'COLOR|RAL' | translate }}
                    </mat-option>
                </mat-select>
            </div>
            <div *ngIf="spaceSelect.length > 0">
                <mat-label>{{ 'COLOR|Pozycja' | translate }}</mat-label>
                <mat-select formControlName="spaceId" (selectionChange)="setAccessorySpace(accessory)">
                    <mat-option *ngFor="let space of spaceSelect" [value]="space.id">
                            {{space.name}}
                    </mat-option>
                </mat-select>
            </div>
            <mat-form-field *ngIf="accessory.type != 'dowel_hole'">
                <input matInput type="number" min="1"
                       [placeholder]="'INTERFACE|Ilość' | translate"
                       formControlName="count">
            </mat-form-field>
            <mat-form-field *ngIf="accessory.price_type == '1'">
                <input matInput iccDimensionInput type="number" min="0.01" value=""
                       [placeholder]="'DIMENSION|Szer.' | translate" step="0.01"
                       formControlName="amount_width">
                <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>
            <mat-form-field
                            *ngIf="(accessory.type == 'ventilator' || accessory.type == 'ventilator_hole') && config().IccConfig.Configurators.ventilatorPositioning">
                <input matInput iccDimensionInput type="number" formControlName="position"
                       (focus)="focused = true" (blur)="focused = false"
                       [placeholder]="'DIMENSION|Pozycja w ' | translate ">
                <input matInput iccDimensionInput *ngIf="!focused" type="number"
                       min="{{topFrameProfile?.minVentilatorSpace + accessory.width / 2}}"
                       max="{{sash?.rWidth - topFrameProfile?.minVentilatorSpace - accessory.width / 2}}"
                       value="{{sash?.rWidth / 2}}" formControlName="position">
                <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>
            <mat-form-field *ngIf="accessory.price_type == '1'">
                <input matInput iccDimensionInput type="number" min="0.01" value="" step="0.01"
                       formControlName="amount_height"
                       [placeholder]="accessory.type == 'windowsill' ? ('DIMENSION|Głęb.' | translate) : ('DIMENSION|Wys.' | translate) ">
                <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>
            <mat-form-field
                            *ngIf="accessory.price_type == '2' && (forIt != 'side' && !(forIt == 'sash' && accessory.type == 'renson'))">
                <input matInput iccDimensionInput type="number" min="0.01" value="" step="0.01"
                       formControlName="amount"
                       [disabled]="accessory.blockAmountChange"
                       [placeholder]="'DIMENSION|Dł.' | translate">
                <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>


            <!-- TODO <span ng-if="access.price_type == 0 && !access.no_price && !maccessory.noPrice">{{ 'OFFER|Cena' | translate }}:
            <b>{{access| accessoryPriceOne:((access.show_colors && access.price_source == 'table') ? access.colorOptions : access.selectedColor) | currency:state.offers[0].doc.currency}}/<span ng-bind-html="::access.price_type|formatUnit"></span></b></span>
        <span ng-if="access.price_type != 0 && !access.no_price && !maccessory.noPrice">{{ 'OFFER|Cena' | translate }}:
            <b>{{$root.user.dimension_unit != 'mm' ? '~' : ''}}{{access| accessoryPriceOne:((access.show_colors && access.price_source == 'table') ? access.colorOptions : access.selectedColor) | unitm:0:0:3 | currency:state.offers[0].doc.currency}}/<span ng-bind-html="::access.price_type|formatUnit"></span></b></span>
        <span ng-if="access.no_price || maccessory.noPrice">{{ 'OFFER|Cena' | translate }}:
            <b>{{ 'CONFIGURATOR|Do wyceny' | translate }}</b>
        </span> -->
        </form>
    </mat-card-content>
    <mat-card-actions class="accessory-box-actions">
        <h4 *ngIf="!hiddenPrice">{{ 'OFFER|Cena' | translate }}:
            <s *ngIf="getPrice(true) && getPrice(true) !== getPrice()">{{getPrice(true) | currency : currency}}</s>
            {{ (getPrice() | currency : currency) || ('CONFIGURATOR|Do wyceny' | translate) }}</h4>
        <button mat-raised-button color="primary" (click)="add()">{{ 'INTERFACE|Dodaj' | translate }}</button>
    </mat-card-actions>
</mat-card>
