<div class="box-form mat-elevation-z3"
    *ngIf="hangerLockAvailable
        || railsBottomCutAvailable
        || concealedStripAvailable
        || inInsulationAvailable
    ">
    <form>
        <mat-form-field *ngIf="hangerLockAvailable">
            <mat-label>{{ 'ROLLERSHUTTER|Wieszaki:' | translate }}</mat-label>
            <mat-select name="hanger" [ngModel]="(hanger$ | async).id" (ngModelChange)="changeHanger($event)">
                <mat-option *ngFor="let sys of hangers" [value]="sys.id"> {{sys.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="railsBottomCutAvailable">
            <mat-label>{{ 'ROLLERSHUTTER|Zacięcie prowadnic:' | translate }}</mat-label>
            <mat-select name="hanger" [ngModel]="railsBottomCut$ | async" (ngModelChange)="updateRailsBottomCut($event)">
                <mat-option *ngFor="let angle of railCutAngles" [value]="angle"> {{angle}}&deg;</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-slide-toggle *ngIf="concealedStripAvailable" name="concealedStrip" [ngModel]="concealedStrip$ | async" (ngModelChange)="changeCoanceledStrip($event)">{{'ROLLERSHUTTER|Czy dodać listwę podtynkową?' | translate}}</mat-slide-toggle>
        <mat-slide-toggle *ngIf="inInsulationAvailable" name="inInsulation" [ngModel]="inInsulation$ | async" (ngModelChange)="changeInInsulation($event)">{{'ROLLERSHUTTER|Pod ocieplenie:' | translate}}</mat-slide-toggle>
    </form>
</div>
