<ng-container *ngIf="mode === 'list'">
    <div class="radioButtons show-layouts" *ngIf="canChangeDoorSide">
        <div *ngIf="layoutSides.arrWL.length || !hideLayoutWhenUnavailable" matRipple (click)="changedDoorSide('W_L')" [class.selected]="doorSide === 'W_L'">
            <div class="img">
                <img src="/application/dist/web/img/layouts/default/drzwi_lewe_do_wewnatrz.jpg">
            </div>
            <p><a>{{ 'DOOR|Drzwi lewe otwierane do wewnątrz' | translate }}</a></p>
        </div>
        <div *ngIf="layoutSides.arrWR.length || !hideLayoutWhenUnavailable" matRipple (click)="changedDoorSide('W_R')" [class.selected]="doorSide === 'W_R'">
            <div class="img">
                <img src="/application/dist/web/img/layouts/default/drzwi_prawe_do_wewnatrz.jpg">
            </div>
            <p><a>{{ 'DOOR|Drzwi prawe otwierane do wewnątrz' | translate }}</a></p>
        </div>
        <div *ngIf="layoutSides.arrZL.length || !hideLayoutWhenUnavailable" matRipple (click)="changedDoorSide('Z_L')" [class.selected]="doorSide === 'Z_L'">
            <div class="img">
                <img src="/application/dist/web/img/layouts/default/drzwi_lewe_do_zewnatrz.jpg">
            </div>
            <p><a>{{ 'DOOR|Drzwi lewe otwierane do zewnątrz' | translate }}</a></p>
        </div>
        <div *ngIf="layoutSides.arrZR.length || !hideLayoutWhenUnavailable" matRipple (click)="changedDoorSide('Z_R')" [class.selected]="doorSide === 'Z_R'">
            <div class="img">
                <img src="/application/dist/web/img/layouts/default/drzwi_prawe_do_zewnatrz.jpg">
            </div>
            <p><a>{{ 'DOOR|Drzwi prawe otwierane do zewnątrz' | translate }}</a></p>
        </div>
    </div>
        <icc-list
            [items]="defaultLayouts"
            itemTemplate="itemImgTitle"
            itemImgHeight="medium"
            (select)="selectWindowSashesLayout($event)"
            [selected]="selectedLayoutId"
        ></icc-list>
    </ng-container>
    <ng-container *ngIf="mode === 'details'">
        <mat-card class="mat-elevation-z3">
            <mat-card-header>
                <div mat-card-avatar class="layout-box-img" id="handles-layout-draw" #parent [ngClass]="{'flip-symbols': flipSymbols}">
                    <icc-draw
                        [details]="drawService.details"
                        fitTo="width"
                        iccDrawResize
                        [options]="drawOptions"
                        [drawWidth]="drawService.details.width"
                        [drawHeight]="drawService.details.height"
                        [parentElement]="parent"
                    ></icc-draw>
                </div>
                <mat-card-title class="layout-box-title">{{ (layout$ | async)?.name }}</mat-card-title>
                <mat-card-subtitle class="buttons">
                    <button mat-raised-button color="primary" (click)="mode = 'list'">
                        {{ 'INTERFACE|Zmień' | translate }}
                    </button>
                    <button mat-raised-button color="primary" (click)="editLayout()" *ngIf="!(allowMirrorLayout || showEditLayoutInOptions())">
                        {{ 'WINDOW|Edycja konstrukcji' | translate }}
                    </button>
                    <button mat-raised-button color="primary" (click)="setDefaultLayout()" *ngIf="showDefaultLayoutButton()">
                        {{ 'WINDOW|Przywróć poprzedni układ' | translate }}
                    </button>
                    <button mat-raised-button color="primary" (click)="mirrorLayout()" *ngIf="allowMirrorLayout">
                        {{ 'WINDOW|Odbicie lustrzane funkcji kwater' | translate }}
                    </button>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-radio-group
                    class="balcony"
                    *ngIf="canBeBalcony()"
                    [ngModel]="balcony"
                    (ngModelChange)="changeBalcony($event)"
                >
                    <mat-radio-button [value]="false">{{ 'OFFER|Okno' | translate }} </mat-radio-button>
                    <mat-radio-button [value]="true"
                        >{{ 'OFFER|Okno balkonowe' | translate }}
                    </mat-radio-button>
                </mat-radio-group>
                <div class="lowThreshold" *ngIf="(lowThresholds0$ | async)">
                        {{ 'OFFER|Niski próg' | translate }}:
                    <mat-slide-toggle
                        [ngModel]="hasThreshold()"
                        (ngModelChange)="switchLowThreshold($event)">
                    </mat-slide-toggle>
                </div>
                <div *ngIf="(lowThresholds1$ | async)">
                    <mat-radio-group
                        class = "lowThreshold-list"
                        *ngFor="let lowThreshold of lowThresholds"
                        [ngModel]='selectedLowThresholdId$ | async'
                        (ngModelChange)="changeLowThreshold($event)"
                    >
                        <mat-radio-button
                            [value]="lowThreshold.id">
                            {{lowThreshold.name}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </mat-card-content>
        </mat-card>
        <form *ngIf="isRenoFrames || isMonoblockFrames" class="monoblock mat-elevation-z3" [ngFormOptions]="{updateOn: 'change'}">
            <span *ngIf="isRenoFrames" class ="renoFrame">{{ 'WINDOW|Rama renowacyjna' | translate }}:</span>
            <span *ngIf="isRenoFrames && !isCircle" class="allFin">
                <mat-checkbox id="reno" name="hasreno" [(ngModel)]="oneFinWidth" (change)="onChangeOneFinWidth()">{{ 'WINDOW|Wszystkie boki przycięte na jedną szerokość' | translate }}</mat-checkbox>
            </span>
            <span class="allFin" *ngIf="isRenoFrames">
                <b>{{ 'WINDOW|Przycięcie ramy do szerokości' | translate }}</b>
                <span *ngIf="oneFinWidth" class ="allFinInput">
                    <span >{{ 'WINDOW|Wszystkie boki' | translate }}:</span>
                    <input *ngIf="!frameSides[0].profile.finWidthInterval" iccDimensionInput name="oneFinWidth" type="number" (ngModelChange)="frameSides[0].finWidth = $event;onChangeOneFinWidth()" [ngModel]="frameSides[0].finWidth">
                    <mat-select *ngIf="frameSides[0].profile.finWidthInterval" name="oneFinWidth" (ngModelChange)="frameSides[0].finWidth = $event; onChangeOneFinWidth()"  [ngModel]="frameSides[0].finWidth">
                        <mat-option *ngFor="let width of frameSides[0].finWidths" [value]="width">{{ width | unitmm:0:0 }}</mat-option>
                    </mat-select>
                    <!-- {{$root.user.dimension_unit}} -->
                </span>
            </span>
            <span *ngIf="isRenoFrames && !oneFinWidth" class="oneFin">
                <span *ngFor="let side of frameSides; index as index;">
                    <span *ngIf="side.side.side == 'bottom'">{{ 'WINDOW|Dół' | translate }}:</span>
                    <span *ngIf="side.side.side == 'left'">{{ 'WINDOW|Bok lewy' | translate }}:</span>
                    <span *ngIf="side.side.side == 'left-top'">{{ 'WINDOW|Bok lewy górny' | translate }}:</span>
                    <span *ngIf="side.side.side == 'right'">{{ 'WINDOW|Bok prawy' | translate }}:</span>
                    <span *ngIf="side.side.side == 'right-top'">{{ 'WINDOW|Bok prawy górny' | translate }}:</span>
                    <span *ngIf="side.side.side == 'top'">{{ 'WINDOW|Góra' | translate }}:</span>
                    <input *ngIf="!side.profile.finWidthInterval" [name]="'finWidth' + side.side.side" iccDimensionInput type="number" (ngModelChange)="side.finWidth = $event; onChangeRenoDimensions(index)"  [ngModel]="side.finWidth">
                    <mat-select *ngIf="side.profile.finWidthInterval" [name]="'finWidth' + side.side.side" (ngModelChange)="side.finWidth = $event; onChangeRenoDimensions(index)" [ngModel]="side.finWidth">
                        <mat-option *ngFor="let width of side.finWidths" [value]="width">
                            {{ width | unitmm:0:0 }}
                        </mat-option>
                    </mat-select>
                    <!-- {{$root.user.dimension_unit}} -->
                </span>
            </span>

            <!-- MONOBLOKOWA -->

            <span *ngIf="isMonoblockFrames" class="monoblock mat-elevation-z3">
                <b>{{ 'WINDOW|Rama monoblokowa' | translate }}:</b>
                <ng-container *ngFor="let side of frameSides; index as index;">
                    <span *ngIf="side.type === 'monoblock'">
                        <span *ngIf="side.side.side == 'bottom'">{{ 'WINDOW|Dół' | translate }}:</span>
                        <span *ngIf="side.side.side == 'left'">{{ 'WINDOW|Bok lewy' | translate }}:</span>
                        <span *ngIf="side.side.side == 'left-top'">{{ 'WINDOW|Bok lewy górny' | translate }}:</span>
                        <span *ngIf="side.side.side == 'right'">{{ 'WINDOW|Bok prawy' | translate }}:</span>
                        <span *ngIf="side.side.side == 'right-top'">{{ 'WINDOW|Bok prawy górny' | translate }}:</span>
                        <span *ngIf="side.side.side == 'top'">{{ 'WINDOW|Góra' | translate }}:</span>
                        <div class="iccSelect">
                            <select
                                [ngModel]="side.id"
                                (ngModelChange)="onChangeRenoDimensions(index)"
                                >
                            </select>
                        </div>
                    </span>
                </ng-container>
            </span>
        </form>
    </ng-container>
