import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { StepComponent, _, ConfiguratorOptions, ModalService, SharedFacade } from '@icc/configurator/shared';
import { Subscription } from 'rxjs';
import { EventBusService, APP_CONFIG, AppConfigFactory } from '@icc/common';
import { TypesService } from '@icc/legacy/configurator/steps/roller_shutter/types.service';
import { RollerDimensionsService } from '@icc/legacy/configurator/steps/roller_shutter/dimensions.service';
import { IccSystem } from '@icc/common/data-types';
import { iccListItem } from '@icc/configurator/ui';
import { ShutterFacade } from '../+state/shutter.facade';
import { map } from 'rxjs/operators';
import { SchemasService } from '@icc/legacy/configurator/steps/roller_shutter/schemas.service';
import { ColorsService } from '@icc/legacy/configurator/steps/roller_shutter/colors.service';
import { TypeInfoComponent } from '../type-info/type-info.component';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';

@Component({
    selector: 'icc-types',
    templateUrl: './type.component.html',
    styleUrls: ['./type.component.scss'],
})
export class TypesComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = (options: ConfiguratorOptions) => options.type === 'external_blind'? _('STEPS|Rodzaj żaluzji') : _('STEPS|Rodzaj rolety');
    static stepIcon = {
        ligature: 'view_column',
    };
    public configurator = 'roller_shutter';
    public stepId = 'rollertype';
    public title = (options?: ConfiguratorOptions) => options.type === 'external_blind'? _('ROLLERSHUTTER|Wybierz rodzaj żaluzji') : _('ROLLERSHUTTER|Wybierz rodzaj rolety');

    private subscriptions: Subscription[] = [];

    selectedSystem$ = this.shutterFacade.selectedSystem$;
    selectedConfiguratorType$ = this.shutterFacade.selectedConfiguratorType$;
    types: iccListItem[] = [];
    profile$ = this.shutterFacade.profile$;
    selectedType$ = this.shutterFacade.selectedType$.pipe(map(type => type.static));
    selectedSystemId$ = this.shutterFacade.selectedSystem$;
    systems: iccListItem[] = [];
    isJoinedTypesAndSystemsStep = this.config().IccConfig.Configurators.roller_shutter.joinTypesAndSystemsStep;
    constructor(
        private eventBusService: EventBusService,
        private typesService: TypesService,
        private schemasService: SchemasService,
        private rollerDimensionsService: RollerDimensionsService,
        private shutterFacade: ShutterFacade,
        private sharedFacade: SharedFacade,
        private colorsService: ColorsService,
        private modalService: ModalService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private currentConfiguratorService: CurrentConfiguratorService,
    ) {
        super();
        if (this.typesService.loadedData) {
            this.init();
        }
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }
    selectSystem(item: iccListItem){
        const system = this.schemasService.getAllSystems().find(s => s.id === item.id);
        this.schemasService.setSystem(system);
    }
    selectType(item: iccListItem) {
        const type = this.typesService.types.find(t => t.static === item.id);
        this.typesService.selectRollerType(type);
    }

    private init() {
        this.types = this.typesService.types.map(type => ({
            id: type.static,
            title: type.name,
            content: type.content,
            tabs: [type.type],
            imageUrl: '/files/windowline/' + type.title_image,
            showInfo: true,
            show_title_image: type?.show_title_image
        }));
        if (this.sharedFacade.isModalConfiguration) {
            this.types = this.types.filter(type => type.id === 'nadstawna')
        }
        if (!this.isJoinedTypesAndSystemsStep){
            this.systems = this.schemasService.getSystems().map(system => ({
                id: system.id,
                title: system.name,
                content: system.description,
                imageUrl: '/files/rollershuttersystem/' + system.image,
                showInfo: true,
                show_title_image: system?.show_title_image
            }));
        } else if (this.currentConfiguratorService.conf === 'roller_shutter') {
            this.systems = this.schemasService.getAllSystems().filter(item=>item.n_type!=="P" && item.n_type!=="A").map(system => ({
                id: system.id,
                title: system.name,
                content: system.description,
                imageUrl: '/files/rollershuttersystem/' + system.image,
                showInfo: true,
                show_title_image: system?.show_title_image
            }));

        } else if (this.currentConfiguratorService.conf === 'external_blind') {
            this.systems = this.schemasService.getAllSystems().filter(item=>item.n_type==="P" || item.n_type==="A").map(system => ({
                id: system.id,
                title: system.name,
                content: system.description,
                imageUrl: '/files/rollershuttersystem/' + system.image,
                showInfo: true,
                show_title_image: system?.show_title_image
            }));
        }
        this.rollerDimensionsService.updateDimensions();
    }
    changeSystem() {
        this.schemasService.openModalSystems();
    }
    changeProfile() {
        this.rollerDimensionsService.selectProfile();
        if (!this.config().IccConfig.Configurators.roller_shutter.autoProfileSelection) {
            this.colorsService.init();
        }
    }
    showInfo(item: iccListItem) {
        const system =  this.isJoinedTypesAndSystemsStep ?
            this.systems.filter(s => s.id === item.id)[0]
            : this.types.filter(types => types.id === item.id)[0];
        const systemSplitArr = system.imageUrl.split('/');
        system.imageUrl = systemSplitArr[systemSplitArr.length-1];
        const modalService = this.modalService
            .open({
                pageComponent: TypeInfoComponent,
                resolve: {
                    system: () => system,
                    imageFieldName: () => "imageUrl",
                    imageDirectory: () => "windowline",
                    button: () => true,
                    showImage: () => system?.show_title_image
                },
            })
            .result.then((result: boolean) => {
                if (result) {
                    this.isJoinedTypesAndSystemsStep ? this.selectSystem(item) : this.selectType(item);
                }
            });
    }

}
