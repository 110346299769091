<icc-select-box [title]="oneFillingBox?.title"
                [description]="oneFillingBox?.name"
                [imageUrl]="oneFillingBox?.img"
                [buttonText]="'INTERFACE|Zmień' | translate"
                [buttonText2]="(oneFillingBox.type === 'deco_panels' && 'INTERFACE|Opcje' | translate) || (oneFillingBox.type === 'glazing' && !oneFillingBox.oneGlazing  && 'WINDOW|Wypełnienie per kwatera' | translate)"
                [descriptionTemplate]="fillingBoxTemplate"
                [descriptionContext]="{box: oneFillingBox}"
                *ngIf="oneFillingBox.show"
                (select)="openModalFilling(oneFillingBox.boxType)"
                (select2)="openModalDecoPanelOptions(oneFillingBox.boxType)"
                ></icc-select-box>
<icc-select-box [title]="doorActiveFillingBox?.title"
                [description]="doorActiveFillingBox?.name"
                [imageUrl]="doorActiveFillingBox?.img"
                [buttonText]="'INTERFACE|Zmień' | translate"
                [buttonText2]="doorActiveFillingBox.type === 'deco_panels' && 'INTERFACE|Opcje' | translate"
                [descriptionTemplate]="fillingBoxTemplate"
                [descriptionContext]="{box: doorActiveFillingBox}"
                *ngIf="doorActiveFillingBox.show"
                (select)="openModalFilling('doorActive')"
                (select2)="openModalDecoPanelOptions('doorActive')"
                ></icc-select-box>
<icc-select-box [title]="doorPassiveFillingBox?.title"
                [description]="doorPassiveFillingBox?.name"
                [imageUrl]="doorPassiveFillingBox?.img"
                [buttonText]="'INTERFACE|Zmień' | translate"
                [buttonText2]="doorPassiveFillingBox.type === 'deco_panels' && 'INTERFACE|Opcje' | translate"
                [descriptionTemplate]="fillingBoxTemplate"
                [descriptionContext]="{box: doorPassiveFillingBox}"
                *ngIf="doorPassiveFillingBox.show"
                (select)="openModalFilling('doorPassive')"
                (select2)="openModalDecoPanelOptions('doorPassive')"
                ></icc-select-box>
<icc-select-box [title]="windowFillingBox?.title"
                [description]="windowFillingBox?.name"
                [imageUrl]="windowFillingBox?.img"
                [buttonText]="'INTERFACE|Zmień' | translate"
                [buttonText2]="windowFillingBox.type === 'deco_panels' && 'INTERFACE|Opcje' | translate"
                [descriptionTemplate]="fillingBoxTemplate"
                [descriptionContext]="{box: windowFillingBox}"
                *ngIf="windowFillingBox.show"
                (select)="openModalFilling('window')"
                (select2)="openModalDecoPanelOptions('window')"
                ></icc-select-box>
<icc-select-box [title]="'WINDOW|Ramka szyby' | translate"
                [description]="(glazingSpacer$ | async)?.name"
                [imageUrl]="'/files/windowwarmedge/' + (glazingSpacer$ | async)?.image"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="openModalWarmEdge()"
                *ngIf="hasWarmEdge$ | async"
                ></icc-select-box>
<icc-select-box [title]="'WINDOW|Listwa przyszybowa' | translate"
                [description]="(glazingBead$ | async)?.name"
                [imageUrl]="'/files/profile/' + (glazingBead$ | async)?.img"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="openModalGlazingBead()"
                *ngIf="availableGlazingBead$ | async"
                ></icc-select-box>
<icc-select-box [title]="'WINDOW|Listwa przyszybowa w skrzydłach' | translate"
                [description]="(glazingBeadInSashes$ | async)?.name"
                [imageUrl]="'/files/profile/' + (glazingBeadInSashes$ | async)?.img"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="openModalGlazingBead('sashes')"
                *ngIf="availableGlazingBeadSashes$ | async"
                ></icc-select-box>
<icc-select-box [title]="'WINDOW|Listwa przyszybowa w stałym szkleniu' | translate"
                [description]="(glazingBeadInFix$ | async)?.name"
                [imageUrl]="'/files/profile/' + (glazingBeadInFix$ | async)?.img"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="openModalGlazingBead('fix')"
                *ngIf="availableGlazingBeadFix$ | async"
                ></icc-select-box>
<icc-select-box [title]="'WINDOW|Kolor uszczelki' | translate"
                [description]="(sealColor$ | async)?.name"
                [imageUrl]="'/files/windowsealcolor/' + (sealColor$ | async)?.color_img"
                [color]="'#' + (sealColor$ | async)?.color"
                [colorSelect]="true"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="openModalSealColor()"
                *ngIf = "availableSealColor$ | async"></icc-select-box>
<icc-select-box [title]="'WINDOW|Kolor silikonu' | translate"
                [description]="(siliconeColor$ | async)?.name"
                [imageUrl]="'/files/windowsiliconecolor/' + (siliconeColor$ | async)?.color_img"
                [color]="'#' + (siliconeColor$ | async)?.color"
                [colorSelect]="true"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="openModalSiliconeColor()"
                *ngIf = "availableSiliconeColor$ | async"></icc-select-box>

<ng-template #fillingBoxTemplate let-box="box">
    <p class="glassTypeDescription"
        *ngIf="box.oneGlazing && box.type != 'deco_panels'">
         {{box.name}}<br>
         U<sub>g</sub> = {{box.u}}
         <span *ngIf="box.bondedGlazing">
            <br>
            {{ 'WINDOW|Wklejane szyby' | translate }}
         </span>
         <span *ngIf="box.bondedGlazing && box.partlyBondedGlazing">
            <br>
            {{ 'WINDOW|Wklejane szyby w części kwater.' | translate }}
         </span>
     </p>
     <p class="glassTypeDescription"
        *ngIf="box.oneGlazing && box.type == 'deco_panels'">
        <span><b>{{ 'WINDOW|Rodzaj:' | translate }}</b>&nbsp;
             <span *ngIf="box.panelType == 'Inset'">{{'DOOR|Wypełnienie wsadowe' | translate }}</span>
             <span *ngIf="box.panelType == 'Outer'">{{'DOOR|Nakładka zewnętrzna' | translate }}</span>
             <span *ngIf="box.panelType == 'Double'">{{'DOOR|Nakładka obustronna' | translate }}</span>
             <span *ngIf="box.panelType == 'Inner'">{{'DOOR|Nakładka wewnętrzna' | translate }}</span>
             <br>
         </span>
        <span><b>{{ 'WINDOW|Model:' | translate }}</b>&nbsp;{{box.name}}<br></span>
        <span *ngIf="box.panelGlazingName"><b>{{ 'WINDOW|Szklenie:' | translate }}</b>&nbsp;{{box.panelGlazingName}}<br></span>
        <span><b>{{ 'WINDOW|Kolor wypełnienia:' | translate }}</b>&nbsp;{{box.color}}<br></span>
        <span *ngIf="box.color2"><b>{{ 'WINDOW|Kolor wypełnienia 2:' | translate }}</b>&nbsp;{{box.color2}}<br></span>
     </p>
     <p class="glassTypeDescription" *ngIf="!box.oneGlazing">
         {{ 'WINDOW|Różne pakiety w poszczególnych kwaterach, szczegóły w podsumowaniu.' | translate }}
     </p>
</ng-template>
