import {PriceFunc, PriceElemsData, PriceSegment, getListPrice} from './Prices';
import { CustomPricesRecords } from '@icc/common/custom-price/CustomPrice';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import {Injectable, Inject} from '@angular/core';
import { Common } from '@icc/common/Common';
import { SealColorsDefaultsService } from '@icc/common/colors/seal-colors-defaults.service';

@Injectable()
export class PriceSealService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private sealColorsDefaultsService: SealColorsDefaultsService
    ) {}

    /**
     * Wylicza dopłatę procentową za zmianę koloru uszczelki.
     *
     * @param {number} price         Wejsciowa cena
     * @param {object} PriceElems    Składowe wyceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @param {array}  constrElems   Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} sealColor     Okucie bezpieczne
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'sealColor',
        data: {
            sealColor  : 'conf.SealColor',
            system     : 'conf.System',
            conf       : 'conf',
            user       : 'user',
            customPrice: 'price.WindowSealColor',
            colors     : 'data.windowSealColors',
            colorsAll  : 'data.windowColorsAll'
        }
    })
    suppSealColor({ listPrice }: PriceElemsData,
        {sealColor, system, conf, user, customPrice, colors, colorsAll}: {sealColor, system, conf, user, customPrice: CustomPricesRecords, colors, colorsAll}
    ): PriceSegment[] {
        let factors = 1;
        if (!this.config().IccConfig.Configurators.sealColorSelect) {
            return [];
        }
        const defaultColor = this.sealColorsDefaultsService.getDefaultColor(colors, conf, user, undefined, colorsAll);
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        if (Common.isObject(sealColor) && defaultColor && Number(defaultColor.id) !== Number(sealColor.id)) {
            let sealColorPrice;
            if (Common.isObject(customPrice) && customPrice[sealColor.id]) {
                sealColorPrice = customPrice[sealColor.id].getPrice('price');
            } else {
                sealColorPrice = parseFloat(getListPrice(sealColor, 'price', listPrice, listPriceEnableInMarket) ?? 0);
            }

            factors *= (100 + sealColorPrice) / 100;
        }

        return <PriceSegment[]>[{
            type: 'sealColor',
            baseValue: factors,
            value: factors,
            valueType: 'percent',
            data: {
                id  : sealColor.id,
                name: sealColor.name,
            }
        }];
    }
}
