import { Common } from './Common';
import { core } from '@icc/common/helpers';
import { Profile, ProfileType, FrameProfile, ProfileShape, Frame, ProfileConnection, ProfileCompatibleProfile } from '@icc/window';
import { ConfiguratorsDataService, LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { MullionsService } from '@icc/common/profiles/mullions.service';
import { ValidationService } from '@icc/common/configurators/validation.service';
import { Inject, Injectable } from '@angular/core';
import { WindowConfiguration } from '@icc/common/configurations/WindowConfiguration';
import { CoupledWindowActiveConfiguration } from '@icc/common/configurations/CoupledWindowActiveConfiguration';
import { PriceLevel, ProfilesPrices } from '@icc/common/data-types';
import { ActiveSash } from './layout/active-sash';
import { APP_CONFIG, AppConfigFactory } from './config';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { activeSideColorsToSideColors } from './configurations/converters/window/colors';

/**
 * Fabryka użytkowników
 * @param {object} $filter             filter
 * @param {object} $q                  q
 * @param {object} SynchronizeService  SynchronizeService
 * @param {String} USER_ID             id użytkownika
 */
@Injectable()
export abstract class ProfilesService {
    loadedData = false;
    profilesPrices: ProfilesPrices = [];
    profilesPricesForLength: any = [];

    protected profiles: Profile[] = [];
    protected profileShapes = [];
    protected profileSets: Profile[] = [];
    protected reinforcements = [];
    protected profileConnections: ProfileConnection[] = [];
    protected priceLevels: PriceLevel[] = [];
    protected profilesCompatibleProfiles: ProfileCompatibleProfile[] = [];
    profileCategories: any = [];
    constructor(
        protected profilesPriceService: ProfilesPriceService,
        protected eventBusService: EventBusService,
        protected mullionsService: MullionsService,
        protected validationService: ValidationService,
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        protected configuratorsDataService?: ConfiguratorsDataService,
    ) {
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.loadProfiles(
                    data.value,
                    data.activeConfiguration as WindowActiveConfiguration
                );
            }
        );
    }

    getProfile(id?: number | null) {
        return this.profiles.filter(profile => profile.id === id)[0];
    }

    getProfilesWeight(
        type:
            | 'alignment'
            | 'extension'
            | 'frame'
            | 'glazingBead'
            | 'mullion'
            | 'coupling'
            | 'sash'
            | 'sashFrame',
        data,
        conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration,
        params: {
            frameId?: number;
            sashId?: number;
            mullionId?: number;
            extensionId?: number;
            alignmentId?: number;
            couplingId?: number;
        } = {}
    ) {
        let weight = 0;
        const dimensionsData = conf.drawData[type].find(el =>
            params
                ? Object.keys(params).reduce(
                      (prev, curr) => prev && el[curr] === params[curr],
                      true
                  )
                : true
        );

        if (dimensionsData) {
            if (Common.isDefined(dimensionsData.sides)) {
                let positionMap: Record<number, number> = {};
                if (type === 'frame') {
                    positionMap = this.parseFrameDrawDataSides(dimensionsData.sides).positionMap;
                }
                weight = dimensionsData.sides.reduce((prev, side, index) => {
                    let profile: Profile;
                    let reinforcementId;

                    if (type === 'frame') {
                        index = positionMap[index];
                    }

                    if (
                        Common.isArray(data)
                        && Common.isDefined(data[index])
                        && Common.isDefined(data[index].profileId)
                    ) {
                        if (side.poly.alt && data[index].alt && data[index].alt.profileId) {
                            profile = this.getProfile(data[index].alt.profileId);
                        } else {
                            profile = this.getProfile(data[index].profileId);
                        }
                        if (data[index].reinforcement && data[index].reinforcement.id) {
                            reinforcementId = data[index].reinforcement.id;
                        }
                    } else if (Common.isObject(data) && Common.isDefined(data.profileId)) {
                        profile = this.getProfile(data.profileId);
                        if (data.reinforcement && data.reinforcement.id) {
                            reinforcementId = data.reinforcement.id;
                        }
                    } else if (
                        Common.isObject(data)
                        && Common.isObject(data[side.outerEdge.side])
                        && Common.isDefined(data[side.outerEdge.side].profileId)
                    ) {
                        profile = this.getProfile(data[side.outerEdge.side].profileId);
                        if (
                            !side.outerEdge.circle
                            && data[side.outerEdge.side].reinforcement
                            && data[side.outerEdge.side].reinforcement.id
                        ) {
                            reinforcementId = data[side.outerEdge.side].reinforcement.id;
                        }
                    }

                    if (profile) {
                        if (
                            WindowActiveConfiguration.is(conf)
                            && conf.System.type === 'wood'
                            && conf.Wood.mass
                            && profile.sectionArea
                        ) {
                            prev +=
                                (((profile.sectionArea || 0) * (side.outerEdge.length || 0))
                                    / 1000000000)
                                * (parseFloat(conf.Wood.mass) || 0);
                        } else {
                            prev += ((profile.mass || 0) * (side.outerEdge.length || 0)) / 1000;
                        }
                        if (reinforcementId) {
                            const reinforcement = this.reinforcements.find(
                                el => el.id === reinforcementId
                            );
                            if (reinforcement) {
                                prev +=
                                    ((reinforcement.weight || 0) * (side.outerEdge.length || 0))
                                    / 1000;
                            }
                        }
                    }
                    return prev;
                }, 0);
            } else {
                let reinforcementId;
                const profile = this.getProfile(data.profileId);
                if (data.reinforcement && data.reinforcement.id) {
                    reinforcementId = data.reinforcement.id;
                }
                if (profile) {
                    if (
                        WindowActiveConfiguration.is(conf)
                        && conf.System.type === 'wood'
                        && conf.Wood.mass
                        && profile.sectionArea
                    ) {
                        weight =
                            (((profile.sectionArea || 0) * (dimensionsData.length || 0))
                                / 1000000000)
                            * (parseFloat(conf.Wood.mass) || 0);
                    } else {
                        weight = ((profile.mass || 0) * (dimensionsData.length || 0)) / 1000;
                    }
                    if (reinforcementId) {
                        const reinforcement = this.reinforcements.find(
                            el => el.id === reinforcementId
                        );
                        if (reinforcement) {
                            weight +=
                                ((reinforcement.weight || 0) * (dimensionsData.length || 0)) / 1000;
                        }
                    }
                }
            }
        }

        return weight;
    }

    getFilteredProfiles(
        conf: WindowActiveConfiguration | WindowConfiguration | null,
        type: ProfileType | ProfileType[] | null,
        options:
            | {
                  and?: string[];
                  not?: string[];
                  filter?: (
                      profile: Profile,
                      conf: WindowActiveConfiguration | WindowConfiguration | null
                  ) => boolean;
              }
            | {
                  and?: string[];
                  not?: string[];
                  filter?: (
                      profile: Profile,
                      conf: WindowActiveConfiguration | WindowConfiguration | null
                  ) => boolean;
              }[] = {
            and: [],
            not: [],
        },
        calcPrice = true
    ) {
        const systemId = conf
            ? WindowConfiguration.is(conf)
                ? conf.system
                : Number(conf.System.id)
            : null;

        const tmpProfileIds = {};

        const blockedItems = this.getBlockadeFrameProfiles(conf);

        return this.profiles
            .filter(el => {
                const result =
                    !tmpProfileIds[el.id]
                    && (!systemId || el.systems.some(sys => Number(sys) === systemId))
                    && (!blockedItems || !blockedItems.some(item => item.ids.includes(Number(el.id))))
                    && ((Common.isArray(type)
                        && Common.isArray(options)
                        && type.some(
                            (t, index) =>
                                (el.type === t || !type)
                                && (!Common.isDefined(options[index].and)
                                    || !options[index].and.length
                                    || options[index].and.every(
                                        option => el.options.indexOf(option) > -1
                                    ))
                                && (!Common.isDefined(options[index].not)
                                    || !options[index].not.length
                                    || options[index].not.every(
                                        option => el.options.indexOf(option) === -1
                                    ))
                                && (!Common.isDefined(options[index].filter)
                                    || options[index].filter(el, conf))
                        ))
                        || (!Common.isArray(type)
                            && !Common.isArray(options)
                            && (el.type === type || !type)
                            && (!Common.isDefined(options.and)
                                || !options.and.length
                                || options.and.every(option => el.options.indexOf(option) > -1))
                            && (!Common.isDefined(options.not)
                                || !options.not.length
                                || options.not.every(option => el.options.indexOf(option) === -1))
                            && (!Common.isDefined(options.filter) || options.filter(el, conf))));

                tmpProfileIds[el.id] = true;
                return result;
            })
            .filter(el => this.filterProfileByConnection(el, conf))
            .map(el => {
                if (calcPrice) {
                    let profileOptions: {
                        and?: string[];
                        not?: string[];
                    } = {
                        and: [],
                        not: [],
                    };
                    if (Common.isArray(options) && Common.isArray(type)) {
                        profileOptions = options[type.indexOf(el.type)];
                    } else if (!Common.isArray(options) && !Common.isArray(type)) {
                        profileOptions = options;
                    }
                    const priceType = this.getProfilePriceType(el.type, profileOptions);
                    const frameColorTypes = ['frame', 'fixed_mullion', 'structured_muntin_frame', 'false_mullion', 'threshold'];
                    const colorFrom = frameColorTypes.includes(el.type) ? 'frame' : 'sash';
                    el.price = systemId
                        ? this.profilesPriceService.getProfilePrice(
                              el.id,
                              priceType,
                              WindowConfiguration.is(conf) ? conf.dictionary.systems[systemId] : conf.System,
                              WindowConfiguration.is(conf) ? activeSideColorsToSideColors(
                                conf.dictionary.constrColors,
                                conf.dictionary.colors,
                                conf.colors[colorFrom],
                                this.config().CurLang
                              ) : conf.Colors[colorFrom],
                              this.profilesPrices,
                              null
                          )
                        : null;
                }
                delete el.selectedColor;
                delete el.selectedWood;

                return el;
            });
    }

    getProfilePriceType(
        type: ProfileType,
        options: {
            and?: string[];
            not?: string[];
        } = {
            and: [],
            not: [],
        }
    ) {
        let priceType: string = type;

        if (options.and && options.and.some(el => el.indexOf('alignment') > -1)) {
            priceType = 'alignment';
        } else if (!priceType) {
            priceType = 'other';
        } else if (
            options.and
            && options.and.some(
                el => el.indexOf('vertical_sash') > -1 || el.indexOf('horizontal_sash') > -1
            )
        ) {
            priceType = 'structured_muntin_sash';
        }

        return priceType;
    }

    getMullionPriceType(mullion, mullions, confType, profiles = this.profiles) {
        let fields1 = mullion.multiAlignLeft;
        let fields2 = mullion.multiAlignRight;
        if (mullion.direction === 'horizontal') {
            fields1 = mullion.multiAlignTop;
            fields2 = mullion.multiAlignBottom;
        }
        const falseMullionSashes = this.getFalseMullionSashes(profiles).map(profile => profile.id);
        const mullionType = this.mullionsService.getMullionTypeBetweenFields(
            fields1,
            fields2,
            mullion.direction,
            falseMullionSashes,
            confType,
            mullions
        );

        if (!mullion.profileId) {
            return false;
        }

        return this.mullionsService.mullionTypes[mullionType].priceType;
    }

    getMullionSetType(mullion, mullions, confType, profiles = this.profiles) {
        let fields1 = mullion.multiAlignLeft;
        let fields2 = mullion.multiAlignRight;
        if (mullion.direction === 'horizontal') {
            fields1 = mullion.multiAlignTop;
            fields2 = mullion.multiAlignBottom;
        }
        const falseMullionSashes = this.getFalseMullionSashes(profiles).map(profile => profile.id);
        const mullionType = this.mullionsService.getMullionTypeBetweenFields(
            fields1,
            fields2,
            mullion.direction,
            falseMullionSashes,
            confType,
            mullions
        );

        if (!mullion.profileId) {
            return false;
        }

        return this.mullionsService.mullionTypes[mullionType].set;
    }

    setDefaultsFromSet(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        this.setDefaultFrames(conf);
        this.setDefaultSashes(conf, defaultConf);
        this.setDefaultMullions(conf);
    }

    setDefaultFrames(conf: WindowActiveConfiguration) {
        conf.Frames.forEach(frame => this.setDefaultFrame(frame, conf));
    }

    setDefaultFrame(frame: Frame, conf: WindowActiveConfiguration) {
        // rama i próg
        const pauseId = this.eventBusService.pause(['setFrameProfile', 'unsetLowThreshold']);
        try {
            const sides = this.getFrameSides(frame, conf);
            sides.forEach((side, index) => {
                this.setDefaultFrameProfile(conf, frame, side.side, side.sideSimple, index);
            });
        } finally {
            this.eventBusService.resume(['setFrameProfile', 'unsetLowThreshold'], pauseId);
        }
    }

    setDefaultFrameProfile(
        conf: WindowActiveConfiguration,
        frame: Frame,
        side: string,
        sideSimple: string,
        position: number
    ) {
        // rama i próg
        const oldProfile = frame?.frame?.[position]?.profileId && this.getProfile(frame.frame[position]?.profileId);
        const profile = this.getDefaultFrameProfile(sideSimple, frame, conf, position);
        let altProfile: Profile | null = null;
        if (frame.splitBottom && sideSimple === 'bottom') {
            altProfile = this.getDefaultFrameProfile(sideSimple, frame, conf, position, null, true);
        }
        this.setFrameProfile(conf, profile, frame, position, {
            isDefault: true,
            finWidth:
                altProfile && sideSimple === 'bottom'
                ? altProfile.finWidth || 0
                : profile?.type !== 'threshold' && (sideSimple !== 'top' || (sideSimple === 'top' && !conf.hasRoller))
                    ? profile?.finWidth || 0
                    : 0,
            side,
            altProfile,
        });

        if (oldProfile?.type === 'threshold' && frame?.lowThreshold && sideSimple === 'bottom' && profile?.type !== 'threshold') {
            frame.lowThreshold = false;
            this.eventBusService.post({ key: 'unsetLowThreshold', value: {} });
        }
    }

    getDefaultFrameProfile(
        sideSimple: string,
        frame: Frame,
        conf: WindowActiveConfiguration,
        position: number,
        doorLightSizeId?: number,
        alt = false
    ) {
        const type = this.getFrameTypeForSide(sideSimple, frame, alt);
        const defaultProfile = this.getProfile(conf.ProfileSet[type]);

        return [defaultProfile, ...this.profiles].find(
            el =>
                el
                && this.validFrameProfile(
                    conf,
                    frame,
                    {
                        profileId: el.id,
                        finWidth: 0,
                    },
                    position,
                    doorLightSizeId,
                    alt,
                )
        );
    }

    getAvailableProfilesForSashType(forFix = false, forNoFix = false, conf) {
        return this.getFilteredProfiles(conf, 'frame', {
            and: [],
            not: [],
        }, false).filter(el => {
            const forGlazing = el.options.includes('for_glazing');
            const forHardware = el.options.includes('for_hardware');
            return (forFix && forGlazing || !forFix) && (forNoFix && forHardware || !forNoFix);
        });
    }


    validFrameProfile(
        conf: WindowActiveConfiguration,
        frame: Frame,
        frameProfile: Pick<FrameProfile, 'profileId' | 'finWidth' | 'alt' | 'segments'>,
        position: number,
        doorLightSizeId?: number,
        altProfile = false,
        validSplitBottom = false,
    ) {
        const sides = this.getFrameSides(frame, conf);
        if (!frameProfile.profileId) {
            return false;
        }
        const profile = this.getProfile(frameProfile.profileId);

        if (profile.systems.indexOf(Number(conf.System.id)) === -1) {
            return false;
        }
        const blockedItems = this.getBlockadeFrameProfiles(conf);
        const hasOutwardSash = conf.Sashes.some(sash => sash.frameId === frame.id && sash.type.out_open);
        let valid =
            profile
            && ((profile.type === 'frame'
                    && (sides[position].sideSimple !== 'bottom' || !frame.lowThreshold || altProfile)
                    && (!blockedItems || !blockedItems.some(item => item.ids.includes(Number(profile.id))))
                    && (!hasOutwardSash && profile.options.includes('for_inward_opening_window') || profile.options.includes('for_outward_opening_window'))
                )
                || (profile.type === 'threshold'
                    && sides[position].sideSimple === 'bottom'
                    && frame.lowThreshold
                    && (!blockedItems || !blockedItems.some(item => item.ids.includes(Number(profile.id))))
                    && !altProfile))
            && ((profile.options.indexOf('renovation_frame') === -1
                && Number(frameProfile.finWidth) === 0)
                || (profile.options.indexOf('renovation_frame') > -1
                    && (!conf.hasRoller
                        || sides[position].sideSimple !== 'top'
                        || (sides[position].sideSimple === 'top'
                            && conf.hasRoller
                            && Number(frameProfile.finWidth) === 0))))
            && this.matchDoorLightSize(profile, conf, doorLightSizeId);

        if (valid) {
            valid = this.matchThresholdToFrameByProfileConnection(profile.id, frame)
        }

        if (valid && validSplitBottom && sides[position].sideSimple === 'bottom') {
            if (frame.splitBottom) {
                if (!frameProfile.alt?.profileId) {
                    return false;
                }

                valid = this.validFrameProfile(conf, frame, {
                    profileId: frameProfile.alt.profileId,
                    finWidth: frameProfile.alt.finWidth,
                }, position, doorLightSizeId, true, false);
                if (valid) {
                    valid = this.validFrameProfileSegments(frameProfile, frame, conf);
                }
            } else {
                valid = !frameProfile.alt;
            }
        }

        return valid;
    }

    private matchThresholdToFrameByProfileConnection(profileId: number, frame: Frame) {
        if (
            this.config().IccConfig.Configurators.window.filterThresholdsByFrameProfileConnections
        ) {
            const frameIdsWithoutThreshold = frame.frame.map((f) => f.side !== 'bottom' && f.profileId).filter(Boolean);

            const selectedFrameIds = Array.from(new Set(frameIdsWithoutThreshold));
            const profileMap = new Map<number, any>();
            this.profiles.forEach(profile => profileMap.set(profile.id, profile));
            const matchedConnections = this.profileConnections.filter(profileConnection => {
                const profile1 = profileMap.get(Number(profileConnection.connected_profile_id));
                const profile2 = profileMap.get(Number(profileConnection.profile_id));
                return (
                    (profile1 && profile1.type === 'frame' && Number(profileConnection.profile_id) === profileId) ||
                    (profile2 && profile2.type === 'frame' && Number(profileConnection.connected_profile_id) === profileId)
                );
            });

            if (matchedConnections.length > 0) {
                return matchedConnections.some(
                    (c) =>
                        selectedFrameIds.includes(Number(c.profile_id)) ||
                        selectedFrameIds.includes(Number(c.connected_profile_id))
                );
            } else {
                return true;
            }
        }

        return true;
    }

    private validFrameProfileSegments(frameProfile: Pick<FrameProfile, 'profileId' | 'finWidth' | 'alt' | 'segments'>, frame: Frame, conf: WindowActiveConfiguration) {
        const segments = this.createBottomFrameProfileSegments(frame, conf);
        if (frameProfile.segments) {
            if (frameProfile.segments.length !== segments.length) {
                return false;
            }
            return frameProfile.segments.every((segment, index) => {
                const s = segments[index];
                if (segment.alt !== s.alt) {
                    return false;
                }
                if (segment.nearMullions.left !== s.nearMullions.left) {
                    return false;
                }
                if (segment.nearMullions.right !== s.nearMullions.right) {
                    return false;
                }
                return true;
            });
        }
        return true;
    }

    private createBottomFrameProfileSegments(frame: Frame, conf: WindowActiveConfiguration): FrameProfile['segments'] {
        const segments: FrameProfile['segments'] = [];

        const sashes = conf.Sashes.filter((s) => this.isSashOnEdge('bottom', s, conf) && s.frameId === frame.id).sort((a, b) => a.rx - b.rx);

        let segment: FrameProfile['segments'][0] | null = null;
        sashes.forEach((sash) => {
            const alt = ['F', 'FF', 'OFF', 'K'].includes(sash.type.type);
            const leftNearMullion = this.getNearMullionId(sash, 'left', conf);
            const rightNearMullion = this.getNearMullionId(sash, 'right', conf);

            if (segment === null) {
                segment = {
                    alt,
                    nearMullions: {
                        left: leftNearMullion,
                        right: rightNearMullion,
                    },
                };
            } else {
                if (segment.alt === alt) {
                    segment.nearMullions.right = rightNearMullion;
                } else {
                    segments.push(segment);
                    segment = {
                        alt,
                        nearMullions: {
                            left: leftNearMullion,
                            right: rightNearMullion,
                        },
                    };
                }
            }
        });
        if (segment !== null) {
            segments.push(segment);
        }

        return segments;
    }

    matchDoorLightSize(
        profile: Profile,
        conf: WindowActiveConfiguration | WindowConfiguration,
        doorLightSizeId?: number
    ) {
        const doorType = conf
            ? WindowConfiguration.is(conf)
                ? conf.dictionary.systems[conf.system]
                  && conf.dictionary.systems[conf.system].doorType
                : conf.System && conf.System.door_type
            : false;
        if (!doorType || profile.type !== 'frame') {
            return true;
        }
        if (doorLightSizeId) {
            return profile.doorLightsSizes && profile.doorLightsSizes.includes(doorLightSizeId);
        }
        const ownedSashesTypes = WindowActiveConfiguration.is(conf)
            ? conf.OwnedSashesTypes
            : conf.ownedSashesTypes;
        let match = true;
        if (ownedSashesTypes.doorLeftLight && conf.doorSizes.leftLightSizeId) {
            match =
                match
                && profile.doorLightsSizes
                && profile.doorLightsSizes.includes(conf.doorSizes.leftLightSizeId);
        }
        if (ownedSashesTypes.doorRightLight && conf.doorSizes.rightLightSizeId) {
            match =
                match
                && profile.doorLightsSizes
                && profile.doorLightsSizes.includes(conf.doorSizes.rightLightSizeId);
        }
        if (ownedSashesTypes.doorTopLight && conf.doorSizes.topLightSizeId) {
            match =
                match
                && profile.doorLightsSizes
                && profile.doorLightsSizes.includes(conf.doorSizes.topLightSizeId);
        }
        return match;
    }

    setDefaultMullion(conf: WindowActiveConfiguration, mullion, internal: boolean) {
        let fields1 = mullion.multiAlignLeft;
        let fields2 = mullion.multiAlignRight;
        if (mullion.direction === 'horizontal') {
            fields1 = mullion.multiAlignTop;
            fields2 = mullion.multiAlignBottom;
        }

        const defaultProfileId = this.getMullionProfileIdBetweenFields(
            conf,
            fields1,
            fields2,
            mullion.direction,
            internal
        );
        const profile = this.getProfile(defaultProfileId);
        this.setMullionProfile(conf, mullion, profile, { isDefault: true });
    }

    setDefaultMullions(conf: WindowActiveConfiguration) {
        // słupki
        conf.Mullions.forEach(mullion => {
            this.setDefaultMullion(conf, mullion, false);
        });
        conf.Sashes.forEach(sash => {
            if (sash.intMullions) {
                sash.intMullions.forEach(mullion => {
                    this.setDefaultMullion(conf, mullion, true);
                });
            }
        });
    }

    setMullionProfile(
        conf: WindowActiveConfiguration,
        mullion,
        profile: Profile,
        { isDefault = false }: { isDefault?: boolean }
    ) {
        if (profile) {
            mullion.profileId = profile.id;
            mullion.type = profile.type;
            mullion.options = profile.options;
        } else {
            mullion.profileId = null;
            mullion.type = 'no_mullion';
            mullion.options = [];
        }
        mullion.isDefault = isDefault;

        this.setUsedProfiles(conf, profile);

        this.eventBusService.post({
            key: 'setMullionProfile',
            value: {
                profile,
                mullion,
            },
            conf,
        });
    }

    getMullionProfileIdBetweenFields(
        conf: WindowActiveConfiguration,
        fields1: ActiveSash[],
        fields2: ActiveSash[],
        direction: 'horizontal' | 'vertical',
        internal: boolean
    ) {
        const falseMullionSashes = this.getFalseMullionSashes().map(profile => profile.id);
        const mullionType = this.mullionsService.getMullionTypeBetweenFields(
            fields1,
            fields2,
            direction,
            falseMullionSashes,
            conf.type,
            conf.Mullions
        );
        const neededWiderMullion = this.mullionsService.neededWiderMullion(
            fields1,
            fields2,
            direction
        );
        const hasOutwardSash = fields1.some(sash => sash?.type?.out_open) || fields2.some(sash => sash?.type?.out_open);
        const matchingProfiles = this.getFilteredProfiles(
            conf,
            this.mullionsService.mullionTypes[mullionType].type,
            {
                and: [
                    ...(this.mullionsService.mullionTypes[mullionType][direction] || []),
                    ...((mullionType === 'fixed_mullion' || mullionType === 'structured_muntin_sash') ? [this.mullionsService.getGlazingOrHardwareOptions(internal, [...fields1, ...fields2])] : []),
                    ...(mullionType === 'fixed_mullion' ? hasOutwardSash ? ['for_outward_opening_window'] : ['for_inward_opening_window'] : []),
                ],
                not: [],
            }
        ).filter(profile => !neededWiderMullion || (neededWiderMullion && profile.width >= 60))
        .filter(profile => mullionType !== 'false_mullion' || this.isFalseMullionMatchToFalseSash(profile, fields1, fields2));
        const matchingProfileIdFromSet =
            conf.ProfileSet[this.mullionsService.mullionTypes[mullionType].set];
        if (matchingProfiles.length > 0 && mullionType !== 'no_mullion') {
            if (
                matchingProfiles.map(profile => profile.id).indexOf(matchingProfileIdFromSet) > -1
            ) {
                return matchingProfileIdFromSet;
            }
            return matchingProfiles[0].id;
        }
        return null;
    }

    isFalseMullionMatchToFalseSash(profile: Profile, fields1: ActiveSash[], fields2: ActiveSash[]) {
        const field1 = fields1[0];
        const field2 = fields2[0];

        if (field1.type?.passive) {
            const sashProfile = field1.frame.right?.profileId;
            return !sashProfile || this.isProfileCompatibleWithProfile(profile.id, sashProfile);
        } else if (field2.type?.passive) {
            const sashProfile = field2.frame.left?.profileId;
            return !sashProfile || this.isProfileCompatibleWithProfile(profile.id, sashProfile);
        }
        return true;
    }

    setDefaultSashes(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        const pauseId = this.eventBusService.pause(['setSashProfile']);
        try {
            conf.Sashes.forEach(sash => {
                this.setDefaultSash(sash, conf, defaultConf);
            });
        } finally {
            this.eventBusService.resume(['setSashProfile'], pauseId);
        }
    }

    setDefaultSash(sash, conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        this.setSash(conf, defaultConf, sash, null, { isDefault: true });
    }

    getDefaultSashProfile(
        conf: WindowActiveConfiguration,
        sash: any,
        position: 'bottom' | 'top' | 'left' | 'right'
    ) {
        const defaultProfile = this.getProfile(
            conf.ProfileSet[sash.type.out_open ? 'sashOutward' : 'sash']
        );
        return [defaultProfile, ...this.profiles].find(
            el => el && this.validSashProfile({ profileId: el.id }, sash, conf, position)
        );
    }

    setSashProfile(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        sash: ActiveSash,
        profile: Profile,
        position: 'bottom' | 'top' | 'left' | 'right',
        { isDefault = false }: { isDefault?: boolean }
    ) {
        const sashProfile: FrameProfile = {
            id: 0,
            isDefault,
            profileId: profile && profile.id ? profile.id : null,
            jointAngle: 'E',
            weldFinishType: 'groove',
            reinforcement: null,
        };
        sash.frame[position] = sashProfile;

        this.setUsedProfiles(conf, profile);

        this.eventBusService.post({
            key: 'setSashProfile',
            value: {
                sash,
                position,
                profile,
            },
            conf,
            defaultConf,
        });
    }

    setSash(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        sash: ActiveSash,
        profile: Profile,
        { isDefault = false }: { isDefault?: boolean }
    ) {
        const sides: ('bottom' | 'top' | 'left' | 'right')[] = ['top', 'bottom', 'left', 'right'];
        sides.forEach((side, index) => {
            const existingProfile = sash.frame?.[side]?.profileId && this.getProfile(sash.frame[side].profileId);
            const matchingProfile = sash.frame?.[side]?.profileId && !sash.frame?.[side]?.isDefault && this.validSashProfile(sash.frame?.[side], sash, conf, side)
                    ? existingProfile
                    : this.getDefaultSashProfile(conf, sash, side);

            if (matchingProfile) {
                this.setSashProfile(conf, defaultConf, sash, matchingProfile, side, { isDefault });
            } else {
                this.setSashProfile(conf, defaultConf, sash, profile, side, { isDefault });
            }
        });
    }

    validSashProfile(sashProfile: { profileId: number }, sash: ActiveSash, conf: WindowActiveConfiguration, side) {
        if(sash?.type?.type === 'F' && !sashProfile.profileId) {
            return true;
        }

        if (!sashProfile || !sashProfile.profileId) {
            return false;
        }
        const profile = this.getProfile(sashProfile.profileId) as any;
        if (!profile) {
            return false;
        }
        if (profile.systems.indexOf(Number(conf.System.id)) === -1) {
            return false;
        }
        let valid = profile.type === (conf.System.door_type ? 'virtual_door_sash' : 'sash');
        if (
            (side === 'right' || side === 'left')
            && sash.type.handle_position === (side === 'right' ? 'R' : 'L')
            && sash.nearMullions[side] > -1
        ) {
            const mullion = conf.Mullions.find(el => el.id === sash.nearMullions[side]);
            const adjacentSashes = mullion[side === 'right' ? 'multiAlignRight' : 'multiAlignLeft'];
            if (sash.type.passive) {
                if (mullion && mullion.type !== 'false_mullion') {
                    valid = profile.type === 'false_mullion_sash';
                    if (sash.type.type === 'DSC') {
                        valid = valid && profile.options.includes('central_handle');
                    }
                }
                if (mullion && mullion.type === 'false_mullion') {
                    valid = this.isProfileCompatibleWithProfile(mullion.profileId, profile.id);
                }
            } else {
                if (mullion && mullion.type === 'false_mullion' && adjacentSashes.length > 0) {
                    const passiveSashProfile = this.getProfile(adjacentSashes[0]?.frame?.[side === 'right' ? 'left' : 'right']?.profileId);
                    if (passiveSashProfile?.compatibleActiveSashProfileId) {
                        valid = +passiveSashProfile.compatibleActiveSashProfileId === profile.id;
                    }
                }
            }
        }

        if (!sash.type.passive && profile.options.includes('passive_sash')) {
            return false;
        }


        if (sash.nearMullions.bottom > -1) {
            const bottomSashes = conf.Mullions.find(el => el.id === sash.nearMullions.bottom)
                .multiAlignBottom;
            const sidesMap = {
                top: 'top_in_top_sash',
                bottom: 'bottom_in_top_sash',
                left: 'side_in_top_sash',
                right: 'side_in_top_sash',
            };

            if (
                (sash.type.type === 'SD' || bottomSashes.some(el => el.type.type === 'SU'))
                && !profile.options.includes(sidesMap[side])
            ) {
                valid = false;
            }
        } else if (sash.nearMullions.top > -1) {
            const topSashes = conf.Mullions.find(el => el.id === sash.nearMullions.top)
                .multiAlignTop;
            const sidesMap = {
                top: 'top_in_bottom_sash',
                bottom: 'bottom_in_bottom_sash',
                left: 'side_in_bottom_sash',
                right: 'side_in_bottom_sash',
            };

            if (
                (sash.type.type === 'SU' || topSashes.some(el => el.type.type === 'SD'))
                && !profile.options.includes(sidesMap[side])
            ) {
                valid = false;
            }
        }

        return (
            valid
            && (profile.type === 'virtual_door_sash'
                || (profile.type !== 'virtual_door_sash'
                    && profile.options.includes('outward_opening') === sash.type.out_open))
        );
    }

    setFrameProfile(
        conf: WindowActiveConfiguration,
        profile: Profile,
        frame: Frame,
        position: number,
        {
            isDefault = false,
            finWidth = 0,
            side,
            altProfile,
        }: { isDefault?: boolean; finWidth?: number; side?: string, altProfile?: Profile | null }
    ) {
        const sides = this.getFrameSides(frame, conf);
        if (position < 0 || position > sides.length - 1) {
            throw new Error('Nie można ustawić profilu ramy w tym miejscu!');
        }

        const frameProfile: FrameProfile = {
            id: position,
            isDefault,
            profileId: profile && profile.id ? profile.id : null,
            finWidth,
            jointAngle: 'E',
            weldFinishType: 'groove',
            reinforcement: null,
            side,
        };

        if (altProfile?.id && side === 'bottom' && frame.lowThreshold && frame.splitBottom) {
            frameProfile.alt = {
                profileId: altProfile.id,
                finWidth,
                isDefault,
            };

            frameProfile.segments = this.createBottomFrameProfileSegments(frame, conf);
        } else {
            frameProfile.alt = null;
        }

        frame.frame[position] = frameProfile;

        this.setUsedProfiles(conf, profile);
        if (altProfile) {
            this.setUsedProfiles(conf, altProfile);
        }

        this.eventBusService.post({
            key: 'setFrameProfile',
            value: {
                frame,
                position,
                profile,
            },
            conf,
        });
    }

    getUsedProfilesIds(conf: WindowActiveConfiguration) {
        const profilesIds = [];
        const profilesSegments = {};

        const frameColor = {
            outer: null,
            inner: null,
            core: null,
        };
        const sashColor = {
            outer: null,
            inner: null,
            core: null,
        };

        Object.keys(frameColor).forEach(side => {
            if (conf.Colors.frame[side] && conf.Colors.frame[side].id) {
                frameColor[side] = {
                    id: conf.Colors.frame[side].id,
                    RAL: conf.Colors.frame[side].RAL,
                    groups: conf.Colors.frame[side].groups,
                    name: conf.Colors.frame[side].name,
                    type: conf.Colors.frame[side].type,
                };
            }
            if (conf.Colors.sash[side] && conf.Colors.sash[side].id) {
                sashColor[side] = {
                    id: conf.Colors.sash[side].id,
                    RAL: conf.Colors.sash[side].RAL,
                    groups: conf.Colors.sash[side].groups,
                    name: conf.Colors.sash[side].name,
                    type: conf.Colors.sash[side].type,
                };
            }
        });

        conf.Frames.forEach(frame => {
            let frameDrawData;
            if (conf.drawData && conf.drawData.frame) {
                frameDrawData = conf.drawData.frame.find(f => f.frameId === frame.id);
            }
            frame.frame.forEach((el, index) => {
                if (profilesIds.indexOf(el.profileId) === -1) {
                    profilesIds.push(el.profileId);
                    profilesSegments[el.profileId] = [];
                }
                if (el.alt?.profileId && profilesIds.indexOf(el.alt?.profileId) === -1) {
                    profilesIds.push(el.alt.profileId);
                    profilesSegments[el.alt.profileId] = [];
                }

                if (frameDrawData) {
                    const frameSides = this.parseFrameDrawDataSides(frameDrawData.sides).sides;
                    if (frameSides[index]) {
                        if (frameSides[index].children) {
                            frameSides[index].children.forEach(child => {
                                profilesSegments[child.poly.alt && el.alt ? el.alt.profileId : el.profileId].push({
                                    length: child.outerEdge.length,
                                    color: frameColor,
                                });
                            });
                        } else {
                            profilesSegments[el.profileId].push({
                                length: frameSides[index].outerEdge.length,
                                color: frameColor,
                            });
                        }
                    }
                }
            });
        });

        conf.Mullions.forEach(el => {
            if (profilesIds.indexOf(el.profileId) === -1) {
                profilesIds.push(el.profileId);

                profilesSegments[el.profileId] = [];
            }
            const mullionDimensions =
                conf.drawData && conf.drawData.mullion
                    ? conf.drawData.mullion.find(m => m.mullionId === el.id)
                    : null;
            if (mullionDimensions) {
                profilesSegments[el.profileId].push({
                    length: mullionDimensions.length,
                    color: frameColor,
                });
            }
        });
        if (conf.Alignments) {
            conf.Alignments.forEach(el => {
                if (profilesIds.indexOf(el.profileId) === -1) {
                    profilesIds.push(el.profileId);

                    profilesSegments[el.profileId] = [];
                }

                const alignmentDimensions =
                    conf.drawData && conf.drawData.alignment
                        ? conf.drawData.alignment.find(m => m.alignmentId === el.id)
                        : null;
                if (alignmentDimensions) {
                    profilesSegments[el.profileId].push({
                        length: alignmentDimensions.length,
                        color: frameColor,
                    });
                }
            });
        }
        conf.SideProfiles.forEach(el => {
            if (profilesIds.indexOf(el.profileId) === -1) {
                profilesIds.push(el.profileId);

                profilesSegments[el.profileId] = [];
            }

            const extensionColor = {
                outer: null,
                inner: null,
                core: null,
            };

            Object.keys(extensionColor).forEach(s => {
                if (el.color && el.color[s] && el.color[s].id) {
                    extensionColor[s] = {
                        id: el.color[s].id,
                        RAL: el.color[s].RAL,
                        groups: el.color[s].groups,
                        name: el.color[s].name,
                        type: el.color[s].type,
                    };
                }
            });

            const extensionDimensions =
                conf.drawData && conf.drawData.extension
                    ? conf.drawData.extension.find(m => m.alignmentId === el.id)
                    : null;
            if (extensionDimensions) {
                profilesSegments[el.profileId].push({
                    length: extensionDimensions.length,
                    color: extensionColor,
                });
            }
        });
        conf.Sashes.forEach(sash => {
            if (sash.glazingBead && sash.intSashes.length === 0) {
                if (profilesIds.indexOf(sash.glazingBead.profileId) === -1) {
                    profilesIds.push(sash.glazingBead.profileId);

                    profilesSegments[sash.glazingBead.profileId] = [];
                }
                const glazingBeadDimensions =
                    conf.drawData && conf.drawData.glazingBead
                        ? conf.drawData.glazingBead.find(m => m.sashId === sash.id)
                        : null;
                if (glazingBeadDimensions) {
                    glazingBeadDimensions.sides.forEach(bead => {
                        profilesSegments[sash.glazingBead.profileId].push({
                            length: bead.outerEdge.length,
                            color: frameColor,
                        });
                    });
                }
            }
            sash.intMullions.forEach(el => {
                if (profilesIds.indexOf(el.profileId) === -1) {
                    profilesIds.push(el.profileId);

                    profilesSegments[el.profileId] = [];
                }

                const mullionDimensions =
                    conf.drawData && conf.drawData.mullion
                        ? conf.drawData.mullion.find(m => m.mullionId === el.id)
                        : null;
                if (mullionDimensions) {
                    profilesSegments[el.profileId].push({
                        length: mullionDimensions.length,
                        color: sashColor,
                    });
                }
            });
            sash.intSashes.forEach(el => {
                if (el.glazingBead && el.glazingBead.profileId) {
                    if (profilesIds.indexOf(el.glazingBead.profileId) === -1) {
                        profilesIds.push(el.glazingBead.profileId);

                        profilesSegments[el.glazingBead.profileId] = [];
                    }

                    const glazingBeadDimensions =
                        conf.drawData && conf.drawData.glazingBead
                            ? conf.drawData.glazingBead.find(m => m.sashId === el.id)
                            : null;
                    if (glazingBeadDimensions) {
                        glazingBeadDimensions.sides.forEach(bead => {
                            profilesSegments[el.glazingBead.profileId].push({
                                length: bead.outerEdge.length,
                                color: sashColor,
                            });
                        });
                    }
                }
            });
            sash.intAlignments.forEach(el => {
                if (profilesIds.indexOf(el.profileId) === -1) {
                    profilesIds.push(el.profileId);

                    profilesSegments[el.profileId] = [];
                }

                const alignmentDimensions =
                    conf.drawData && conf.drawData.alignment
                        ? conf.drawData.alignment.find(m => m.alignmentId === el.id)
                        : null;
                if (alignmentDimensions) {
                    profilesSegments[el.profileId].push({
                        length: alignmentDimensions.length,
                        color: sashColor,
                    });
                }
            });
            const sashFrameDimensions =
                conf.drawData && conf.drawData.sashFrame
                    ? conf.drawData.sashFrame.find(m => m.sashId === sash.id)
                    : null;
            ['left', 'right', 'top', 'bottom'].forEach((el, index) => {
                if (
                    sash.frame[el]
                    && sash.frame[el].profileId
                    && profilesIds.indexOf(sash.frame[el].profileId) === -1
                ) {
                    profilesIds.push(sash.frame[el].profileId);

                    profilesSegments[sash.frame[el].profileId] = [];
                }
                if (
                    sash.frame[el]
                    && sash.frame[el].profileId
                    && sashFrameDimensions
                    && sashFrameDimensions.sides
                    && sashFrameDimensions.sides[index]
                ) {
                    profilesSegments[sash.frame[el].profileId].push({
                        length: sashFrameDimensions.sides[index].outerEdge.length,
                        color: sashColor,
                    });
                }
            });
        });

        conf.couplings.forEach(el => {
            if (profilesIds.indexOf(el.profileId) === -1) {
                profilesIds.push(el.profileId);

                profilesSegments[el.profileId] = [];
            }

            const couplingColor = {
                outer: null,
                inner: null,
                core: null,
            };

            Object.keys(couplingColor).forEach(s => {
                if (el.color && el.color[s] && el.color[s].id) {
                    couplingColor[s] = {
                        id: el.color[s].id,
                        RAL: el.color[s].RAL,
                        groups: el.color[s].groups,
                        name: el.color[s].name,
                        type: el.color[s].type,
                    };
                }
            });

            const couplingDimensions =
                conf.drawData && conf.drawData.coupling
                    ? conf.drawData.coupling.find(m => m.couplingId === el.id)
                    : null;
            if (couplingDimensions) {
                profilesSegments[el.profileId].push({
                    length: couplingDimensions.length,
                    color: couplingColor,
                });
            }
        });

        return { profilesIds, profilesSegments };
    }

    getUsedProfileShapesIds(conf: WindowActiveConfiguration) {
        const profileShapesIds = [];
        let profile;

        conf.Sashes.forEach(sash => {
            if (sash.glazingBead && sash.glazingBead.profileId) {
                profile = this.getProfile(sash.glazingBead.profileId);
                if (profileShapesIds.indexOf(profile.profileShapeId) === -1) {
                    profileShapesIds.push(profile.profileShapeId);
                }
            }
            sash.intSashes.forEach(el => {
                if (el.glazingBead && el.glazingBead.profileId) {
                    profile = this.getProfile(el.glazingBead.profileId);
                    if (profileShapesIds.indexOf(profile.profileShapeId) === -1) {
                        profileShapesIds.push(profile.profileShapeId);
                    }
                }
            });
        });

        return profileShapesIds;
    }

    setUsedProfiles(conf: WindowActiveConfiguration, profile?: Profile) {
        if (profile && Common.isUndefined(core.fId(conf.UsedProfiles, profile.id))) {
            conf.UsedProfiles.push(profile);
        }
        const { profilesIds, profilesSegments } = this.getUsedProfilesIds(conf);
        conf.UsedProfiles = conf.UsedProfiles.length
            ? conf.UsedProfiles.filter(el => profilesIds.indexOf(el.id) > -1)
            : (conf.UsedProfiles = this.profiles.filter(el => profilesIds.indexOf(el.id) > -1));

        conf.UsedProfilesSegments = profilesSegments;
    }

    setSideProfilesPrice(conf: WindowActiveConfiguration) {
        if(conf.System?.id) {
            conf.SideProfiles.forEach(sideProfile => {
                const profile = this.getProfile(sideProfile.profileId);
                if(this.profilesPrices?.[profile.id]?.[conf.System.id]?.[profile.type]?.length) {
                    sideProfile.pricePiece = this.profilesPrices[profile.id][conf.System.id][profile.type][0].price_piece;
                    sideProfile.listPricePiece = this.profilesPrices[profile.id][conf.System.id][profile.type][0].list_price_piece
                }
            });
        }
    }

    setUsedProfileShapes(conf: WindowActiveConfiguration, profileShape?: ProfileShape) {
        if (profileShape && Common.isUndefined(core.fId(conf.UsedProfileShapes, profileShape.id))) {
            conf.UsedProfileShapes.push(profileShape);
        }
        const profileShapesIds = this.getUsedProfileShapesIds(conf);
        if (conf.UsedProfiles.length) {
            conf.UsedProfileShapes = conf.UsedProfileShapes.filter(
                el => profileShapesIds.indexOf(el.id) > -1
            );
        } else {
            conf.UsedProfileShapes = this.profileShapes.filter(
                el => profileShapesIds.indexOf(el.id) > -1
            );
        }
    }

    getWindowSides(conf: WindowActiveConfiguration) {
        let sides: {
            side: string;
            sideSimple: string;
        }[] = [];
        const sidesMap = [
            { side: 'bottom', sideSimple: 'bottom', test: this.hasBottomSide },
            { side: 'right', sideSimple: 'side', test: this.hasRightSide },
            { side: 'right-top', sideSimple: 'side', test: this.hasRightTopSide },
            { side: 'top', sideSimple: 'top', test: this.hasTopSide },
            { side: 'left-top', sideSimple: 'side', test: this.hasLeftTopSide },
            { side: 'left', sideSimple: 'side', test: this.hasLeftSide },
        ];
        if (conf.Shape) {
            sides = sidesMap.filter(side => side.test(conf.Shape));
        }

        return sides;
    }

    getFrameSidesOnEdge(conf: WindowActiveConfiguration) {
        const sides: {
            side: string;
            sideSimple: string;
            frameEdges: {
                frameId: number;
                frameEdgeIndex: number;
            }[];
        }[] = [];
        const windowSides = this.getWindowSides(conf);

        windowSides.forEach((side, sideIndex) => {
            if (conf.Frames && conf.Frames.length && conf.drawData && conf.drawData.frame.length) {
                for (const frame of conf.Frames) {
                    const frameData =
                        conf.drawData && conf.drawData.frame.find(f => f.frameId === frame.id);
                    let sidePolyIndex = sideIndex;
                    if (
                        conf.Shape.shape === 'arc'
                        && conf.Shape.type === 'F'
                        && side.side === 'left'
                    ) {
                        sidePolyIndex = sidePolyIndex + 1;
                    }
                    if (frameData && frameData.parallel.poly.indexOf(sidePolyIndex) > -1) {
                        const matchSide = sides.find(s => s.side === side.side);
                        if (!matchSide) {
                            sides.push({
                                side: side.side,
                                sideSimple: side.sideSimple,
                                frameEdges: [
                                    {
                                        frameId: frame.id,
                                        frameEdgeIndex: frameData.parallel.poly.indexOf(
                                            sidePolyIndex
                                        ),
                                    },
                                ],
                            });
                        } else {
                            matchSide.frameEdges.push({
                                frameId: frame.id,
                                frameEdgeIndex: frameData.parallel.poly.indexOf(sidePolyIndex),
                            });
                        }
                    }
                }
            } else {
                sides.push({
                    side: side.side,
                    sideSimple: side.sideSimple,
                    frameEdges: [
                        {
                            frameId: 0,
                            frameEdgeIndex: sideIndex,
                        },
                    ],
                });
            }
        });

        return sides;
    }

    getFrameSides(frame: Frame, conf: WindowActiveConfiguration) {
        let sides: {
            side: string;
            sideSimple: string;
        }[] = [];

        const frameData = conf.drawData && conf.drawData.frame.find(f => f.frameId === frame.id);

        if (frameData) {
            sides = this.parseFrameDrawDataSides(frameData.sides).sides.map(side => ({
                side: side.outerEdge.side,
                sideSimple: this.sideToSimpleSide(side.outerEdge.side),
            }));
        } else {
            sides = this.getWindowSides(conf);
        }

        return sides;
    }

    parseFrameDrawDataSides(sides: any[]) {
        const newSides: any[] = [];
        const positionMap: Record<number, number> = {};

        let bottomSide;
        let bottomSideIndex;
        sides.forEach((side, index) => {
            if (side.outerEdge.side !== 'bottom') {
                newSides.push(side);
                positionMap[index] = newSides.length - 1;
                return;
            }
            if (!bottomSide) {
                bottomSide = {
                    ...side,
                    children: [side],
                };
                newSides.push(bottomSide);
                positionMap[index] = newSides.length - 1;
                bottomSideIndex = newSides.length - 1;
            } else {
                bottomSide.children.push(side);
                positionMap[index] = bottomSideIndex;
            }
        });
        return {sides: newSides, positionMap};
    }

    getProfileShape(id: number) {
        return this.profileShapes.filter(profileShape => profileShape.id === id)[0];
    }

    getProfileShapes() {
        return this.profileShapes;
    }

    getProfileShapesNames() {
        return this.profileShapes.reduce((prev, cur) => {
            prev[cur.id] = cur.name;
            return prev;
        }, {});
    }

    getArcFramesEdges(conf: WindowActiveConfiguration) {
        const frameSides = this.getFrameSidesOnEdge(conf);
        if (conf.Shape.shape === 'circle') {
            const topEdges = frameSides.filter(s => s.side === 'top' || s.side === 'bottom');
            const temp = topEdges.reduce<FrameProfile[]>(
                (edges, side) =>
                    edges.concat(
                        side.frameEdges.reduce<FrameProfile[]>((prev, cur) => {
                            const frame = conf.Frames.find(f => f.id === cur.frameId);
                            return frame ? prev.concat(frame.frame[cur.frameEdgeIndex]) : prev;
                        }, [])
                    ),
                []
            );
            return temp;
        } else if (conf.Shape.shape === 'arc') {
            const topEdges = frameSides.find(s => s.side === 'top') || frameSides[0];
            return (
                (topEdges
                    && topEdges.frameEdges.reduce<FrameProfile[]>((prev, cur) => {
                        const frame = conf.Frames.find(f => f.id === cur.frameId);
                        return frame
                            ? prev.concat(frame.frame[cur.frameEdgeIndex] || frame.frame[0])
                            : prev;
                    }, []))
                || []
            );
        }
        return null;
    }

    filterProfileByConnection(profile: Profile, conf: WindowActiveConfiguration | WindowConfiguration | null) {
        if (
            conf
            && (profile.type === 'extension'
            || (profile.type === 'threshold' && this.config().IccConfig.Configurators.window.filterThresholdsByFrameProfileConnections))
        ) {
            const connections = this.profileConnections.filter(c => Number(c.profile_id) === Number(profile.id) || Number(c.connected_profile_id) == profile.id);
            if (connections.length > 0) {
                const usedProfiles = WindowConfiguration.is(conf) ? Object.values(conf.dictionary.profiles).map(p => ({
                    id: p.id,
                    type: p.type
                })) : conf.UsedProfiles;
                const frameIds = usedProfiles.filter(p => p.type === 'frame').map(p => p.id);
                const profileConnections = connections.some(c => frameIds.includes(Number(c.profile_id)) || frameIds.includes(Number(c.connected_profile_id)));

                if (profile.type === 'extension') {
                    return profileConnections && (this.isThresholdConnectedWithExtension(profile, conf as WindowActiveConfiguration));
                } else {
                    return profileConnections;
                }
            }
        }

        return true;
    }

    isThresholdConnectedWithExtension(profile: Profile, conf: WindowActiveConfiguration | null): boolean {
        const thresholdId = this.getUsedThresholdId(conf);
        const extensionConnections = this.profileConnections.filter(
            (c) =>
                Number(c.profile_id) === Number(profile.id) ||
                Number(c.connected_profile_id) === Number(profile.id)
        );

        if (!extensionConnections.length) {
            return false;
        }

        const thresholdsExtensionsConnections = this.profileConnections.filter(
            (c) =>
                Number(c.profile_id) === thresholdId ||
                Number(c.connected_profile_id) === thresholdId
        );

        let showExtension = true;
        thresholdsExtensionsConnections.filter((connection) => {
                const extension = this.profiles.filter(p => p.type === 'extension').find(
                        (e) =>
                            Number(e.id) === Number(connection.connected_profile_id) ||
                            Number(e.id) === Number(connection.profile_id)
                )

                if (extension?.options.some(option => profile.options.some(side => option === side))) {
                    showExtension = extensionConnections.some(
                        (c) =>
                        this.profiles.find(p => thresholdId === Number(c.profile_id) && Number(p.id) === Number(c.profile_id))?.type === 'threshold' &&
                        this.profiles.find(p => Number(p.id) === Number(c.connected_profile_id)).type === 'extension' ||
                        this.profiles.find(p => Number(p.id) === Number(c.profile_id)).type === 'extension' &&
                        this.profiles.find(p => thresholdId === Number(c.connected_profile_id) && Number(p.id) === Number(c.connected_profile_id))?.type === 'threshold'
                    )
                }
            }
        );
        return showExtension;
    }

    getUsedThresholdId(conf: WindowActiveConfiguration) {
        const frameWithLowThreshold =
            conf.Frames.length && conf.Frames.find(frame => frame.lowThreshold);
        if (frameWithLowThreshold && frameWithLowThreshold.frame[0]) {
            return frameWithLowThreshold.frame[0].profileId;
        }
    }

    getProfileColorGroups(system, colors) {
        if (
            !colors
            || !colors.sash
            || ((!colors.sash.core || !colors.sash.core.groups) && system.type !== 'wood')
            || !system
            || !system.id
        ) {
            return null;
        }
        let colorGroups = [];
        let colorGroupsOut = [];
        let side = 'double';

        if (colors.sash.inner?.groups && colors.sash.outer?.isCore) {
            colorGroups = colors.sash.inner.groups.map(Number);
            side = 'single';
        } else if (colors.sash.outer?.groups && colors.sash.inner?.isCore) {
            colorGroups = colors.sash.outer.groups.map(Number);
            side = 'single';
        } else if (colors.sash.inner?.groups && colors.sash.outer?.groups && colors.sash.outer.id === colors.sash.outer.id) {
            colorGroups = colors.sash.inner.groups.map(Number);
        } else if (colors.sash.inner?.groups && colors.sash.outer?.groups && colors.sash.outer.id !== colors.sash.outer.id) {
            colorGroups = colors.sash.inner.groups.map(Number);
            colorGroupsOut = colors.sash.outer.groups.map(Number);
            side = 'bicolor';
        } else {
            return null;
        }

        return {
            colorGroups,
            colorGroupsOut,
            side,
        };
    }

    getBlockadeFrameProfiles(conf: WindowActiveConfiguration | WindowConfiguration) {
        const blockedFrameProfiles: {ids: number[]}[] = [];

        if(WindowActiveConfiguration.is(conf) && this.configuratorsDataService) {
            const blockedItemIds = conf.Dependencies.filter(
                (p) => p.type === 'blockade_to_configuration'
            ).map(block => Number(String(block.id).split('-')[0]));

            const dependencies = this.configuratorsDataService.data.dependencies || [];
            dependencies
            .filter(dependency => blockedItemIds.includes(dependency.id))
            .forEach((dependency: any) => {
                if(dependency) {
                    dependency.conditions.forEach((condition: any) => {
                        if(condition.type === 'frameProfile' || condition.type === 'thresholdProfile') {
                            blockedFrameProfiles.push({ids: condition.value.map(value => Number(value))});
                        }
                    });
                }

            })
        }

        return blockedFrameProfiles;
    }

    abstract async openProfilesShapesModal(selectedShape, shapes);

    protected loadProfiles(data, conf: WindowActiveConfiguration) {
        if (
            conf
            && ['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(conf.type) > -1
        ) {
            this.validationService.indeterminate(conf, 'loadedProfiles');
        }
        if (data.profiles && data.profiles.length > 0) {
            this.profiles = data.profiles || [];
            this.profileCategories = data.profileCategories || [];
            this.profileShapes = data.profileShapes || [];
            this.profilesPrices = data.profilesPrices || [];
            this.profilesPricesForLength = data.profilesPricesForLength || [];
            this.reinforcements = data.reinforcements || [];
            this.profileConnections = data.profilesProfiles || [];
            this.profileConnections = data.profilesProfiles || [];
            this.profilesCompatibleProfiles = data.profilesCompatibleProfiles || [];
            this.priceLevels = data.priceLevels || [];

            this.loadedData = true;
            if (
                conf
                && ['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(conf.type) > -1
            ) {
                Object.assign(conf, this.validationService.valid(conf, 'loadedProfiles'));
                this.eventBusService.post({
                    key: 'loadedProfiles',
                    value: {
                        profiles: this.profiles,
                        profileShapes: this.profileShapes,
                    },
                });
            }
        } else if (conf) {
            this.validationService.invalid(conf, 'loadedProfiles');
        }
    }

    protected getFalseMullionSashes(profiles = this.profiles) {
        const falseMullionSashes = profiles.filter(
            profile => profile.type === 'false_mullion_sash'
        );
        return falseMullionSashes;
    }

    protected isThreshold(side: string, frame: Frame) {
        return side === 'bottom' && frame.lowThreshold;
    }

    protected getFrameTypeForSide(side: string, frame: Frame, alt = false) {
        let type = 'frame' + side[0].toUpperCase() + side.substring(1);

        if (this.isThreshold(side, frame) && !alt) {
            type = 'threshold';
        }

        return type;
    }

    protected hasBottomSide(shape) {
        return shape.shape !== 'circle';
    }

    protected hasTopSide(shape) {
        return (
            ['rect', 'circle', 'arc'].indexOf(shape.shape) > -1
            || (shape.shape === 'poligon' && shape.s2 > 0)
        );
    }

    protected hasRightSide(shape) {
        return (
            ['rect', 'triangle'].indexOf(shape.shape) > -1
            || (shape.shape === 'poligon'
                && (['SLT', 'SLS', 'SLC'].includes(shape.type) || shape.h2 > 0))
            || (shape.shape === 'arc' && (shape.h1 > 0 || shape.type === 'L'))
        );
    }

    protected hasRightTopSide(shape) {
        return (
            shape.shape === 'poligon'
            && (['SRT', 'SRS', 'SRC'].includes(shape.type) || shape.h1 - shape.h2 > 0)
            && shape.s3 > 0
        );
    }

    protected hasLeftTopSide(shape) {
        return shape.shape === 'poligon' && shape.h3 > 0;
    }

    protected hasLeftSide(shape) {
        return (
            ['rect', 'triangle'].indexOf(shape.shape) > -1
            || (shape.shape === 'poligon' && shape.h1 - shape.h3 > 0 && shape.s1 > 0)
            || (shape.shape === 'arc' && (shape.h1 > 0 || shape.type === 'R'))
        );
    }

    protected isFalseMullion(mullion, conf: WindowActiveConfiguration) {
        if (mullion.multiAlignLeft.length === 1 && mullion.multiAlignRight.length === 1) {
            const falseSashTypes = ['DS', 'DSC', 'DRP'];

            const leftSash = mullion.multiAlignLeft[0];
            const rightSash = mullion.multiAlignRight[0];

            if (
                (leftSash.type
                    && falseSashTypes.indexOf(leftSash.type.type) > -1
                    && leftSash.type.handle_position === 'R')
                || (rightSash.type
                    && falseSashTypes.indexOf(rightSash.type.type) > -1
                    && rightSash.type.handle_position === 'L')
            ) {
                return true;
            }
        }

        return false;
    }
    protected isFrameOrThreshold(type: string, position: string) {
        return type === 'frame' || (type === 'threshold' && position === 'bottom');
    }

    protected sideToSimpleSide(side: 'top' | 'bottom' | 'left' | 'right') {
        if (side === 'left' || side === 'right') {
            return 'side';
        } else {
            return side;
        }
    }

    getUsedFrameProfileIds(conf: WindowActiveConfiguration): number[] {
        if (!Array.isArray(conf.Frames)) {
            return;
        }

        const frameProfileIds = [];

        conf.Frames.forEach(frame => {
            if (!Array.isArray(frame.frame)) return;

            frame.frame.forEach((frameProfile) => {
                if (frameProfile?.side && frameProfile.side !== 'bottom') {
                    frameProfileIds.push(frameProfile?.profileId)
                }
            });
        });

        return frameProfileIds;
    }

    getPriceLevel(priceLevelId: number) {
        return this.priceLevels.find(l => l.id == priceLevelId);
    }

    getProfilePriceLevelColorSides(profileId: number): any[] {
        const profile = this.getProfile(profileId);
        const priceLevel = profile && this.getPriceLevel(Number(profile.priceLevelId));
        const colorSides = priceLevel && Array.isArray(priceLevel.price_levels) && priceLevel.price_levels.map(l => l.side)
        return (colorSides && Array.isArray(colorSides) && Array.from(new Set(colorSides))) || [];
    }

    getProfileCategories() {
        return this.profileCategories;
    }

    getCasings() {
        return this.profiles.filter(p => p.type === 'casing');
    }

    getFrames() {
        return this.profiles.filter(p => p.type === 'frame');
    }

    getProfilesCompatibleProfiles() {
        return this.profilesCompatibleProfiles;
    }

    getProfilesByType(type: string) {
        return this.profiles.filter(p => p.type === type)
    }

    matchProfileToFrame(profileId: number, type: string) {
        const compatibleProfilesIds = this.getProfilesCompatibleProfiles();
        const profileRelatedToProfile = compatibleProfilesIds.filter(
            (c) => Number(c.profile_id) === profileId
        );

        const profiles = this.getProfilesByType(type);

        return profiles.filter((c) =>
            profileRelatedToProfile.some((p) => c.id === Number(p.compatible_profile_id))
        );
    }

    isProfileCompatibleWithProfile(profileId: number, compatibleProfileId: number) {
        const compatibleProfiles = this.getProfilesCompatibleProfiles().filter(
            (c) =>
                Number(c.compatible_profile_id) === profileId
        );
        return !compatibleProfiles.length || compatibleProfiles.some(
            (c) =>
                Number(c.profile_id) === compatibleProfileId
        );
    }

    isSashOnEdge(side: 'left' | 'right' | 'top' | 'bottom', sash: ActiveSash, conf: WindowActiveConfiguration) {
        if (sash.nearAlignments[side] === -1 && sash.nearMullions[side] === -1) {
            return true;
        }

        if (sash.nearAlignments[side] > -1) {
            let nearAlignment = conf.Alignments.find(el => el.id === sash.nearAlignments[side]);
            do {
                if (nearAlignment?.parallelMullions[side].length > 0) {
                    return false;
                }
                if (nearAlignment?.parallelMullions[side].length === 0 && nearAlignment?.parallelAlignments[side].length === 0) {
                    return true;
                }
                // eslint-disable-next-line no-loop-func
                nearAlignment = conf.Alignments.find(el => el.id === nearAlignment.parallelAlignments[side][0]);
            } while (nearAlignment);
        }

        return false;
    }

    getNearMullionId(sash: ActiveSash, side: string, conf: WindowActiveConfiguration) {
        if (sash.nearMullions[side] > -1) {
            return sash.nearMullions[side];
        }
        if (sash.nearAlignments[side] === -1) {
            return -1;
        }
        let nearAlignment = conf.Alignments.find(el => el.id === sash.nearAlignments[side]);
        do {
            if (nearAlignment?.parallelMullions[side].length > 0) {
                return nearAlignment.parallelMullions[side][0];
            }
            if (nearAlignment?.parallelMullions[side].length === 0 && nearAlignment?.parallelAlignments[side].length === 0) {
                return -1;
            }
            // eslint-disable-next-line no-loop-func
            nearAlignment = conf.Alignments.find(el => el.id === nearAlignment.parallelAlignments[side][0]);
        } while (nearAlignment);

        return -1;
    }
}
