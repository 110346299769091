import { Component, OnInit } from '@angular/core';
import {
    StepComponent,
    _,
    ModalService,
    InfoService,
} from '@icc/configurator/shared';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { Subscription } from 'rxjs';
import { WindowFacade } from 'libs/configurator/window/src/lib/+state/window.facade';
import { map } from 'rxjs/operators';
import { ModelService } from '@icc/legacy/configurator/steps/door/models/model.service';
import { ConfigurationsService, ProfilesService, TranslateService } from '@icc/common';
import { FillingsService } from '@icc/legacy/configurator/steps/window/glazings/fillings.service';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { ModelInfoComponent } from '../model-info/model-info.component';
import { FillingsFacade } from 'libs/configurator/window/src/lib/glazings/fillings.facade';
import { DoorPortalsService } from 'libs/configurator/window/src/lib/door-portals/door-portals.service';
import { ProfilesModalService } from '@icc/configurator/window';

@Component({
    selector: 'icc-model',
    templateUrl: './model.component.html',
    styleUrls: ['./model.component.scss'],
})
export class ModelComponent extends StepComponent implements OnInit {
    static stepName = _('STEPS|Model');
    static stepIcon = {
        ligature: 'local_movies',
    };
    public configurator = 'door';
    public stepId = 'model';
    public title = _('DOOR|Model');

    sashActiveModels;
    sashActiveModels$ = this.modelService.sashActiveModels$.pipe(map(models => models.map<iccListItem>(model => ({
        id: String(model.id),
        title: model.name,
        imageUrl: '/files/filling/' + model.image,
        tabs: [model.fillings_category_id],
        showInfo: true,
    }))));
    modelCategories$ = this.modelService.modelsGroups$.pipe(map(modelGroups => modelGroups.map<iccListTab>(group => ({
        id: Number(group.id),
        name: group.name,
    }))));
    currentModelGroup = null;
    selectedFilling$ = this.windowFacade.selectedModel$.pipe(
        map((filling) => filling && String(filling.id))
    );
    selectedModelForSashActive$ = this.windowFacade.selectedModelForSashActive$.pipe(
        map((filling) => filling && String(filling.id))
    )
    mode: 'list' | 'all' = 'list';
    conf: DoorActiveConfiguration;
    private subscriptions: Subscription[] = [];
    doorActiveModelBox: ReturnType<FillingsFacade['modelBox']>;
    doorActiveInnerModelBox: ReturnType<FillingsFacade['modelBox']>;
    doorPassiveModelBox: ReturnType<FillingsFacade['modelBox']>;
    doorPassiveInnerModelBox: ReturnType<FillingsFacade['modelBox']>;

    constructor(
        private windowFacade: WindowFacade,
        private modelService: ModelService,
        private fillingsService: FillingsService,
        private configurationsService: ConfigurationsService<'door'>,
        private modalService: ModalService,
        public fillingsFacade: FillingsFacade,
        private doorPortalsService: DoorPortalsService,
        private infoService: InfoService,
        private translateService: TranslateService,
        private profilesModalService: ProfilesModalService,
        private profilesService: ProfilesService
    ) {
        super();
    }

    ngOnInit() {
        this.conf = this.configurationsService.conf.Current;
        this.subscriptions.push(
            this.fillingsFacade.doorActiveModelBox$.subscribe(box => {
                this.doorActiveModelBox = box;
            }),
            this.fillingsFacade.doorActiveInnerModelBox$.subscribe(box => {
                this.doorActiveInnerModelBox = box;
            }),
            this.fillingsFacade.doorPassiveModelBox$.subscribe(box => {
                this.doorPassiveModelBox = box;
            }),
            this.fillingsFacade.doorPassiveInnerModelBox$.subscribe(box => {
                this.doorPassiveInnerModelBox = box;
            }),
            this.windowFacade.modelHasBeenChosen$.subscribe(hasBeenChosen => {
                if (hasBeenChosen) {
                    this.mode = 'all';
                }
            }),
            this.modelService.sashActiveModels$.subscribe(m => {
                this.sashActiveModels = m;
            })
        );
    }

    changeOuterModel(field: string | undefined | ActiveSash) {
        if (DoorActiveConfiguration.is(this.configurationsService.conf.Current)) {
            this.fillingsService.openModelModal(field, this.configurationsService.conf.Current, this.configurationsService.conf.Default);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Wybieranie modelu
     * @param  {Object} model Model
     */
    selectModel(modelItem: iccListItem) {
        const model = this.sashActiveModels.find(m => String(m.id) === String(modelItem.id));
        if (model) {
            this.modelService.selectModel(model, 'doorActive').then(() => {
                if (this.doorActiveInnerModelBox.show || this.doorPassiveModelBox.show) {
                    this.mode = 'all';
                }
            });
        }
    }

    openModalDecoPanelOptions(field: string | undefined | ActiveSash) {
        if (DoorActiveConfiguration.is(this.configurationsService.conf.Current)) {
            this.fillingsService.openModalPanelOptions(
                field,
                this.configurationsService.conf.Current
            );
        }
    }

    changePanelGlazing(field: string | undefined | ActiveSash) {
        if (DoorActiveConfiguration.is(this.configurationsService.conf.Current)) {
            this.fillingsService.changePanelGlazing(
                field,
                this.configurationsService.conf.Current
            );
        }
    }

    showInfo(item: iccListItem) {
        const model = this.modelService.getModels().find(m => String(m.id) === String(item.id));
        const modalService = this.modalService
            .open({
                pageComponent: ModelInfoComponent,
                resolve: {
                    filling: () => model,
                    button: () => true,
                },
            })
            .result.then((result: boolean) => {
                if (result) {
                    this.selectModel(item);
                }
            });
    }

    isActiveModelButton1Disabled(conf = this.configurationsService.conf.Current) {
        const fillings = this.fillingsService.getMatchingFillingsForDoorSashes(conf, "doorActive", true).filter(
            f => f.type === (conf.System.door_type ? 'door_panels' : 'deco_panels')
        );
        return fillings?.length <= 1;
    }

    isDoorActiveInnerButton1Disabled(conf = this.configurationsService.conf.Current) {
        const innerPanels = this.fillingsService.getPanelsBasedOnPanelBaseSide(conf, true);

        return innerPanels?.length <= 1;
    }

    isDoorPassiveInnerButton1Disabled(conf = this.configurationsService.conf.Current) {
        const innerPassivePanels = this.fillingsService.getPassiveInnerPanelsBasedOnPanelBaseSide(conf, true);

        return innerPassivePanels?.length <= 1;
    }

    isActiveModelButton2Disabled(field = 'doorActive', conf = this.configurationsService.conf.Current) {
        const fillingsData = this.fillingsService.getPanelGlazingsByFieldType(field, conf, true);
        const panelGlazings = fillingsData?.panelGlazings;
        return !this.doorActiveModelBox?.showOptions || panelGlazings?.length <= 1;
    }
}
