import { ActiveConfiguration } from './ActiveConfiguration';
import { MosquitoConfiguration } from './MosquitoConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { MosquitoNetType } from '../data-types/Mosquito';
import { IccConstructColor, IccAccessory } from '../data-types';
import { ActiveAccessory } from './parts/common';

export class MosquitoActiveConfiguration implements ActiveConfiguration {

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (MosquitoActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (MosquitoConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.ListPrice = configuration.listPrice;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.ListPriceNoMargin = configuration.listPriceNoMargin;
                    this.PriceAfterDiscountsInCurrency = configuration.priceAfterDiscountsInCurrency;
                    this.ListPriceAfterDiscountsInCurrency = configuration.listPriceAfterDiscountsInCurrency;
                    this.PriceGross = configuration.priceGross;
                    this.ListPriceGross = configuration.listPriceGross;
                    this.PriceGrossAfterDiscounts = configuration.priceGrossAfterDiscounts;
                    this.ListPriceGrossAfterDiscounts = configuration.listPriceGrossAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.ListPriceSegments = configuration.listPriceSegments;
                    this.ListPriceSegmentsNoMargin = configuration.listPriceSegmentsNoMargin;
                    this.ListPriceSegmentsBeforePromotions = configuration.listPriceSegmentsBeforePromotions;
                    this.ListPriceParts = configuration.listPriceParts;
                    this.ListPricePartsNoMargin = configuration.listPricePartsNoMargin;
                    this.ListPricePartsBeforePromotions = configuration.listPricePartsBeforePromotions;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.Description = configuration.description;
                    this.valid = configuration.valid;
                    this.attributes = configuration.attributes;

                    this.System = configuratorsDataService.get(
                        'mosquitoLines',
                        configuration.system.id
                    );
                    this.Height = configuration.height;
                    this.Width = configuration.width;
                    this.HeightModify = configuration.heightModify;
                    this.WidthModify = configuration.widthModify;
                    this.Shape = {
                        width: configuration.width,
                        height: configuration.height,
                        circuit: configuration.circuit,
                        sashWidth: configuration.sashWidth,
                        sashHeight: configuration.sashHeight
                    };

                    if (configuration.variant) {
                        this.Type = configuratorsDataService.get(
                            'mosquitoTypes',
                            String(configuration.system.id),
                            configuration.variant.id
                        );
                        if (configuration.variant.hasDirection) {
                            this.Direction = configuration.direction === 'L' ? 'left' : 'right';
                        }
                    }
                    if (configuration.netType) {
                        this.NetType = configuratorsDataService.get(
                            'mosquitoNetTypes',
                            configuration.netType.id
                        );
                    }
                    if (configuration.colors.frame && configuration.colors.frame.name) {
                        this.Colors.frame = this.configuratorsDataService.get(
                            'windowColorsAll',
                            configuration.colors.frame.id
                        );
                    }
                    if (configuration.colors.net && configuration.colors.net.name) {
                        this.Colors.net = {
                            code: configuration.colors.net.code,
                            name: configuration.colors.net.name,
                            color: configuration.colors.net.code === 'black' ? '000000' : 'A9A9A9',
                        };
                    }
                    if (configuration.colors.panel && configuration.colors.panel.name) {
                        this.Colors.panel = {
                            code: configuration.colors.panel.code,
                            name: configuration.colors.panel.name,
                            color:
                                configuration.colors.panel.code === 'white' ? 'FFFFFF' : '654321',
                        };
                    }

                    if (configuration.montage) {
                        this.Montage = configuratorsDataService.get(
                            'mosquitoMontages',
                            configuration.montage.id
                        );
                    }
                    if (configuration.windowLine) {
                        this.WindowLine = configuratorsDataService.get(
                            'mosquitoWindowLines',
                            String(configuration.system.id),
                            configuration.windowLine.id
                        );
                    }
                    ['up', 'down', 'left', 'right'].forEach(side => {
                        if (configuration.profilePositions[side]) {
                            this.ProfilePositions[side] = configuration.profilePositions[side];
                        }
                    });
                    this.HoldersWidth = configuration.holdersWidth;
                    this.MuntinType = configuration.muntinPosition.type;
                    if (configuration.muntinPosition.type === 'custom') {
                        this.MuntinPosition = configuration.muntinPosition.position;
                    }
                    this.hasThickSeal = configuration.hasThickSeal;
                    this.sealThickness = configuration.sealThickness;

                    this.Accessories = (configuration.accessories ?? [])
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(accessory, null, configuratorsDataService)
                        );
                }
            }
        }
        this.configuratorsDataService = null;
    }
    type: 'mosquito' = 'mosquito';
    Name = '';
    Price = 0;
    ListPrice = 0;
    PriceNoMargin = 0;
    ListPriceNoMargin = 0;
    PriceAfterDiscountsInCurrency = 0;
    ListPriceAfterDiscountsInCurrency = 0;
    PriceBeforePromotions = 0;
    ListPriceBeforePromotions = 0;
    PriceGross = 0;
    ListPriceGross = 0;
    PriceGrossAfterDiscounts = 0;
    ListPriceGrossAfterDiscounts = 0;
    PriceGrossBeforePromotions = 0;
    ListPriceGrossBeforePromotions = 0;
    Quantity = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    ListPriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    ListPriceSegmentsNoMargin: PriceSegment[] = [];
    PriceSegmentsBeforePromotions: PriceSegment[] = [];
    ListPriceSegmentsBeforePromotions: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    ListPriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    ListPricePartsNoMargin: PricePart[] = [];
    PricePartsBeforePromotions: PricePart[] = [];
    ListPricePartsBeforePromotions: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    Discount = 0;
    ListDiscount = 0;
    Title = '';
    Description = '';
    Attachments = [];
    valid: {} = {};
    PriceValid = false;
    drawData: any;

    System: any = {};
    Height = 1300;
    Width = 1300;
    HeightModify = 0;
    WidthModify = 0;
    Shape: any = {};
    Type: any = {};
    NetType: MosquitoNetType | null = null;
    Colors: {
        frame?: IccConstructColor,
        net?: {
            code: string;
            name: string;
            color: string;
        },
        panel?: {
            code: string;
            name: string;
            color: string;
        },
    } = {};
    Direction: any = null;
    Montage: any = null;
    WindowLine: any = null;
    ProfilePositions: any = {};
    HoldersWidth: any = null;
    MuntinPosition: any = null;
    MuntinType: any = null;
    hasThickSeal = false;
    sealThickness: number | null = null;
    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];
    Accessories: IccAccessory[] = [];

    public static is(configuration): configuration is MosquitoActiveConfiguration {
        return configuration instanceof MosquitoActiveConfiguration || !configuration.$version;
    }
}

export type IMosquitoActiveConfiguration = Pick<
    MosquitoActiveConfiguration,
    keyof MosquitoActiveConfiguration
>;

