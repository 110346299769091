import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ICC_PAGE_DATA, SharedFacade, StepComponent, _ } from '@icc/configurator/shared';

@Component({
    selector: 'icc-gray-polystyrene-info',
    templateUrl: './gray-polystyrene-info.component.html',
    styleUrls: ['./gray-polystyrene-info.component.scss'],
})
export class GrayPolystyreneInfoComponent extends StepComponent implements OnInit {
    public configurator = 'roller_shutter';
    public stepId: string;
    public title = _('ROLLERSHUTTER|Szary styropian');
    info;

    constructor(
        private sharedFacade: SharedFacade,
        private sanitizer: DomSanitizer,
        @Inject(ICC_PAGE_DATA) public pageData: {
            data: any,
        }
    ) {
        super();
    }

    ngOnInit(): void {
        this.info = this.sanitizer.bypassSecurityTrustHtml(this.pageData.data);
    }

    close() {
        this.sharedFacade.closePage(true);
    }
}
