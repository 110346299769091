/* eslint-disable max-statements */
import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectionStrategy,
    Inject,
    ChangeDetectorRef,
    AfterViewInit,
    NgZone
} from '@angular/core';
import {
    PageComponent,
    _,
    ICC_PAGE_DATA,
    SharedFacade,
    ModalService,
} from '@icc/configurator/shared';
import { WindowFacade } from '../+state/window.facade';
import { Observable, Subject, merge, BehaviorSubject, Subscription } from 'rxjs';
import { iccListItem, iccListTab, IccSliderStep } from '@icc/configurator/ui';
import { map, startWith, distinctUntilChanged, tap } from 'rxjs/operators';
import {
    APP_CONFIG,
    TranslateService,
    AppConfigFactory,
    core,
    ConfiguratorsDataService,
    ConfigurationsService,
} from '@icc/common';
import { IccFilling, IccGlassTypeVariant, IccGlassType, IccWarmEdge } from '@icc/common/data-types';
import { FormBuilder } from '@angular/forms';
import { GlassTypeVariantsPageComponent } from '../glass-type-variants-page/glass-type-variants-page.component';
import { FillingsFiltersPageComponent } from '../fillings-filters-page/fillings-filters-page.component';
import { GlassRwFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-rw.filter';
import { GlassSecurityFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-security.filter';
import { GlassUgFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-ug.filter';
import { GlassOrnamentFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-ornament.filter';
import { GlassTypeFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-type.filter';
import { GlassCountFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-count.filter';
import { GlassOftenFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-often.filter';
import { Glass, InterPaneSpace, GlazingUnitElement, FillingsProducer } from '@icc/window';
import { GlazingUnitPageComponent } from '../glazing-unit-page/glazing-unit-page.component';
import { GlazingUnitsService } from '@icc/legacy/configurator/steps/window/glazings/glazing-units.service';
import { FillingOptionsPageComponent } from '../filling-options-page/filling-options-page.component';
import { FillingsListPageStoreService } from './fillings-list-page-store.service';
import { GlassTypesPageComponent } from '../glass-types-page/glass-types-page.component';

interface ThermalTransmittanceLevel extends IccSliderStep {
    id: number;
    label: string;
    labelAlt: string;
    description: string;
    minValue: number | null;
    maxValue: number | null;
}

interface GlassCountLevel extends IccSliderStep {
    id: number;
    label: string;
    labelAlt: string;
    description: string;
    value: number | null;
}

interface SecurityLevel extends IccSliderStep {
    id: number;
    label: string;
    labelAlt: string;
    description: string;
    levels: (string | null)[];
}

interface GeneralSecurityLevel extends IccSliderStep {
    id: number;
    label: string;
    description: string;
    levels: (string | null)[];
}

interface NoiseProtectionLevel extends IccSliderStep {
    id: number;
    label: string;
    labelAlt: string;
    description: string;
    minValue: number | null;
    maxValue: number | null;
}

type FiltersKeys = 'thermalTransmittance' | 'glassCount' | 'securityLevelOutside' | 'securityLevelInside' | 'noiseProtection' | 'glassType' | 'glassTypeVariant' | 'generalSecurityLevel';

@Component({
    selector: 'icc-fillings-list-page',
    templateUrl: './fillings-list-page.component.html',
    styleUrls: ['./fillings-list-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FillingsListPageComponent extends PageComponent implements OnInit, OnDestroy, AfterViewInit {
    public title = _('STEPS|Wypełnienie');
    isB2BGlazingViewDefault = this.config().IccConfig.Configurators.window.isB2BGlazingViewDefault && this.config().preset==='b2b';
    public options = [
        {
            title: _('WINDOW|Lista'),
            callback: () => {
                this.mode = 'list';
                this.cdr.markForCheck();
            },
            icon: {
                ligature: 'view_list',
            },
            show: () => this.selectedTab === 'glazing' && this.mode !== 'list',
        },
        {
            title:  _('WINDOW|Dostosowanie pakietów szybowych'),
            callback: () => {
                this.mode = 'filters';
                this.cdr.markForCheck();
            },
            icon: {
                ligature: 'linear_scale',
            },
            show: () =>
                this.selectedTab === 'glazing'
                && this.mode === 'list'
                && !this.pageData.complementary,
        },
        {
            title: _('WINDOW|Filtry'),
            callback: () => {
                this.openClassicFilters();
            },
            icon: {
                ligature: 'tune',
            },
            show: () => this.mode === 'list',
        },
    ];
    isSummaryHidden = true;
    isStepperHidden = true;
    public isSliderDisabled = false;

    mode: 'filters' | 'list' = 'filters';
    fillings: iccListItem[] = [];
    mainTabs: iccListTab[] = [
        {
            id: 'glazing',
            name: this.translateService.instant('WINDOW|Pakiety szybowe'),
        },
        {
            id: 'pvc_panels',
            name: this.translateService.instant('WINDOW|Panele wypełnieniowe'),
        },
        {
            id: 'deco_panels',
            name: this.translateService.instant('WINDOW|Panele ozdobne'),
        },
    ];
    initTab = 0;
    selectedTab = 'glazing';

    classicFilters: any = null;

    thermalTransmittanceLevels: ThermalTransmittanceLevel[] = this.config().IccConfig.Configurators.fillingsSelect.thermalTransmittanceSlider ? [
        {
            id: 0,
            label: this.translateService.instant('WINDOW|≥0,9 W/(m2·K)'),
            labelAlt: this.translateService.instant('WINDOW|≥0,9 W/(m2·K)'),
            description: this.translateService.instant(
                'WINDOW|Pakiety dwuszybowe. Rozwiązanie ekonomiczne w zakupie. Ze względu na parametry termiczne polecane do budynków tradycyjnych o niższych parametrach izolacji lub wykorzystywanych sezonowo.'
            ),
            minValue: 0.9,
            maxValue: null,
        },
        {
            id: 1,
            label: this.translateService.instant('WINDOW|0,6-0,8 W/(m2·K)'),
            labelAlt: this.translateService.instant('WINDOW|0,6-0,8 W/(m2·K)'),
            description: this.translateService.instant(
                'WINDOW|Pakiety trzyszybowe. Poziom izolacji cieplnej odpowiedni dla budynków termooszczędnych.'
            ),
            minValue: 0.61,
            maxValue: 0.89,
        },
        {
            id: 2,
            label: this.translateService.instant('WINDOW|≤0,6 W/(m2·K)'),
            labelAlt: this.translateService.instant('WINDOW|≤0,6 W/(m2·K)'),
            description: this.translateService.instant(
                'WINDOW|Pakiety trzyszybowe, poziom izolacji cieplnej odpowiedni dla tzw. budynków pasywnych. Bezkompromisowa oszczędność wydatków na ogrzewanie.'
            ),
            minValue: null,
            maxValue: 0.6,
        },
    ] : [
        {
            id: 0,
            label: '',
            labelAlt: '',
            description: '',
            minValue: null,
            maxValue: null,
        },
    ];

    glassCountLevels: GlassCountLevel[] = [
        {
            id: 0,
            label: this.translateService.instant('WINDOW|2 szyby'),
            labelAlt: this.translateService.instant('WINDOW|2 szyby'),
            description: this.translateService.instant(
                'WINDOW|Pakiety dwuszybowe. Rozwiązanie ekonomiczne w zakupie. Ze względu na parametry termiczne polecane do budynków tradycyjnych o niższych parametrach izolacji lub wykorzystywanych sezonowo.'
            ),
            value: 2,
        },
        {
            id: 1,
            label: this.translateService.instant('WINDOW|3 szyby'),
            labelAlt: this.translateService.instant('WINDOW|3 szyby'),
            description: this.translateService.instant(
                'WINDOW|Pakiety trzyszybowe. Poziom izolacji cieplnej odpowiedni dla budynków termooszczędnych.'
            ),
            value: 3
        },
    ];

    securityLevels: SecurityLevel[] = [
        {
            id: 0,
            label: this.translateService.instant('WINDOW|STD'),
            labelAlt: this.translateService.instant('WINDOW|Standardowe'),
            description: this.translateService.instant(
                'WINDOW|Szyba standardowa, rozwiązanie ekonomiczne w zakupie, nie posiada żadnych dodatkowych zabezpieczeń. W przypadku uszkodzenia może grozić poważnym zranieniem.'
            ),
            levels: [null],
        },
        {
            id: 1,
            label: this.translateService.instant('WINDOW|ESG'),
            labelAlt: this.translateService.instant('WINDOW|ESG, Hartowana'),
            description: this.translateService.instant(
                'WINDOW|Szyba bezpieczna hartowana, jest kilkukrotnie odporniejsza na rozbicie od zwykłej szyby, ponadto w przypadku uszkodzenia rozpada się na drobne kawałki, co zmniejsza ryzyko poważnych zranień.'
            ),
            levels: ['esg'],
        },
        {
            id: 2,
            label: this.translateService.instant('WINDOW|O1/2'),
            labelAlt: this.translateService.instant('WINDOW|O1/2, Laminowana'),
            description: this.translateService.instant(
                'WINDOW|Szyba bezpieczna laminowana, jest kilkukrotnie odporniejsza na rozbicie od zwykłej szyby, ponadto w przypadku uszkodzenia szkło pozostaje na miejscu utrzymane na folii, co zmniejsza ryzyko jakichkolwiek zranień.'
            ),
            levels: ['o1', 'o2'],
        },
        {
            id: 3,
            label: this.translateService.instant('WINDOW|P1/2'),
            labelAlt: this.translateService.instant('WINDOW|P1/2, Antywłamaniowa'),
            description: this.translateService.instant(
                'WINDOW|Szyba antywłamaniowa P1 lub P2, stanowi podstawowe zabezpieczenie przed włamaniem podjętym bez przygotowania'
            ),
            levels: ['p1a', 'p2a'],
        },
        {
            id: 4,
            label: this.translateService.instant('WINDOW|≥P3'),
            labelAlt: this.translateService.instant('WINDOW|P3 i mocniejsze, Antywłamaniowa'),
            description: this.translateService.instant(
                'WINDOW|Szyba antywłamaniowa klasy P3 lub wyższej, szyby utrudniające włamanie. Klasa P3 ma porównywalną skuteczność do kraty o oczku 15 cm z drutu o średnicy 1cm. Jeśli potrzebujesz pakietów szybowych o wyższych właściwościach (w tym Kuloodporne, odporne na wybuchy, itp.) – skontaktuj się z nami aby otrzymać indywidualną ofertę.'
            ),
            levels: ['p3a', 'p4a', 'p5a', 'p6b', 'p7b', 'p8b'],
        },
    ];

    noiseProtectionLevels: NoiseProtectionLevel[] = [
        {
            id: 0,
            label: this.translateService.instant('WINDOW|NPD'),
            labelAlt: this.translateService.instant('WINDOW|Brak danych'),
            description: this.translateService.instant(
                'WINDOW|Nie podano danych o poziomie izolacji akustycznej.'
            ),
            minValue: null,
            maxValue: null,
        },
        {
            id: 1,
            label: this.translateService.instant('WINDOW|≤31 dB'),
            labelAlt: this.translateService.instant('WINDOW|≤31 dB'),
            description: this.translateService.instant(
                'WINDOW|Szklenie standardowe, odpowiednie dla budynków znajdujących się w cichej okolicy, oddalonej od ruchliwych ulic.'
            ),
            minValue: 0,
            maxValue: 31,
        },
        {
            id: 2,
            label: this.translateService.instant('WINDOW|32-36 dB'),
            labelAlt: this.translateService.instant('WINDOW|32-36 dB'),
            description: this.translateService.instant(
                'WINDOW|Szklenie o umiarkowanych właściwościach dźwiękochłonnych, odpowiednie dla budynków znajdujących się na osiedlach z umiarkowanym ruchem ulicznym, oddalonych od źródeł intensywniejszego hałasu.'
            ),
            minValue: 32,
            maxValue: 36,
        },
        {
            id: 3,
            label: this.translateService.instant('WINDOW|37-42 dB'),
            labelAlt: this.translateService.instant('WINDOW|37-42 dB'),
            description: this.translateService.instant(
                'WINDOW|Szklenie o podwyższonych właściwościach dźwiękochłonnych, odpowiednie dla budynków znajdujących się w pobliżu ruchliwych ulic, klubów nocnych itp.'
            ),
            minValue: 37,
            maxValue: 42,
        },
        {
            id: 4,
            label: this.translateService.instant('WINDOW|≥43 dB'),
            labelAlt: this.translateService.instant('WINDOW|≥43 dB'),
            description: this.translateService.instant(
                'WINDOW|Szklenie o wyraźnych właściwościach dźwiękochłonnych, odpowiednie dla budynków znajdujących się w pobliżu dróg szybkiego ruchu, torów kolejowych i portów lotniczych.'
            ),
            minValue: 43,
            maxValue: null,
        },
    ];

    generalSecurityLevels: GeneralSecurityLevel[] = [
        {
            id: 0,
            label: 'STD',
            description: this.translateService.instant(
                'WINDOW|Szyba standardowa, rozwiązanie ekonomiczne w zakupie, nie posiada żadnych dodatkowych zabezpieczeń. W przypadku uszkodzenia może grozić poważnym zranieniem.'
            ),
            levels: [null],
        },
        {
            id: 1,
            label: this.translateService.instant('WINDOW|bezpieczna'),
            description: this.translateService.instant(
                'WINDOW|Szyba bezpieczna hartowana, jest kilkukrotnie odporniejsza na rozbicie od zwykłej szyby, ponadto w przypadku uszkodzenia rozpada się na drobne kawałki, co zmniejsza ryzyko poważnych zranień.'
            ),
            levels: ['esg', 'o1', 'o2', 'p1a'],
        },
        {
            id: 2,
            label: this.translateService.instant('WINDOW|antywłamaniowa P2'),
            description: this.translateService.instant(
                'WINDOW|Szyba antywłamaniowa P2, stanowi podstawowe zabezpieczenie przed włamaniem podjętym bez przygotowania'
            ),
            levels: ['p2a'],
        },
        {
            id: 3,
            label: this.translateService.instant('WINDOW|antywłamaniowa P3'),
            description: this.translateService.instant(
                'WINDOW|Szyba antywłamaniowa klasy P3, szyby utrudniające włamanie. Klasa P3 ma porównywalną skuteczność do kraty o oczku 15 cm z drutu o średnicy 1cm.'
            ),
            levels: ['p3a'],
        },
        {
            id: 4,
            label: this.translateService.instant('WINDOW|antywłamaniowa P4'),
            description: this.translateService.instant(
                'WINDOW|Szyba antywłamaniowa klasy P4, szyby utrudniające włamanie. Klasa P4 ma porównywalną skuteczność do kraty o oczku 15 cm z drutu o średnicy 1cm.'
            ),
            levels: ['p4a'],
        },
        {
            id: 5,
            label: this.translateService.instant('WINDOW|antywłamaniowa P5'),
            description: this.translateService.instant(
                'WINDOW|Zwiększona odporność na włamanie – szyby zastępują kraty z prętów stalowych o średnicy 1,2 cm.'
            ),
            levels: ['p5a', 'p6b', 'p7b', 'p8b']
        }
    ];

    thermalTransmittanceDescription$ = new Observable<string>();
    glassCountDescription$ = new Observable<string>();
    securityLevelOutsideDescription$ = new Observable<string>();
    securityLevelInsideDescription$ = new Observable<string>();
    noiseProtectionDescription$ = new Observable<string>();
    generalSecurityDescription$ = new Observable<string>();

    thermalTransmittanceLabelAlt$ = new Observable<string>();
    glassCountLabelAlt$ = new Observable<string>();
    securityLevelOutsideLabelAlt$ = new Observable<string>();
    securityLevelInsideLabelAlt$ = new Observable<string>();
    noiseProtectionLabelAlt$ = new Observable<string>();
    generalSecurityLabelAlt$ = new Observable<string>();

    fillingFilters = this.fb.group({
        thermalTransmittance: [this.thermalTransmittanceLevels[0]],
        glassCount: [this.glassCountLevels[0]],
        securityLevelOutside: [this.securityLevels[0]],
        securityLevelInside: [this.securityLevels[0]],
        noiseProtection: [this.noiseProtectionLevels[0]],
        glassType: ['0'],
        glassTypeVariant: ['0'],
        generalSecurityLevel: [this.generalSecurityLevels[0]],
    });

    selectedFilling?: IccFilling;
    matchedFilling?: IccFilling;
    selectedGlassType: IccGlassType | null = null;
    selectedGlassTypeVariant: IccGlassTypeVariant | null = null;

    glassTypes: IccGlassType[] = [];
    glassTypeVariants: IccGlassTypeVariant[] = [];
    filtersMap: Record<string, string[]> = {};
    filtersLevels: {
        thermalTransmittance: ThermalTransmittanceLevel[];
        glassCount: GlassCountLevel[];
        securityLevelOutside: SecurityLevel[];
        securityLevelInside: SecurityLevel[];
        noiseProtection: NoiseProtectionLevel[];
        glassType: IccGlassType[];
        glassTypeVariant: IccGlassTypeVariant[];
        generalSecurityLevel: GeneralSecurityLevel[];
    } = {
        thermalTransmittance: [],
        glassCount: [],
        securityLevelOutside: [],
        securityLevelInside: [],
        noiseProtection: [],
        glassType: [],
        glassTypeVariant: [],
        generalSecurityLevel: [],
    };
    filtersLevelsBeforeMatch: {
        thermalTransmittance: ThermalTransmittanceLevel[];
        glassCount: GlassCountLevel[];
        securityLevelOutside: SecurityLevel[];
        securityLevelInside: SecurityLevel[];
        noiseProtection: NoiseProtectionLevel[];
        glassType: IccGlassType[];
        glassTypeVariant: IccGlassTypeVariant[];
        generalSecurityLevel: GeneralSecurityLevel[];
    } = {
        thermalTransmittance: [],
        glassCount: [],
        securityLevelOutside: [],
        securityLevelInside: [],
        noiseProtection: [],
        glassType: [],
        glassTypeVariant: [],
        generalSecurityLevel: [],
    };

    sliderValue!: number;
    availableGlazings: iccListItem[] = [];

    private filters = {
        thermalTransmittance: (thermalTransmittance: ThermalTransmittanceLevel) => (
            filling: IccFilling
        ) =>
            (thermalTransmittance.minValue === null || filling.u >= thermalTransmittance.minValue)
            && (thermalTransmittance.maxValue === null
                || thermalTransmittance.maxValue >= filling.u),
        glassCount: (glassCount: GlassCountLevel) => (
            filling: IccFilling
        ) => glassCount.value === Number(filling.glazing_count),
        securityLevelOutside: (securityLevelOutside: SecurityLevel) => (filling: IccFilling) =>
            securityLevelOutside.levels.includes(filling.security_level_outer || null),
        securityLevelInside: (securityLevelInside: SecurityLevel) => (filling: IccFilling) =>
            securityLevelInside.levels.includes(filling.security_level_inner || null),
        noiseProtection: (noiseProtection: NoiseProtectionLevel) => (filling: IccFilling) =>
            filling.rw === null && noiseProtection.minValue === null && noiseProtection.maxValue === null
            || noiseProtection.minValue !== null && Number(filling.rw) > 0 && (Number(filling.rw) >= noiseProtection.minValue)
                && (noiseProtection.maxValue === null || Number(filling.rw) <= noiseProtection.maxValue),
        glassType: (glassType: string) => (filling: IccFilling) =>
            ((!glassType || Number(glassType) === 0)
                && (!filling.glass_types || filling.glass_types.length === 0))
            || (glassType
                && filling.glass_types
                && filling.glass_types.some(gt => gt.glass_type_id === glassType)),
        glassTypeVariant: (glassType: string, glassTypeVariant: number) => (filling: IccFilling) =>
            ((!glassTypeVariant || Number(glassTypeVariant) === 0)
                && (!filling.glass_types || filling.glass_types.length === 0))
            || (glassTypeVariant
                && filling.glass_types
                && filling.glass_types.some(
                    gt =>
                        Number(gt.glass_type_id) === Number(glassType)
                        && Number(gt.glass_type_variant_id) === Number(glassTypeVariant)
                )),
        generalSecurityLevel: (generalSecurityLevel: GeneralSecurityLevel) => (filling: IccFilling) =>
            generalSecurityLevel.levels.includes(filling.security_level_outer || null),
    };


    readonly fieldIndexes: Record<FiltersKeys, number> = {
        thermalTransmittance: 0,
        glassCount: 1,
        securityLevelOutside: 2,
        securityLevelInside: 3,
        noiseProtection: 4,
        generalSecurityLevel: 5,
        glassType: 6,
        glassTypeVariant: 7,
    } as const;

    private setAll = 0;
    private changedGlassType = false;
    private changedGlassTypeVariant = false;

    private subscriptions: Subscription[] = [];

    editGlazingUnit = this.config().IccConfig.Configurators.editGlazingUnit;
    hasGlazingUnit = true;

    constructor(
        private sharedFacade: SharedFacade,
        private translateService: TranslateService,
        private windowFacade: WindowFacade,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            complementary: boolean;
            fillings: IccFilling[];
            selGlassType: IccFilling;
            glassTypes: IccGlassType[];
            glassTypeVariants: IccGlassTypeVariant[];
            modalData: {
                glassTab: 'glazing' | 'pvc_panels' | 'deco_panels';
                selectedSecurity: any;
                selectedOrnament: any;
                selectedUg: any;
                selectedRw: any;
                selectedCategory: any;
            };
            filters$: BehaviorSubject<{
                selectedFilling: string | null;
                filters: {
                    thermalTransmittance: ThermalTransmittanceLevel;
                    glassCount: GlassCountLevel;
                    securityLevelOutside: SecurityLevel;
                    securityLevelInside: SecurityLevel;
                    noiseProtection: NoiseProtectionLevel;
                    glassType: string;
                    glassTypeVariant: number;
                    generalSecurityLevel: GeneralSecurityLevel;
                };
                classicFilters: {
                    glassTab: 'glazing' | 'pvc_panels' | 'deco_panels';
                    selectedSecurity: any;
                    selectedOrnament: any;
                    selectedUg: any;
                    selectedRw: any;
                    selectedCategory: any;
                };
            }>;
            addGlazingUnit: any;
            onlyGlazingList: boolean;
            warmEdges: IccWarmEdge[];
        },
        private fb: FormBuilder,
        private modalService: ModalService,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        private cdr: ChangeDetectorRef,
        private fillingsListPageStoreService: FillingsListPageStoreService,
        private configurationsService: ConfigurationsService<'door'>,
        private ngZone: NgZone,
    ) {
        super();
    }

    ngOnInit() {
        this.mainTabs = this.mainTabs.filter(
            tab =>
                (this.pageData.onlyGlazingList && tab.id === 'glazing')
                || (!this.pageData.onlyGlazingList
                    && this.pageData.fillings.some(f => f.type === tab.id))
        );
        this.initializeTabs();
        this.selectedTab = this.mainTabs.find(o => o.id === this.pageData.selGlassType?.type)?.id as string || 'glazing';
        if (this.pageData.complementary || this.pageData.onlyGlazingList || !this.mainTabs.some(tab => tab.id === 'glazing') || (this.selectedTab !== 'glazing' && this.mode !== 'list') || this.isB2BGlazingViewDefault) {
            this.mode = 'list';
        }
        const thermalTransmittanceControl = this.fillingFilters.get('thermalTransmittance');
        const glassCountControl = this.fillingFilters.get('glassCount');
        const securityLevelOutsideControl = this.fillingFilters.get('securityLevelOutside');
        const securityLevelInsideControl = this.fillingFilters.get('securityLevelInside');
        const noiseProtectionControl = this.fillingFilters.get('noiseProtection');
        const securityLevelControl = this.fillingFilters.get('generalSecurityLevel');
        const glassTypeControl = this.fillingFilters.get('glassType');
        const glassTypeVariantControl = this.fillingFilters.get('glassTypeVariant');
        this.glassTypes = this.getGlassTypes();
        this.glassTypeVariants = this.pageData.glassTypeVariants || [];
        this.loadFillings();
        let oldKey = '';
        if (
            thermalTransmittanceControl
            && glassCountControl
            && securityLevelOutsideControl
            && securityLevelInsideControl
            && noiseProtectionControl
            && glassTypeControl
            && glassTypeVariantControl
            && securityLevelControl
        ) {
            const currentFilterValues: Record<FiltersKeys, any> = {
                thermalTransmittance: 0,
                glassCount: 0,
                securityLevelOutside: 0,
                securityLevelInside: 0,
                noiseProtection: 0,
                glassType: '0',
                glassTypeVariant: '0',
                generalSecurityLevel: 0,
            }
            this.subscriptions.push(
                merge(
                    thermalTransmittanceControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'thermalTransmittance', value }))
                    ),
                    glassCountControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'glassCount', value }))
                    ),
                    securityLevelOutsideControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'securityLevelOutside', value }))
                    ),
                    securityLevelInsideControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'securityLevelInside', value }))
                    ),
                    noiseProtectionControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'noiseProtection', value }))
                    ),
                    glassTypeControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'glassType', value }))
                    ),
                    glassTypeVariantControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'glassTypeVariant', value }))
                    ),
                    securityLevelControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'generalSecurityLevel', value }))
                    ),
                ).subscribe(({ field, value }: { field: FiltersKeys, value: any}) => {
                    const filtersValue = this.fillingFilters.value;
                    filtersValue[field] = value;
                    currentFilterValues[field] = value;
                    if (--this.setAll > 0 && (!this.changedGlassTypeVariant || field !== 'glassTypeVariant') && (!this.changedGlassType || field !== 'glassType')) {
                        return;
                    }
                    this.setAll = 0;
                    if (field === 'glassType') {
                        this.selectedGlassType =
                            this.glassTypes.find(
                                variant => Number(variant.id) === Number(value)
                            ) || null;
                    }
                    if (field === 'glassTypeVariant') {
                        this.selectedGlassTypeVariant =
                            this.glassTypeVariants.find(
                                variant => Number(variant.id) === Number(value)
                            ) || null;
                    }

                    const key = this.getKey(currentFilterValues);

                    if (this.filtersMap[key] || oldKey === key) {
                        oldKey = key;
                        this.matchFilling(key);
                    } else {
                        oldKey = key;
                        const availableCombination = Object.keys(this.filtersMap)
                            .filter(k => k[1] === '_')
                            .reduce(
                                (max, k) => {
                                    const values = key.split('_');
                                    const values2 = k.split('_');
                                    if (
                                        values2[this.fieldIndexes[field]]
                                            === values[this.fieldIndexes[field]]
                                        && ((this.filtersLevels.glassType
                                            && this.filtersLevels.glassType.length === 1
                                            && this.filtersLevels.glassTypeVariant
                                            && this.filtersLevels.glassTypeVariant.length > 1)
                                            || (values2[this.fieldIndexes.glassType]
                                                === values[this.fieldIndexes.glassType]
                                                && (values2[this.fieldIndexes.glassTypeVariant]
                                                    === values[this.fieldIndexes.glassTypeVariant]
                                                    || field === 'glassType'
                                                    || values[this.fieldIndexes.glassTypeVariant]
                                                        === '0'
                                                    || values[this.fieldIndexes.glassTypeVariant]
                                                        === 'undefined')))
                                    ) {
                                        const matchCount = values.reduce(
                                            (prev, cur, index) =>
                                                values2[index] === cur ? ++prev : prev,
                                            0
                                        );
                                        const matchDiff = values.reduce(
                                            (prev, cur, index) =>
                                                Number(values2[index]) - Number(cur) + prev,
                                            0
                                        );
                                        if (
                                            matchCount > max.match
                                            || (matchCount === max.match
                                                && Math.abs(matchDiff) < max.matchDiff)
                                        ) {
                                            return {
                                                key: k,
                                                match: matchCount,
                                                matchDiff: Math.abs(matchDiff),
                                            };
                                        } else {
                                            return max;
                                        }
                                    } else {
                                        return max;
                                    }
                                },
                                { key: '', match: 0, matchDiff: 0 }
                            );
                        if (availableCombination.key) {
                            const newFiltersValues = availableCombination.key.split('_');
                            this.setAll = 7 - availableCombination.match;

                            this.fillingFilters.patchValue({
                                thermalTransmittance: this.thermalTransmittanceLevels.find(
                                    l => l.id === Number(newFiltersValues[0])
                                ),
                                glassCount: this.glassCountLevels.find(
                                    l => l.id === Number(newFiltersValues[1])
                                ),
                                securityLevelOutside: this.securityLevels.find(
                                    l => l.id === Number(newFiltersValues[2])
                                ),
                                securityLevelInside: this.securityLevels.find(
                                    l => l.id === Number(newFiltersValues[3])
                                ),
                                noiseProtection: this.noiseProtectionLevels.find(
                                    l => l.id === Number(newFiltersValues[4])
                                ),
                                generalSecurityLevel: this.generalSecurityLevels.find(
                                    l => l.id === Number(newFiltersValues[5] ?? '')
                                ),
                                glassType: String(newFiltersValues[6] || '0'),
                                glassTypeVariant: newFiltersValues[7] || 0,
                            });
                        } else {
                            this.matchFilling('');
                        }
                    }
                    if (this.config().IccConfig.Configurators.fillingsSelect.glassTypeSelectionAsFirst) {
                        this.updateSliderLevels(currentFilterValues);
                    }
                })
            );
        }

        this.selectedFilling = this.pageData.selGlassType;
        this.matchedFilling = this.pageData.selGlassType;
        this.setStartFiltersValues();
        this.subscriptions.push(
            this.pageData.filters$.subscribe(filters => {
                if (filters.selectedFilling) {
                    this.matchedFilling = this.pageData.fillings.find(
                        f => f.id === filters.selectedFilling
                    );
                    if (this.matchedFilling) {
                        const match = Object.keys(this.fillingFilters.value).reduce(
                            (prev, cur) =>
                                prev
                                + (this.fillingFilters.value[cur] !== filters.filters[cur] ? 1 : 0),
                            0
                        );
                        this.changedGlassType = this.fillingFilters.value.glassType !== filters.filters.glassType;
                        this.changedGlassTypeVariant = this.fillingFilters.value.glassTypeVariant !== filters.filters.glassTypeVariant;
                        this.setAll = match;
                        const updateFilters = this.updateFilterRefs(filters.filters);

                        this.fillingFilters.setValue(updateFilters);
                    }
                }
                if (filters.classicFilters) {
                    this.mode = 'list';
                    this.filterFillings(filters.classicFilters);
                    this.classicFilters = filters.classicFilters;
                }
            })
        );

        if (securityLevelControl) {
            this.generalSecurityDescription$ = securityLevelControl.valueChanges.pipe(
                startWith(this.fillingFilters.controls.generalSecurityLevel.value),
                map((value: GeneralSecurityLevel) => value.description)
            );
        }

        if (thermalTransmittanceControl) {
            this.thermalTransmittanceDescription$ = thermalTransmittanceControl.valueChanges.pipe(
                startWith(this.fillingFilters.controls.thermalTransmittance.value),
                map((value: ThermalTransmittanceLevel) => value.description)
            );
            this.thermalTransmittanceLabelAlt$ = thermalTransmittanceControl.valueChanges.pipe(
                startWith(this.fillingFilters.controls.thermalTransmittance.value),
                map((value: ThermalTransmittanceLevel) => value.labelAlt)
            );
        }

        if (glassCountControl) {
            this.glassCountDescription$ = glassCountControl.valueChanges.pipe(
                startWith(this.fillingFilters.controls.glassCount.value),
                map((value: GlassCountLevel) => value.description)
            );
            this.glassCountLabelAlt$ = glassCountControl.valueChanges.pipe(
                startWith(this.fillingFilters.controls.glassCount.value),
                map((value: GlassCountLevel) => value.labelAlt)
            );
        }

        if (securityLevelOutsideControl) {
            this.securityLevelOutsideDescription$ = securityLevelOutsideControl.valueChanges.pipe(
                startWith(this.fillingFilters.controls.securityLevelOutside.value),
                map((value: SecurityLevel) => value.description)
            );
            this.securityLevelOutsideLabelAlt$ = securityLevelOutsideControl.valueChanges.pipe(
                startWith(this.fillingFilters.controls.securityLevelOutside.value),
                map((value: SecurityLevel) => value.labelAlt)
            );
        }

        if (securityLevelInsideControl) {
            this.securityLevelInsideDescription$ = securityLevelInsideControl.valueChanges.pipe(
                startWith(this.fillingFilters.controls.securityLevelInside.value),
                map((value: SecurityLevel) => value.description)
            );
            this.securityLevelInsideLabelAlt$ = securityLevelInsideControl.valueChanges.pipe(
                startWith(this.fillingFilters.controls.securityLevelInside.value),
                map((value: SecurityLevel) => value.labelAlt)
            );
        }

        if (noiseProtectionControl) {
            this.noiseProtectionDescription$ = noiseProtectionControl.valueChanges.pipe(
                startWith(this.fillingFilters.controls.noiseProtection.value),
                map((value: NoiseProtectionLevel) => value.description)
            );
            this.noiseProtectionLabelAlt$ = noiseProtectionControl.valueChanges.pipe(
                startWith(this.fillingFilters.controls.noiseProtection.value),
                map((value: NoiseProtectionLevel) => value.labelAlt)
            );
        }

        if (this.config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues) {
            this.getGlazingsForSliderSettings();
        }
    }

    private getKey(currentFilterValues: Record<FiltersKeys, any>) {
        return `${currentFilterValues.thermalTransmittance.id}_${currentFilterValues.glassCount.id}_${currentFilterValues.securityLevelOutside.id}_${currentFilterValues.securityLevelInside.id}_${currentFilterValues.noiseProtection.id}_${currentFilterValues.generalSecurityLevel.id}_${currentFilterValues.glassType}_${currentFilterValues.glassTypeVariant}`;
    }

    ngAfterViewInit(): void {
        this.getGlazingsForSecurityPackage(this.fillingFilters.controls.generalSecurityLevel.value.id)
    }

    getGlassTypes(): IccGlassType[] {
        const glassTypes = [
            ...(this.pageData.glassTypes || []),
        ];

        this.isStandardGlassAvailable() && glassTypes.unshift(this.getStandardGlassType());

        return glassTypes;
    }

    getStandardGlassType() {
        return {
            id: '0',
            name: this.translateService.instant('WINDOW|Szyba standardowa'),
            image: null,
        };
    }

    isStandardGlassAvailable() {
        return this.pageData?.fillings.filter(f => f.glass_types.length <= 0).filter(p => p?.type === "glazing").length > 0;
    }

    initializeTabs() {
        if (this.fillingsListPageStoreService.selectedGlassTypeVariant) {
            this.initTab = this.mainTabs.findIndex(o => o.id === 'glazing');
        } else {
            this.fillingsListPageStoreService.selectedGlassTypeVariant && this.fillingsListPageStoreService.setSelectedGlassTypeVariant$(null);
            this.initTab = this.mainTabs.findIndex(o => o.id === this.pageData.selGlassType?.type) || 0;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    loadFillings(fillings = this.pageData.fillings) {
        const conf = this.configurationsService.conf.Current;
        const activeSash = conf.Sashes?.find(sash => ['DRA', 'DOA'].indexOf(sash.type?.type || '') !== -1);
        const glazingIds = activeSash?.glazing.glazing_ids?.map(f => Number(f));
        this.filtersMap = {};
        this.fillings = fillings
            .filter(filling => filling.type === this.selectedTab)
            .filter(filling => (glazingIds?.length !== 0 && (glazingIds?.includes(Number(filling.id.toString().split('.')[0])) || !filling.glazing_to_filter))
                || (glazingIds?.length === 0 && !filling.glazing_to_filter)
            )
            .map<iccListItem>(filling => {
                const {
                    thermalTransmittance,
                    glassCount,
                    securityLevelOutside,
                    securityLevelInside,
                    noiseProtection,
                    glassType,
                    glassTypeVariant,
                    generalSecurityLevel,
                } = this.matchFiltersValues(filling);

                if (thermalTransmittance) {
                    if (!this.filtersMap['thermalTransmittance_' + thermalTransmittance.id]) {
                        this.filtersMap['thermalTransmittance_' + thermalTransmittance.id] = [];
                    }
                    this.filtersMap['thermalTransmittance_' + thermalTransmittance.id].push(
                        filling.id
                    );
                }
                if (glassCount) {
                    if (!this.filtersMap['glassCount_' + glassCount.id]) {
                        this.filtersMap['glassCount_' + glassCount.id] = [];
                    }
                    this.filtersMap['glassCount_' + glassCount.id].push(filling.id);
                }
                if (securityLevelOutside) {
                    if (!this.filtersMap['securityLevelOutside_' + securityLevelOutside.id]) {
                        this.filtersMap['securityLevelOutside_' + securityLevelOutside.id] = [];
                    }
                    this.filtersMap['securityLevelOutside_' + securityLevelOutside.id].push(
                        filling.id
                    );
                }
                if (securityLevelInside) {
                    if (!this.filtersMap['securityLevelInside_' + securityLevelInside.id]) {
                        this.filtersMap['securityLevelInside_' + securityLevelInside.id] = [];
                    }
                    this.filtersMap['securityLevelInside_' + securityLevelInside.id].push(
                        filling.id
                    );
                }
                if (noiseProtection) {
                    if (!this.filtersMap['noiseProtection_' + noiseProtection.id]) {
                        this.filtersMap['noiseProtection_' + noiseProtection.id] = [];
                    }
                    this.filtersMap['noiseProtection_' + noiseProtection.id].push(filling.id);
                }
                if (glassType) {
                    if (!this.filtersMap['glassType_' + glassType.id]) {
                        this.filtersMap['glassType_' + glassType.id] = [];
                    }
                    this.filtersMap['glassType_' + glassType.id].push(filling.id);
                }
                if (glassTypeVariant) {
                    if (!this.filtersMap['glassTypeVariant_' + glassTypeVariant.id]) {
                        this.filtersMap['glassTypeVariant_' + glassTypeVariant.id] = [];
                    }
                    this.filtersMap['glassTypeVariant_' + glassTypeVariant.id].push(filling.id);
                }
                if (generalSecurityLevel) {
                    if (!this.filtersMap['generalSecurityLevel_' + generalSecurityLevel.id]) {
                        this.filtersMap['generalSecurityLevel_' + generalSecurityLevel.id] = [];
                    }
                    this.filtersMap['generalSecurityLevel_' + generalSecurityLevel.id].push(filling.id);
                }

                const key = `${thermalTransmittance.id}_${glassCount.id}_${securityLevelOutside.id}_${
                    securityLevelInside.id
                }_${noiseProtection.id}_${generalSecurityLevel.id}_${(glassType && glassType.id) || '0'}_${(glassTypeVariant
                    && glassTypeVariant.id)
                    || '0'}`;
                if (!this.filtersMap[key]) {
                    this.filtersMap[key] = [];
                }
                this.filtersMap[key].push(filling.id);
                return {
                    id: filling.id,
                    title: filling.name,
                    imageUrl: `/files/filling/${filling.image}`,
                    description: `${filling.code || filling.name}`,
                    filters: {
                        thermalTransmittance,
                        glassCount,
                        securityLevelOutside,
                        securityLevelInside,
                        noiseProtection,
                        glassType: (glassType && glassType.id) || '0',
                        glassTypeVariant: (glassTypeVariant && glassTypeVariant.id) || 0,
                        generalSecurityLevel
                    },
                    editGlazing: filling.has_glazing_unit,
                };
            });
        Object.keys(this.filtersMap).forEach(key => {
            const [filter, id] = key.split('_');
            if (
                filter === 'thermalTransmittance'
                && !this.filtersLevels.thermalTransmittance.map(l => l.id).includes(Number(id))
            ) {
                this.filtersLevels.thermalTransmittance.push(
                    this.thermalTransmittanceLevels.find(l => l.id === Number(id))
                        || this.thermalTransmittanceLevels[0]
                );
            }
            if (
                filter === 'glassCount'
                && !this.filtersLevels.glassCount.map(l => l.id).includes(Number(id))
            ) {
                this.filtersLevels.glassCount.push(
                    this.glassCountLevels.find(l => l.id === Number(id)) || this.glassCountLevels[0]
                );
            }
            if (
                filter === 'securityLevelOutside'
                && !this.filtersLevels.securityLevelOutside.map(l => l.id).includes(Number(id))
            ) {
                this.filtersLevels.securityLevelOutside.push(
                    this.securityLevels.find(l => l.id === Number(id)) || this.securityLevels[0]
                );
            }
            if (
                filter === 'securityLevelInside'
                && !this.filtersLevels.securityLevelInside.map(l => l.id).includes(Number(id))
            ) {
                this.filtersLevels.securityLevelInside.push(
                    this.securityLevels.find(l => l.id === Number(id)) || this.securityLevels[0]
                );
            }
            if (
                filter === 'noiseProtection'
                && !this.filtersLevels.noiseProtection.map(l => l.id).includes(Number(id))
            ) {
                this.filtersLevels.noiseProtection.push(
                    this.noiseProtectionLevels.find(l => l.id === Number(id))
                        || this.noiseProtectionLevels[0]
                );
            }
            if (
                filter === 'glassType'
                && !this.filtersLevels.glassType.map(l => Number(l.id)).includes(Number(id))
            ) {
                this.filtersLevels.glassType.push(
                    this.glassTypes.find(l => Number(l.id) === Number(id)) || this.glassTypes[0]
                );
            }
            if (
                filter === 'glassTypeVariant'
                && !this.filtersLevels.glassTypeVariant.map(l => Number(l.id)).includes(Number(id))
            ) {
                this.filtersLevels.glassTypeVariant.push(
                    this.glassTypeVariants.find(l => Number(l.id) === Number(id))
                        || this.glassTypeVariants[0]
                );
            }
            if (
                filter === 'generalSecurityLevel'
                && !this.filtersLevels.generalSecurityLevel.map(l => l.id).includes(Number(id))
            ) {
                this.filtersLevels?.generalSecurityLevel.push(
                    this.generalSecurityLevels.find(l => l.id === Number(id)) || this.generalSecurityLevels[0]
                );
            }
        });
        this.filtersLevels.thermalTransmittance.sort((a, b) => a.id - b.id);
        this.filtersLevels.glassCount.sort((a, b) => a.id - b.id);
        this.filtersLevels.securityLevelOutside.sort((a, b) => a.id - b.id);
        this.filtersLevels.securityLevelInside.sort((a, b) => a.id - b.id);
        this.filtersLevels.noiseProtection.sort((a, b) => a.id - b.id);
        this.filtersLevels.glassType.sort((a, b) => Number(a.id) - Number(b.id));
        this.filtersLevels.glassTypeVariant.sort((a, b) => Number(a.id) - Number(b.id));
        this.filtersLevels.generalSecurityLevel.sort((a, b) => a.id - b.id);
        this.filtersLevelsBeforeMatch = core.copy(this.filtersLevels);
        this.cdr.markForCheck();
    }

    updateSliderLevels(filtersValues: Record<FiltersKeys, any>) {
        const availableFiltersLevels: Record<FiltersKeys, number[]> = {
            thermalTransmittance: [],
            glassCount: [],
            securityLevelOutside: [],
            securityLevelInside: [],
            noiseProtection: [],
            glassType: [],
            glassTypeVariant: [],
            generalSecurityLevel: [],
        };
        Object.keys(this.filtersMap).filter(key => {
            const values = key.split('_');
            return (
                Number(values[this.fieldIndexes.glassType]) === Number(filtersValues.glassType) &&
                Number(values[this.fieldIndexes.glassTypeVariant]) === Number(filtersValues.glassTypeVariant)
            )
        }).forEach(key => {
            const values = key.split('_');
            if (
                values[this.fieldIndexes.thermalTransmittance]
                && !availableFiltersLevels.thermalTransmittance.includes(Number(values[this.fieldIndexes.thermalTransmittance]))
            ) {
                availableFiltersLevels.thermalTransmittance.push(
                    Number(values[this.fieldIndexes.thermalTransmittance])
                );
            }
            if (
                values[this.fieldIndexes.glassCount]
                && !availableFiltersLevels.glassCount.includes(Number(values[this.fieldIndexes.glassCount]))
            ) {
                availableFiltersLevels.glassCount.push(
                    Number(values[this.fieldIndexes.glassCount])
                );
            }
            if (
                values[this.fieldIndexes.securityLevelOutside]
                && !availableFiltersLevels.securityLevelOutside.includes(Number(values[this.fieldIndexes.securityLevelOutside]))
            ) {
                availableFiltersLevels.securityLevelOutside.push(
                    Number(values[this.fieldIndexes.securityLevelOutside])
                );
            }
            if (
                values[this.fieldIndexes.securityLevelInside]
                && !availableFiltersLevels.securityLevelInside.includes(Number(values[this.fieldIndexes.securityLevelInside]))
            ) {
                availableFiltersLevels.securityLevelInside.push(
                    Number(values[this.fieldIndexes.securityLevelInside])
                );
            }
            if (
                values[this.fieldIndexes.noiseProtection]
                && !availableFiltersLevels.noiseProtection.includes(Number(values[this.fieldIndexes.noiseProtection]))
            ) {
                availableFiltersLevels.noiseProtection.push(
                    Number(values[this.fieldIndexes.noiseProtection])
                        || this.noiseProtectionLevels[0].id
                );
            }
            if (
                values[this.fieldIndexes.generalSecurityLevel]
                && !availableFiltersLevels.generalSecurityLevel.includes(Number(values[this.fieldIndexes.generalSecurityLevel]))
            ) {
                availableFiltersLevels?.generalSecurityLevel.push(
                    Number(values[this.fieldIndexes.generalSecurityLevel]) || this.generalSecurityLevels[0].id
                );
            }
        });

        this.filtersLevels = core.copy(this.filtersLevelsBeforeMatch);
        this.filtersLevels.thermalTransmittance = this.filtersLevels.thermalTransmittance.filter(
            l => availableFiltersLevels.thermalTransmittance.includes(l.id)
        );
        this.filtersLevels.glassCount = this.filtersLevels.glassCount.filter(
            l => availableFiltersLevels.glassCount.includes(l.id)
        );
        this.filtersLevels.securityLevelOutside = this.filtersLevels.securityLevelOutside.filter(
            l => availableFiltersLevels.securityLevelOutside.includes(l.id)
        );
        this.filtersLevels.securityLevelInside = this.filtersLevels.securityLevelInside.filter(
            l => availableFiltersLevels.securityLevelInside.includes(l.id)
        );
        this.filtersLevels.noiseProtection = this.filtersLevels.noiseProtection.filter(
            l => availableFiltersLevels.noiseProtection.includes(l.id)
        );
        this.filtersLevels.generalSecurityLevel = this.filtersLevels.generalSecurityLevel.filter(
            l => availableFiltersLevels.generalSecurityLevel.includes(l.id)
        );

        this.filtersLevels.thermalTransmittance.sort((a, b) => a.id - b.id);
        this.filtersLevels.glassCount.sort((a, b) => a.id - b.id);
        this.filtersLevels.securityLevelOutside.sort((a, b) => a.id - b.id);
        this.filtersLevels.securityLevelInside.sort((a, b) => a.id - b.id);
        this.filtersLevels.noiseProtection.sort((a, b) => a.id - b.id);
        this.filtersLevels.generalSecurityLevel.sort((a, b) => a.id - b.id);
        this.cdr.markForCheck();
    }

    selectFilling(item: iccListItem) {
        const filling = this.pageData.fillings.find(f => f.id === item.id);
        filling?.type !== 'glazing' && this.fillingsListPageStoreService.selectedGlassTypeVariant?.id && this.fillingsListPageStoreService.setSelectedGlassTypeVariant$(null)
        if (this.pageData.complementary) {
            this.modalService
                .open({
                    pageComponent: FillingOptionsPageComponent,
                    resolve: {
                        filling,
                        warmEdges: this.pageData.warmEdges,
                    },
                })
                .result.then(result => {
                    this.sharedFacade.closePage({
                        glass: result.accessory,
                        selectedSecurity: null,
                        selectedOrnament: null,
                        selectedUg: null,
                        selectedRw: null,
                        selectedCategory: null,
                        glassTab: null,
                        bondedGlazing: null,
                    });
                });
        } else {
            this.sharedFacade.closePage({
                glass: filling,
                selectedSecurity: null,
                selectedOrnament: null,
                selectedUg: null,
                selectedRw: null,
                selectedCategory: null,
                glassTab: null,
                bondedGlazing: null,
            });
        }
    }

    select() {
        this.sharedFacade.closePage({
            glass: this.matchedFilling,
            selectedSecurity: null,
            selectedOrnament: null,
            selectedUg: null,
            selectedRw: null,
            selectedCategory: null,
            glassTab: null,
            bondedGlazing: null,
        });
    }

    changedTab(tab: iccListTab) {
        if (this.selectedTab !== String(tab.id)) {
            this.selectedTab = String(tab.id);
            if (
                tab.id === 'glazing'
                && this.mode !== 'filters'
                && !this.pageData.complementary
                && !this.pageData.onlyGlazingList
            ) {
                this.mode = 'filters';
            } else if (tab.id !== 'glazing' && this.mode !== 'list') {
                this.mode = 'list';
            }
            this.loadFillings();

            if (tab.id === 'glazing' && this.matchedFilling?.type !== 'glazing') {
                this.matchFilling(this.getKey(this.fillingFilters.value));
            }
            this.updateSliderLevels(this.fillingFilters.value);
        }
    }

    changeGlassType(glassType: IccGlassType) {
        this.fillingFilters.patchValue({
            glassType: glassType.id,
        });
    }

    changeGlassTypeVariant(glassTypeVariant: IccGlassTypeVariant) {
        this.fillingFilters.patchValue({
            glassTypeVariant: glassTypeVariant.id,
        })
    }

    matchFilling(key: string) {
        if (
            this.filtersMap[key]
            && (!this.matchedFilling || !this.filtersMap[key].includes(this.matchedFilling.id))
        ) {
            this.matchedFilling = this.pageData.fillings.find(filling => {
                return (
                    filling.type === 'glazing'
                    && this.filtersMap[key]
                    && filling.id === this.filtersMap[key][0]
                );
            });
        }
    }

    selectGlassType() {
        this.modalService
            .open({
                pageComponent: GlassTypesPageComponent,
                resolve: {
                    glassTypes: this.glassTypes,
                    glassTypeVariants: (this.pageData.glassTypeVariants || []).filter(variant =>
                        this.filtersLevels.glassType.map(v => Number(v.id)).includes(Number(variant.glass_type_id))
                    ),
                    selectedType: this.fillingFilters.value.glassType,
                    selectedVariant: this.fillingFilters.value.glassTypeVariant,
                },
            })
            .result.then(result => {
                if (result?.glassTypeId) {
                    const glassTypeControl = this.fillingFilters.get('glassType');
                    if (glassTypeControl) {
                        glassTypeControl.setValue(result.glassTypeId);
                        this.selectedGlassType =
                            this.glassTypes.find(
                                type => String(type.id) === String(result.glassTypeId)
                            ) || null;
                    }
                    const glassTypeVariantControl = this.fillingFilters.get('glassTypeVariant');
                    if (glassTypeVariantControl) {
                        const getMatchedVariant = this.getMatchingVariants().find(glass => glass.id === result.glassTypeVariantId)
                        getMatchedVariant && this.fillingsListPageStoreService.setSelectedGlassTypeVariant$(getMatchedVariant);
                        glassTypeVariantControl.setValue(result.glassTypeVariantId);
                        this.selectedGlassTypeVariant =
                            this.glassTypeVariants.find(
                                variant => String(variant.id) === String(result.glassTypeVariantId)
                            ) || null;
                    }
                    this.pageData.filters$.next({
                        selectedFilling:
                            (this.matchedFilling && this.matchedFilling.id) || null,
                        filters: this.fillingFilters.value,
                        classicFilters: this.classicFilters,
                    });
                }
            });
    }

    selectGlassTypeVariant() {
        this.modalService
            .open({
                pageComponent: GlassTypeVariantsPageComponent,
                resolve: {
                    glassTypeVariants: this.getMatchingVariants(),
                    selectedVariant: this.fillingFilters.value.glassTypeVariant,
                },
            })
            .result.then(result => {
                if (result?.glassTypeVariantId) {
                    const glassTypeVariantControl = this.fillingFilters.get('glassTypeVariant');
                    if (glassTypeVariantControl) {
                        const getMatchedVariant = this.getMatchingVariants().find(glass => String(glass.id) === String(result?.glassTypeVariantId))
                        getMatchedVariant && this.fillingsListPageStoreService.setSelectedGlassTypeVariant$(getMatchedVariant);
                        glassTypeVariantControl.setValue(result.glassTypeVariantId);
                        this.selectedGlassTypeVariant =
                            this.glassTypeVariants.find(
                                variant => String(variant.id) === String(result.glassTypeVariantId)
                            ) || null;
                        this.pageData.filters$.next({
                            selectedFilling:
                                (this.matchedFilling && this.matchedFilling.id) || null,
                            filters: this.fillingFilters.value,
                            classicFilters: this.classicFilters,
                        });
                    }
                }
            });
    }

    getMatchingVariants() {
        return (this.pageData.glassTypeVariants || []).filter(
            variant =>
                Number(variant.glass_type_id) === Number(this.fillingFilters.value.glassType)
                && this.filtersLevels.glassTypeVariant.map(v => Number(v.id)).includes(Number(variant.id))
        );
    }

    openClassicFilters() {
        this.modalService
            .open({
                pageComponent: FillingsFiltersPageComponent,
                resolve: {
                    IccConfig: this.config().IccConfig,
                    filters: this.classicFilters,
                    areOrnaments: GlassOrnamentFilter()(this.pageData.fillings, true).length > 0,
                    glassTypes: this.glassTypes,
                },
                filters: true,
            })
            .result.then(result => {
                if (result) {
                    this.classicFilters = result.filters;
                    this.pageData.filters$.next({
                        selectedFilling: (this.matchedFilling && this.matchedFilling.id) || null,
                        filters: this.fillingFilters.value,
                        classicFilters: result.filters,
                    });
                }
            });
    }

    openGlazingUnitModal(item: iccListItem) {
        const filling = this.pageData.fillings.find(f => String(f.id) === String(item.id));
        if (this.editGlazingUnit && filling) {
            this.pageData.addGlazingUnit(filling).then((f: IccFilling) => {
                if (f) {
                    if (f.custom) {
                        this.pageData.fillings.unshift(f);
                    }
                    this.selectFilling({
                        id: f.id,
                        title: f.code || f.name,
                        imageUrl: `/files/filling/${f.image}`,
                        description: `${f.name}`,
                        editGlazing: f.has_glazing_unit,
                    });
                } else {
                    this.selectFilling({
                        id: filling.id,
                        title: filling.code || f.name,
                        imageUrl: `/files/filling/${filling.image}`,
                        description: `${filling.name}`,
                        editGlazing: filling.has_glazing_unit,
                    });
                }
            });
        }
    }

    private matchFiltersValues(filling: IccFilling) {
        const thermalTransmittance = this.config().IccConfig.Configurators.fillingsSelect.thermalTransmittanceSlider ? (
            this.thermalTransmittanceLevels.find(level =>
                this.filters.thermalTransmittance(level)(filling)
            ) || this.thermalTransmittanceLevels[0]
        ) : this.thermalTransmittanceLevels[0];
        const glassCount = this.config().IccConfig.Configurators.fillingsSelect.glassCountSlider ? (
            this.glassCountLevels.find(level => this.filters.glassCount(level)(filling))
            || this.glassCountLevels[0]
        ) : this.glassCountLevels[0];
        const securityLevelOutside = this.config().IccConfig.Configurators.fillingsSelect.securitySlider === 'detailed' ? (
            this.securityLevels.find(level => this.filters.securityLevelOutside(level)(filling))
            || this.securityLevels[0]
        ) : this.securityLevels[0];
        const securityLevelInside = this.config().IccConfig.Configurators.fillingsSelect.securitySlider === 'detailed' ? (
            this.securityLevels.find(level => this.filters.securityLevelInside(level)(filling))
            || this.securityLevels[0]
        ) : this.securityLevels[0];
        const noiseProtection = this.config().IccConfig.Configurators.fillingsSelect.noiseProtectionSlider ? (
            this.noiseProtectionLevels.find(level => this.filters.noiseProtection(level)(filling))
            || this.noiseProtectionLevels[0]
        ) : this.noiseProtectionLevels[0];
        const glassType = this.glassTypes.find(type => this.filters.glassType(type.id)(filling));
        let glassTypeVariant = null;
        if (glassType) {
            glassTypeVariant = this.glassTypeVariants.find(variant =>
                this.filters.glassTypeVariant(glassType.id, variant.id)(filling)
            );
        }
        const generalSecurityLevel = this.config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues && this.config().IccConfig.Configurators.fillingsSelect.securitySlider === 'general' ? (
            this.generalSecurityLevels.find(level => this.filters.generalSecurityLevel(level)(filling))
            || this.generalSecurityLevels[0]
        ) : this.generalSecurityLevels[0];
        return {
            thermalTransmittance,
            glassCount,
            securityLevelOutside,
            securityLevelInside,
            noiseProtection,
            glassType,
            glassTypeVariant,
            generalSecurityLevel
        };
    }

    private setStartFiltersValues() {
        if (this.matchedFilling) {
            const {
                thermalTransmittance,
                glassCount,
                securityLevelOutside,
                securityLevelInside,
                noiseProtection,
                glassType,
                glassTypeVariant,
                generalSecurityLevel
            } = this.matchFiltersValues(this.matchedFilling);
            const startValue = {
                thermalTransmittance,
                glassCount,
                securityLevelOutside,
                securityLevelInside,
                noiseProtection,
                glassType: (glassType && String(glassType.id)) || this.pageData?.glassTypes[0]?.id || 0,
                glassTypeVariant: (glassTypeVariant && glassTypeVariant.id)
                    || Number(this.pageData?.glassTypeVariants?.find((p) => p.glass_type_id === Number(this.pageData.glassTypes[0].id))?.id)
                    || 0,
                generalSecurityLevel
            };
            const match = Object.keys(this.fillingFilters.value).reduce(
                (prev, cur) =>
                    prev
                    + (this.fillingFilters.value[cur] !== startValue[cur]
                    || this.fillingFilters.value[cur].id !== startValue[cur].id
                        ? 1
                        : 0),
                0
            );
            this.setAll = match;

            this.fillingFilters.patchValue(startValue);
        }
    }

    getGlassType(glassType: IccGlassType | undefined) {
        return (glassType && String(glassType.id)) || this.pageData?.glassTypes[0]?.id || 0;
    }

    getGlassTypeVariant(glassTypeVariant: IccGlassTypeVariant | null | undefined) {
        return (glassTypeVariant && glassTypeVariant.id)
            || Number(this.pageData?.glassTypeVariants?.find((p) => p.glass_type_id === Number(this.pageData.glassTypes[0].id))?.id)
            || 0;
    }

    private filterFillings(filters: { glassTab?: "glazing" | "pvc_panels" | "deco_panels"; selectedSecurity: any; selectedOrnament: any; selectedUg: any; selectedRw: any; selectedCategory?: any; selectedColored?: any; glazingCount?: any; selectedOften?: any; }) {
        let fillingsFiltered = this.pageData.fillings;
        fillingsFiltered = GlassSecurityFilter()(
            fillingsFiltered,
            filters.selectedSecurity,
            this.config().IccConfig.Configurators.glazingFilters
        );
        fillingsFiltered = GlassUgFilter()(fillingsFiltered, filters.selectedUg);
        fillingsFiltered = GlassRwFilter()(fillingsFiltered, filters.selectedRw);
        fillingsFiltered = GlassOrnamentFilter()(fillingsFiltered, filters.selectedOrnament);
        fillingsFiltered = GlassTypeFilter()(fillingsFiltered, filters.selectedColored);
        fillingsFiltered = GlassCountFilter()(fillingsFiltered, filters.glazingCount);
        fillingsFiltered = GlassOftenFilter()(fillingsFiltered, filters.selectedOften);
        this.loadFillings(fillingsFiltered);
    }

    private updateFilterRefs(filters: {
        thermalTransmittance: ThermalTransmittanceLevel;
        glassCount: GlassCountLevel;
        securityLevelOutside: SecurityLevel;
        securityLevelInside: SecurityLevel;
        noiseProtection: NoiseProtectionLevel;
        glassType: string;
        glassTypeVariant: number;
        generalSecurityLevel: GeneralSecurityLevel;
    }): {
        thermalTransmittance: ThermalTransmittanceLevel;
        glassCount: GlassCountLevel;
        securityLevelOutside: SecurityLevel;
        securityLevelInside: SecurityLevel;
        noiseProtection: NoiseProtectionLevel;
        glassType: string;
        glassTypeVariant: number;
        generalSecurityLevel: GeneralSecurityLevel;
    } {
        return {
            thermalTransmittance: this.filtersLevels.thermalTransmittance.find(level => level.id == filters.thermalTransmittance.id) || this.filtersLevels.thermalTransmittance[0],
            glassCount: this.filtersLevels.glassCount.find(level => level.id == filters.glassCount.id) || this.filtersLevels.glassCount[0],
            securityLevelOutside: this.filtersLevels.securityLevelOutside.find(level => level.id == filters.securityLevelOutside.id) || this.filtersLevels.securityLevelOutside[0],
            securityLevelInside: this.filtersLevels.securityLevelInside.find(level => level.id == filters.securityLevelInside.id) || this.filtersLevels.securityLevelInside[0],
            noiseProtection: this.filtersLevels.noiseProtection.find(level => level.id == filters.noiseProtection.id) || this.filtersLevels.noiseProtection[0],
            glassType: filters.glassType,
            glassTypeVariant: filters.glassTypeVariant,
            generalSecurityLevel: this.filtersLevels.generalSecurityLevel.find(level => level.id == filters.generalSecurityLevel.id) || this.filtersLevels.generalSecurityLevel[0],
        };
    }

    /**
     * Funkcja, która powoduje wyświetlenie szkleń w zależności od ustawień suwaków
     */
    getGlazingsForSliderSettings() {
        this.ngZone.run(() => {
            if (!this.config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues) {
                return;
            }

            this.availableGlazings = [];

            const securityLevelInside = this.fillingFilters.controls.securityLevelInside.value.id;
            const securityLevelOutside = this.fillingFilters.controls.securityLevelOutside.value.id;
            const noiseProtection = this.fillingFilters.controls.noiseProtection.value.id;
            const thermalTransmittance = this.fillingFilters.controls.thermalTransmittance.value.id;
            const glassCount = this.fillingFilters.controls.glassCount.value.id;

            const sliderValues = {
                securityLevelInside,
                securityLevelOutside,
                noiseProtection,
                thermalTransmittance,
                glassCount,
            };

            this.fillings.forEach(f => {
                const np = f.filters.noiseProtection.id;
                const sli = f.filters.securityLevelInside.id;
                const slo = f.filters.securityLevelOutside.id;
                const tt = f.filters.thermalTransmittance.id;
                const gc = f.filters.glassCount.id;

                if (
                    np === sliderValues.noiseProtection
                    && sli === sliderValues.securityLevelInside
                    && slo === sliderValues.securityLevelOutside
                    && tt === sliderValues.thermalTransmittance
                    && gc === sliderValues.glassCount
                ) {
                    this.availableGlazings.push(f);
                }
            });
        });
    }

    getGlazingsForSecurityPackage(glazingId: number) {
        this.availableGlazings = [];
        this.sliderValue = glazingId;

        this.fillings.forEach(f => {
            if (f.filters.generalSecurityLevel.id === this.sliderValue) {
                this.availableGlazings.push(f);
            }
        })
    }

    trackByGlassTypeId(index: number, item: IccGlassType) {
        return item.id;
    }
}
