import {
    ActiveRollerShutter,
    ColorOptions,
    Type,
    RoundReel,
    Drive,
    RollerShutterSystem,
    RollerShutterProfile,
    Shutter,
    GrayPolystyrene
} from '../roller_shutter';
import {
    Accessory,
    ColorLegacy,
    SimpleAccessory,
} from '../common';
import {GuideRail} from './GuideRail';
import {Slat} from './Slat';

export class RollerShutter {
    type: Type;
    colors: {
        boxInner?: ColorLegacy;
        boxGuideOuter?: ColorLegacy;
        profile?: ColorLegacy;
        endslat?: ColorLegacy;
        guideRail?: ColorLegacy;
        box?: ColorLegacy;
        revision?: ColorLegacy;
        boxSideSurface?: ColorLegacy;
    };
    colorsOptions: {
        boxInner?: ColorOptions;
        boxGuideOuter?: ColorOptions;
        profile?: ColorOptions;
        endslat?: ColorOptions;
        guideRail?: ColorOptions;
        box?: ColorOptions;
        revision?: ColorOptions;
        boxSideSurface?: ColorOptions;
    };
    roundReel: RoundReel;
    guideType: 'pvc' | 'alu';
    hasBox: boolean;
    changedBoxHeight: boolean;
    boxHeight: number;
    originalBoxHeight: number;
    boxWidth: number;
    boxHeightLevel: number;
    rollerHeight: number;
    hasAssembly: boolean;
    inInsulation: boolean;
    concealedStrip: boolean;
    mountingLength: number | string;
    crankSlope: number | string;
    crankLength: number | string;
    crankOffset: number;
    driveSide: 'L' | 'R';
    driveOutputSide: 'rear-up'
        | 'rear-down'
        | 'front-up'
        | 'front-down'
        | 'side'
        | 'side-up'
        | 'side-down' ;
    hasOverride: boolean;
    driveAccessories: SimpleAccessory[];
    drive: Drive;
    hanger: any;
    system: RollerShutterSystem;
    profile: RollerShutterProfile;
    schemaId: string;
    shutters: Shutter[];
    guideRails: GuideRail[];
    slats: Slat[];
    railsBottomCut: string;
    railsHeightModify: number;
    openingLock: boolean;
    Accessories: any;
    grayPolystyrene: GrayPolystyrene | null;
    weight = 0;
    boxHeightChangedManually = false;

    constructor(rollerShutter: ActiveRollerShutter) {
        this.mapRollerShutter(rollerShutter);
    }

    // eslint-disable-next-line max-statements
    public mapRollerShutter(rollerShutter: ActiveRollerShutter) {
        this.weight = rollerShutter.weight;
        this.type = rollerShutter.type ? {
            id: Number(rollerShutter.type.id),
            type: rollerShutter.type.type,
            measureType: rollerShutter.type.measureType,
            name: rollerShutter.type.name,
            static: rollerShutter.type.static,
            titleImage: rollerShutter.type.title_image,
            offerInfo: rollerShutter.type.offerInfo
        } : null;
        this.colors = {
            boxInner: null,
            boxGuideOuter: null,
            profile: null,
            endslat: null,
            guideRail: null,
            box: null,
            revision: null,
        };
        this.colorsOptions = {
            boxInner: null,
            boxGuideOuter: null,
            profile: null,
            endslat: null,
            guideRail: null,
            box: null,
            revision: null,
        };
        if (rollerShutter.roundReel) {
            this.roundReel = {
                id: Number(rollerShutter.roundReel.id),
                name: rollerShutter.roundReel.name
            };
        }
        if (rollerShutter.Accessories) {
            this.Accessories = rollerShutter.Accessories;
        }
        if (rollerShutter.Accessories) {
            const accessories = rollerShutter.Accessories?.map(
                (accessory) => {
                    if (accessory && !accessory?.id) {
                        return new Accessory(accessory);
                    } else {
                        return accessory;
                    }
                }
            );

            if (accessories.filter(Boolean).length) {
                this.Accessories = accessories.filter(Boolean);
            }
        }
        this.guideType = rollerShutter.guideType;
        this.guideRails = rollerShutter.guideRails;
        this.slats = rollerShutter.slats;
        this.changedBoxHeight = rollerShutter.changedSize;
        this.boxHeight = Number(rollerShutter.realBoxHeight);
        this.originalBoxHeight = Number(rollerShutter.originalBoxHeight);
        this.boxHeightLevel = Number(rollerShutter.boxHeightLevel);
        this.boxWidth = Number(rollerShutter.realBoxWidth);
        this.rollerHeight = Number(rollerShutter.realRollerHeight);
        this.hasAssembly = rollerShutter.hasAssembly;
        this.inInsulation = rollerShutter.inInsulation;
        this.concealedStrip = rollerShutter.concealedStrip;
        this.driveSide = rollerShutter.driveSide;
        this.driveOutputSide = rollerShutter.driveOutputSide;
        this.hasOverride = rollerShutter.driveType === 'override';
        this.driveAccessories = [];
        this.railsBottomCut = rollerShutter.railsBottomCut;
        this.railsHeightModify = rollerShutter.railsHeightModify;
        this.boxHeightChangedManually = rollerShutter.boxHeightChangedManually;
        this.drive = rollerShutter.drive ? {
            id: Number(rollerShutter.drive.id),
            driveEngine: rollerShutter?.drive?.driveEngine ? rollerShutter?.drive?.driveEngine : null,
            engineGroups: rollerShutter?.drive?.engineGroups ? rollerShutter?.drive?.engineGroups : null,
            name: rollerShutter.drive.name,
            producer: rollerShutter.drive.producer,
            type: rollerShutter.drive.type,
        } : null;
        this.hanger = rollerShutter.hanger;
        this.system = rollerShutter.system
            ? {
                  id: Number(rollerShutter.system.id),
                  name: rollerShutter.system.name,
                  availableRevisionColor: rollerShutter.system.available_revision_color,
                  dimensionsInfo: rollerShutter.system.dimensionsInfo,
                  hasBox: rollerShutter.system.hasBox,
                  additionalDescription: rollerShutter.system.additional_description,
                  boxColorType: rollerShutter.system.box_color_type
                      ? rollerShutter.system.box_color_type
                      : rollerShutter.type?.type === 'N'
                      ? 'bicolor'
                      : rollerShutter.type?.type === 'Z'
                      ? 'bilateral'
                      : 'none',
              }
            : null;
        this.profile = rollerShutter.profile ? {
            id: Number(rollerShutter.profile.id),
            name: rollerShutter.profile.name,
        } : null;
        this.schemaId = rollerShutter.schemaId;
        this.shutters = rollerShutter.shutters.map<Shutter>(this.mapShutter.bind(this));
        this.openingLock = rollerShutter.openingLock;
        ['boxInner', 'boxGuideOuter', 'profile', 'endslat', 'guideRail', 'box', 'revision', 'boxSideSurface'].forEach(side => {
            if (rollerShutter.colors[side] && rollerShutter.colors[side].id) {
                this.colors[side] = {
                    id: Number(rollerShutter.colors[side].id),
                    name: rollerShutter.colors[side].name,
                    code: rollerShutter.colors[side].code,
                    RAL: Boolean(rollerShutter.colors[side].RAL),
                    isDefault: rollerShutter.colors[side].isDefault,
                    groups: rollerShutter.colors[side].groups,
                };
                this.colorsOptions[side] = {
                    group: rollerShutter.colors[side + 'Group'],
                    charge: {
                        percent: Number(rollerShutter.colors[side + 'Charge']),
                        m2: Number(rollerShutter.colors[side + 'ChargeM2']),
                        height: Number(rollerShutter.colors[side + 'ChargeH']),
                        width: Number(rollerShutter.colors[side + 'ChargeW']),
                    },
                };
            }
        });

        if (this.drive && this.drive.type === 'manual' && rollerShutter.driveManual && rollerShutter.driveManual.id) {
            this.driveAccessories = [{
                id: Number(rollerShutter.driveManual.id),
                name: rollerShutter.driveManual.name,
                manufacturerCode: '',
                type: 'rollerShutterManual',
                priceSource: 'table',
                color: {type: rollerShutter.driveManual.type}
            }];
        } else {
            this.driveAccessories = rollerShutter.driveElements.filter(el => el).map<SimpleAccessory>(this.mapSimpleAccessory.bind(this));
        }

        this.grayPolystyrene = rollerShutter.grayPolystyrene;
    }

    public mapShutter(shutter): Shutter {
        return {
            id: Number(shutter.id),
            x: Number(shutter.x),
            width: Number(shutter.realWidth),
            height: Number(shutter.realHeight),
            railsHeightModify: Number(shutter.railsHeightModify),
            controlSide: shutter.handling === 'l' ? 'L' : 'R',
            commonRail: Boolean(shutter.commonRail),
            withMosquito: Boolean(shutter.mosquito),
            railCutHeight: Number(shutter.railCutHeight),
        };
    }

    private mapSimpleAccessory(accessory): SimpleAccessory {
        const newAccessory: SimpleAccessory = {
            id: Number(accessory.id),
            name: accessory.name,
            priceSource: accessory.price_source,
            type: accessory.type,
            manufacturerCode: accessory.manufacturer_code,
        };

        return newAccessory;
    }
}
