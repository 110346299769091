import { PriceFunc, PriceElemsData, PriceSegment, getListPrice } from './Prices';
import { CustomPricesRecords } from '@icc/common/custom-price/CustomPrice';
import { Injectable, Inject } from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { Sill } from '@icc/common/configurations/parts/complementary_goods';
import { Common } from '@icc/common/Common';
import { ColorsDefaultsService } from '@icc/common/colors/colors-defaults.service';
import { AccessoriesActiveConfiguration } from '@icc/common/configurations/AccessoriesActiveConfiguration';
import { ComplementaryGoodsActiveConfiguration } from '@icc/common/configurations/ComplementaryGoodsActiveConfiguration';
import { IccAccessoryAccessory, IccProfileSideColors, IccWindowColors } from '@icc/common/data-types';
import { CustomPricesService } from '@icc/common/custom-price/custom-prices.service';
import { DiscountsAndMultipliersService } from './discounts-and-multipliers.service';
import { PriceBaseService } from './price-base.service';
import { StateService } from '@icc/common/state.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';

@Injectable()
export class PriceAccessoryService {
    constructor(
        private stateService: StateService,
        private priceBaseService: PriceBaseService,
        private discountsAndMultipliersService: DiscountsAndMultipliersService,
        private customPricesService: CustomPricesService,
        private colorsDefaultsService: ColorsDefaultsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    /**
     * Zwraca pole ceny dla danego zestawu kolorów.
     * @param  {object} colors Kolory konstrukcji
     * @param  {string} type   Rodzaj konfiguracji
     * @return {string}        Pole ceny
     */
    getAccessoryPriceField(colors: IccWindowColors | null, type) {
        let field = 'price_white';
        if (colors && (type === 'window' || type === 'door' || type === 'hs' || type === 'sliding_door' || type === 'folding_door')) {
            if (colors.frame.outer?.type === 'white'
                && colors.frame.inner?.type === 'white'
            ) {
                field = 'price_white';
            } else if (
                colors.frame.outer?.type === 'white'
                || colors.frame.inner?.type === 'white'
            ) {
                field = 'price_white_colour';
            } else {
                field = 'price_colour_colour';
            }

            if (
                colors.frame.inner?.isRal
                || colors.frame.outer?.isRal
                || colors.sash.inner?.isRal
                || colors.sash.outer?.isRal
            ) {
                field = 'price_ral';
            }
        }

        return field;
    }

    /**
     * Zwraca pole ceny dla ręcznie wybranego zestawu kolorów.
     * @param  {object}             accessory   Akcesorium
     * @param  {object}             color       Ręczny kolor
     * @param  {CustomPriceRecords} customPrice Indywidualne ceny
     * @return {string}                         Pole ceny
     */
    getAccessoryPriceCustom(accessory, color, customPrice: CustomPricesRecords) {
        let field = 'price_white';

        if (color === 'white') {
            field = 'price_white';
        } else if (color === 'color-white') {
            field = 'price_white_colour';
        } else if (color === 'color-color') {
            field = 'price_colour_colour';
        } else if (color === 'ral') {
            field = 'price_ral';
        }

        let price = parseFloat(accessory[field]);
        if (Common.isObject(customPrice) && customPrice[accessory.id]) {
            const customPriceAccessory = customPrice[accessory.id].getPrice(field);
            if (!isNaN(customPriceAccessory)) {
                price = customPriceAccessory;
            }
        }
        return price;
    }

    /**
     * Przelicza cenę dodatków przez ilość.
     * @param  {number} price     Cena jednostkowa
     * @param  {object} accessory Akcesorium
     * @return {number}           Cena za akcesorium
     */
    priceAccessoryUnit(price, accessory, sides, width, height) {
        switch (~~accessory.accessory.price_type) {
            // szt
            case 0:
                return parseFloat(price) * parseFloat(accessory.count);
            // m2
            case 1:
                return (
                    parseFloat(price) * parseFloat(accessory.count) * parseFloat(accessory.amount)
                );
            // mb
            case 2:
                let amount = accessory.amount;
                if (accessory.accessory.place.indexOf('side') > -1) {
                    switch (accessory.side) {
                        case 'left':
                        case 'right':
                            amount = height;
                            break;
                        case 'top':
                        case 'bottom':
                            amount = width + sides.left + sides.right;
                            break;
                    }
                }
                return (
                    (parseFloat(price) * parseFloat(accessory.count) * parseFloat(amount)) / 1000
                );
            // cena wyliczona indywidualnie w dobrach
            default:
                return parseFloat(price);
        }
    }

    /**
     * Zwraca cenę jednostkową dla dodatku z cenami  per kolor.
     * @param  {object}             accessory   Akcesorium
     * @param  {CustomPriceRecords} customPrice Indywidualne ceny
     * @return {number}                         Cena jednostkowa
     */
    priceAccessoryColor(accessory, customPrice: CustomPricesRecords, listPrice = false) {
        let customPriceAccessory;
        if (Common.isObject(customPrice) && customPrice[accessory.id]) {
            customPriceAccessory = customPrice[accessory.id].getPrice(
                'WindowHandlesColor',
                accessory.selectedColor
            );
        }
        if (!isNaN(parseFloat(customPriceAccessory))) {
            return customPriceAccessory;
        }
        const listPriceEnableInMarket  = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        return parseFloat(getListPrice(accessory, 'colors_prices', listPrice, listPriceEnableInMarket, null, accessory.selectedColor));
    }

    getPriceAccessoryColor(accessory, colorId, listPrice = false) {
        if (accessory && colorId) {
            const listPriceEnableInMarket  = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

            if (listPrice || !listPriceEnableInMarket) {
                return parseFloat(accessory.list_colors_prices[colorId]);
            } else {
                return parseFloat(accessory.colors_prices[colorId]);
            }
        }
    }

    priceAccessoryConfColor(accessory, customPrice: CustomPricesRecords, listPrice = false) {
        if (!accessory.selectedColor) {
            return NaN;
        }
        const price = this.getAccessoryPrice(
            accessory,
            { type: accessory.material },
            accessory.selectedColor,
            customPrice,
            listPrice
        );
        return price;
    }

    getAccessoryPrice(accessory, system, colors: IccProfileSideColors, customPrice: CustomPricesRecords, listPrice = false) {
        const colorPrices = accessory.prices_colors || [];
        let colorGroups = [];
        let colorGroupsOut = [];
        let side = 'double';

        if (colors.inner?.isCore && colors.outer?.isCore) {
            colorGroups = colors.core.colorGroups.map(Number);
        } else if (colors.inner?.groups && colors.outer?.isCore) {
            colorGroups = colors.inner.groups.map(Number);
            side = 'single';
        } else if (colors.outer?.groups && colors.inner?.isCore) {
            colorGroups = colors.outer.groups.map(Number);
            side = 'single';
        } else if (colors.inner?.groups && colors.outer?.groups && colors.outer.id === colors.inner.id) {
            colorGroups = colors.inner.groups.map(Number);
        } else if (colors.inner?.groups && colors.outer?.groups && colors.outer.id !== colors.inner.id) {
            colorGroups = colors.inner.groups.map(Number);
            colorGroupsOut = colors.outer.groups.map(Number);
            side = 'bicolor';
        }

        const accessoryPrice = colorPrices
            .map((p, index) => {
                p.id = index;
                return p;
            })
            .filter(
                p =>
                    p.material === system.type
                    && Common.isArray(colorGroups)
                    && colorGroups.indexOf(Number(p.colorGroup)) > -1
                    && (side !== 'bicolor'
                        || (side === 'bicolor'
                            && Common.isArray(colorGroupsOut)
                            && colorGroupsOut.indexOf(Number(p.colorGroupOut)) > -1))
                    && p.side === side
            )[0];
        if (accessoryPrice) {
            let accessoryPriceCustom;
            if (Common.isObject(customPrice) && customPrice[accessory.id]) {
                accessoryPriceCustom = customPrice[accessory.id].getPrice(
                    'prices_colors',
                    accessoryPrice.id,
                    'price'
                );
            }
            if (accessoryPriceCustom && !isNaN(accessoryPriceCustom)) {
                return accessoryPriceCustom;
            }
            const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;
            return Common.isNumber(
                getListPrice(accessoryPrice, 'price', listPrice, listPriceEnableInMarket)
            )
                ? getListPrice(accessoryPrice, 'price', listPrice, listPriceEnableInMarket)
                : NaN;
        }
        return NaN;
    }

    /**
     * Zwraca ceną jednostkową dla dodatku z cenami za układ kolorów.
     * @param  {object}             accessory   Akcesorium
     * @param  {array}              colors      Kolory konstrukcji
     * @param  {string}             type        Rodzaj konfiguracji
     * @param  {CustomPriceRecords} customPrice Indywidualne ceny
     * @return {number}                         Cena jednostkowa
     */
    priceAccessoryOwn(accessory, colors: IccWindowColors | null = null, type, customPrice: CustomPricesRecords, listPrice = false) {
        const field = this.getAccessoryPriceField(colors, type);
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        let price = parseFloat(getListPrice(accessory, field, listPrice, listPriceEnableInMarket));
        if (Common.isObject(customPrice) && customPrice[accessory.id]) {
            const customPriceAccessory = customPrice[accessory.id].getPrice(field);
            if (!isNaN(customPriceAccessory)) {
                price = customPriceAccessory;
            }
        }
        return price;
    }

    /**
     * Zwraca cenę akcesorium.
     * @param  {object} accessory   Akcesorium
     * @param  {object} colors      Kolory konstrukcji
     * @param  {string} type        Rodzaj konfiguracji
     * @return {number}             Cena za akcesorium
     */
    priceAccessory(
        accessory,
        colors: IccWindowColors | null = null,
        customPrice: CustomPricesRecords,
        sides?,
        width?,
        height?,
        type?,
        multiplier = 1,
        listPrice = false
    ) {
        if (!accessory.accessory.no_price) {
            let price = 0;
            const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

            if (accessory.accessory.show_colors && accessory.accessory.price_source === 'table') {
                price = this.getAccessoryPriceCustom(
                    accessory.accessory,
                    accessory.colorOptions,
                    customPrice
                );
            } else if (accessory.accessory.price_source === 'table') {
                price = this.priceAccessoryOwn(accessory.accessory, colors, type, customPrice, listPrice);
            } else if (accessory.accessory.price_source === 'colors') {
                price = this.priceAccessoryColor(accessory.accessory, customPrice, listPrice);
            } else if (accessory.accessory.price_source === 'confColors') {
                price += this.priceAccessoryConfColor(accessory.accessory, customPrice, listPrice);
            } else {
                price = parseFloat(getListPrice(accessory.accessory, 'price', listPrice, listPriceEnableInMarket));
            }

            if (['window', 'door', 'hs', 'folding_door', 'sliding_door'].includes(type)) {
                price += Number(
                    getListPrice(accessory.accessory, 'price_for_assembly', listPrice, listPriceEnableInMarket)
                );
            }

            return this.priceAccessoryUnit(price, accessory, sides, width, height) * multiplier;
        } else {
            return NaN;
        }
    }

    /**
     * Zwraca cenę jednostkową akcesorium.
     * @param  {object} accessory   Akcesorium
     * @param  {object} colors      Kolory konstrukcji
     * @param  {string} type        Rodzaj konfiguracji
     * @return {number}             Cena za akcesorium
     */
    priceAccessoryOne(
        accessory,
        colors: IccWindowColors | null,
        customPrice: CustomPricesRecords,
        type,
        color,
        multiplier = 1,
        listPrice = false,
    ) {
        if (!accessory.no_price) {
            let price = 0;

            if (accessory.show_colors && accessory.price_source === 'table') {
                price += this.getAccessoryPriceCustom(accessory, color, customPrice);
            } else if (accessory.price_source === 'table') {
                price += this.priceAccessoryOwn(accessory, colors, type, customPrice, listPrice);
            } else if (accessory.price_source === 'confColors') {
                price += this.priceAccessoryConfColor(accessory, customPrice, listPrice);
            } else if (accessory.price_source === 'colors') {
                price += this.priceAccessoryColor(accessory, customPrice, listPrice);
            }

            if (['window', 'door', 'hs', 'folding_door', 'sliding_door'].includes(type)) {
                const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

                price += Number(getListPrice(accessory, 'price_for_assembly', listPrice, listPriceEnableInMarket));
            }

            return price * multiplier;
        } else {
            return NaN;
        }
    }

    /**
     * Dolicza dopłatę za dodatki.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {array}  accessories  Tablica z akcesoriami
     * @param  {object} colors       Kolory konstrukcji
     * @param  {string} type         Rodzaj konfiguracji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'accessories',
        data: {
            accessories: 'conf.Accessories',
            rollerShutterAccessoriesInWindowConfiguration: 'conf.RollerShutter.Accessories',
            colors: 'conf.Colors',
            type: 'conf.type',
            customPrice: 'price.WindowAccessory',
        },
    })
    suppAccessories(
        { PriceElems, NoPriceElems, listPrice }: PriceElemsData,
        {
            accessories,
            colors,
            type,
            customPrice,
            rollerShutterAccessoriesInWindowConfiguration,
        }: { accessories; colors: IccWindowColors; type; customPrice: CustomPricesRecords, rollerShutterAccessoriesInWindowConfiguration }
    ): PriceSegment[] {
        if (rollerShutterAccessoriesInWindowConfiguration) {
            accessories = [...rollerShutterAccessoriesInWindowConfiguration, ...accessories];
        }

        let elemPrice = 0;
        const priceSegments: PriceSegment[] = [];

        for (let i = accessories.length - 1; i >= 0; i--) {
            if (!accessories[i].accessory.no_price) {
                elemPrice = this.priceAccessory(
                    accessories[i],
                    colors,
                    customPrice,
                    null,
                    null,
                    null,
                    type,
                    1,
                    listPrice
                );
                if (!isNaN(elemPrice)) {
                    PriceElems.accessories.push({
                        id: Number(accessories[i].accessory.id),
                        name: accessories[i].accessory.name,
                        count: accessories[i].count,
                        amount: accessories[i].amount,
                        type: accessories[i].accessory.price_type,
                        comment: accessories[i].comment,
                        price: elemPrice,
                        colorField: accessories[i].colorOptions,
                        colorSash:
                            accessories[i].accessory.selectedColor
                            && accessories[i].accessory.selectedColor
                                ? accessories[i].accessory.selectedColor
                                : '',
                        color: accessories[i].accessory.color
                            ? accessories[i].accessory.color.name
                            : '',
                        colorId: accessories[i].accessory.color
                            ? Number(accessories[i].accessory.color.id)
                            : null,
                    });
                    priceSegments.push({
                        type: 'accessories',
                        baseValue: elemPrice,
                        value: elemPrice,
                        valueType: 'value',
                        data: {
                            id: Number(accessories[i].accessory.id),
                            noSystemMultiplier: accessories[i].accessory.no_discount,
                            name: accessories[i].accessory.name,
                            count: accessories[i].count,
                            amount: accessories[i].amount,
                            type: accessories[i].accessory.price_type,
                            comment: accessories[i].comment,
                            colorField: accessories[i].colorOptions,
                            color: accessories[i].accessory.color
                                ? accessories[i].accessory.color.name
                                : '',
                            colorSash:
                                accessories[i].accessory.selectedColor
                                && accessories[i].accessory.selectedColor
                                    ? accessories[i].accessory.selectedColor
                                    : '',
                            categoryId: accessories[i].accessory.window_accessories_category_id,
                            colorId: accessories[i].accessory.color
                                ? Number(accessories[i].accessory.color.id)
                                : null,
                        },
                    });
                    continue;
                }
            }
            NoPriceElems.accessories.push({
                id: Number(accessories[i].accessory.id),
                name: accessories[i].accessory.name,
                count: accessories[i].count,
                amount: accessories[i].amount,
                type: accessories[i].accessory.price_type,
                comment: accessories[i].comment,
                price: NaN,
                colorField: accessories[i].colorOptions,
                color: accessories[i].accessory.color ? accessories[i].accessory.color.name : '',
                colorSash:
                    accessories[i].accessory.selectedColor
                    && accessories[i].accessory.selectedColor
                        ? accessories[i].accessory.selectedColor
                        : '',
                colorId: accessories[i].accessory.color ? Number(accessories[i].accessory.color.id) : null,
                cause: 'no price',
            });
            priceSegments.push({
                type: 'accessories',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {
                    id: Number(accessories[i].accessory.id),
                    noSystemMultiplier: accessories[i].accessory.no_discount,
                    name: accessories[i].accessory.name,
                    count: accessories[i].count,
                    amount: accessories[i].amount,
                    type: accessories[i].accessory.price_type,
                    comment: accessories[i].comment,
                    colorField: accessories[i].colorOptions,
                    color: accessories[i].accessory.color
                        ? accessories[i].accessory.color.name
                        : '',
                    colorSash:
                        accessories[i].accessory.selectedColor
                        && accessories[i].accessory.selectedColor
                            ? accessories[i].accessory.selectedColor
                            : '',
                    categoryId: accessories[i].accessory.window_accessories_category_id,
                    colorId: accessories[i].accessory.color
                        ? Number(accessories[i].accessory.color.id)
                        : null,
                    cause: 'no price',
                },
            });
        }

        return priceSegments;
    }

    /**
     * Dolicza dopłatę za dodatki per kwatera.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {array}  sashes       Skrzydła
     * @param  {object} colors       Kolory konstrukcji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'sashesHardware',
        data: {
            sashes: 'conf.Sashes',
            colors: 'conf.Colors',
            type: 'conf.type',
            customPrice: 'price.WindowAccessory',
        },
    })
    suppSashesHardware(
        { PriceElems, NoPriceElems }: PriceElemsData,
        {
            sashes,
            colors,
            type,
            customPrice,
        }: { sashes; colors: IccWindowColors; type; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        let supp = 0,
            elemPrice = 0;
        let sash: any = {};
        let i = 0,
            j = 0;
        const priceSegments: PriceSegment[] = [];

        for (i = sashes.length - 1; i >= 0; i--) {
            sash = sashes[i];
            for (j = sash.hardware.length - 1; j >= 0; j--) {
                if (!sash.hardware[j].accessory.no_price) {
                    elemPrice = this.priceAccessory(
                        sash.hardware[j],
                        colors,
                        customPrice,
                        null,
                        null,
                        null,
                        type
                    );
                    if (!isNaN(supp)) {
                        PriceElems.sashAccessories.push({
                            id: Number(sash.hardware[j].accessory.id),
                            sashId: sash.id,
                            name: sash.hardware[j].accessory.name,
                            count: sash.hardware[j].count,
                            amount: sash.hardware[j].amount,
                            type: sash.hardware[j].accessory.price_type,
                            comment: sash.hardware[j].comment,
                            price: elemPrice,
                            colorField: sash.hardware[j].colorOptions,
                            color: sash.hardware[j].accessory.color
                                ? sash.hardware[j].accessory.color.name
                                : '',
                            colorSash:
                                sash.hardware[j].accessory.selectedColor
                                && sash.hardware[j].accessory.selectedColor
                                    ? sash.hardware[j].accessory.selectedColor
                                    : '',
                            colorId: sash.hardware[j].accessory.color
                                ? Number(sash.hardware[j].accessory.color.id)
                                : null,
                        });
                        priceSegments.push({
                            type: 'sashesHardware',
                            baseValue: elemPrice,
                            value: elemPrice,
                            valueType: 'value',
                            data: {
                                id: Number(sash.hardware[j].accessory.id),
                                noSystemMultiplier: sash.hardware[j].accessory.no_discount,
                                sashId: sash.id,
                                name: sash.hardware[j].accessory.name,
                                count: sash.hardware[j].count,
                                amount: sash.hardware[j].amount,
                                type: sash.hardware[j].accessory.price_type,
                                comment: sash.hardware[j].comment,
                                colorField: sash.hardware[j].colorOptions,
                                color: sash.hardware[j].accessory.color
                                    ? sash.hardware[j].accessory.color.name
                                    : '',
                                colorSash:
                                    sash.hardware[j].accessory.selectedColor
                                    && sash.hardware[j].accessory.selectedColor
                                        ? sash.hardware[j].accessory.selectedColor
                                        : '',
                                categoryId:
                                    sash.hardware[j].accessory.window_accessories_category_id,
                                colorId: sash.hardware[j].accessory.color
                                    ? Number(sash.hardware[j].accessory.color.id)
                                    : null,
                            },
                        });
                        continue;
                    }
                }
                NoPriceElems.sashAccessories.push({
                    id: Number(sash.hardware[j].accessory.id),
                    sashId: sash.id,
                    name: sash.hardware[j].accessory.name,
                    count: sash.hardware[j].count,
                    amount: sash.hardware[j].amount,
                    type: sash.hardware[j].accessory.price_type,
                    comment: sash.hardware[j].comment,
                    price: NaN,
                    colorField: sash.hardware[j].colorOptions,
                    color: sash.hardware[j].accessory.color
                        ? sash.hardware[j].accessory.color.name
                        : '',
                    colorSash:
                        sash.hardware[j].accessory.selectedColor
                        && sash.hardware[j].accessory.selectedColor
                            ? sash.hardware[j].accessory.selectedColor
                            : '',
                    colorId: sash.hardware[j].accessory.color
                        ? Number(sash.hardware[j].accessory.color.id)
                        : null,
                    cause: 'no price',
                });
                priceSegments.push({
                    type: 'sashesHardware',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {
                        id: Number(sash.hardware[j].accessory.id),
                        noSystemMultiplier: sash.hardware[j].accessory.no_discount,
                        sashId: sash.id,
                        name: sash.hardware[j].accessory.name,
                        count: sash.hardware[j].count,
                        amount: sash.hardware[j].amount,
                        type: sash.hardware[j].accessory.price_type,
                        comment: sash.hardware[j].comment,
                        colorField: sash.hardware[j].colorOptions,
                        color: sash.hardware[j].accessory.color
                            ? sash.hardware[j].accessory.color.name
                            : '',
                        colorSash:
                            sash.hardware[j].accessory.selectedColor
                            && sash.hardware[j].accessory.selectedColor
                                ? sash.hardware[j].accessory.selectedColor
                                : '',
                        categoryId: sash.hardware[j].accessory.window_accessories_category_id,
                        colorId: sash.hardware[j].accessory.color
                            ? Number(sash.hardware[j].accessory.color.id)
                            : null,
                        cause: 'no price',
                    },
                });
            }
        }

        return priceSegments;
    }

    /**
     * Dolicza dopłatę za dodatki boczne.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {array}  sashes       Skrzydła
     * @param  {object} colors       Kolory konstrukcji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'sideAccessories',
        data: {
            sideAccessories: 'conf.SideAccessories',
            colors: 'conf.Colors',
            width: 'conf.Width',
            height: 'conf.Height',
            type: 'conf.type',
            customPrice: 'price.WindowAccessory',
        },
    })
    suppSidesAccessories(
        { PriceElems, NoPriceElems }: PriceElemsData,
        {
            sideAccessories,
            colors,
            width,
            height,
            type,
            customPrice,
        }: { sideAccessories; colors: IccWindowColors; width; height; type; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        let supp = 0,
            elemPrice = 0;
        let side = '',
            accessories = [];
        let i = 0,
            j = 0;
        const sides = ['left', 'right', 'top', 'bottom'];
        const priceSegments: PriceSegment[] = [];

        for (i = sides.length - 1; i >= 0; i--) {
            side = sides[i];
            accessories = sideAccessories[side];
            for (j = accessories.length - 1; j >= 0; j--) {
                if (!accessories[j].accessory.no_price) {
                    elemPrice = this.priceAccessory(
                        accessories[j],
                        colors,
                        customPrice,
                        sideAccessories.sizes,
                        width,
                        height,
                        type
                    );
                    if (!isNaN(supp)) {
                        PriceElems.sideAccessories.push({
                            id: Number(accessories[j].accessory.id),
                            side,
                            name: accessories[j].accessory.name,
                            count: accessories[j].count,
                            amount: accessories[j].amount,
                            type: accessories[j].accessory.price_type,
                            comment: accessories[j].comment,
                            price: elemPrice,
                            colorField: accessories[j].colorOptions,
                            color: accessories[j].accessory.color
                                ? accessories[j].accessory.color.name
                                : '',
                            colorSash:
                                accessories[j].accessory.selectedColor
                                && accessories[j].accessory.selectedColor
                                    ? accessories[j].accessory.selectedColor
                                    : '',
                            colorId: accessories[j].accessory.color
                                ? Number(accessories[j].accessory.color.id)
                                : null,
                        });
                        priceSegments.push({
                            type: 'sideAccessories',
                            baseValue: elemPrice,
                            value: elemPrice,
                            valueType: 'value',
                            data: {
                                id: Number(accessories[j].accessory.id),
                                noSystemMultiplier: accessories[j].accessory.no_discount,
                                side,
                                name: accessories[j].accessory.name,
                                count: accessories[j].count,
                                amount: accessories[j].amount,
                                type: accessories[j].accessory.price_type,
                                comment: accessories[j].comment,
                                colorField: accessories[j].colorOptions,
                                color: accessories[j].accessory.color
                                    ? accessories[j].accessory.color.name
                                    : '',
                                colorSash:
                                    accessories[j].accessory.selectedColor
                                    && accessories[j].accessory.selectedColor
                                        ? accessories[j].accessory.selectedColor
                                        : '',
                                categoryId: accessories[j].accessory.window_accessories_category_id,
                                colorId: accessories[j].accessory.color
                                    ? Number(accessories[j].accessory.color.id)
                                    : null,
                            },
                        });
                        continue;
                    }
                }
                supp = NaN;
                NoPriceElems.sideAccessories.push({
                    id: Number(accessories[j].accessory.id),
                    side,
                    name: accessories[j].accessory.name,
                    count: accessories[j].count,
                    amount: accessories[j].amount,
                    type: accessories[j].accessory.price_type,
                    comment: accessories[j].comment,
                    price: NaN,
                    colorField: accessories[j].colorOptions,
                    color: accessories[j].accessory.color
                        ? accessories[j].accessory.color.name
                        : '',
                    colorSash:
                        accessories[j].accessory.selectedColor
                        && accessories[j].accessory.selectedColor
                            ? accessories[j].accessory.selectedColor
                            : '',
                    cause: 'no price',
                });
                priceSegments.push({
                    type: 'sideAccessories',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {
                        id: Number(accessories[j].accessory.id),
                        noSystemMultiplier: accessories[j].accessory.no_discount,
                        side,
                        name: accessories[j].accessory.name,
                        count: accessories[j].count,
                        amount: accessories[j].amount,
                        type: accessories[j].accessory.price_type,
                        comment: accessories[j].comment,
                        colorField: accessories[j].colorOptions,
                        color: accessories[j].accessory.color
                            ? accessories[j].accessory.color.name
                            : '',
                        colorSash:
                            accessories[j].accessory.selectedColor
                            && accessories[j].accessory.selectedColor
                                ? accessories[j].accessory.selectedColor
                                : '',
                        categoryId: accessories[j].accessory.window_accessories_category_id,
                        colorId: accessories[j].accessory.color
                            ? Number(accessories[j].accessory.color.id)
                            : null,
                        cause: 'no price',
                    },
                });
            }
        }
        return priceSegments;
    }

    /**
     * Dolicza dopłatę za dobra komplementarne.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {object} colors       Kolory konstrukcji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'complementaryGoods',
        data: {
            complementaryGoods: 'conf.ComplementaryGoods',
            customPrice: 'price.WindowAccessory',
        },
    })
    suppComplementaryGoods(
        { PriceElems, NoPriceElems }: PriceElemsData,
        {
            complementaryGoods,
            customPrice,
        }: { complementaryGoods; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        let supp = 0,
            elemPrice = 0;
        let side = '',
            accessories = [];
        let i = 0,
            j = 0;
        const sides = ['windowsill', 'glass', 'accessory', 'profile'];
        const priceSegments: PriceSegment[] = [];

        for (i = sides.length - 1; i >= 0; i--) {
            side = sides[i];
            accessories = complementaryGoods[side];
            for (j = accessories.length - 1; j >= 0; j--) {
                if (!accessories[j].accessory.no_price) {
                    // elemPrice = parseFloat(accessories[j].accessory.price) * accessories[j].count;
                    elemPrice = this.priceAccessory(
                        accessories[j],
                        null,
                        customPrice,
                        null,
                        null,
                        null,
                        'complementary_goods'
                    );
                    if (!isNaN(elemPrice)) {
                        PriceElems.complementaryGoods[side].push({
                            id: Number(accessories[j].accessory.id),
                            good: side,
                            name: accessories[j].accessory.name,
                            count: accessories[j].count,
                            amount: accessories[j].amount,
                            type: accessories[j].accessory.price_type,
                            warmEdge: accessories[j].accessory.warmEdge,
                            comment: accessories[j].comment,
                            price: elemPrice,
                            color: accessories[j].accessory.color
                                ? accessories[j].accessory.color.name
                                : accessories[j].accessory.colorName
                                ? accessories[j].accessory.colorName
                                : '',
                            colorSash:
                                accessories[j].accessory.selectedColor
                                && accessories[j].accessory.selectedColor
                                    ? accessories[j].accessory.selectedColor
                                    : '',
                            colorId: accessories[j].accessory.color
                                ? Number(accessories[j].accessory.color.id)
                                : null,
                            colorField: accessories[j].colorOptions
                                ? accessories[j].colorOptions
                                : null,
                            categoryId: accessories[j].accessory.window_accessories_category_id,
                            currentWidth: accessories[j].accessory.currentWidth,
                            currentHeight: accessories[j].accessory.currentHeight,
                            currentLength: accessories[j].accessory.currentLength,
                            plugs: !!~~accessories[j].accessory.plugs,
                        });
                        priceSegments.push({
                            type: 'complementaryGoods',
                            baseValue: elemPrice,
                            value: elemPrice,
                            valueType: 'value',
                            data: {
                                id: Number(accessories[j].accessory.id),
                                good: side,
                                name: accessories[j].accessory.name,
                                count: accessories[j].count,
                                amount: accessories[j].amount,
                                type: accessories[j].accessory.price_type,
                                warmEdge: accessories[j].accessory.warmEdge,
                                comment: accessories[j].comment,
                                color: accessories[j].accessory.color
                                    ? accessories[j].accessory.color.name
                                    : accessories[j].accessory.colorName
                                    ? accessories[j].accessory.colorName
                                    : '',
                                colorSash:
                                    accessories[j].accessory.selectedColor
                                    && accessories[j].accessory.selectedColor
                                        ? accessories[j].accessory.selectedColor
                                        : '',
                                colorId: accessories[j].accessory.color
                                    ? Number(accessories[j].accessory.color.id)
                                    : null,
                                colorField: accessories[j].colorOptions
                                    ? accessories[j].colorOptions
                                    : null,
                                categoryId: accessories[j].accessory.window_accessories_category_id,
                                currentWidth: accessories[j].accessory.currentWidth,
                                currentHeight: accessories[j].accessory.currentHeight,
                                currentLength: accessories[j].accessory.currentLength,
                                plugs: !!~~accessories[j].accessory.plugs,
                            },
                        });
                        continue;
                    }
                }
                supp = NaN;
                NoPriceElems.complementaryGoods[side].push({
                    id: Number(accessories[j].accessory.id),
                    good: side,
                    name: accessories[j].accessory.name,
                    count: accessories[j].count,
                    amount: accessories[j].amount,
                    type: accessories[j].accessory.price_type,
                    warmEdge: accessories[j].accessory.warmEdge,
                    comment: accessories[j].comment,
                    price: NaN,
                    color: accessories[j].accessory.color
                        ? accessories[j].accessory.color.name
                        : '',
                    colorId: accessories[j].accessory.color
                        ? Number(accessories[j].accessory.color.id)
                        : null,
                    colorField: accessories[j].colorOptions ? accessories[j].colorOptions : null,
                    categoryId: accessories[j].accessory.window_accessories_category_id,
                    cause: 'no price',
                });
                priceSegments.push({
                    type: 'complementaryGoods',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {
                        id: Number(accessories[j].accessory.id),
                        good: side,
                        name: accessories[j].accessory.name,
                        count: accessories[j].count,
                        amount: accessories[j].amount,
                        type: accessories[j].accessory.price_type,
                        warmEdge: accessories[j].accessory.warmEdge,
                        comment: accessories[j].comment,
                        color: accessories[j].accessory.color
                            ? accessories[j].accessory.color.name
                            : '',
                        colorId: accessories[j].accessory.color
                            ? Number(accessories[j].accessory.color.id)
                            : null,
                        colorField: accessories[j].colorOptions
                            ? accessories[j].colorOptions
                            : null,
                        categoryId: accessories[j].accessory.window_accessories_category_id,
                    },
                });
            }
        }

        return priceSegments;
    }

    /**
     * Dolicza dopłatę za dobra komplementarne.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {object} colors       Kolory konstrukcji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'sills',
        data: {
            windowSills: 'conf.windowSills',
            customPrice: 'price.WindowAccessory',
        },
    })
    suppWindowSills(
        {  }: PriceElemsData,
        { windowSills }: { windowSills: Sill[] }
    ): PriceSegment[] {
        let elemPrice = 0;
        const side = '';
        let j = 0;
        const priceSegments: PriceSegment[] = [];

        for (j = windowSills.length - 1; j >= 0; j--) {
            elemPrice = Number(windowSills[j].price);
            if (!isNaN(elemPrice)) {
                priceSegments.push({
                    type: 'complementaryGoods',
                    baseValue: elemPrice,
                    value: elemPrice,
                    valueType: 'value',
                    data: {
                        id: Number(windowSills[j].id),
                        good: 'windowsill',
                        name: windowSills[j].name,
                        count: windowSills[j].count,
                        amount: windowSills[j].length,
                        comment: windowSills[j].comment,
                        color: windowSills[j].color ? windowSills[j].color.name : '',
                        colorId: windowSills[j].color ? Number(windowSills[j].color.id) : null,
                        currentWidth: windowSills[j].width,
                        currentLength: windowSills[j].length,
                        plugs: !!~~windowSills[j].plugs,
                    },
                });
                continue;
            }

            priceSegments.push({
                type: 'complementaryGoods',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {
                    id: Number(windowSills[j].id),
                    good: 'windowsill',
                    name: windowSills[j].name,
                    count: windowSills[j].count,
                    amount: windowSills[j].length,
                    comment: windowSills[j].comment,
                    color: windowSills[j].color ? windowSills[j].color.name : '',
                    colorId: windowSills[j].color ? Number(windowSills[j].color.id) : null,
                    currentWidth: windowSills[j].width,
                    currentLength: windowSills[j].length,
                    plugs: !!~~windowSills[j].plugs,
                },
            });
        }

        return priceSegments;
    }

    getPriceAccessoryOne(accessory: IccAccessoryAccessory, color: any, conf: WindowActiveConfiguration | AccessoriesActiveConfiguration | ComplementaryGoodsActiveConfiguration, listPrice = false) {
        const offer = this.stateService.getCurrentOffer();
        const dealerId = offer ? offer.dealer_id : null;
        const customPrice = this.customPricesService.get(dealerId);
        let multipliers = offer
            ? offer.multipliers
            : this.config().multipliers
            ? this.config().multipliers
            : this.discountsAndMultipliersService.getMultipliers();
        let multiplier = 1;
        multipliers = multipliers && multipliers[dealerId] || multipliers;
        if (multipliers) {
            multiplier = multipliers.Supplement && multipliers.Supplement[0] || 1;
            if (conf.type === 'accessory'
                || conf.type === 'complementary_goods'
            ) {
                multiplier = multipliers.Commodity && multipliers.Commodity[0] || 1;
            }
        }
        return this.priceBaseService.addMarginMarketFactor(this.priceAccessoryOne(
            accessory,
            conf.type !== 'accessory' && conf.type !== 'complementary_goods' && conf.Colors || null,
            (customPrice || {}).WindowAccessory,
            conf.type, color, multiplier, listPrice
        ));
    }
}
