import * as angular from 'angular';

import Alternatives from './alternatives/alternatives';
import DrawModule from './draw/draw.module.ajs';
import LayoutModule from './layout/layout.module.ajs';
import StepsModule from './steps/steps.module.ajs';
import ColorsModule from './colors/colors.module.ajs';

import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { ConfiguratorsAvailabilityService } from './configurators-availability.service';
import ConfiguratorCtrl from './ConfiguratorCtrl';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { LegacyCurrentConfiguratorService } from './current-configurator.service';
import { LegacyInfoService } from './info.service';
import ModalConfirmCtrl from './ModalConfirmCtrl';
import ModalInfoCtrl from './ModalInfoCtrl';
import { ModalParametersCtrl } from './ModalParametersCtrl';
import ModalWarningCtrl from './ModalWarningCtrl';
import OrdersPositionsElementsModalCtrl from './OrdersPositionsElementsModalCtrl';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { ParametersModalService } from './parameters-modal.service';
import PopularLayoutsFactory from './PopularLayoutsFactory';
import ProductMistakesFactory from './ProductMistakesFactory';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DrawService } from '@icc/common/configurators/draw.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { IssuesService } from '@icc/helpers';
import { ValidationService } from '@icc/common/configurators/validation.service';
import CoupledWindowModule from './coupled/coupled-window.module.ajs';
import { SizeRangeService } from '@icc/common/size-range.service';

export default angular
    .module('icc.configurators', [
        Alternatives,
        DrawModule,
        LayoutModule,
        StepsModule,
        ColorsModule,
        CoupledWindowModule,
    ])
    .controller('ConfiguratorCtrl', ConfiguratorCtrl)
    .controller('ModalConfirmCtrl', ModalConfirmCtrl)
    .controller('ModalInfoCtrl', ModalInfoCtrl)
    .controller('ModalParametersCtrl', ModalParametersCtrl)
    .controller('ModalWarningCtrl', ModalWarningCtrl)
    .controller('OrdersPositionsElementsModalCtrl', OrdersPositionsElementsModalCtrl)
    .service('InfoService', LegacyInfoService)
    .factory('PopularLayoutsFactory', PopularLayoutsFactory)
    .factory('ProductMistakesFactory', ProductMistakesFactory)
    .factory('ParametersService', downgradeInjectable(ParametersService))
    .factory('DrawService', downgradeInjectable(DrawService))
    .factory('ConfiguratorsDataService', downgradeInjectable(ConfiguratorsDataService))
    .factory('ConfigurationsService', downgradeInjectable(ConfigurationsService))
    .factory(
        'ConfiguratorsAvailabilityService',
        downgradeInjectable(ConfiguratorsAvailabilityService)
    )
    .factory('EventBusService', downgradeInjectable(EventBusService))
    .factory('IssuesService', downgradeInjectable(IssuesService))
    .factory('ValidationService', downgradeInjectable(ValidationService))
    .factory('SizeRangeService', downgradeInjectable(SizeRangeService))
    .service('CurConfService', LegacyCurrentConfiguratorService)
    .service('ParametersModalService', ParametersModalService).name;
