import { Injectable, Inject } from '@angular/core';
import { Profile } from '@icc/window';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ProfilesService } from '@icc/common/profiles.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { logger, core } from '@icc/common/helpers';
import { IssuesService, IssueLevel, ModalService } from '@icc/helpers';
import { ValidationService, ValidationElement } from '@icc/common/configurators/validation.service';
import { GlazingBeadsService } from '@icc/common/glazing-beads.service';
import { FramesService } from '@icc/common/layout/frames.service';
import { GlazingBeadPageComponent } from 'libs/configurator/window/src/lib/glazing-bead-page/glazing-bead-page.component';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { TranslateService } from '@icc/common/translate.service';
import { IccFilling } from '@icc/common/data-types';

@Injectable()
export class BrowserGlazingBeadsService extends GlazingBeadsService {
    constructor(
        protected eventBusService: EventBusService,
        protected profilesService: ProfilesService,
        private modalService: ModalService,
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        private translateService: TranslateService
    ) {
        super(profilesService, eventBusService, config);
        this.eventBusService.subscribe('initializedConfigurator', data => {
            try {
                this.loadGlazingBeads(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.loadGlazingBeadMatches(data.value);
            }
        );

        this.eventBusService.subscribe('setSystem', data => {
            try {
                this.loadGlazingBeads(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('changedConfigurator', data => {
            try {
                if (data.activeConfiguration) {
                    this.loadGlazingBeads(data.activeConfiguration as WindowActiveConfiguration);
                }
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('loadedProfiles', data => {
            try {
                this.loadGlazingBeads(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setSealColor', data => {
            try {
                this.loadGlazingBeads(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('validatedFillings', data => {
            try {
                this.validateGlazingBeadsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('loadedGlazingBeads', data => {
            try {
                this.validateGlazingBeadsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setGlazingBeadInSash', data => {
            try {
                this.checkSingleGlazingBeads(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.checkSingleGlazingBeadsShape(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.validateGlazingBeads(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setProfileSet', data => {
            try {
                this.setDefaultsFromSet(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });
    }

    setGlazingBeadInAllSashes(
        conf: WindowActiveConfiguration,
        glazingBead: Profile,
        isDefault = false
    ) {
        const pauseId = this.eventBusService.pause(['setGlazingBeadInSash']);
        try {
            conf.Sashes.forEach(sash => {
                this.setGlazingBeadInSash(sash, glazingBead, isDefault, conf);
                sash.intSashes.forEach(intSash => {
                    this.setGlazingBeadInSash(intSash, glazingBead, isDefault, conf);
                });
            });
        } finally {
            this.eventBusService.resume(['setGlazingBeadInSash'], pauseId);
        }
    }

    setGlazingBeadInFilteredSashes(
        conf: WindowActiveConfiguration,
        glazingBead: Profile,
        isDefault = false,
        filter: (sash) => boolean = () => true
    ) {
        const pauseId = this.eventBusService.pause(['setGlazingBeadInSash']);
        conf.Sashes.filter(filter).forEach(sash => {
            this.setGlazingBeadInSash(sash, glazingBead, isDefault);
            sash.intSashes.forEach(intSash => {
                this.setGlazingBeadInSash(intSash, glazingBead, isDefault);
            });
        });
        this.eventBusService.resume(['setGlazingBeadInSash'], pauseId);
    }

    setDefaultGlazingBeadInSashFromSet(
        field,
        sash,
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration
    ) {
        const defaultGlazingBead = this.getDefaultGlazingBeadInSash(field, sash, conf);
        this.setGlazingBeadInSash(field, defaultGlazingBead, true, conf, defaultConf);
    }

    loadGlazingBeads(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'loadedGlazingBeads');
        const requiredElements: ValidationElement[] = ['system', 'loadedProfiles'];
        if (this.config().IccConfig.Configurators.glazingBeadSealColor) {
            requiredElements.push('sealColor');
        }
        if (this.validationService.isValidElements(conf, requiredElements)) {
            this.glazingBeads = this.profilesService
                .getFilteredProfiles(conf, 'glazing_bead')
                .filter(
                    profile =>
                        (this.config().IccConfig.Configurators.glazingBeadSealColor
                            && profile.windowSealColorId === Number(conf.SealColor.id))
                        || !this.config().IccConfig.Configurators.glazingBeadSealColor
                );
            if (this.glazingBeads.length === 0) {
                this.validationService.invalid(conf, 'loadedGlazingBeads');
                this.issuesService.registerDataProblem(
                    'no-glazing-beads',
                    'Nie ma listew przyszybowych',
                    conf,
                    {
                        level: IssueLevel.FATAL,
                        extra: {
                            sealColorId: conf.SealColor && Number(conf.SealColor.id),
                            systemId: conf.System && conf.System.id,
                        },
                    }
                );
            } else {
                Object.assign(conf, this.validationService.valid(conf, 'loadedGlazingBeads'));
                this.issuesService.unregister('no-glazing-beads', conf);
                this.eventBusService.post({
                    key: 'loadedGlazingBeads',
                    value: this.glazingBeads,
                    conf,
                });
            }
        }
    }

    loadGlazingBeadMatches(data) {
        this.glazingBeadMatches = core.copy(data.glazingBeadMatches);
    }

    validateGlazingBeadsAndFixIssues(conf: WindowActiveConfiguration, defaultConf) {
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'fillings',
                'loadedGlazingBeads',
            ])
        ) {
            const pauseId = this.eventBusService.pause(['setGlazingBeadInSash']);
            try {
                conf.Sashes.forEach(sash => {
                    if (!this.validGlazingBeadInSash(sash, sash, conf)) {
                        this.setDefaultGlazingBeadInSash(sash, sash, conf, defaultConf);
                    }
                    sash.intSashes.forEach(intSash => {
                        if (!this.validGlazingBeadInSash(intSash, sash, conf)) {
                            this.setDefaultGlazingBeadInSash(intSash, sash, conf, defaultConf);
                        }
                    });
                });
            } finally {
                this.eventBusService.resume(['setGlazingBeadInSash'], pauseId);
            }
        }
    }

    validateGlazingBeads(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'glazingBeads');
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'fillings',
                'loadedGlazingBeads',
            ])
        ) {
            let valid = true;
            valid = conf.Sashes.every(
                sash =>
                    this.validGlazingBeadInSash(sash, sash, conf)
                    && sash.intSashes.every(intSash =>
                        this.validGlazingBeadInSash(intSash, sash, conf)
                    )
            );
            if (!valid) {
                this.validationService.invalid(conf, 'glazingBeads');
                this.issuesService.simpleRegister(
                    'invalid-glazing-beads',
                    'Niepoprawne listwy przyszybowe',
                    this.translateService.instant('WINDOW|Niepoprawne listwy przyszybowe'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.INFO,
                        blockStepsAfter: 'glazing',
                    }
                );
            } else {
                Object.assign(conf, this.validationService.valid(conf, 'glazingBeads'));
                this.issuesService.unregister('invalid-glazing-beads', conf);
            }
        }
    }

    validGlazingBeadInSash(field: ActiveSash, sash: ActiveSash, conf: WindowActiveConfiguration) {
        let sashProfile;
        if (sash.type.type !== 'F') {
            sashProfile = this.profilesService.getProfile(sash.frame.bottom.profileId);
        } else {
            const frame = FramesService.getFrameProfilesForSash(sash, conf);
            sashProfile = this.profilesService.getProfile((frame[1] || frame[0]).profileId);
        }
        const fieldFillingThickness = Number(field.glazing.thickness_mm);
        const fieldFillingType = field.glazing.type;
        if (
            (fieldFillingType !== 'glazing'
                && (sash.panelType === 'Double'
                    || sash.panelType === 'Inner'
                    || sash.panelType === 'Mixed'))
            || fieldFillingType === 'door_panels'
        ) {
            return !field.glazingBead || !field.glazingBead.profileId;
        }
        if (!field.glazingBead || !field.glazingBead.profileId) {
            return false;
        }
        if (
            this.glazingBeads.filter(bead => bead.id === field.glazingBead.profileId).length === 0
        ) {
            return false;
        }

        const glazingBead = this.profilesService.getProfile(field.glazingBead.profileId);

        let minBeadDepth = Number((sashProfile.glazingRebate
            - sashProfile.repairGaskets
            - fieldFillingThickness
            - glazingBead.decreaseGasketSqueeze).toFixed(2));

        let maxBeadDepth = Number((sashProfile.glazingRebate
            - sashProfile.repairGaskets
            - fieldFillingThickness
            + glazingBead.increaseGasketSqueeze).toFixed(2));

        if (sash.panelType === 'Outer') {
            minBeadDepth = Number((sashProfile.depthToGlazing
                + sashProfile.glazingRebate
                - fieldFillingThickness
                - glazingBead.decreaseGasketSqueeze).toFixed(2));
            maxBeadDepth = Number((sashProfile.depthToGlazing
                + sashProfile.glazingRebate
                - fieldFillingThickness
                + glazingBead.increaseGasketSqueeze).toFixed(2));
        }

        return (
            ((this.config().IccConfig.Configurators.glazingBeadsByMatches
                && glazingBead.matchingThicknesses.indexOf(fieldFillingThickness) !== -1)
                || (glazingBead.width >= sashProfile.glazingBeadMinHeight
                    && glazingBead.width <= sashProfile.glazingBeadMaxHeight
                    && minBeadDepth <= glazingBead.depth
                    && maxBeadDepth >= glazingBead.depth))
            && glazingBead.systems.indexOf(Number(conf.System.id)) > -1
        );
    }

    setDefaultsFromSet(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        const pauseId = this.eventBusService.pause(['setGlazingBeadInSash']);
        try {
            conf.Sashes.forEach(sash => {
                this.setDefaultGlazingBeadInSashFromSet(sash, sash, conf, defaultConf);
                sash.intSashes.forEach(intSash => {
                    this.setDefaultGlazingBeadInSashFromSet(intSash, sash, conf, defaultConf);
                });
            });
        } finally {
            this.eventBusService.resume(['setGlazingBeadInSash'], pauseId);
        }
    }

    checkSingleGlazingBeadsShape(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration
    ) {
        let isSingleGlazingBeadShape = true;
        let firstGlazingBeadShape = null;

        isSingleGlazingBeadShape = conf.Sashes.every((sash, i) => {
            let isSingleGlazingBeadShapeInSash = true;
            let firstGlazingBeadShapeInSash = this.getGlazingBeadShape(sash.glazingBead.profileId);

            isSingleGlazingBeadShapeInSash = sash.intSashes.every((intSash, j) => {
                if (j === 0) {
                    firstGlazingBeadShapeInSash = this.getGlazingBeadShape(
                        intSash.glazingBead.profileId
                    );
                }
                return (
                    firstGlazingBeadShapeInSash
                    === this.getGlazingBeadShape(intSash.glazingBead.profileId)
                );
            });

            if (i === 0) {
                firstGlazingBeadShape = firstGlazingBeadShapeInSash;
            }

            return (
                isSingleGlazingBeadShapeInSash
                && firstGlazingBeadShape === firstGlazingBeadShapeInSash
            );
        });

        if (isSingleGlazingBeadShape) {
            conf.GlazingBeadType = firstGlazingBeadShape;
            defaultConf.GlazingBeadType = firstGlazingBeadShape;
            if (
                this.config().IccConfig.Configurators.profileSetSelect
                && conf.ProfileSet.glazingBeadShape !== firstGlazingBeadShape
            ) {
                conf.ProfileSet.glazingBeadShape = firstGlazingBeadShape;
                conf.ProfileSet.id = null;
                conf.ProfileSet.isDefault = false;
                defaultConf.ProfileSet.glazingBeadShape = firstGlazingBeadShape;
                defaultConf.ProfileSet.id = null;
                defaultConf.ProfileSet.isDefault = false;
            }
        } else {
            conf.GlazingBeadType = false;
            defaultConf.GlazingBeadType = false;
        }
    }

    /**
     * Funkcja okna modal listwy szyby
     * @param  {object} sash Skrzydło
     */
    openModalGlazingBead(
        conf: WindowActiveConfiguration,
        field?: ActiveSash | 'fix' | 'sashes',
        sash?
    ) {
        let glazingBeads = this.getMatchingGlazingBeads(conf);
        let selectedGlazingBead;
        let sashFilter: (sash: any) => boolean = sash => true;
        if (field === 'fix') {
            sashFilter = s => s.type.type === 'F';
            const fix = conf.Sashes.find(sashFilter);
            if (fix) {
                glazingBeads = this.getMatchingGlazingBeadsInSash(fix, fix, conf);
            }
            selectedGlazingBead = {
                profileId: conf.OneGlazingBeadSash.fix,
            };
        } else if (field === 'sashes') {
            sashFilter = s => s.type.type !== 'F';
            sash = conf.Sashes.find(sashFilter);
            if (sash) {
                const fieldSash = sash.intSashes[0];
                glazingBeads = this.getMatchingGlazingBeadsInSash(fieldSash, sash, conf);
            }
            selectedGlazingBead = {
                profileId: conf.OneGlazingBeadSash.sashes,
            };
        } else if (field) {
            selectedGlazingBead = field.glazingBead;
            if (sash) {
                glazingBeads = this.getMatchingGlazingBeadsInSash(field, sash, conf);
            }
        } else {
            selectedGlazingBead = conf.Sashes[0].glazingBead;
        }

        const modalInstance = this.modalService.open({
            templateUrl: 'modalGlazingBead.html',
            controller: 'ModalGlazingBeadCtrl as mgb',
            pageComponent: GlazingBeadPageComponent,
            resolve: {
                beads: () => glazingBeads,
                selectBead: () => selectedGlazingBead,
            },
        });

        modalInstance.result.then(selectedData => {
            if (selectedData) {
                if (field === 'fix' || field === 'sashes') {
                    this.setGlazingBeadInFilteredSashes(
                        conf,
                        selectedData.glazingBead,
                        undefined,
                        sashFilter
                    );
                } else if (field) {
                    this.setGlazingBeadInSash(field, selectedData.glazingBead, false, conf);
                } else {
                    this.setGlazingBeadInAllSashes(conf, selectedData.glazingBead);
                }
            }
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });

        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'glazingBeads',
            });
        }
    }

    getMatchingFillingThickness(conf: WindowActiveConfiguration) {
        let matchingFillingThickness = null;
        conf.Sashes.forEach(sash => {
            sash.intSashes.forEach(intSash => {
                matchingFillingThickness = this.getCommonGlazingBeadsInSash(
                    matchingFillingThickness,
                    intSash,
                    sash,
                    conf
                );
            });
            if (sash.intSashes.length === 0) {
                matchingFillingThickness = this.getCommonGlazingBeadsInSash(
                    matchingFillingThickness,
                    sash,
                    sash,
                    conf
                );
            }
        });
        return matchingFillingThickness;
    }

    getMatchingFillingThicknessInSash(field, sash, conf: WindowActiveConfiguration, panelType: IccFilling['panel_type'] = 'panel_type_inset') {
        let allRanges: {
            min: number,
            max: number
        }[] = [];
        if (this.config().IccConfig.Configurators.glazingBeadsByMatches) {
            allRanges = this.getFillingThicknessRangesBySystemMatches(conf.System.id);
        } else {
            allRanges = this.getFillingThicknessRangesByGlazingRebate(sash, conf, panelType);
        }

        const normalizedRanges = this.normalizeRanges(allRanges);
        return normalizedRanges;
    }

    getFillingThicknessRangesBySystemMatches(currentSystemId) {
        const allRanges: {
            min: number,
            max: number
        }[] = [];
        this.glazingBeadMatches.forEach(m => {
            if (m.systems.includes(Number(currentSystemId))) {
                for (const [key, value] of Object.entries(m.thicknesses)) {
                    allRanges.push({ min: Number(value), max: Number(value) });
                }
            }
        });
        return allRanges;
    }

    getFillingThicknessRangesByGlazingRebate(sash, conf, panelType: IccFilling['panel_type'] = 'panel_type_inset') {
        const matchedBeads = core.copy(this.glazingBeads);
        let sashProfile: Profile;
        if (sash.type.type !== 'F') {
            sashProfile = this.profilesService.getProfile(sash.frame.bottom.profileId);
        } else {
            const frame = FramesService.getFrameProfilesForSash(sash, conf);
            sashProfile = this.profilesService.getProfile((frame[1] || frame[0]).profileId);
        }
        const [allRanges] = matchedBeads.reduce<[{
            min: number,
            max: number
        }[], string[]]>(([ranges, indices], cur) => {
            const minDepthBead = Number(cur.depth) - Number(cur.increaseGasketSqueeze);
            const maxDepthBead = Number(cur.depth) + Number(cur.decreaseGasketSqueeze);
            let min =
                Number(sashProfile.glazingRebate)
                - maxDepthBead
                - Number(sashProfile.repairGaskets);
            let max =
                Number(sashProfile.glazingRebate)
                - minDepthBead
                - Number(sashProfile.repairGaskets);

            if (panelType === 'panel_type_double') {
                min = Number(sashProfile.depth);
                max = Number(sashProfile.depth);
            } else if (panelType === 'panel_type_outer') {
                min = Number(sashProfile.depthToGlazing) + Number(sashProfile.glazingRebate) - maxDepthBead;
                max = Number(sashProfile.depthToGlazing) + Number(sashProfile.glazingRebate) - minDepthBead;
            } else if (panelType === 'panel_type_inner') {
                min = Number(sashProfile.depth) - Number(sashProfile.repairGaskets) - Number(sashProfile.depthToGlazing);
                max = Number(sashProfile.depth) - Number(sashProfile.repairGaskets) - Number(sashProfile.depthToGlazing);
            }

            if (indices.indexOf(`${min}_${max}`) === -1) {
                indices.push(`${min}_${max}`);
                ranges.push({
                    min,
                    max,
                });
            }
            return [ranges, indices];
        }, [[], []]);

        return allRanges;
    }

    private normalizeRanges(ranges: {
        min: number,
        max: number
    }[]) {
        const newRanges = ranges.slice(0);
        newRanges.sort((a, b) => a.min - b.min);
        let oldLength;
        do {
            oldLength = newRanges.length;
            for (let i = newRanges.length - 1; i >= 0; i--) {
                if (i > 0 && newRanges[i - 1].max >= newRanges[i].min) {
                    newRanges.splice(i - 1, 2, this.mergeRanges(newRanges[i - 1], newRanges[i]));
                }
            }
        } while (oldLength !== newRanges.length);
        return newRanges;
    }

    private mergeRanges(range1, range2) {
        return {
            min: range1.min,
            max: range2.max > range1.max ? range2.max : range1.max,
        };
    }

    private getMatchingGlazingBeads(conf: WindowActiveConfiguration) {
        let matchingGlazingBeads = [];
        conf.Sashes.forEach(sash => {
            sash.intSashes.forEach(intSash => {
                matchingGlazingBeads = this.getCommonGlazingBeadsInSash(
                    matchingGlazingBeads,
                    intSash,
                    sash,
                    conf
                );
            });
            if (sash.intSashes.length === 0) {
                matchingGlazingBeads = this.getCommonGlazingBeadsInSash(
                    matchingGlazingBeads,
                    sash,
                    sash,
                    conf
                );
            }
        });
        return matchingGlazingBeads;
    }

    private getCommonGlazingBeadsInSash(
        matchingGlazingBeads,
        field,
        sash,
        conf: WindowActiveConfiguration
    ) {
        if (matchingGlazingBeads.length === 0) {
            matchingGlazingBeads = this.getMatchingGlazingBeadsInSash(field, sash, conf);
        } else {
            matchingGlazingBeads = this.getCommonGlazingBeads(
                matchingGlazingBeads,
                this.getMatchingGlazingBeadsInSash(field, sash, conf)
            );
        }
        return matchingGlazingBeads;
    }

    private getCommonFillingThicknessInSash(
        matchingFillingThickness,
        field,
        sash,
        conf: WindowActiveConfiguration
    ) {
        if (!matchingFillingThickness) {
            matchingFillingThickness = this.getMatchingFillingThicknessInSash(field, sash, conf);
        } else {
            matchingFillingThickness = this.getCommonFillingThickness(
                matchingFillingThickness,
                this.getMatchingFillingThicknessInSash(field, sash, conf)
            );
        }
        return matchingFillingThickness;
    }

    private getCommonGlazingBeads(list1: Profile[], list2: Profile[]) {
        return list1.filter(function(a) {
            return this.has(a.id);
        }, list2.reduce((hash, b) => hash.add(b.id), new Set()));
    }

    private getCommonFillingThickness(range1, range2) {
        const mergedRanges = [...range1, ...range2];
        return this.normalizeRanges(mergedRanges);
    }

    private getGlazingBeadShape(profileId: number) {
        const profile = this.profilesService.getProfile(profileId);
        if (profile) {
            return profile.profileShapeId;
        }
        return null;
    }
}
