import { IccProfileSideColors } from '@icc/common/data-types';
import { SideColorsLegacy, Wood } from '@icc/common/types';
import { Reinforcement } from '@icc/window';

/** Profile montowane z boku ramy. */
export class SideProfileActive {
    /** Id */
    id = 0;
    profileCategoryId?: number | undefined;
    name? = '';
    code? = '';
    /** Ilość sztuk */
    count?: number;
    /** Id profilu */
    profileId = 0;
    /** Id profilu */
    reinforcement: Reinforcement | null = null;
    /** Przesunięcie początku względem punktu. */
    shift = 0;
    /**
     * Punkt zaczepu początku.
     *
     * `{frame: 1}` - id ramy, od której górnej lub lewej krawędzi jest liczone przsunięcie
     * `{sideProfile: 1}` - id profilu bocznego, od którego początku jest liczone przesunięcie
     * `null` - początek znajdujący się na górnej lub lewej krawędzi konstrukcji
     */
    shiftPin: { frame: number } | { sideProfile: number } | null = null;
    /** Długość */
    length = 0;
    /** Czy długość jest domyślna */
    isDefaultLength?: boolean = false;
    /**
     * Punkt zaczepu końca.
     *
     * `{frame: 1}` - id ramy, od której dolnej lub prawej krawędzi jest liczone przsunięcie
     * `{sideProfile: 1}` - id profilu bocznego, od którego końca jest liczone przesunięcie
     * `null` - początek znajdujący się na dolnej lub prawej krawędzi konstrukcji
     */
    lengthPin: { frame: number } | { sideProfile: number } | null = null;
    /** Szerokość wewnętrzna */
    width = 0;
    /** Szerokość zewnętrzna */
    widthOut = 0;
    /** Kolorystyka */
    color: IccProfileSideColors | null = null;
    /** Drewno */
    wood?: Wood | null = null;
    /** Komentarz */
    comment = '';
    /** Identyfikatory sąsiadujących profili, do których jest przykręcony boczny profil */
    adjacentSideProfileId?: number[] = [];
    /** Identyfikatory sąsiadujących profili, które są przykręcone do tego profilu */
    adjacentOtherSideProfileId?: number[] = [];
    /** Identyfikatory sąsiadujących łączników, do których jest przykręcony boczny profil */
    adjacentCouplingId?: number[] = [];
    /** Identyfikatory sąsiadujących łączników, które są przykręcone do tego profilu */
    adjacentOtherCouplingId?: number[] = [];
    /**
     * Identyfikatory sąsiadujących ram oraz indeksy krawędzi ram,
     * do których jest przykręcony boczny profil
     */
    framesId: {
        id: number;
        edges: number[];
        positionId?: string;
    }[] = [];

    side: 'left' | 'right' | 'top' | 'bottom' | 'left-top' | 'right-top' = 'bottom';

    totalQuantity? = 0;
    pricePiece?: number | undefined;
    listPricePiece?: number | undefined;

    constructor(params?: {
        id: number;
        profileId: number;
        profileCategoryId?: number;
        name?: string;
        code?: string;
        pricePiece?: number;
        listPricePiece?: number;
        length: number;
        width: number;
        widthOut: number;
        color: IccProfileSideColors;
        count?: number;
        wood: Wood;
        reinforcement: Reinforcement | null;
        adjacentSideProfileId: number[];
        adjacentCouplingId?: number[];
        framesId: {
            id: number;
            edges: number[];
            positionId?: string;
        }[];
        side: 'left' | 'right' | 'top' | 'bottom' | 'left-top' | 'right-top';
        totalQuantity?: number;
        shift?: number;
    }) {
        if (params) {
            this.id = params.id;
            this.profileId = params.profileId;
            this.profileCategoryId = params.profileCategoryId;
            this.name = params.name || '';
            this.code = params.code || '';
            this.pricePiece = params.pricePiece || undefined;
            this.listPricePiece = params.listPricePiece || undefined;
            this.length = params.length;
            this.width = params.width;
            this.widthOut = params.widthOut;
            this.color = params.color;
            this.count = params.count;
            this.wood = params.wood;
            this.reinforcement = params.reinforcement;
            this.adjacentSideProfileId = params.adjacentSideProfileId;
            this.adjacentCouplingId = params.adjacentCouplingId || [];
            this.framesId = params.framesId;
            this.side = params.side;
            this.totalQuantity = params.totalQuantity || 1;
            this.shift = params.shift || 0;
        }
    }
}
