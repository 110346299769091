import {
    core,
    TranslateService,
    Common,
    ParametersService,
    EventBusService,
    ProfilesService,
    WindowActiveConfiguration,
    UserService,
    AppConfigFactory,
    APP_CONFIG,
    StateService,
} from '@icc/common';
import {
    InfoService,
    ModalService,
    StepsService,
    dimToMilimeters,
    isUndefined,
    isArray,
    IssuesService,
    IssueLevel,
} from '@icc/helpers';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService, DiscountsAndMultipliersService, PriceBaseService } from '@icc/price/b2b';
import { PriceAccessoryService } from 'libs/price/src/lib/price-accessory.service';
import { IccAccessoryAccessory, IccAccessory, IccAccessoryColor, HardwareVisualization } from '@icc/common/data-types';
import { Inject, Injectable } from '@angular/core';
import { AccessoriesListPageComponent } from 'libs/configurator/accessory/src/lib/accessories-list-page/accessories-list-page.component';
import { AccessoriesColorsPageComponent } from 'libs/configurator/accessory/src/lib/accessories-colors-page/accessories-colors-page.component';
import { CustomPricesService } from '@icc/common/custom-price/custom-prices.service';
import { AccessoriesActiveConfiguration } from '@icc/common/configurations/AccessoriesActiveConfiguration';
import { RollerShutterActiveConfiguration } from '@icc/common/configurations/RollerShutterActiveConfiguration';
import { TimeLimitService } from '@icc/common/time-limit/time-limit.service';
import { Frame, Profile } from '@icc/window';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { MosquitoActiveConfiguration } from '@icc/common/configurations/MosquitoActiveConfiguration';
import { AccessoriesSpaceService } from './accessories-space.service';
import { NewColorsService } from 'libs/configurator/window/src/lib/colors/new-colors.service';
import { WoodService } from 'libs/configurator/window/src/lib/construction/wood.service';
import { ActiveConfiguration } from '@icc/common/configurations/ActiveConfiguration';

@Injectable()
export class AccessoriesService {
    private configurators = [
        'window',
        'hs',
        'door',
        'folding_door',
        'sliding_door',
        'accessory',
        'garage_door',
        'roller_shutter',
        'external_blind',
        'mosquito',
    ];
    private allAccessories: IccAccessoryAccessory[] = [];
    private accessories = [];
    private categories: any[] = [];
    private subcategories: any[] = [];
    private sashHardwares = [];
    private priceProp = 'price_white';
    private dowelHoleParams = {
        between: 700,
        fromEdge: 200,
    };

    colorsAll: IccAccessoryColor[] = [];
    loadedData = false;
    private dependencies = [];

    constructor(
        private infoService: InfoService,
        private modalService: ModalService,
        private configurationsService: ConfigurationsService<
            | 'window'
            | 'roller_shutter'
            | 'external_blind'
            | 'garage_door'
            | 'door'
            | 'accessory'
            | 'mosquito'
        >,
        private currentConfiguratorService: CurrentConfiguratorService,
        private configuratorsDataService: ConfiguratorsDataService,
        private priceService: PriceService,
        private parametersService: ParametersService,
        private priceAccessoryService: PriceAccessoryService,
        private priceBaseService: PriceBaseService,
        private stepService: StepsService,
        private newColorsService: NewColorsService,
        private eventBusService: EventBusService,
        private timeLimitService: TimeLimitService,
        private profilesService: ProfilesService,
        private translateService: TranslateService,
        private userService: UserService,
        private customPricesService: CustomPricesService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private discountsAndMultipliersService: DiscountsAndMultipliersService,
        private stateService: StateService,
        private accessoriesSpaceService: AccessoriesSpaceService,
        private woodService: WoodService,
        private issuesService: IssuesService
    ) {
        'ngInject';

        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribe(['setFrameProfile'], () => this.validateFrames());

        this.eventBusService.subscribe(
            [
                'changedSashes',
                'setGlazingInSash',
                'setShape',
                'setExtensionProfile',
                'setFrameProfile',
                'setSashProfile',
                'setMullionProfile',
                'putAlignmentInField',
                'removedAlignmentInField',
                'removedAlignment',
                'setLowThreshold',
                'unsetLowThreshold',
            ],
            (data) => {
                this.updateAccessories(data.activeConfiguration as WindowActiveConfiguration);
            }
        );

        this.eventBusService.subscribe(['changedShutter', 'changedDrive'], (data) => {
            this.validateShutterDriveDependantAccessories(
                data.activeConfiguration as RollerShutterActiveConfiguration
            );
        });

        this.eventBusService.subscribe(
            ['changedSashes', 'changedFillings', 'changedFitting'],
            () => {
                this.setupAccessoriesBasedOnBlockadeToConfiguration(this.dependencies);
                this.checkAccessoriesBaseOnSpace();
            }
        );
        this.eventBusService.subscribe(['processedDependencies'], () => {
            this.setupAccessoriesBasedOnBlockadeToConfiguration(this.dependencies);
        });

        this.eventBusService.subscribe(['setFillingColor', 'setConstructionColor'], () => {
            if (
                this.configurationsService.conf.Current &&
                DoorActiveConfiguration.is(this.configurationsService.conf.Current)
            ) {
                this.validateAccessoriesBasedOnFrameColor();
                this.checkAccessoriesBaseOnSpace();
            }
        });
    }

    /**
     * Funkcja inicjalizujaca
     */
    init() {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }
        this.dependencies = this.configuratorsDataService.data.dependencies || [];
        // wszystkie akcesorie połączone (sash, sides i conf)
        this.allAccessories = this.configuratorsDataService.data.windowAccessories;
        this.subcategories =
            this.configuratorsDataService.data.windowAccessoriesSubCategories || [];
        this.colorsAll = this.configuratorsDataService.data.windowHandlesColors;
        this.dowelHoleParams.between = this.configuratorsDataService.data.dowelHoleParams
            ? this.configuratorsDataService.data.dowelHoleParams.between
            : 0;
        this.dowelHoleParams.fromEdge = this.configuratorsDataService.data.dowelHoleParams
            ? this.configuratorsDataService.data.dowelHoleParams.fromEdge
            : 0;
        this.categories = this.filterWindowAccessoriesCategories(
            this.configuratorsDataService.data.windowAccessories
        );

        this.findAccessories();
        this.refreshPriceProp();
        this.loadedData = true;

        if (this.currentConfiguratorService.conf === 'accessory') {
            this.configurationsService.conf.Current.Name = this.translateService.instant(
                'ACCESSORY|Akcesoria'
            );
        }
    }

    /**
     * Funkcja znajdujaca akcesoria
     */
    findAccessories(conf = this.configurationsService.conf.Current, confType = conf.type) {
        if (conf.type === 'garage_door') {
            return;
        }
        if (
            Common.isUndefined(conf) ||
            this.configurators.indexOf(this.configurationsService.conf.conf) === -1
        ) {
            return;
        }
        this.accessories = [];
        this.sashHardwares = [];
        const sides = ['top', 'bottom', 'left', 'right'];
        let i = 0;

        Common.forEach(this.allAccessories, (accessory) => {
            const availableSystemsIds = accessory.window_lines_ids;
            if (
                (WindowActiveConfiguration.is(conf) || RollerShutterActiveConfiguration.is(conf)) &&
                (confType === 'roller_shutter' || confType === 'external_blind')
            ) {
                if (
                    Common.isDefined(conf.RollerShutter.type.id) &&
                    availableSystemsIds.indexOf(String(conf.RollerShutter.type.id)) > -1
                ) {
                    this.accessories.push(accessory);
                }
            } else if (
                confType === 'accessory' ||
                (WindowActiveConfiguration.is(conf) &&
                    Common.isDefined(conf.System.id) &&
                    availableSystemsIds.indexOf(conf.System.id) > -1 &&
                    (!Object.values(accessory.visualizations || {}).find(
                        (visualization: HardwareVisualization) => visualization.type === 'space'
                    ) ||
                        this.accessoriesSpaceService.getAccessorySpaces(accessory).length > 0))
            ) {
                this.accessories.push(accessory);
            } else if (
                confType === 'mosquito' &&
                MosquitoActiveConfiguration.is(conf) &&
                Common.isDefined(conf.System.id) &&
                availableSystemsIds.indexOf(conf.System.id) > -1
            ) {
                this.accessories.push(accessory);
            }
            // szukaj akcesorii dla skrzydeł
            if (WindowActiveConfiguration.is(conf) && Common.isDefined(conf.Fitting)) {
                if (
                    availableSystemsIds.indexOf(conf.System.id) > -1 &&
                    !Object.values(accessory.visualizations || {}).find(
                        (visualization: HardwareVisualization) => visualization.type === 'space'
                    )
                ) {
                    this.sashHardwares.push(accessory);
                }
            }
        });

        if (this.accessories.length > 0 || this.configurationsService.conf.conf === 'garage_door') {
            this.stepService.enable('accessories');
        } else {
            this.stepService.disable('accessories');
        }

        conf.Accessories = conf.Accessories.filter((el) => this.filterAcessory(el, conf));
        if (WindowActiveConfiguration.is(conf) && conf.SideAccessories) {
            for (i = 0; i < sides.length; i++) {
                conf.SideAccessories[sides[i]] = conf.SideAccessories[sides[i]].filter((el) =>
                    this.filterAcessory(el, conf)
                );
            }
        }
        if (WindowActiveConfiguration.is(conf) && Common.isDefined(conf.Sashes)) {
            for (i = 0; i < conf.Sashes.length; i++) {
                conf.Sashes[i].hardware = conf.Sashes[i].hardware.filter((el) =>
                    this.filterAcessory(el, conf)
                );
            }
        }
        this.priceService.count();
        if (conf.type !== 'accessory' && conf.type !== 'mosquito') {
            this.parametersService.count(conf);
        }
    }

    checkAccessorySpace(accessory, space) {
        this.accessoriesSpaceService.checkAccessorySpace(accessory, space);
        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Aktualizacja danych w akcesoriach
     *
     * @param {any} place Miejsce
     * @param {any} accessory Akcesoria
     */
    updateAccessories(conf = this.configurationsService.conf.Current) {
        if (WindowActiveConfiguration.is(conf)) {
            ['top', 'bottom'].map((side) => {
                if (
                    conf.SideAccessories &&
                    conf.SideAccessories[side] &&
                    conf.SideAccessories[side].length
                ) {
                    conf.SideAccessories[side].map((accessory) => {
                        if (accessory.accessory.type === 'widening') {
                            accessory.amount =
                                conf.Width +
                                conf.SideAccessories.sizes.left +
                                conf.SideAccessories.sizes.right;
                        }
                    });
                }
            });
            if (conf.Accessories && conf.Accessories.length) {
                conf.Accessories.forEach((accessory) =>
                    this.setAmountAccessory(accessory, 'configuration', conf, null)
                );
            }
            if (conf.Sashes && conf.Sashes.length) {
                conf.Sashes.forEach((sash) =>
                    sash.hardware.forEach((accessory) =>
                        this.setAmountAccessory(accessory, 'sash', conf, sash)
                    )
                );
            }
        }
    }

    /**
     * Funkcja filtra akcesoriów
     * @param {object} el Akcesoium
     * @return {boolean}  Czy pasuuje do filtra
     */
    filterAcessory(el, conf = this.configurationsService.conf.Current) {
        const availableSystemsIds = el.accessory.window_lines_ids;
        if (conf.type === 'roller_shutter' || conf.type === 'external_blind') {
            if (
                Common.isDefined(conf.RollerShutter.type.id) &&
                availableSystemsIds.indexOf(String(conf.RollerShutter.type.id)) > -1
            ) {
                return true;
            }
        } else if (
            conf.type === 'accessory' ||
            ((WindowActiveConfiguration.is(conf) || MosquitoActiveConfiguration.is(conf)) &&
                Common.isDefined(conf.System.id) &&
                availableSystemsIds.indexOf(conf.System.id) > -1) ||
            (WindowActiveConfiguration.is(conf) &&
                conf.hasRoller &&
                Common.isDefined(conf.RollerShutter.type.id) &&
                availableSystemsIds.indexOf(String(conf.RollerShutter.type.id)) > -1)
        ) {
            return true;
        }
        return false;
    }

    /**
     * Funkcja dodajaca
     * @param {object} place     Miejsce
     * @param {object} accessory Akcesoria
     * @param {object} color     Kolor
     */
    // eslint-disable-next-line max-statements
    add(place, accessory, color?, conf = this.configurationsService.conf.Current) {
        let count = Number(accessory.count);
        count = count && count >= 1 ? count : 1;
        const colorOptions =
            accessory.show_colors && accessory.price_source === 'table'
                ? accessory.colorOptions
                : null;

        const sides = ['top', 'bottom', 'left', 'right'];
        const filteredCategories = this.filterWindowAccessoriesCategories(
            this.configuratorsDataService.data.windowAccessoriesCategories
        );
        const category = filteredCategories.filter(
            (e) => e.id === accessory.window_accessories_category_id
        );
        const subcategory = this.configuratorsDataService.data.windowAccessoriesSubCategories.filter(
            (e) => e.id === accessory.window_accessories_category_id
        );
        // pozycja środka nawiewnika
        let position = null;
        if (accessory.type === 'ventilator' || accessory.type === 'ventilator_hole') {
            position =
                Number(accessory.position) || (Common.isObject(place) ? place.rWidth / 2 : 1) || 1;
        }

        // Lista akcesoriow - domyslnie do calego zestawu
        let accessoriesArr = conf.Accessories;

        let sizes: Record<'left' | 'right' | 'top' | 'bottom', number> = {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        };
        if (WindowActiveConfiguration.is(conf)) {
            sizes = conf.SideAccessories.sizes;
        }

        // Miejsce docelowa gdzie trafia akcesoria
        let forIt = 'construction';
        if (Common.isObject(place) && Common.isDefined(place.id)) {
            accessoriesArr = place.hardware;
            forIt = 'sash';
        } else if (
            Common.isString(place) &&
            sides.indexOf(place) > -1 &&
            WindowActiveConfiguration.is(conf)
        ) {
            accessoriesArr = conf.SideAccessories[place];
            forIt = 'side';
        }

        /** Można dodać tylko jeden dodatek z tej grupy */
        let accessoriesFromSameCategory =
            Common.isDefined(category[0]) && category[0].only_one_accessory
                ? accessoriesArr.filter(
                      (el) =>
                          el.accessory.window_accessories_category_id === category[0].id &&
                          el.accessory.id !== accessory.id
                  )
                : [];

        const isOnlyOneAccessoryInConstructionAndSashes = this.isOnlyOneAccessoryFromSameCategoryInConstructionAndSashes(
            accessory,
            category
        );
        if (isOnlyOneAccessoryInConstructionAndSashes || accessoriesFromSameCategory.length) {
            return;
        }

        if (!accessoriesFromSameCategory.length) {
            accessoriesFromSameCategory =
                Common.isDefined(subcategory[0]) && subcategory[0].only_one_accessory
                    ? accessoriesArr.filter(
                          (el) =>
                              el.accessory.window_accessories_category_id === subcategory[0].id &&
                              el.accessory.id !== accessory.id
                      )
                    : [];
        }

        /** Można dodać tylko jedną sztukę z tej grupy */
        let accessoryFromSameCategory =
            Common.isDefined(category[0]) && category[0].only_single_accessory
                ? accessoriesArr.filter(
                      (el) =>
                          el.accessory.window_accessories_category_id === category[0].id &&
                          el.accessory.id === accessory.id
                  )
                : [];

        if (accessoryFromSameCategory.length) {
            return;
        }

        if (!accessoryFromSameCategory.length) {
            accessoryFromSameCategory =
                Common.isDefined(subcategory[0]) && subcategory[0].only_single_accessory
                    ? accessoriesArr.filter(
                          (el) =>
                              el.accessory.window_accessories_category_id === subcategory[0].id &&
                              el.accessory.id === accessory.id
                      )
                    : [];
        }

        if (
            this.config().IccConfig.Configurators.oneVentilator &&
            ['accessory', 'complementary_goods'].indexOf(this.configurationsService.conf.conf) ===
                -1 &&
            ['ventilator', 'ventilator_hole'].includes(accessory.type) &&
            accessoriesArr.some((o) => ['ventilator', 'ventilator_hole'].includes(o.accessory.type))
        ) {
            this.infoService.showInfo(
                this.translateService.instant('ACCESSORY|Można dodać tylko jeden nawiewnik')
            );
        } else if (
            ['accessory', 'complementary_goods'].indexOf(this.configurationsService.conf.conf) ===
                -1 &&
            accessoriesFromSameCategory.length &&
            this.config().IccConfig.Configurators.onlyOneAccessoryFromSameCategory
        ) {
            this.infoService.showInfo(
                this.translateService.instant(
                    'ACCESSORY|Można dodać tylko jeden dodatek z tej Kategorii. Aktualnie dodany dodatek z tej kategorii to {accessoryName}',
                    { accessoryName: accessoriesFromSameCategory[0].accessory.name }
                )
            );
        } else if (
            ['accessory', 'complementary_goods'].indexOf(this.configurationsService.conf.conf) ===
                -1 &&
            accessoryFromSameCategory.length
        ) {
            this.infoService.showInfo(
                this.translateService.instant(
                    'ACCESSORY|Można dodać tylko jedną sztukę dodatków z tej Kategorii. Aktualnie dodany dodatek z tej kategorii to {accessoryName}',
                    { accessoryName: accessoryFromSameCategory[0].accessory.name }
                )
            );
        } else {
            if (
                (this.config().IccConfig.Configurators.oneVentilator &&
                    ['ventilator', 'ventilator_hole'].includes(accessory.type)) ||
                (category[0] && category[0].only_single_accessory) ||
                (subcategory[0] && subcategory[0].only_single_accessory)
            ) {
                count = 1;
            }

            // Wybrany kolor akcesorium
            let colorId = null;
            if (Common.isObject(color) && Common.isDefined(color.id)) {
                colorId = color.id;
                this.eventBusService.post({
                    key: 'accessoryColorBasedOnMapping',
                    value: { colorId: Number(color.id), place: 'accessory' },
                });
            }

            // Wymiar elementu
            let amount = 0;

            // Masa elementu
            let weight = 0;

            // Szerokość
            let width = 0;

            // Wysokość
            let height = 0;

            if (
                ['renson', 'ventilator', 'ventilator_hole', 'windowsill'].includes(accessory.type)
            ) {
                width = parseFloat(accessory.width) || 0;
                height = parseFloat(accessory.height) || 0;
            }

            const user = this.userService.get();

            // cena za sztukę
            if (Number(accessory.price_type) === 0) {
                amount = 0;
                weight = parseFloat(accessory.weight) * count || 0;
                // cena za m2
            } else if (Number(accessory.price_type) === 1) {
                width = parseFloat(accessory.amount_width);
                height = parseFloat(accessory.amount_height);

                if (width && height) {
                    amount = (width * height) / 1000000;
                    weight =
                        ((count * width * height) / 1000000) * parseFloat(accessory.weight) || 0;
                } else {
                    this.infoService.showInfo(
                        this.translateService.instant(
                            'CONFIGURATOR|Uzupełnij wszystkie pola przed dodaniem akcesoriów.'
                        ),
                        null
                    );
                    return;
                }
                // cena za mb
            } else {
                const valAmount = parseFloat(accessory.amount);

                if (valAmount > 0) {
                    amount = valAmount;
                } else if (forIt === 'side' && conf.type !== 'accessory') {
                    amount =
                        place === 'left' || place === 'right'
                            ? conf.Height
                            : conf.Width + sizes.left + sizes.right;
                } else if (forIt === 'sash' && accessory.type === 'renson') {
                    amount = width =
                        place.intSashes && place.intSashes.length
                            ? place.intSashes
                                  .filter((o) => !o.ry)
                                  .map((o) => o.glazingSizes.width)
                                  .reduce((a, b) => a + b, 0)
                            : place.glazingSizes.width;
                } else {
                    this.infoService.showInfo(
                        this.translateService.instant(
                            'CONFIGURATOR|Uzupełnij wszystkie pola przed dodaniem akcesoriów.'
                        ),
                        null
                    );
                    return;
                }

                weight = (amount / 1000) * parseFloat(accessory.weight) * count || 0;
            }

            // nowe akcesoria
            if (
                !accessoriesArr.some(
                    (el) =>
                        el.accessory.id === accessory.id &&
                        el.amount === amount &&
                        ((el.color === colorId && accessory.price_source === 'colors') ||
                            (accessory.price_source === 'table' &&
                                el.colorOptions === colorOptions) ||
                            (accessory.price_source === 'confColors' &&
                                accessory.selectedColor &&
                                el.accessory.selectedColor &&
                                accessory.selectedColor &&
                                accessory.selectedColor.core &&
                                accessory.selectedColor.inner &&
                                accessory.selectedColor.outer &&
                                el.accessory.selectedColor &&
                                el.accessory.selectedColor.core &&
                                el.accessory.selectedColor.inner &&
                                el.accessory.selectedColor.outer &&
                                el.accessory.selectedColor.core.id ===
                                    accessory.selectedColor.core.id &&
                                el.accessory.selectedColor.inner.id ===
                                    accessory.selectedColor.inner.id &&
                                el.accessory.selectedColor.outer.id ===
                                    accessory.selectedColor.outer.id))
                )
            ) {
                if (
                    (Number(accessory.price_type) === 0 &&
                        count > 0 &&
                        (accessory.price_source !== 'colors' ||
                            (Common.isDefined(color) && Common.isDefined(colorId)))) ||
                    (Number(accessory.price_type) !== 0 &&
                        count > 0 &&
                        (amount > 0 || forIt === 'side') &&
                        (accessory.price_source !== 'colors' ||
                            (Common.isDefined(color) && Common.isDefined(colorId))))
                ) {
                    let newPos = 0;
                    if (forIt === 'side') {
                        sizes[place] += count * accessory.height;

                        if (accessoriesArr.length > 0) {
                            newPos =
                                accessoriesArr[accessoriesArr.length - 1].pos +
                                accessoriesArr[accessoriesArr.length - 1].accessory.height *
                                    accessoriesArr[accessoriesArr.length - 1].count;
                        }
                    }
                    const accessoryClone = core.copy(accessory);
                    accessoryClone.color = core.copy(color);
                    delete accessoryClone.count;
                    delete accessoryClone.amount;
                    delete accessoryClone.position;
                    delete accessoryClone.amount_width;
                    delete accessoryClone.amount_height;
                    accessoriesArr.push({
                        count,
                        amount,
                        position,
                        weight,
                        width,
                        height,
                        colorOptions,
                        accessory: accessoryClone,
                        color: Common.isObject(color) ? color.id : null,
                        pos: newPos,
                        side: Common.isDefined(place)
                            ? Common.isDefined(place.id)
                                ? place.id
                                : place
                            : undefined,
                        category: Common.isDefined(category[0])
                            ? category[0].name
                            : Common.isDefined(subcategory[0])
                            ? subcategory[0].name
                            : null,
                        comment: accessory.comment,
                    });
                }
                // zwiększenei ilości
            } else {
                for (let i = 0; i < accessoriesArr.length; i++) {
                    if (
                        accessoriesArr[i].accessory.id === accessory.id &&
                        accessoriesArr[i].amount === amount &&
                        ((accessoriesArr[i].color === colorId &&
                            accessoriesArr[i].accessory.price_source === 'colors') ||
                            (accessoriesArr[i].accessory.price_source === 'table' &&
                                accessoriesArr[i].colorOptions === colorOptions) ||
                            (accessoriesArr[i].accessory.price_source === 'confColors' &&
                                accessory.selectedColor &&
                                accessoriesArr[i].accessory.selectedColor &&
                                accessory.selectedColor &&
                                accessory.selectedColor.core &&
                                accessory.selectedColor.inner &&
                                accessory.selectedColor.outer &&
                                accessoriesArr[i].accessory.selectedColor &&
                                accessoriesArr[i].accessory.selectedColor.core &&
                                accessoriesArr[i].accessory.selectedColor.inner &&
                                accessoriesArr[i].accessory.selectedColor.outer &&
                                accessoriesArr[i].accessory.selectedColor.core.id ===
                                    accessory.selectedColor.core.id &&
                                accessoriesArr[i].accessory.selectedColor.inner.id ===
                                    accessory.selectedColor.inner.id &&
                                accessoriesArr[i].accessory.selectedColor.outer.id ===
                                    accessory.selectedColor.outer.id))
                    ) {
                        accessoriesArr[i].count += count;
                        accessoriesArr[i].weight += weight;
                        if (forIt === 'side') {
                            sizes[place] += count * accessory.height;
                            if (i < accessoriesArr.length - 1) {
                                accessoriesArr[i + 1].pos += count * accessory.height;
                            }
                        }
                    }
                    if (forIt === 'side') {
                        if (i === 0) {
                            accessoriesArr[i].pos = 0;
                        } else {
                            accessoriesArr[i].pos =
                                accessoriesArr[i - 1].accessory.height *
                                    accessoriesArr[i - 1].count +
                                accessoriesArr[i - 1].pos;
                        }
                    }
                }
            }
        }

        accessory.count = this.getCountAccessory(
            accessory,
            forIt,
            conf,
            forIt === 'sash' ? place : null
        );
        this.updateAccessories();
        this.priceService.count();
        if (conf.type !== 'accessory' && conf.type !== 'garage_door' && conf.type !== 'mosquito') {
            this.parametersService.count(conf);
        }
    }

    private isOnlyOneAccessoryFromSameCategoryInConstructionAndSashes(
        accessory,
        category,
        conf = this.configurationsService.conf.Current
    ) {
        if (!WindowActiveConfiguration.is(conf) || !accessory || !category) {
            return;
        }

        let isOnlyOneAccessoryInConstructionAndSashes = false;

        if (
            conf.Accessories.find(
                (k) =>
                    Number(k.accessory.window_accessories_category_id) ===
                    Number(accessory.window_accessories_category_id)
            ) &&
            category.find((c) => Number(c.id) === Number(accessory.window_accessories_category_id))
                ?.only_one_accessory
        ) {
            isOnlyOneAccessoryInConstructionAndSashes = true;
        }

        conf.Sashes.every((sash) => {
            sash.hardware.every((hardware) => {
                if (
                    Number(hardware.accessory.window_accessories_category_id) ===
                        Number(accessory.window_accessories_category_id) &&
                    category.find(
                        (c) => Number(c.id) === Number(accessory.window_accessories_category_id)
                    )?.only_one_accessory
                ) {
                    isOnlyOneAccessoryInConstructionAndSashes = true;
                }
            });
        });

        return isOnlyOneAccessoryInConstructionAndSashes;
    }

    removeAllFromSide(place) {
        const sides = ['top', 'bottom', 'left', 'right'];

        if (sides.indexOf(place) === -1) {
            return;
        }
        if (WindowActiveConfiguration.is(this.configurationsService.conf.Current)) {
            this.configurationsService.conf.Current.SideAccessories.sizes[place] = 0;
            this.configurationsService.conf.Current.SideAccessories[place] = [];
        }
    }

    /**
     * Funkcja sprawdzajaca czy i ktory typ nawiewnika wybrano
     * @param  {object} sashNumber  Numer skrzydła
     */
    selectedVentilatorType(sashNumber) {
        let ventilatorOption = null;
        if (
            WindowActiveConfiguration.is(this.configurationsService.conf.Current) &&
            this.configurationsService.conf.Current.Sashes
        ) {
            for (let i = 0; i < this.configurationsService.conf.Current.Sashes.length; i++) {
                if (this.configurationsService.conf.Current.Sashes[i].index === sashNumber) {
                    for (
                        let j = 0;
                        j < this.configurationsService.conf.Current.Sashes[i].hardware.length;
                        j++
                    ) {
                        switch (
                            this.configurationsService.conf.Current.Sashes[i].hardware[j].accessory
                                .type
                        ) {
                            case 'ventilator_hole':
                                ventilatorOption = 'ventilator_hole';
                                break;
                            case 'ventilator':
                                ventilatorOption = 'ventilator';
                                break;
                            default:
                                ventilatorOption = null;
                        }
                    }
                }
            }
        }
        return ventilatorOption;
    }

    /**
     * Funkcja usuwajaca
     * @param  {object} place     Miejsce
     * @param  {object} accessory Akcesoria
     */
    remove(place, accessory, removeDependencyAccessory = false, processDependencies = true) {
        const pauseId = this.eventBusService.pause(['processedDependencies']);

        const conf = this.configurationsService.conf.Current;
        let newAccessoriesArr;
        const sides = ['top', 'bottom', 'left', 'right'];
        // Disable ability to completely remove an accessory that is added from dependency
        if (
            !removeDependencyAccessory &&
            accessory.accessory?.fromDependency &&
            !accessory.accessory?.addedWithRemovableDependency
        ) {
            accessory.count = 1;
            return true;
        }
        if (Common.isObject(place) && Common.isDefined(place.id)) {
            newAccessoriesArr = place.hardware.filter((el) => {
                return (
                    String(el.amount) !== String(accessory.amount) ||
                    el.accessory.id !== accessory.accessory.id ||
                    (el.color !== accessory.color &&
                        accessory.accessory.price_source === 'colors') ||
                    el.colorOptions !== accessory.colorOptions
                );
            });
            place.hardware = newAccessoriesArr;
        } else if (
            Common.isString(place) &&
            sides.indexOf(place) > -1 &&
            WindowActiveConfiguration.is(conf)
        ) {
            newAccessoriesArr = conf.SideAccessories[place].filter((el) => {
                return (
                    String(el.amount) !== String(accessory.amount) ||
                    el.accessory.id !== accessory.accessory.id ||
                    (el.color !== accessory.color &&
                        accessory.accessory.price_source === 'colors') ||
                    el.colorOptions !== accessory.colorOptions
                );
            });
            conf.SideAccessories.sizes[place] -= accessory.count * accessory.accessory.height;

            conf.SideAccessories[place] = newAccessoriesArr;
        } else {
            newAccessoriesArr = conf.Accessories.filter(
                (el) =>
                    String(el.amount) !== String(accessory.amount) ||
                    el.accessory.id !== accessory.accessory.id ||
                    (el.color !== accessory.color &&
                        accessory.accessory.price_source === 'colors') ||
                    el.colorOptions !== accessory.colorOptions
            );
            conf.Accessories = newAccessoriesArr;
        }
        if (Common.isObject(place)) {
            this.selectedVentilatorType(place.index);
        }
        this.updateAccessories();

        this.priceService.count();
        if (conf.type !== 'accessory' && conf.type !== 'garage_door' && conf.type !== 'mosquito') {
            this.parametersService.count(conf);
        }

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });

        if (this.config().IccConfig.Configurators.dependencies && processDependencies) {
            this.eventBusService.post({ key: 'processDependencies', value: null });
        }
        this.eventBusService.resume(['processedDependencies'], pauseId);
    }

    /**
     * Funkcja odświeżajaca cenę
     */
    refreshPriceProp() {
        const conf = this.configurationsService.conf.Current;
        if (WindowActiveConfiguration.is(conf)) {
            this.priceProp = this.priceAccessoryService.getAccessoryPriceField(
                conf.Colors,
                conf.type
            );
        }
    }

    getAccessoryPrice(accessory: IccAccessory, listPrice = false) {
        const offer = this.stateService.getCurrentOffer();
        const dealerId = offer ? offer.dealer_id : null;
        const customPrice = this.customPricesService.get(dealerId);
        let multipliers = this.discountsAndMultipliersService.getMultipliers();
        let multiplier = 1;
        multipliers = (multipliers && multipliers[dealerId]) || multipliers;

        const conf = this.configurationsService.conf.Current;
        if (multipliers) {
            multiplier = (multipliers.Supplement && multipliers.Supplement[0]) || 1;
            if (
                conf.type === 'accessory'
                //|| conf.type === 'complementary_goods'
            ) {
                multiplier = (multipliers.Commodity && multipliers.Commodity[0]) || 1;
            }
        }
        return this.priceBaseService.addMarginMarketFactor(
            this.priceAccessoryService.priceAccessory(
                accessory,
                (WindowActiveConfiguration.is(conf) && conf.Colors) || null,
                (customPrice || {}).WindowAccessory,
                WindowActiveConfiguration.is(conf) && conf.SideAccessories
                    ? conf.SideAccessories.sizes
                    : undefined,
                conf.type !== 'accessory' && conf.Width,
                conf.type !== 'accessory' && conf.Height,
                conf.type,
                multiplier,
                listPrice
            ),
            accessory.accessory.no_discount
        );
    }

    /**
     * Funkcja otwierajaca modal z akcesoriami
     * @param  {object} sash       Skrzydło
     * @param  {object} sashNumber Numer skrzydła
     */
    // eslint-disable-next-line max-statements
    openModalAccessories(
        sash?,
        sashNumber?,
        callback?,
        confType?,
        conf = this.configurationsService.conf.Current
    ): PromiseLike<void | { accessory: IccAccessoryAccessory; color?: IccAccessoryColor }> {
        this.findAccessories(conf, confType || conf.type);
        const filteredCategories = this.filterWindowAccessoriesCategories(
            this.configuratorsDataService.data.windowAccessoriesCategories
        );
        const sides = ['top', 'bottom', 'left', 'right'];
        let forIt = 'configuration';
        let sideIdx = '0';
        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'accessories',
            });
        }

        if (Common.isDefined(sash) && Common.isDefined(sash.id)) {
            forIt = 'sash';
        } else if (Common.isString(sash) && sides.indexOf(sash) > -1) {
            forIt = 'side';

            if (sash === 'top') {
                sideIdx = '2';
            } else if (sash === 'bottom') {
                sideIdx = '3';
            } else if (sash === 'left') {
                sideIdx = '0';
            } else {
                sideIdx = '1';
            }
        } else if (
            Common.isString(sash) &&
            sash === 'drive' &&
            conf.type !== 'accessory' &&
            conf.type !== 'garage_door' &&
            conf.type !== 'mosquito'
        ) {
            forIt = 'drive';
            sideIdx = conf.RollerShutter.drive && conf.RollerShutter.drive.id;
        } else if (confType === 'roller_shutter') {
            forIt = 'roller_shutter';
        }

        if (
            forIt === 'side' &&
            sash === 'top' &&
            WindowActiveConfiguration.is(conf) &&
            conf.hasRoller
        ) {
            return Promise.resolve() as PromiseLike<void>;
        }

        let modalAccessories = this.accessories;
        if (this.currentConfiguratorService.conf !== 'accessory') {
            switch (forIt) {
                case 'configuration':
                case 'roller_shutter':
                    modalAccessories = this.accessories.filter(
                        (el) =>
                            el.place.indexOf('conf') > -1 &&
                            (el.type !== 'roller_control_dependent' ||
                                (el.type === 'roller_control_dependent' &&
                                    el.roller_shutter_drives_id &&
                                    el.roller_shutter_drives_id.includes(
                                        conf.type === 'roller_shutter' &&
                                            conf.RollerShutter.drive.id
                                    ) &&
                                    conf.type === 'roller_shutter' &&
                                    conf.RollerShutter.shutters.every(
                                        (shutter) => shutter.realWidth >= Number(el.min_width)
                                    )))
                    );
                    break;
                case 'sash':
                    let hardwares = [];
                    if (Common.isObject(sash)) {
                        hardwares = this.sashHardwares.filter((el) => {
                            const availableSashTypesIds = el.sash_types_ids;
                            if (
                                !Common.isUndefined(sash.type) &&
                                !Common.isUndefined(sash.type.id)
                            ) {
                                // sprawdź czy dodatek należy do 'sash'
                                if (
                                    el.place.indexOf('sash') > -1 &&
                                    availableSashTypesIds.indexOf(sash.type.id) > -1
                                ) {
                                    return true;
                                }
                            }
                            return false;
                        });
                    }
                    modalAccessories = hardwares;
                    break;
                case 'side':
                    const sideAccessories = this.accessories.filter(
                        (el) =>
                            el.place_side.indexOf(sideIdx) !== -1 && el.place.indexOf('side') > -1
                    );
                    modalAccessories = sideAccessories;
                    break;
                case 'drive':
                    const driveAccessories = this.accessories.filter(
                        (el) =>
                            el.roller_shutter_drives_id &&
                            el.roller_shutter_drives_id.includes(sideIdx) &&
                            el.type === 'roller_control'
                    );
                    modalAccessories = driveAccessories;
                    break;
            }
            modalAccessories = modalAccessories.map((accessory) =>
                this.setAmountAccessory(accessory, forIt, conf, forIt === 'sash' ? sash : null)
            );
        } else {
            modalAccessories = this.accessories.filter((access) => {
                const category = filteredCategories.filter(
                    (el) => el.id === access.window_accessories_category_id
                );
                return access.access_conf || !category.show;
            });
        }

        const categories = filteredCategories.filter(
            (el) => filterAccessories(modalAccessories, el.id).length > 0
        );

        const subcategoriesArr = this.subcategories.filter(
            (el) => filterAccessories(modalAccessories, el.parent_id, el.id).length > 0
        );

        const modalInstance = this.modalService.open({
            templateUrl: 'modalAccessories.html',
            controller: 'ModalAccessoriesCtrl as maccessory',
            pageComponent: AccessoriesListPageComponent,
            resolve: {
                hideTitle: () => this.config().filterAccessoriesByCategories,
                forIt: () => {
                    return forIt;
                },
                accessories: () => {
                    const accessories = modalAccessories.map((el) => {
                        if (el.price_source === 'confColors') {
                            this.setDefaultColors(el, conf);
                        }
                        if (isArray(el.colors_ids) && el.price_source === 'colors') {
                            const accessoryColor = this.colorsAll.find(
                                (color) =>
                                    el.colors_ids &&
                                    el.colors_ids.some((id) => Number(id) === Number(color.id))
                            );
                            if (accessoryColor) {
                                el.selectedColor = accessoryColor.id;
                            }
                        }
                        if (!el.blockAmountChange) {
                            el.amount = null;
                        }
                        return el;
                    });

                    const frameProfiles = WindowActiveConfiguration.is(conf)
                        ? conf.UsedProfiles.filter((profile) => profile.type === 'frame')
                        : false;

                    if (frameProfiles) {
                        return this.matchAccessoriesForFrameProfile(frameProfiles, accessories);
                    } else {
                        return accessories;
                    }
                },
                categories: () => {
                    return categories;
                },
                subcategories: () => {
                    return subcategoriesArr.map((el) => {
                        el.id *= 1;
                        el.parent_id *= 1;
                        return el;
                    });
                },
                sash: () => {
                    return sash;
                },
                sashNum: () => {
                    return sashNumber;
                },
                colors: () => {
                    return this.colorsAll;
                },
                noPrice: () => {
                    return this.configurationsService.price.noPrice;
                },
            },
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });

        return modalInstance.result.then(
            (result: { accessory: IccAccessoryAccessory; color?: IccAccessoryColor }) => {
                if (callback && typeof callback === 'function') {
                    callback(result);
                } else {
                    if (result) {
                        this.add(sash, result.accessory, result.color);
                    }
                    if (this.config().IccConfig.Configurators.dependencies) {
                        this.eventBusService.post({ key: 'processDependencies', value: null });
                        this.priceService.count();
                    }
                    this.eventBusService.post({
                        key: 'icc-redraw',
                        value: 'frame',
                    });
                    this.timeLimitService.count();
                }
                return result;
            }
        );
    }

    /**
     * Funkcja liczaca akcesoria (w górę)
     * @param  {object} accessory Akcesoria
     */
    countUp(accessory) {
        if (
            WindowActiveConfiguration.is(this.configurationsService.conf.Current) ||
            AccessoriesActiveConfiguration.is(this.configurationsService.conf.Current)
        ) {
            this.configurationsService.conf.Current.SideAccessories.sizes[accessory.side] += Number(
                accessory.accessory.height
            );
        }
        accessory.count++;

        this.updateAccessories();
        this.priceService.count();

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Funkcja liczaca akcesoria (w dół)
     * @param  {object} accessory Akcesoria
     */
    countDown(accessory) {
        if (accessory.count > 1) {
            accessory.count--;
            if (WindowActiveConfiguration.is(this.configurationsService.conf.Current)) {
                this.configurationsService.conf.Current.SideAccessories.sizes[
                    accessory.side
                ] -= Number(accessory.accessory.height);
            }
        }

        this.updateAccessories();
        this.priceService.count();

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Przelicz cenę
     */
    countPrice() {
        this.priceService.count();
    }

    /**
     * Ustawia domyślne kolory dodatku
     *
     * @param {any} accessory Dodatek
     */
    setDefaultColors(accessory: IccAccessoryAccessory, conf) {
        if (accessory.material === 'wood') {
            accessory.selectedWood = this.woodService.woods[0];
        } else {
            accessory.selectedWood = null;
        }

        const outerColor = this.newColorsService.getDefaultElementColor(conf, {
            element: 'accessory',
            side: 'outer',
            accessory,
        });
        const outerConstrColor = this.newColorsService.getMatchingConstrColor(
            conf,
            outerColor,
            {},
            {
                element: 'accessory',
                side: 'outer',
                accessory,
            }
        );
        const innerColor = this.newColorsService.getDefaultElementColor(conf, {
            element: 'accessory',
            side: 'inner',
            accessory,
        });
        const innerConstrColor = this.newColorsService.getMatchingConstrColor(
            conf,
            innerColor,
            {},
            {
                element: 'accessory',
                side: 'inner',
                accessory,
            }
        );
        accessory.selectedColor = {
            outer: outerConstrColor
                ? {
                      ...outerConstrColor,
                      isDefault: true,
                  }
                : null,
            inner: innerConstrColor
                ? {
                      ...innerConstrColor,
                      isDefault: true,
                  }
                : null,
        };
        const coreColor = this.newColorsService.getDefaultElementColor(conf, {
            element: 'accessory',
            side: 'core',
            accessory,
        });
        accessory.selectedColor.core = coreColor
            ? {
                  ...coreColor,
                  isDefault: true,
              }
            : null;
    }

    /**
     * Funkcja liczaca otwory
     * @param  {object} sash Skrzydło
     * @param  {object} side Strona
     */
    getCountHoles(sash, side) {
        let length = sash.rWidth;
        if (side === 'left' || side === 'right') {
            length = sash.rHeight;
        }
        const fromEdge = this.dowelHoleParams.fromEdge;
        const betweenO = this.dowelHoleParams.between;
        if (length - 2 * fromEdge > 0) {
            return Math.ceil((length - 2 * fromEdge) / betweenO) + 1;
        } else {
            return 0;
        }
    }

    /**
     * Funkcja wyznaczajaca pozycje otworów pod dyble
     * @param  {object} sash Skrzydło
     * @param  {object} side Strona
     * @param  {object} n    N
     */
    placeDowelHole(sash, side, n) {
        let length = sash.rWidth;
        if (side === 'left' || side === 'right') {
            length = sash.rHeight;
        }
        const fromEdge = this.dowelHoleParams.fromEdge;
        const countSide = this.getCountHoles(sash, side) - 1;
        let between = 0;
        if (countSide > 0) {
            between = (length - 2 * fromEdge) / countSide;
        }

        return fromEdge + n * between;
    }

    setAmountAccessory(accessory, forIt, conf, sash = null) {
        let accessoryData = accessory;
        if (accessory.accessory) {
            accessoryData = accessory.accessory;
        }
        let amount;
        if (forIt === 'sash') {
            amount = this.calcAmountFromFormula(accessoryData.amount_sash_formula, conf, sash);
        } else if (forIt === 'configuration' || forIt === 'roller_shutter') {
            amount = this.calcAmountFromFormula(accessoryData.amount_conf_formula, conf, sash);
        }
        accessory.count = accessory.count || 1;
        if (amount) {
            if (Number(accessoryData.price_type) === 2) {
                accessory.amount = amount;
                accessory.blockAmountChange = true;
            } else if (Number(accessoryData.price_type) === 0) {
                accessory.count = amount;
                accessory.blockCountChange = true;
            }
        }
        return accessory;
    }

    getCountAccessory(accessory, forIt, conf, sash = null) {
        let amount;
        if (forIt === 'sash') {
            amount = this.calcAmountFromFormula(accessory.amount_sash_formula, conf, sash);
        } else if (forIt === 'configuration') {
            amount = this.calcAmountFromFormula(accessory.amount_conf_formula, conf, sash);
        }
        if (amount && Number(accessory.price_type) === 0) {
            return amount;
        }
        return 1;
    }

    calcAmountFromFormula(formula, conf, sash = null) {
        if (formula) {
            let value = 0;
            formula = core.parseJson(formula);
            let symbol = 'plus';
            formula.forEach((block) => {
                if (['plus', 'minus'].indexOf(block.type) > -1) {
                    symbol = block.type;
                } else {
                    let valueToAdd = 0;
                    switch (block.type) {
                        case 'number':
                            valueToAdd = Number(block.value);
                            break;
                        case 'frameWidth':
                            valueToAdd = conf.Width;
                            break;
                        case 'frameHeight':
                            valueToAdd = conf.Height;
                            break;
                        case 'frameLightOuterWidth':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    (s) => s.sashId === sash.id
                                );
                                const frame = conf.Frames.find((f) => f.id === sash.frameId);
                                const frameSides = this.profilesService.getFrameSides(frame, conf);
                                if (sashDimensions) {
                                    valueToAdd = ['left', 'right'].reduce((val, side) => {
                                        const profile = conf.UsedProfiles.find(
                                            (p) =>
                                                p.id ===
                                                (sash.nearMullions[side] !== -1
                                                    ? conf.Mullions.find(
                                                          (m) => m.id === sash.nearMullions[side]
                                                      ).profileId
                                                    : frame.frame[
                                                          frameSides.findIndex(
                                                              (s) => s.side === side
                                                          )
                                                      ].profileId)
                                        );
                                        const profileRatio = sash.nearMullions[side] !== -1 ? 2 : 1;
                                        return profile
                                            ? val +
                                                  profile.width / profileRatio -
                                                  profile.widthOut / profileRatio
                                            : 0;
                                    }, sashDimensions.inner.rect.width);
                                }
                            }
                            break;
                        case 'frameLightOuterHeight':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    (s) => s.sashId === sash.id
                                );
                                const frame = conf.Frames.find((f) => f.id === sash.frameId);
                                const frameSides = this.profilesService.getFrameSides(frame, conf);
                                if (sashDimensions) {
                                    valueToAdd = ['top', 'bottom'].reduce((val, side) => {
                                        const profile = conf.UsedProfiles.find(
                                            (p) =>
                                                p.id ===
                                                (sash.nearMullions[side] !== -1
                                                    ? conf.Mullions.find(
                                                          (m) => m.id === sash.nearMullions[side]
                                                      ).profileId
                                                    : frame.frame[
                                                          frameSides.findIndex(
                                                              (s) => s.side === side
                                                          )
                                                      ].profileId)
                                        );
                                        const profileRatio = sash.nearMullions[side] !== -1 ? 2 : 1;
                                        return profile
                                            ? val +
                                                  profile.width / profileRatio -
                                                  profile.widthOut / profileRatio
                                            : 0;
                                    }, sashDimensions.inner.rect.height);
                                }
                            }
                            break;
                        case 'frameLightWidth':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    (s) => s.sashId === sash.id
                                );
                                if (sashDimensions) {
                                    valueToAdd = sashDimensions.inner.rect.width;
                                }
                            }
                            break;
                        case 'frameLightHeight':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    (s) => s.sashId === sash.id
                                );
                                if (sashDimensions) {
                                    valueToAdd = sashDimensions.inner.rect.height;
                                }
                            }
                            break;
                        case 'sashWidth':
                            if (sash) {
                                const sashFrameDimensions = conf.drawData.sashFrame.find(
                                    (s) => s.sashId === sash.id
                                );
                                if (sashFrameDimensions) {
                                    valueToAdd = sashFrameDimensions.outer.rect.width;
                                }
                            }
                            break;
                        case 'sashHeight':
                            if (sash) {
                                const sashFrameDimensions = conf.drawData.sashFrame.find(
                                    (s) => s.sashId === sash.id
                                );
                                if (sashFrameDimensions) {
                                    valueToAdd = sashFrameDimensions.outer.rect.height;
                                }
                            }
                            break;
                        case 'chamberGlazing':
                            if (sash) {
                                valueToAdd = Number(sash.glazing.glazing_count)
                                    ? Number(sash.glazing.glazing_count) - 1
                                    : 0;
                            }
                            break;
                    }
                    if (symbol === 'minus') {
                        value -= valueToAdd;
                    } else {
                        value += valueToAdd;
                    }
                }
            });
            return value;
        }
        return 0;
    }

    getAccessory(id) {
        return core.copy(this.accessories.find((el) => Number(el.id) === Number(id)));
    }

    markAccessoryAsAddedWithDependency(condition, conf, addedWithDependency = false) {
        const accessory = this.getConstructionAccessory(condition.value, conf);
        if (accessory && accessory.accessory) {
            accessory.accessory.addedWithDependency = addedWithDependency;

            if (condition.type === 'addRemovableAccessoryToConstruction') {
                accessory.accessory.addedWithRemovableDependency = true;
            }
        }
    }

    markAccessoryWithDependencyId(condition, conf, dependencyId) {
        const accessory = this.getConstructionAccessory(condition.value, conf);
        if (accessory && accessory.accessory) {
            accessory.accessory.fromDependency = dependencyId;

            if (condition.type === 'addRemovableAccessoryToConstruction') {
                accessory.accessory.addedWithRemovableDependency = true;
            }
        }
    }

    markAccessoryWithDependencyAssociationIds(accessoryId, conf, dependencyAssociationIds) {
        const accessory = this.getConstructionAccessory(accessoryId, conf);
        if (accessory && accessory.accessory)
            accessory.accessory.dependencyAssociationIds = dependencyAssociationIds;
    }

    getConstructionAccessory(accessoryId, conf) {
        const accessory =
            Common.isArray(conf.Accessories) &&
            conf.Accessories.find((el) => el.accessory && el.accessory.id == accessoryId);

        if (accessory && accessory.accessory && accessory.accessory.id) {
            return accessory;
        }
        return undefined;
    }

    validateFrames(
        conf = this.configurationsService.conf.Current as
            | DoorActiveConfiguration
            | WindowActiveConfiguration
    ) {
        conf.Frames.forEach((frame) => this.validFrame(frame));
    }

    validFrame(frame: Frame) {
        const frames = frame.frame.map((f) => f.side !== 'bottom' && f.profileId).filter(Boolean);
        if (frames) {
            const uniqueFramesIds = Array.from(new Set(frames));
            uniqueFramesIds.forEach((f) => this.removeInvalidFrameDependentAccessories(f));
        }
    }

    removeInvalidFrameDependentAccessories(
        frameProfileId: string | number,
        conf = this.configurationsService.conf.Current as
            | DoorActiveConfiguration
            | WindowActiveConfiguration
    ) {
        //  Accessories for the entire construction
        const filteredAccessories = conf.Accessories.filter((a: IccAccessory) => {
            if (a.accessory.dependent_on_frame_profile) {
                return a.accessory.frame_profiles_ids.map(Number).includes(Number(frameProfileId));
            }
            return a;
        });
        conf.Accessories = filteredAccessories;

        // Accessories for the quarters/ edges
        conf.Sashes.forEach((sash) => {
            const filteredSashesAccessories = sash.hardware.filter((a: IccAccessory) => {
                if (a.accessory.dependent_on_frame_profile) {
                    return a.accessory.frame_profiles_ids
                        .map(Number)
                        .includes(Number(frameProfileId));
                }
                return a;
            });
            sash.hardware = filteredSashesAccessories;
        });
    }

    matchAccessoriesForFrameProfile(frameProfile: Profile[], accessories: IccAccessoryAccessory[]) {
        const accessoriesArr = [];

        accessories.forEach((a) => {
            if (a.dependent_on_frame_profile !== true) {
                accessoriesArr.push(a);
            } else {
                if (
                    frameProfile.every((frame) =>
                        a.frame_profiles_ids.map(Number).includes(frame.id)
                    )
                ) {
                    accessoriesArr.push(a);
                }
            }
        });

        return accessoriesArr;
    }

    setAccessoriesColorBasedOnHardware(
        type: 'plates',
        conf = this.configurationsService.conf.Current as DoorActiveConfiguration
    ) {
        if (conf.Accessories) {
            conf.Accessories.forEach((a) => {
                if (
                    a.accessory.color_related_to_plate &&
                    conf.doorHardware[type] &&
                    conf.doorHardware[type].color
                ) {
                    a.accessory.selectedColor = conf.doorHardware[type].color.toString();
                }
            });
        }

        if (conf.Sashes) {
            conf.Sashes.forEach((sash) => {
                sash.hardware.forEach((a) => {
                    if (
                        a.accessory.color_related_to_plate &&
                        conf.doorHardware[type] &&
                        conf.doorHardware[type].color
                    ) {
                        a.accessory.selectedColor = conf.doorHardware[type].color.toString();
                    }
                });
            });
        }
    }

    setAccessoryColor(accessory: IccAccessory, colors: IccAccessoryColor[]) {
        if (colors) {
            accessory.accessory.changedColor = true;
            accessory.color =
                accessory.accessory.selectedColor &&
                <IccAccessoryColor>(
                    colors.find((c) => Number(c.id) === Number(accessory.accessory.selectedColor))
                );
            accessory.accessory.color = accessory.color;
        }
    }

    filterWindowAccessoriesCategories(categories) {
        return (categories || []).filter((el) => !el.show && el.parent_id === null);
    }

    setupAccessoriesBasedOnBlockadeToConfiguration(
        dependencies,
        conf = this.configurationsService.conf?.Current as WindowActiveConfiguration
    ) {
        if (conf && Array.isArray(dependencies) && dependencies.length > 0) {
            const blockedAccessoriesToConstructionDependency =
                conf.Dependencies &&
                conf.Dependencies?.filter(
                    (p) => p.type === 'blockade_to_configuration' || p.type === 'blockadeAccessory'
                );
            const accessoriesIdsBasedOnBlockadeToConfiguration = this.getAccessoriesIdsBasedOnBlockadeToConfiguration(
                blockedAccessoriesToConstructionDependency,
                dependencies
            );
            if (accessoriesIdsBasedOnBlockadeToConfiguration.length > 0) {
                const accessories = this.getAccessories(conf);
                if (accessories.length > 0) {
                    this.removeAccessoriesBasedOnBlockadeToConfiguration(
                        accessoriesIdsBasedOnBlockadeToConfiguration,
                        accessories
                    );
                }
                this.removeAccessoriesFromSash(conf, accessoriesIdsBasedOnBlockadeToConfiguration);
            }
        }
    }

    removeAccessoriesBasedOnBlockadeToConfiguration(
        accessoriesIdsBasedOnBlockadeToConfigurationDependency,
        accessories: IccAccessory[]
    ) {
        if (accessories && accessoriesIdsBasedOnBlockadeToConfigurationDependency.length > 0) {
            accessoriesIdsBasedOnBlockadeToConfigurationDependency.forEach((accessoryId) => {
                const accessory = accessories.find(
                    (a) => Number(a.accessory.id) === Number(accessoryId)
                );
                if (accessory) {
                    this.remove(null, accessory);
                }
            });
        }
    }

    removeAccessoriesFromSash(conf, accessoriesIdsBasedOnBlockadeToConfiguration) {
        conf.Sashes.forEach((sash) => {
            if (sash.hardware && sash.hardware.length > 0) {
                const accessories = sash.hardware.filter((a) =>
                    accessoriesIdsBasedOnBlockadeToConfiguration.includes(a.accessory.id)
                );
                if (accessories.length > 0) {
                    accessories.forEach((accessory) => {
                        this.remove(sash, accessory);
                    });
                }
            }
        });
    }

    getAccessoriesIdsBasedOnBlockadeToConfiguration(
        blockedAccessoriesToConstruction,
        dependencies
    ) {
        const accessoryId = [];
        if (
            Array.isArray(blockedAccessoriesToConstruction) &&
            blockedAccessoriesToConstruction.length > 0 &&
            dependencies
        ) {
            dependencies.forEach((dependency) => {
                blockedAccessoriesToConstruction.forEach((blocked) => {
                    if (
                        dependency &&
                        Number(dependency.id) === Number(String(blocked.id).split('-')[0]) &&
                        dependency.conditions
                    ) {
                        if (blocked.type === 'blockadeAccessory') {
                            const accessoriesId =
                                dependency.actions.find((p) => p.type === 'blockadeAccessory')
                                    ?.value || [];
                            accessoriesId.forEach((accessory) => {
                                accessoryId.push(accessory);
                            });
                        } else {
                            dependency.conditions.forEach((condition) => {
                                if (condition.type === 'accessory') {
                                    accessoryId.push(condition.value);
                                }
                                if (condition.type === 'sashAccessory') {
                                    accessoryId.push(condition.value);
                                }
                            });
                        }
                    }
                });
            });
        }

        return accessoryId;
    }

    setAccessories(accessory: IccAccessory[], conf = this.configurationsService.conf.Current) {
        if (accessory) {
            conf.Accessories = accessory;
        }
    }

    getAccessories(conf = this.configurationsService.conf.Current): IccAccessory[] {
        return conf.Accessories;
    }

    restoreAccessoriesInSashes(
        sashPositionWithAccessoryId: { accessoryPosition: string; hardware: any }[],
        conf = this.configurationsService.conf.Current
    ) {
        if (DoorActiveConfiguration.is(conf) && Common.isDefined(conf.Sashes)) {
            const notDoorSash = (s) => ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(s.type.type) === -1;
            const isDoorLight = (s) => conf.type === 'door' && notDoorSash(s);
            const isTopLight = (s, frame) =>
                isDoorLight(s) && frame.y + s.ry + s.rHeight < conf.Height;
            conf.Sashes.forEach((sash) => {
                let accessoryPosition: 'left' | 'door' | 'right' | 'top';
                const frame = conf.Frames.find((f) => f.id === sash.frameId);
                if (!notDoorSash(sash)) {
                    accessoryPosition = 'door';
                } else if (isTopLight(sash, frame)) {
                    accessoryPosition = 'top';
                } else {
                    accessoryPosition = frame.x === 0 ? 'left' : 'right';
                }
                const accessories = sashPositionWithAccessoryId.filter(
                    (accessory) => accessory.accessoryPosition === accessoryPosition
                );
                if (accessories) {
                    accessories.forEach((a) => {
                        if (a.hardware.accessory.sash_types_ids.indexOf(sash.type.id) !== -1) {
                            sash.hardware.push(a.hardware);
                            if (this.config().IccConfig.Configurators.dependencies) {
                                this.eventBusService.post({
                                    key: 'processDependencies',
                                    value: null,
                                });
                                this.priceService.count();
                            }
                            this.eventBusService.post({
                                key: 'icc-redraw',
                                value: 'frame',
                            });
                            this.timeLimitService.count();
                        }
                    });
                }
            });
        }
    }

    validateAccessoriesBasedOnFrameColor(
        conf = this.configurationsService.conf.Current as DoorActiveConfiguration
    ) {
        if (WindowActiveConfiguration.is(conf)) {
            const accessories = conf.Accessories;
            const blockedAccessories = conf.BlockedAccessories.reduce(
                (acc, val) => acc.concat(val),
                []
            );

            blockedAccessories.forEach((bAcc) => {
                accessories.forEach((acc, index) => {
                    if (bAcc === acc.accessory.id) {
                        accessories.splice(index, 1);
                    }
                });
            });
        }
    }

    getAccessorySpaces(accessory, list = false) {
        return this.accessoriesSpaceService.getAccessorySpaces(accessory, list);
    }

    setAccessorySpaces(accessory, selected) {
        const conf = this.configurationsService.conf.Current;
        const confAccessory = conf.Accessories.find((a) => a.accessory.id === accessory.id);
        const spaces = this.getAccessorySpaces(confAccessory.accessory, true);
        const space = spaces.find((s) => s.id === selected);
        const colorId =
            confAccessory.accessory.price_source === 'color' && confAccessory.color
                ? confAccessory.accessory.color.id
                : '-1';
        confAccessory.accessory.visualizations[colorId].space = space;
        confAccessory.visualization = confAccessory.accessory.visualizations[colorId];

        this.checkAccessorySpace(accessory, space);
    }

    checkAccessoriesBaseOnSpace() {
        const conf = this.configurationsService.conf.Current;
        const accessories = conf.Accessories;
        accessories
            .filter((a) => {
                const colorId =
                    a.accessory.price_source === 'color' && a.color ? a.accessory.color.id : '-1';
                return (
                    a.accessory.visualizations &&
                    a.accessory.visualizations[colorId]?.type === 'space'
                );
            })
            .forEach((a) => {
                const spaces = this.accessoriesSpaceService.getAccessorySpaces(a.accessory);
                if (spaces.length === 0) {
                    this.remove(null, a);
                } else {
                    const colorId =
                        a.accessory.price_source === 'color' && a.color
                            ? a.accessory.color.id
                            : '-1';
                    const defaultTag = a.accessory.visualizations[colorId].defaultTag;
                    let space =
                        spaces.find(
                            (s) => s.tag === a.accessory.visualizations[colorId].space.tag
                        ) ?? spaces.find((s) => s.tag === defaultTag);
                    if (space) {
                        a.accessory.visualizations[colorId].space = space;
                        a.accessory.spaceId = space.id;
                    } else {
                        const tags = a.accessory.visualizations[colorId].tags;
                        space = spaces.filter((s) => tags.includes(s.tag));
                        if (space.length > 0) {
                            a.accessory.visualizations[colorId].space = space[0];
                            a.accessory.spaceId = space[0].id;
                        }
                    }
                    this.eventBusService.post({
                        key: 'icc-redraw',
                        value: 'frame',
                    });
                }
            });
    }

    validateShutterDriveDependantAccessories(conf = this.configurationsService.conf.Current) {
        if (conf.type === 'roller_shutter') {
            const drive = conf.RollerShutter.drive;
            const removedAccessories = [];
            if (drive) {
                conf.Accessories = conf.Accessories.filter((acc) => {
                    if (
                        acc.accessory.type === 'roller_control_dependent' &&
                        (!acc.accessory.roller_shutter_drives_id ||
                            !acc.accessory.roller_shutter_drives_id.includes(drive.id) ||
                            !conf.RollerShutter.shutters.every(
                                (shutter) => shutter.realWidth >= Number(acc.accessory.min_width)
                            ))
                    ) {
                        removedAccessories.push(acc.accessory);
                        return false;
                    }
                    return true;
                });
            }
            if (removedAccessories.length > 0) {
                this.issuesService.simpleRegister(
                    'removed-roller-control-dependent',
                    `Dodatek ${removedAccessories.map(el => el.name).join(', ')} został usunięty, ponieważ nie spełnia już wymagań konfiguracyjnych.`,
                    this.translateService.instant(
                        'ACCESSORY|Dodatek {accessory} został usunięty, ponieważ nie spełnia już wymagań konfiguracyjnych.',
                        { accessory: removedAccessories.map(el => el.name).join(', ') }
                    ),
                    conf,
                    {
                        logLevel: IssueLevel.NONE,
                        blockAddToOffer: false,
                        noPrice: false,
                    }
                );
            }
        }
    }
}

export function filterAccessories(
    accessories: IccAccessoryAccessory[],
    categoryId: string | number,
    subcatId?: string | number
): IccAccessoryAccessory[] {
    if (isUndefined(categoryId) || categoryId === null || categoryId === '') {
        return accessories;
    } else {
        return accessories.filter(gl => {
            if (gl.parent_id == categoryId || gl.window_accessories_category_id == categoryId) {
                if (isUndefined(subcatId) || subcatId === null || subcatId === '') {
                    return true;
                } else if (gl.window_accessories_category_id == subcatId) {
                    return true;
                }
            }
            return false;
        });
    }
}
