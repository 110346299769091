import { WindowConfigurationLegacy16 } from '../../legacy/WindowConfigurationLegacy16';
import { IWindowConfigurationLegacy17 } from '../../legacy/WindowConfigurationLegacy17';

// eslint-disable-next-line max-statements
export function changesInVersion17(
    oldConfiguration: WindowConfigurationLegacy16,

): IWindowConfigurationLegacy17 {
    const configuration: IWindowConfigurationLegacy17 = {
        ...oldConfiguration,
        oneFilling: {
            ...oldConfiguration.oneFilling,
            doorPassiveInner: oldConfiguration.oneFilling.doorPassive
        }
    };
    return configuration;
}
