<button mat-flat-button color="primary" (click)="addAccessoriesToConstruction()" *ngIf="!(isAccessoryConfigurator$ | async)">{{ 'INTERFACE|Dodaj do całej konstrukcji' | translate }}</button>
<button mat-flat-button color="primary" (click)="addAccessoriesToPart()" *ngIf="(sashes$ | async)">{{ 'INTERFACE|Dodaj do kwatery/krawędzi' | translate }}</button>
<div class="goods-buttons">
    <button mat-flat-button color="primary" (click)="addComplementaryAccessories()" *ngIf="(isAccessoryConfigurator$ | async) && isEnabledType('accessory')">
        <div class="img menu-svg-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="57" height="50" version="1" viewBox="0 0 57 50">
                <path fill="#1D1D1B" d="M23.37 13.472V11.01H20.7v27.98h2.668V36.53H40.54V13.472H23.37zm.1 2.342h2.36v2.36h-2.36v-2.36zm2.362 18.372h-2.36v-2.36h2.36v2.36zm4.464-9.006h-2.228c-.222.498-.72.847-1.3.847s-1.078-.35-1.3-.847H23.24V24h2.242c.228-.485.716-.82 1.286-.82.57 0 1.06.335 1.286.82h2.242v1.18zm8.107 9.006h-2.36v-2.36h2.36v2.36zm0-16.01h-2.36v-2.36h2.36v2.36zM16.458 29.207h3.284v-8.416h-3.284v8.416z"/>
            </svg>
        </div>
        {{  'ACCESSORY|Akcesoria' | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="addComplementarySills()" *ngIf="(isAccessoryConfigurator$ | async) && isEnabledType('windowsill')">
        <div class="img menu-svg-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="57" height="50" version="1" viewBox="0 0 57 50">
                <path fill="#1D1D1B" d="M11.35 28.676h29.084v-4.412H11.35v4.412zm32.737-9.833H11.35v3.446h29.29c.86 0 1.564.7 1.564 1.562v5.744c0 .858.702 1.563 1.563 1.563h.32c.86 0 1.564-.705 1.564-1.564v-9.19c0-.86-.705-1.563-1.563-1.563z"/>
            </svg>
        </div>
        {{ 'GOODS|Parapety' | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="addComplementaryCassonetto()" *ngIf="(isAccessoryConfigurator$ | async) && isEnabledType('cassonetto')">
        <div class="img menu-svg-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="57" height="50" version="1" viewBox="0 0 57 50">
                <path fill="#1D1D1B" d="M17.417 13.917v22.166H31.85l7.733-7.735v-14.43H17.417zM36.304 26.92l-5.37 5.372H20.907V16.896h15.396V26.92z"/>
            </svg>
        </div>
        {{ 'GOODS|Kasonetki' | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="addComplementaryFilling()" *ngIf="(isAccessoryConfigurator$ | async) && isEnabledType('glass')">
        <div class="img menu-svg-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="57" height="50" version="1" viewBox="0 0 57 50">
                <path fill="#1D1D1B" d="M29.484 15.415l-11.672-1.93v21.84l19.15 3.187-.03-13.43-7.448-9.667zm1.876-1.902L19.3 11.488l-1.132 1.054 12.315 2.067 7.463 9.684v13.85l1.24-1.078v-13.41L31.36 13.514z"/>
            </svg>
        </div>
        {{ 'GOODS|Szyby' | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="addComplementaryProfile()" *ngIf="(isAccessoryConfigurator$ | async) && isEnabledType('profile')">
        <div class="img menu-svg-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="57" height="50" version="1" viewBox="0 0 57 50">
                <path fill="#1D1D1B" d="M43.601,22.686l-3.337-3.338c-0.18-0.181-0.43-0.283-0.685-0.283h-2.107c-0.533,0-0.967,0.433-0.967,0.965v6.384H22.031c-0.533,0-0.967,0.434-0.967,0.966v12.037c0,0.534,0.434,0.968,0.967,0.968h4.416c0.274,0,0.538-0.118,0.725-0.326l1.973-2.231h6.662l1.973,2.234c0.187,0.205,0.448,0.323,0.72,0.323h4.419c0.532,0,0.966-0.434,0.966-0.968V23.368C43.884,23.109,43.783,22.866,43.601,22.686zM41.951,38.45h-3.015l-1.971-2.23c-0.185-0.207-0.449-0.325-0.725-0.325h-7.534c-0.275,0-0.539,0.118-0.724,0.326l-1.972,2.229h-3.014V28.345h14.474c0.532,0,0.966-0.434,0.966-0.966v-6.382h0.74l2.773,2.772V38.45zM29.522,18.532c-0.532,0-0.966,0.434-0.966,0.966v0.236h-3.634v-0.236c0-0.532-0.434-0.966-0.967-0.966c-0.532,0-0.966,0.434-0.966,0.966v0.236h-3.635v-0.236c0-0.532-0.434-0.966-0.967-0.966s-0.967,0.434-0.967,0.966v1.203c0,0.532,0.434,0.966,0.967,0.966h11.134c0.532,0,0.966-0.434,0.966-0.966v-1.203C30.488,18.966,30.055,18.532,29.522,18.532zM34.796,23.734V12.988c0-0.256-0.104-0.506-0.283-0.684l-2.407-2.405c-0.183-0.183-0.425-0.283-0.684-0.283h-1.899c-0.532,0-0.966,0.433-0.966,0.964v4.915h-9.201V10.58c0-0.531-0.434-0.964-0.967-0.964H16.49c-0.259,0-0.502,0.101-0.683,0.283l-2.408,2.404c-0.18,0.18-0.283,0.43-0.283,0.685v16.021c0,1.202,0.979,2.181,2.181,2.181h3.092c0.533,0,0.967-0.434,0.967-0.967v-5.521h14.473C34.362,24.701,34.796,24.268,34.796,23.734zM32.864,22.769H18.389c-0.533,0-0.967,0.434-0.967,0.966v5.522h-2.125c-0.137,0-0.248-0.111-0.248-0.248v-15.62l1.841-1.84h0.532v4.912c0,0.533,0.434,0.967,0.967,0.967h11.134c0.532,0,0.966-0.434,0.966-0.967v-4.912h0.533l1.843,1.84V22.769zM37.472,29.446H27.387c-0.533,0-0.967,0.434-0.967,0.967v3.413c0,0.532,0.434,0.966,0.967,0.966h10.085c0.532,0,0.966-0.434,0.966-0.966v-3.413C38.438,29.88,38.004,29.446,37.472,29.446zM36.505,32.86h-8.152v-1.481h8.152V32.86z"/>
            </svg>
        </div>
        {{ 'WINDOW|Profile' | translate }}
    </button>
</div>
<div *ngIf="(accessories$ | async).length > 0" class="icc-list">
    <h4>{{ 'WINDOW|Dodatki do całej konstrukcji' | translate }}</h4>
    <div *ngFor="let item of (accessories$ | async)" class="icc-list-item">
        <ng-container *ngTemplateOutlet="accessoryItem;context:{accessory: item, type: 'accessory'}"></ng-container>
    </div>
</div>

<ng-container *ngFor="let side of (sideAccessories$ | async | keyvalue)">
    <div *ngIf="side.value.length > 0" class="icc-list">
        <h4 *ngIf="side.key == 'left'">{{ 'ACCESSORY|Dodatki do krawędzi lewej' | translate }}</h4>
        <h4 *ngIf="side.key == 'right'">{{ 'ACCESSORY|Dodatki do krawędzi prawej' | translate }}</h4>
        <h4 *ngIf="side.key == 'top'">{{ 'ACCESSORY|Dodatki do krawędzi górnej' | translate }}</h4>
        <h4 *ngIf="side.key == 'bottom'">{{ 'ACCESSORY|Dodatki do krawędzi dolnej' | translate }}</h4>
        <div *ngFor="let item of side.value" class="icc-list-item">
            <ng-container *ngTemplateOutlet="accessoryItem;context:{accessory: item, side: side.key, type: 'accessory'}"></ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngFor="let side of (sideProfiles$ | async | keyvalue)">
    <div *ngIf="side.value.length > 0" class="icc-list">
        <h4 *ngIf="side.key == 'left'">{{ 'ACCESSORY|Poszerzenia lewe' | translate }}</h4>
        <h4 *ngIf="side.key == 'right'">{{ 'ACCESSORY|Poszerzenia prawe' | translate }}</h4>
        <h4 *ngIf="side.key == 'top'">{{ 'ACCESSORY|Poszerzenia górne' | translate }}</h4>
        <h4 *ngIf="side.key == 'bottom'">{{ 'ACCESSORY|Poszerzenia dolne' | translate }}</h4>
        <div *ngFor="let item of side.value" class="icc-list-item">
            <ng-container *ngTemplateOutlet="extensionItem;context:{profile: item, type: 'profile'}"></ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngFor="let sash of (sashes$ | async)">
    <div *ngIf="sash.hardware.length > 0" class="icc-list">
        <h4>{{ 'ACCESSORY|Dodatki do kwatery ID' | translate }} {{sash.index}}:</h4>
        <div *ngFor="let item of sash.hardware" class="icc-list-item">
            <ng-container *ngTemplateOutlet="accessoryItem;context:{accessory: item, sash: sash, type: 'accessory'}"></ng-container>
        </div>
    </div>
</ng-container>
<div *ngIf="(complementaryAccessories$ | async).length > 0" class="icc-list">
    <h4>{{ 'ACCESSORY|Akcesoria' | translate }}</h4>
    <div *ngFor="let item of (complementaryAccessories$ | async)" class="icc-list-item">
        <ng-container *ngTemplateOutlet="accessoryItem;context:{accessory: item, type: 'accessory'}"></ng-container>
    </div>
</div>
<div *ngIf="(complementarySills$ | async).length > 0" class="icc-list">
    <h4>{{ 'GOODS|Parapety' | translate }}</h4>
    <div *ngFor="let item of (complementarySills$ | async)" class="icc-list-item">
        <ng-container *ngTemplateOutlet="accessoryItem;context:{accessory: item, type: 'windowsill'}"></ng-container>
    </div>
</div>

<div *ngIf="(complementaryCassonettos$ | async).length > 0" class="icc-list">
    <h4>{{ 'GOODS|Kasonetki' | translate }}</h4>
    <div *ngFor="let item of (complementaryCassonettos$ | async)" class="icc-list-item">
        <ng-container *ngTemplateOutlet="accessoryItem;context:{accessory: item, type: 'cassonetto'}"></ng-container>
    </div>
</div>

<div *ngIf="(complementaryFillings$ | async).length > 0" class="icc-list">
    <h4>{{ 'GOODS|Szyby' | translate }}</h4>
    <div *ngFor="let item of (complementaryFillings$ | async)" class="icc-list-item">
        <ng-container *ngTemplateOutlet="accessoryItem;context:{accessory: item, type: 'glass'}"></ng-container>
    </div>
</div>

<div *ngIf="(complementaryProfiles$ | async).length > 0" class="icc-list">
    <h4>{{ 'WINDOW|Profile' | translate }}</h4>
    <div *ngFor="let item of (complementaryProfiles$ | async)" class="icc-list-item">
        <ng-container *ngTemplateOutlet="accessoryItem;context:{accessory: item, type: 'profile'}"></ng-container>
    </div>
</div>

<ng-template #accessoryItem let-item="accessory" let-sash="sash" let-side="side" let-type="type">
    <div class="item-imgTitleDescription">
        <img class="item-imgTitleDescription-img" *ngIf="type === 'accessory'" [src]="'/files/windowaccessory/' + item.accessory.img" [alt]="item.accessory.name" defaultImg>
        <img class="item-imgTitleDescription-img" *ngIf="type === 'windowsill'" [src]="'/files/windowsill/' + item.accessory.image" [alt]="item.accessory.name" defaultImg>
        <img class="item-imgTitleDescription-img" *ngIf="type === 'cassonetto'" [src]="'/files/cassonetto/' + item.accessory.image" [alt]="item.accessory.name" defaultImg>
        <img class="item-imgTitleDescription-img" *ngIf="type === 'glass'" [src]="'/files/filling/' + item.accessory.image" [alt]="item.accessory.name" defaultImg>
        <img class="item-imgTitleDescription-img" *ngIf="type === 'profile'" [src]="'/files/profile/' + item.accessory.img" [alt]="item.accessory.name" defaultImg>
        <p class="item-imgTitleDescription-title"><span *ngIf="windowAccessoriesManufacturerCode">{{item.accessory.manufacturer_code}} </span>{{item.accessory.name}}</p>
        <span class="item-imgTitleDescription-description">
            <small *ngIf="item.accessory.code">{{item.accessory.code}}</small>
            <span *ngIf="item.accessory.description" [innerHTML]="item.accessory.description" class="item-description"></span>
            <br>

            <span *ngIf="item.accessory.show_colors && item.accessory.price_source == 'table'">{{ 'COLOR|Kolor' | translate }}:
                <b *ngIf="item.colorOptions == 'white'">{{ 'COLOR|Biały' | translate }}</b>
                <b *ngIf="item.colorOptions == 'color-white'">{{ 'COLOR|Kolor' | translate }}/{{ 'COLOR|Biały' | translate }}</b>
                <b *ngIf="item.colorOptions == 'color-color'">{{ 'COLOR|Kolor' | translate }}/{{ 'COLOR|Kolor' | translate }}</b>
                <b *ngIf="item.colorOptions == 'ral'">{{ 'COLOR|RAL' | translate }}</b>
                <br>
            </span>
            <span *ngIf="item.accessory.price_source == 'confColors' && type !== 'profile'">
                <span *ngIf="item.accessory.selectedWood?.name">{{ 'WINDOW|Rodzaj drewna' | translate }}:
                    <b>{{ item.accessory.selectedWood.name }}</b>
                    <br />
                </span>
                <span *ngIf="item.accessory.selectedColor.outer.name">{{ 'COLOR|Kolor zewn.' | translate }}:
                    <b>{{ item.accessory.selectedColor.outer.name }}</b>
                    <br />
                </span>
                <span *ngIf="item.accessory.selectedColor.inner.name">{{ 'COLOR|Kolor wewn.' | translate }}:
                    <b>{{ item.accessory.selectedColor.inner.name }}</b>
                    <br />
                </span>
                <span *ngIf="item.accessory.selectedColor.core.name && showCoreColorInAdditionalProfilesAndAccessories">{{ 'WINDOW|Kolor bazy' | translate }}:
                    <b>{{ item.accessory.selectedColor.core.name }}</b>
                    <br />
                </span>
            </span>
            <span *ngIf="type === 'profile'">
                <span *ngIf="item.accessory.selectedWood?.name">{{ 'WINDOW|Rodzaj drewna' | translate }}:
                    <b>{{ item.accessory.selectedWood.name }}</b>
                    <br />
                </span>
                <ng-container *ngIf="(!item.accessory?.selectedColor?.outer?.id && !item.accessory?.selectedColor?.inner?.id && item.accessory?.selectedColor?.core?.name) || (item.accessory?.selectedColor?.outer?.id === item.accessory?.selectedColor?.inner?.id && item.accessory?.selectedColor?.outer?.name)">
                    <span>
                        {{ 'COLOR|Kolor' | translate }}:
                        <b *ngIf="!item.accessory?.selectedColor?.outer?.id && !item.accessory?.selectedColor?.inner?.id">
                            {{ item.accessory?.selectedColor?.core?.name }}
                        </b>
                        <b *ngIf="item.accessory?.selectedColor?.outer?.id === item.accessory?.selectedColor?.inner?.id">
                            {{ item.accessory?.selectedColor?.outer?.name }}
                        </b>
                        <br />
                    </span>
                    <span *ngIf="
                        showCoreColorInAdditionalProfilesAndAccessories
                        && !item.accessory?.selectedColor?.outer?.isCore
                        && !item.accessory?.selectedColor?.inner?.isCore
                        && item.accessory?.selectedColor?.core?.name
                    ">
                        {{ 'COLOR|Kolor bazy' | translate }}:
                        <b>
                            {{ item.accessory?.selectedColor?.core?.name }}
                        </b>
                        <br />
                    </span>
                </ng-container>
                <ng-container *ngIf="(item.accessory?.selectedColor?.outer?.id || item.accessory?.selectedColor?.inner?.id) && item.accessory?.selectedColor?.outer?.id !== item.accessory?.selectedColor?.inner?.id">
                    <span *ngIf="item.accessory?.selectedColor?.outer?.name || item.accessory?.selectedColor?.core?.name">
                        {{ 'COLOR|Kolor zewn.' | translate }}:
                        <b *ngIf="item.accessory?.selectedColor?.outer?.name">
                            {{ item.accessory?.selectedColor?.outer?.name }}
                        </b>
                        <b *ngIf="!item.accessory?.selectedColor?.outer?.name && item.accessory?.selectedColor?.core?.name">
                            {{ item.accessory?.selectedColor?.core?.name }}
                        </b>
                        <br />
                    </span>
                    <span *ngIf="item.accessory?.selectedColor?.inner?.name || item.accessory?.selectedColor?.core?.name">
                        {{ 'COLOR|Kolor wewn.' | translate }}:
                        <b *ngIf="item.accessory?.selectedColor?.inner?.name">
                            {{ item.accessory?.selectedColor?.inner?.name }}
                        </b>
                        <b *ngIf="!item.accessory?.selectedColor?.inner?.name && item.accessory?.selectedColor?.core?.name">
                            {{ item.accessory?.selectedColor?.core?.name }}
                        </b>
                        <br />
                    </span>
                    <span *ngIf="
                        showCoreColorInAdditionalProfilesAndAccessories
                        && !item.accessory?.selectedColor?.outer?.isCore
                        && !item.accessory?.selectedColor?.inner?.isCore
                        && item.accessory?.selectedColor?.core?.name
                    ">
                        {{ 'COLOR|Kolor bazy' | translate }}:
                        <b>
                            {{ item.accessory?.selectedColor?.core?.name }}
                        </b>
                        <br />
                    </span>
                </ng-container>
            </span>
            <span *ngIf="item.accessory.price_source == 'colors' && !item.accessory.fromDependency">
                {{ 'COLOR|Kolor' | translate }}: <b>{{getColor(item.accessory.selectedColor) }}</b><br>
            </span>
            <span *ngIf="item.accessory.price_source == 'colors' && item.accessory.fromDependency">
                <mat-form-field>
                    <mat-label>{{ 'INTERFACE|Kolor' | translate }}</mat-label>
                    <mat-select [(ngModel)]="item.accessory.selectedColor" (ngModelChange)="changeAccessoryColor(item)">
                        <mat-option *ngFor="let color of getAccessoryColors(item)"
                                    [value]="color.id">
                            {{color.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
            </span>
            <span *ngIf="item.accessory.warmEdge">
                <span >
                    {{ 'WINDOW|Ciepła ramka' | translate }}: <b>{{item.accessory.warmEdgeName || item.warmEdgeName}}</b>
                </span>
                <br>
            </span>
            <span *ngIf="commentInAccessory">
                <span >
                    {{ 'INTERFACE|Komentarz' | translate }}: <b>{{item.accessory.comment || item.comment}}<mat-icon *ngIf="!item.editComment" class='comment__edit' (click)="item.editComment=true">mode_edit</mat-icon>
                    </b>
                </span>
                <br>
            </span>
            <textarea *ngIf="item.editComment" [(ngModel)]="item.comment" (focusout)='item.editComment = false' name="" id="" cols="40" rows="5" class='comment__textarea'></textarea>
            <span *ngIf="item.accessory.type != 'dowel_hole'">
                {{ 'INTERFACE|Ilość' | translate }}: <b>{{item.count}}</b>
            </span><br />
            <span *ngIf="item.accessory.price_type == 1 || item.accessory.price_type == 2">
                <span  *ngIf="item.accessory.price_type == 1">
                    {{ 'DIMENSION|Powierznia' | translate }}:
                </span>
                <span *ngIf="item.accessory.price_type == 2">
                    {{ 'DIMENSION|Długość' | translate }}:
                </span>
                <b>
                    <span *ngIf="item.accessory.price_type == 1">{{item.amount|unitm2:1}}</span>
                    <span *ngIf="item.accessory.price_type == 2">{{item.amount|unitmm:1}}</span>
                </b>
            </span>
            <span *ngIf="item.accessory.currentWidth && type !== 'cassonetto'">
                <span>
                    {{ 'DIMENSION|Szerokość' | translate }}:
                </span>
                <b>{{item.accessory.currentWidth|unitmm:1:0}}</b>
                <br />
            </span>

            <span *ngIf="item.accessory.currentHeight && type !== 'cassonetto'">
                <span>
                    {{ 'DIMENSION|Wysokość' | translate }}:
                </span>
                <b>{{item.accessory.currentHeight|unitmm:1:0}}</b>
                <br />
            </span>

            <span *ngIf="item.accessory.currentLength && type !== 'accessory'">
                <span>
                    {{ 'DIMENSION|Długość' | translate }}:
                </span>
                <b>{{item.accessory.currentLength|unitmm:1:0}}</b>
                <br />
            </span>
            <span *ngIf="item.accessory.plugs == '1'">
                <span>
                    {{ 'GOODS|Zaślepki PCV' | translate }}:
                </span>
                <b>{{ 'CONFIGURATOR|Standard' | translate }}</b>
                <br />
            </span>
            <span *ngIf="item.accessory.pricing_data && item.accessory.pricing_data[0]?.colors?.length > 0">
                <span>
                    {{ 'COLOR|Kolor' | translate }}:
                </span>
                <b>{{item.accessory.colors_pricing[item.accessory.selectedColor].name}}</b>
                <br />
            </span>

            <span *ngIf="type === 'cassonetto'">
                <span *ngIf="item.accessory.currentWidth">
                    <span>
                        L:
                    </span>
                    <b>{{item.accessory.currentWidth | unitmm:1:0}}</b>
                </span><br />
                <span *ngIf="item.accessory.currentHeight">
                    <span>
                        H:
                    </span>
                    <b>{{item.accessory.currentHeight | unitmm:1:0}}</b>
                </span><br />
                <span *ngIf="item.accessory.currentL1">
                    <span>
                        L1:
                    </span>
                    <b>{{item.accessory.currentL1 | unitmm:1:0}}</b>
                    <br />
                </span>
                <span *ngIf="item.accessory.currentP1">
                    <span>
                        P1:
                    </span>
                    <b>{{item.accessory.currentP1 | unitmm:1:0}}</b>
                    <br />
                </span>
                <span *ngIf="item.accessory.currentP2">
                    <span>
                        P2:
                    </span>
                    <b>{{item.accessory.currentP2 | unitmm:1:0}}</b>
                    <br />
                </span>
                <span>
                    {{ 'COLOR|Kolor' | translate }}: <b>{{getColor(item.color, item.accessory.colors) }}</b><br>
                </span>
            </span>
        </span>
        <span class="item-action">
            <h4 *ngIf="!item.accessory.no_price && !hiddenPrice" class="item-price">
                {{ 'CONFIGURATOR|Cena' | translate }}: <s *ngIf="getAccessoryPrice(item, true) && getAccessoryPrice(item, true) !== getAccessoryPrice(item)">{{getAccessoryPrice(item, true) | currency:currency}}</s> {{getAccessoryPrice(item) | currency:currency}}
            </h4>
            <h4 *ngIf="item.accessory.no_price && !hiddenPrice" class="item-price item-price_noprice">
                {{ 'CONFIGURATOR|Do wyceny' | translate }}
            </h4>
            <span *ngIf="hasSpaces(item.accessory)">
                <mat-form-field>
                    <mat-label>{{ 'INTERFACE|Pozycja' | translate }}</mat-label>
                    <mat-select [(ngModel)]="item.accessory.spaceId" (ngModelChange)="setSpace($event, item.accessory)">
                        <mat-option *ngFor="let space of getSpaces(item.accessory)" [value]="space.id">
                            {{space.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
            </span>
            <button [disabled]="!item.accessory.addedWithRemovableDependency && item.accessory.fromDependency && item.count == 1"
                mat-raised-button color="primary" (click)="remove(sash || side, item, type)">
                {{ 'INTERFACE|Usuń'| translate}}
            </button>
        </span>
    </div>
</ng-template>

<ng-template #extensionItem let-item="profile" let-sash="sash" let-type="type">
    <div class="item-imgTitleDescription">
        <img class="item-imgTitleDescription-img" [src]="'/files/profile/' + item.img" [alt]="item.name" defaultImg>
        <p class="item-imgTitleDescription-title"><span *ngIf="windowAccessoriesManufacturerCode">{{item.code}} </span>{{item.name}}</p>
        <span class="item-imgTitleDescription-description">
            <span *ngIf="item.description" [innerHTML]="item.description" class="item-description"></span>

            <span>
                <span *ngIf="item.wood?.name">{{ 'WINDOW|Rodzaj drewna' | translate }}:
                    <b>{{ item.wood.name }}</b>
                    <br />
                </span>
                <span *ngIf="item.color?.outer?.name">{{ 'COLOR|Kolor zewn.' | translate }}:
                    <b>{{ item.color.outer.name }}</b>
                    <br />
                </span>
                <span *ngIf="item.color?.inner?.name">{{ 'COLOR|Kolor wewn.' | translate }}:
                    <b>{{ item.color.inner.name }}</b>
                    <br />
                </span>
                <span *ngIf="item.color?.core?.name && showCoreColorInAdditionalProfilesAndAccessories">{{ 'WINDOW|Kolor bazy' | translate }}:
                    <b>{{ item.color.core.name }}</b>
                    <br />
                </span>
            </span>

            <!-- <h4 *ngIf="!item.no_price" class="item-price">
                {{getAccessoryPrice(item) | currency:currency}}
            </h4>
            <h4 *ngIf="item.no_price" class="item-price item-price_noprice">
                {{ 'CONFIGURATOR|Do wyceny' | translate }}
            </h4> -->
        </span>
        <span class="item-action"></span>
    </div>
</ng-template>
