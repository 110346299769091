import { ActiveConfiguration } from './ActiveConfiguration';
import { WinterGardenConfiguration } from './WinterGardenConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';

export class WinterGardenActiveConfiguration implements ActiveConfiguration {
    public static is(configuration): configuration is WinterGardenActiveConfiguration {
        return configuration instanceof WinterGardenActiveConfiguration || !configuration.$version;
    }
    type: 'winter_garden' = 'winter_garden';
    Name: string = '';
    Price: number = NaN;
    ListPrice: number = NaN;
    PriceNoMargin: number = NaN;
    ListPriceNoMargin: number = NaN;
    PriceAfterDiscountsInCurrency: number = 0;
    ListPriceAfterDiscountsInCurrency: number = 0;
    PriceBeforePromotions: number = 0;
    ListPriceBeforePromotions: number = 0;
    PriceGross: number = 0;
    ListPriceGross: number = 0;
    PriceGrossAfterDiscounts: number = 0;
    ListPriceGrossAfterDiscounts: number = 0;
    PriceGrossBeforePromotions: number = 0;
    ListPriceGrossBeforePromotions: number = 0;
    Quantity: number = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    ListPriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    ListPriceSegmentsNoMargin: PriceSegment[] = [];
    PriceSegmentsBeforePromotions: PriceSegment[] = [];
    ListPriceSegmentsBeforePromotions: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    ListPriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    ListPricePartsNoMargin: PricePart[] = [];
    PricePartsBeforePromotions: PricePart[] = [];
    ListPricePartsBeforePromotions: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    Discount: number = 0;
    ListDiscount: number = 0;
    Title = '';
    Attachments = [];
    valid: {} = {};
    PriceValid = false;
    drawData: any;

    Garden: any = {};
    Description: string = '';

    attributes: {
        id: string;
        name: string;
        value: string;
    }[] = [];

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (WinterGardenActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (WinterGardenConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.ListPrice = configuration.listPrice;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.ListPriceNoMargin = configuration.listPriceNoMargin;
                    this.PriceAfterDiscountsInCurrency = configuration.priceAfterDiscountsInCurrency;
                    this.ListPriceAfterDiscountsInCurrency = configuration.listPriceAfterDiscountsInCurrency;
                    this.PriceGross = configuration.priceGross;
                    this.ListPriceGross = configuration.listPriceGross;
                    this.PriceGrossAfterDiscounts = configuration.priceGrossAfterDiscounts;
                    this.ListPriceGrossAfterDiscounts = configuration.listPriceGrossAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.ListPriceSegments = configuration.listPriceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.ListPriceSegmentsNoMargin = configuration.listPriceSegmentsNoMargin;
                    this.PriceSegmentsBeforePromotions = configuration.priceSegmentsBeforePromotions;
                    this.ListPriceSegmentsBeforePromotions = configuration.listPriceSegmentsBeforePromotions;
                    this.PriceParts = configuration.priceParts;
                    this.ListPriceParts = configuration.listPriceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.ListPricePartsNoMargin = configuration.listPricePartsNoMargin;
                    this.PricePartsBeforePromotions = configuration.pricePartsBeforePromotions;
                    this.ListPricePartsBeforePromotions = configuration.listPricePartsBeforePromotions;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.valid = configuration.valid;
                    this.attributes = configuration.attributes;

                    this.Garden = configuratorsDataService.get(
                        'winterGardens',
                        configuration.garden.id
                    );
                    this.Garden.dimensions = {};
                    for (const label in configuration.dimensions) {
                        if (configuration.dimensions.hasOwnProperty(label)) {
                            this.Garden.dimensions[label] = configuration.dimensions[label];
                        }
                    }
                    this.Garden.handle_facet = configuration.handleFacet;
                    this.Description = configuration.description;
                }
            }
        }
        this.configuratorsDataService = null;
    }
}
