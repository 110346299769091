/* eslint-disable require-jsdoc */
import angular from 'angular';
import format from 'date-fns/format';
import { parseDate } from '@icc/helpers';

import { Completion } from '@icc/common/Completion';
import { OfferTransportCostService } from '@icc/common/offers/OfferTransportCostService';
import { PositionService } from '@icc/common/offers/PositionService';
import { PositionsDetailsService } from '@icc/common/PositionsDetailsService';
import { PositionDetailedSummaryService } from '@icc/common/PositionDetailedSummaryService';
import { OffersService as OffersServiceStatic } from '@icc/legacy/panel/offers.service';
import { OfferSequenceService } from '@icc/common/offers/OfferSequenceService';
import { MontagesService as MontagesServiceStatic } from '@icc/common/offers/montages.service';
import { OfferHistoryService } from '@icc/common/offers/offer-history.service';
import {
    OfferStatuses,
    OfferStatusDict,
    OrderStatuses,
    OrderStatusDict,
    ShopOrderStatuses,
    ShopOrderStatusDict,
 } from '@icc/common/offers/offer-statuses';

import * as hash from 'object-hash';
import { update } from 'lodash';

/**
 * Funkcja (Konstruktor) kontrolera widoku oferty
 * @param {object} $rootScope                  rootScope
 * @param {object} $scope                      scope
 * @param {object} $timeout                    timeout
 * @param {object} $routeParams                routeParams
 * @param {object} $location                   location
 * @param {object} $http                       http
 * @param {object} $filter                     filter
 * @param {object} Core                        Core
 * @param {object} OffersFactory               OffersFactory
 * @param {object} PositionsFactory            PositionsFactory
 * @param {object} OfferAttachmentsFactory     OfferAttachmentsFactory
 * @param {object} PositionAttachmentsFactory  PositionAttachmentsFactory
 * @param {object} OnlineStatusService         OnlineStatusService
 * @param {object} ConfiguratorsDataService    ConfiguratorsDataService
 * @param {object} AdditionalsFactory          AdditionalsFactory
 * @param {object} OfferViewFactory            OfferViewFactory
 * @param {object} InfoService                 InfoService
 * @param {object} IccConfig                   IccConfig
 * @param {object} AlternativeLayoutsFactory   AlternativeLayoutsFactory
 */

// eslint-disable-next-line max-params
export default function OffersViewCtrl(
    $rootScope,
    $scope,
    $timeout,
    $routeParams,
    $location,
    $http,
    $filter,
    $q,
    $sce,
    Core,
    OffersFactory,
    OffersService,
    PositionsFactory,
    OfferAttachmentsFactory,
    PositionAttachmentsFactory,
    OnlineStatusService,
    ConfiguratorsDataService,
    AdditionalsFactory,
    OfferViewFactory,
    InfoService,
    IccConfig,
    EnvConfig,
    ProjectName,
    machine,
    AlternativeLayoutsFactory,
    EditPositionFactory,
    NewPositionService,
    TaxRatesService,
    ClientsFactory,
    PdfService,
    offerOptions,
    OfferPriceService,
    DrawService,
    EventBusService,
    GtmService,
    StateService,
    SynchronizeService,
    UserService,
    isElectron,
    ConfiguratorsAvailabilityService,
    ScrollbarService,
    ConfigurationsService,
    ManyPositionsService,
    CoupledWindowService,
    MontagesService,
    DiscountsAndMultipliersService,
    SystemsComparisonService,
    OfferSummaryService,
    LangService,
    MontagesDataService,
    ColorsPositionService,
    theme
) {
    'ngInject';

    var vm = this;
    var transportCostPosition = null;
    var loadedOffer = false;

    $rootScope.loader = true;
    $rootScope.offerAdded = false;
    $scope.synced = { val: true };
    $scope.inEdit = {};
    $scope.hash = hash;
    vm.pdf = false;
    vm.clientView = true;
    vm.addAdditionalPosition = addAdditionalPosition;
    vm.addCopy = addCopy;
    vm.addCustomPosition = addCustomPosition;
    vm.addFile = addFile;
    vm.additionalPositionForm = false;
    vm.addNewOfferDiscount = addNewOfferDiscount;
    vm.addNewPosition = addNewPosition;
    vm.addOfferFile = addOfferFile;
    vm.addOfferInternalFile = addOfferInternalFile;
    vm.addSubOffer = addSubOffer;
    vm.copyDemoOffer = copyDemoOffer;
    vm.addSubOrder = addSubOrder;
    vm.addSubShopOrder = addSubShopOrder;
    vm.openModalHistory = OfferViewFactory.openModalHistory;
    vm.anotherClientOffer = anotherClientOffer;
    vm.attachments = {};
    vm.cancelOffer = cancelOffer;
    vm.cancelShopOrder = cancelShopOrder;
    vm.clearOffer = clearOffer;
    vm.clientsTypes = {};
    vm.createOfferForClient = createOfferForClient;
    vm.createOrder = OfferViewFactory.confirmOrder;
    vm.createDemoOffer = OfferViewFactory.createDemoOffer;
    vm.createValuation = OfferViewFactory.createValuation;
    vm.consultOffer = OfferViewFactory.consultOffer;
    vm.createCoupledWindow = createCoupledWindow;
    vm.dealerPrices = false;
    vm.dealerProducts = [];
    vm.dealers = [];
    vm.deleteOfferDiscount = deleteOfferDiscount;
    vm.downloadFile = downloadFile;
    vm.downloadOfferFile = downloadOfferFile;
    vm.editNewDiscount = editNewDiscount;
    vm.editPosition = editPosition;
    vm.getDealersList = getDealersList;
    vm.getFirstDealersList = getFirstDealersList;
    vm.getOffer = getOffer;
    vm.getTaxValue = getTaxValue;
    vm.groups = [];
    vm.completion = [];
    vm.isEdited = isEdited;
    vm.isNonRectangularPosition = false;
    vm.isOnline = isOnline;
    vm.movePosition = movePosition;
    vm.offerToCheck = false;
    vm.openAlterModal = openAlterModal;
    vm.openPdf = openPdf;
    vm.generateCompletionInPdf = false;
    vm.openQr = openQr;
    vm.openDWU = openDWU;
    vm.openCE = openCE;
    vm.openServicePage = OfferViewFactory.openServicePage;
    vm.quickSendInquiry = quickSendInquiry;
    vm.quickSendOrder = quickSendOrder;
    vm.recalcDiscountRate = recalcDiscountRate;
    vm.recalcDiscountValue = recalcDiscountValue;
    vm.refreshDiscounts = refreshDiscounts;
    vm.removeFile = removeFile;
    vm.removeOfferFile = removeOfferFile;
    vm.removePosition = removePosition;
    vm.saveOffer = saveOffer;
    vm.selectDealer = selectDealer;
    vm.sendClientInquiry = sendClientInquiry;
    vm.sendEmailToClient = sendEmailToClient;
    vm.sentToClient = sentToClient;
    vm.clientSubmitOffer = clientSubmitOffer;
    vm.setClientToOffer = setClientToOffer;
    vm.setDealerAndRefferToOffer = setDealerAndRefferToOffer;
    vm.showSendInquiry = false;
    vm.taxes = [];
    vm.updateOfferClientSpecialDiscount = updateOfferClientSpecialDiscount;
    vm.updateOfferDeliveryAddress = OfferViewFactory.updateOfferDeliveryAddress;
    vm.updateOfferDealerAdvance = OfferViewFactory.updateOfferDealerAdvance;
    vm.updateOfferDescription = OfferViewFactory.updateOfferDescription;
    vm.updateOfferName = OfferViewFactory.updateOfferName;
    vm.updatePositionCustomTitle = updatePositionCustomTitle;
    vm.updatePositionDescription = updatePositionDescription;
    vm.updatePostitionQuantity = updatePostitionQuantity;
    vm.countPricesFromClient = countPricesFromClient;
    vm.countPricesFromDealer = countPricesFromDealer;
    vm.updateTaxRate = updateTaxRate;
    vm.offerHeader = {};
    vm.updateOfferHeader = updateOfferHeader;
    vm.openDeadline = openDeadline;
    vm.openDeliveryDate = openDeliveryDate;
    vm.openAdvance = openAdvance;
    vm.openWarranty = openWarranty;
    vm.transportCost = { new: null, old: null };
    vm.dateOptions = { startingDay: 1, showWeeks: false, format: 'yyyy-MM-dd' };
    vm.updateOfferPositionsDiscount = updateOfferPositionsDiscount;
    vm.addComment = addComment;
    vm.comments = {};
    vm.internalComment = '';
    vm.externalComment = '';
    vm.valuateProducent = valuateProducent;
    vm.valuateTechnologist = valuateTechnologist;
    vm.valuatedPositions = {};
    vm.hasNotStandardPosition = false;
    vm.newAdditionalPossition = { quantity: 1, type: 'product', price: 0 };
    vm.confirmOrderEdit = confirmOrderEdit;
    vm.confirmShopOrderEdit = confirmShopOrderEdit;
    vm.setOrderStatus = setOrderStatus;
    vm.setOfferStatus = setOfferStatus;
    vm.sumDiscounts = PositionDetailedSummaryService.sumDiscounts;
    vm.dealersSellersList = [];
    vm.updateOfferDealerTaxRate = updateOfferDealerTaxRate;
    vm.setDealerSellerToOffer = confirmSetDealerSellerToOffer;
    vm.addClient = addClient;
    vm.extendedPdf = false;
    vm.round = round;
    vm.sellers = [];
    vm.offerOptions = offerOptions;
    vm.priceType = 'client';
    vm.order = false;
    vm.shopOrder = false;
    vm.hasClientDiscounts = false;
    vm.redirectToStoreCart = OfferViewFactory.redirectToStoreCart;
    vm.offerHeaderUpdating = false;
    vm.sendInquiry = sendInquiry;
    vm.goToConfigurator = goToConfigurator;
    vm.notValuatedPositionsList = [];
    vm.showNewOfferDiscountForm = showNewOfferDiscountForm;
    vm.hideNewOfferDiscountForm = hideNewOfferDiscountForm;
    vm.drawService = DrawService;
    vm.drawOptions = angular.merge({}, DrawService.options, { indexes: { multiplier: 3 } });
    vm.drawOptionsCoupled = angular.merge({}, DrawService.options, {
        indexes: { multiplier: 3 },
        dimensions: { multiplier: 1 },
    });
    vm.belowLogisticMinimum = () =>
        OfferTransportCostService.belowLogisticMinimum(UserService.getDealer(), vm.offer || {});
    vm.selectTransportCost = selectTransportCost;
    vm.transport_from_producent = '';
    vm.clientTransportCostVisible = false;
    vm.clientSplitTransportCost = false;
    vm.clientTransportCost = 0;
    vm.addClientTransportCost = () => (vm.clientTransportCostVisible = true);
    vm.availableConfigs = ConfiguratorsAvailabilityService.availableConfigs;
    vm.offer = {};
    vm.offerMontagesGroups = {};
    vm.offerMontagesDescriptions = [];
    vm.montageToSave = false;

    vm.openTransportCostInfo = openTransportCostInfo;
    vm.updateClientTransportCost = updateClientTransportCost;
    vm.toggleClientSplitTransportCost = toggleClientSplitTransportCost;
    vm.removeClientTransportCost = removeClientTransportCost;
    vm.inEditCustomKeyValue = false;
    vm.saveCustomKeyValue = saveCustomKeyValue;

    vm.focusInHandler = focusInHandler;
    vm.focusOutHandler = focusOutHandler;
    vm.updateOfferMargin = updateOfferMargin;
    vm.changeMontageAndMeasure = changeMontageAndMeasure;
    vm.translatedData = null;
    vm.openPayments = openPayments;
    vm.isSyncedAndOnline = isSyncedAndOnline;
    vm.isSyncedAndOnlineOrElectron = isSyncedAndOnlineOrElectron;
    vm.syncedOnce = false;

    vm.toggleSystemsComparison = toggleSystemsComparison;
    vm.selectGroupForSystemsComparison = selectGroupForSystemsComparison;
    vm.systemComparisonSubOffer = systemComparisonSubOffer;
    vm.inSystemsComparison = SystemsComparisonService.inSystemsComparison;
    vm.coupledPositionsQuantity = {
        positions: 0,
        quantity: 0
    };
    vm.changedGroups = SystemsComparisonService.changedGroups;
    vm.invalidPositions = SystemsComparisonService.invalidPositions;
    vm.invalidPositionIndex = null;
    vm.prevInvalidPosition = prevInvalidPosition;
    vm.nextInvalidPosition = nextInvalidPosition;
    vm.isEmpty = isEmpty;
    vm.montagesAvailable = montagesAvailable;

    vm.offerStatuses = OfferStatuses;
    vm.offerStatusDict = OfferStatusDict;
    vm.orderStatuses = OrderStatuses;
    vm.orderStatusDict = OrderStatusDict;
    vm.shopOrderStatuses = ShopOrderStatuses;
    vm.shopOrderStatusDict = ShopOrderStatusDict;

    vm.updateChanceId = updateChanceId;

    vm.pointOfService = {};
    vm.transportCostTypes = {};
    vm.usedSashTypes = [];

    vm.customPositionsImages = {};
    vm.getCustomPositionImage = getCustomPositionImage;
    vm.updatePositionMainImage = updatePositionMainImage;
    vm.updatePositionExtendedImage = updatePositionExtendedImage;
    vm.statusChange = statusChange;
    vm.sendCustomEmailToClient = sendCustomEmailToClient;
    vm.colorsPositions = [];
    vm.getSystemsComparisonColorPosition = getSystemsComparisonColorPosition;
    vm.systemsComparisonOptions = SystemsComparisonService.options;
    vm.selectSystemsComparisonOption = selectSystemsComparisonOption;
    vm.offerInTax = offerInTax;
    vm.getLastModifiedPosition = getLastModifiedPosition;
    vm.getLastSyncDatePosition = getLastSyncDatePosition;
    vm.lastUtcDateFromPosition = lastUtcDateFromPosition;
    vm.dealerClause = $sce.getTrustedHtml($rootScope.user?.dealer?.clause ?? '');
    OfferViewFactory.getTransportCostTypes().then(types => vm.transportCostTypes = types);
    recalcDrawDimension();

    $scope.$on('$destroy', () => {
        if (!SystemsComparisonService.edit.value) {
            SystemsComparisonService.inSystemsComparison.value = false;
            SystemsComparisonService.changedGroups = [];
            SystemsComparisonService.invalidPositions = [];
            vm.invalidPositionIndex = null;
            SystemsComparisonService.onOffer = false;
            vm.groups = SystemsComparisonService.originalGroups;
        }
    });

    const clearSyncedOnceListener = $scope.$watch('offersView.offer.synced', (newValue, oldValue) => {
        if (newValue === true && oldValue === false) {
            vm.syncedOnce = true;
            clearSyncedOnceListener();
        }
    });

    vm.shopOfferHeaderEdit = () => {
        return vm.offer.shop_order == 0 || vm.offer.shop_order == 1 && vm.offer.status != 7 && vm.offer.status != 8 && vm.offer.status != 11 && !vm.offer.valuation;
    };

    vm.shopOfferPositionsEdit = () => {
        return vm.offer.shop_order == 0 || vm.offer.shop_order == 1 && vm.offer.status == 10;
    };

    vm.showTaxMethodSelect = $rootScope.user.access && $rootScope.user.access !== 'producent';

    // Fix TypeError: "$rootScope.user.dealer is null"
    vm.producer_montages_allowed = $rootScope.user.access === 'producent'
        ? true
        : ($rootScope.user.dealer
            ? $rootScope.user.dealer.producer_montages_allowed
            : false);

    vm.calculateOwnMontageCosts = (group) => {
        const multiplier = DiscountsAndMultipliersService.getMontageMultiplier();
        MontagesServiceStatic.calculateOwnMontageCosts(group, vm.offer, multiplier);
        group.modified_own_montage_cost = group.own_montage_cost;
        group.modified_own_montage_cost_on_view = group.modified_own_montage_cost / vm.offer.currency.value;
        vm.updateMontages();
    }

    vm.calculateProducerMontageCosts = (group) => {
        const multiplier = DiscountsAndMultipliersService.getMontageMultiplier();
        MontagesServiceStatic.calculateProducerMontageCosts(group, vm.offer, multiplier);
        vm.updateMontages();
    }

    vm.updateMontages = (emit=true) => {
        return new Promise((resolve, reject) => {
            vm.offer.montages.groups = vm.offerMontagesGroups
            const sequenceKeys = OfferSequenceService.keysFromSequence(vm.offer.sequence);
            MontagesDataService.getMontages().then(returned => {
                vm.offerMontagesDescriptions = returned.montages.map(m => ({id: m.Montage.id, description: m.Montage.description, name: m.Montage.name}))
                MontagesService.setMontagesData(
                    vm.offer,
                    vm.groups,
                    returned.montages,
                    vm.offer.dealer_id,
                    $rootScope.user.marketId
                );
                ManyPositionsService.listById(sequenceKeys, vm.offer.tmp_id).then(allPositions => {
                    const offer = MontagesServiceStatic.updateMontages(vm.offer, vm.groups, allPositions.pos, $rootScope.user, $rootScope.user.market, IccConfig);
                    vm.offerMontagesGroups = Core.copy(offer.montages.groups);
                    $scope.$apply();
                    if (offer && emit) {
                        OffersService.update(offer.tmp_id, offer).then(() => {
                            OffersService.emitModifiedOffer();
                            resolve();
                        });
                    }
                });
            });
        });
    }

    vm.prepareMontages = () => {
        vm.montageToSave = true;
        const copyOffer = Core.copy(vm.offer);
        const copyGroups = Core.copy(vm.groups)
        copyOffer.montages.groups = vm.offerMontagesGroups;
        const sequenceKeys = OfferSequenceService.keysFromSequence(copyOffer.sequence);
        MontagesDataService.getMontages().then(returned => {
           vm.offerMontagesDescriptions = returned.montages.map(m => ({id: m.Montage.id, description: m.Montage.description, name: m.Montage.name}))
            MontagesService.setMontagesData(
                copyOffer,
                copyGroups,
                returned.montages,
                copyOffer.dealer_id,
                $rootScope.user.marketId
            );
            ManyPositionsService.listById(sequenceKeys, copyOffer.tmp_id).then(allPositions => {
                const offer = MontagesServiceStatic.updateMontages(copyOffer, copyGroups, allPositions.pos, $rootScope.user, $rootScope.user.market, IccConfig);
                vm.offerMontagesGroups = Core.copy(offer.montages.groups);
                $scope.$apply();
            });
        });
    }

    vm.saveMontages = () => {
        vm.montageToSave = false;
        vm.updateMontages();
    }

    vm.setMontageForGroup = (group) => {
        if(group.if_own_montage || group.if_producer_montage) {
            group.if_own_montage = false;
            group.if_producer_montage = false;
        } else if(group.own_montages.length > 0) {
            group.if_own_montage = true;
            group.own_montages[0].selected = true;
        } else if(vm.producer_montages_allowed && group.producer_montages.length > 0) {
            group.if_producer_montage = true;
            group.producer_montages[0].selected = true;
        }
        vm.prepareMontages();
    }

    vm.setProducerMontage = (group) => {
        if(!group.if_producer_montage) {
            group.if_producer_montage = true;
            group.if_own_montage = false;
            vm.unselectAllMontages(group)
            vm.prepareMontages();
        }
    }

    vm.setOwnMontage = (group) => {
        if(!group.if_own_montage) {
            group.if_own_montage = true;
            group.if_producer_montage = false;
            vm.unselectAllMontages(group)
            vm.prepareMontages();
        }
    }

    vm.unselectAllMontages = (group) => {
        group.own_montages.forEach(montage => montage.selected = false);
        group.producer_montages.forEach(montage => montage.selected = false);
    }

    vm.offerHasProducerMontages = (montagesGroup) => Object.values(montagesGroup).some(group => group.producer_montages.length > 0);

    vm.editMontageGroup = (montageGroup) => {
        OfferViewFactory.openModalMontageEdit(Core.copy(vm.offer), montageGroup).result.then(group => {
            if(group) {
                montageGroup = group;
                vm.prepareMontages();
            }
        });
    }

    vm.openModalMontageDescription = (montageId) => {
        if(vm.offerMontagesDescriptions.length === 0) {
            MontagesDataService.getMontages().then(returned => {
                vm.offerMontagesDescriptions = returned.montages.map(m => ({id: m.Montage.id, description: m.Montage.description, name: m.Montage.name}));
                const description = vm.offerMontagesDescriptions.find(m => m.id === montageId);
                OfferViewFactory.openModalMontageDescription(description.description, description.name);
            })
        } else {
            const description = vm.offerMontagesDescriptions.find(m => m.id === montageId);
            OfferViewFactory.openModalMontageDescription(description.description, description.name);
        }
    }

    vm.checkModifiedPrice = function(group) {
        group.modified_own_montage_cost = group.modified_own_montage_cost_on_view * vm.offer.currency.value;
        if(group.modified_own_montage_cost_on_view == null) {
            group.modified_own_montage_cost = group.own_montage_cost;
            group.modified_own_montage_cost_on_view = group.modified_own_montage_cost / vm.offer.currency.value;

        }
        vm.updateMontages();
    };

    vm.clientTaxValue = (vm.offer.client_price + vm.offer.measure_price) * getTaxValue(vm.offer.tax_rate_id) / 100

    let getOfferOnceRun = null


    const offerElements = {};

    if (ConfiguratorsDataService.loaded) {
        vm.taxes = ConfiguratorsDataService.data.taxRates;
        vm.extendedPdf = ConfiguratorsDataService.data.marketExtendedPdf;
    }

    if (isOnline()) {
        const offerId = getOfferId();
        StateService.setKey('offer_id', offerId);
        OffersFactory.exists(offerId).then(
            exists => getOfferOnceRun === null && exists && getOffer()
        ).then(
            () => vm.offer && OfferViewFactory.updateOfferCurrency(vm.offer)
        );
    } else {
        getOffer();
    }

    vm.positions_tax_rates = {};
    vm.updatingVatType = false;

    vm.updateVatType = async (type, $event) => {
        if (!vm.updatingVatType) {
            vm.updatingVatType = true;
            if(type == "local") {
                let positions = vm.offer.sequence.map(e => Object.values(e)).reduce((a,b) => a.concat(b)).reduce((a,b) => a.concat(b));
                for(let i = 0; i < positions.length; i++) {
                    vm.positions_tax_rates[positions[i]] = getTaxValue(vm.offer.tax_rate_id) || null;
                }
            } else if(type == "global") {
                vm.positions_tax_rates = {};
                vm.clientTaxValue = (vm.offer.client_price + vm.offer.measure_price) * getTaxValue(vm.offer.tax_rate_id) / 100
            }
            vm.offer.positions_tax_rates = vm.positions_tax_rates;
            var offer = vm.offer;
            await OffersService.update(offer._id, angular.extend(offer, {positions_tax_rates: vm.offer.positions_tax_rates}));
            getOffer();
            updatedOfferData(['Offer']);
            vm.updateTaxValues();
            $event && $event.target.blur();
            vm.updatingVatType = false;
        }
    }

    vm.updateTaxValues = () => {
        const positions = vm.groups
            .map(positionGroup => positionGroup.rows)
            .reduce((a, b) => a.concat(b), [])
            .map(pos => ({
                id: pos.id,
                price: pos.doc.client_price,
                dealerPrice: pos.doc.client_price,
                taxRate: vm.positions_tax_rates[pos.id],
                quantity: pos.doc.quantity,
            }));
        const taxRateValue = getTaxValue(vm.offer.tax_rate_id);
        let value = 0;
        vm.taxes_values = {};
        vm.dealerTaxesValues = {};
        if (vm.vat_type == 'local') {
            vm.clientTaxValue = 0;
            vm.dealerClientTaxValue = 0;
            vm.dealerTaxValue = 0;
            for (const pos of positions) {
                if (pos.taxRate) {
                    if(vm.offer.tax_method === 'offer') {
                      value = ((pos.price * pos.taxRate) / 100) * pos.quantity;
                    } else {
                        value = Core.round((pos.price * pos.taxRate) / 100) * pos.quantity;
                    }
                    if (vm.taxes_values[+pos.taxRate]) {
                        vm.taxes_values[+pos.taxRate] += value;
                    } else {
                        vm.taxes_values[+pos.taxRate] = value
                    }
                    if(vm.offer.tax_method === 'offer') {
                        value = ((pos.dealerPrice * pos.taxRate) / 100) * pos.quantity;
                      } else {
                        value = Core.round((pos.dealerPrice * pos.taxRate) / 100) * pos.quantity;
                      }
                    if (vm.dealerTaxesValues[+pos.taxRate]) {
                        vm.dealerTaxesValues[+pos.taxRate] += value
                    } else {
                        vm.dealerTaxesValues[+pos.taxRate] = value;
                    }
                    if(vm.offer.tax_method === 'offer') {
                        vm.clientTaxValue += ((pos.price *  (1 - vm.offer.client_discount_special / 100) * pos.taxRate) / 100) * pos.quantity;
                        vm.dealerClientTaxValue  += ((pos.price * pos.taxRate) / 100) * pos.quantity;
                        vm.dealerTaxValue  += ((pos.dealerPrice * pos.taxRate) / 100) * pos.quantity;
                    } else {
                        vm.clientTaxValue += Core.round((pos.price *  (1 - vm.offer.client_discount_special / 100) * pos.taxRate) / 100) * pos.quantity;
                        vm.dealerClientTaxValue  += Core.round((pos.price * pos.taxRate) / 100) * pos.quantity;
                        vm.dealerTaxValue  += Core.round((pos.dealerPrice * pos.taxRate) / 100) * pos.quantity;
                    }

                }
            }
            if (vm.offer.measure_price > 0) {
                if(vm.offer.tax_method === 'offer') {
                    value = (vm.offer.measure_price * taxRateValue) / 100;
                } else {
                    value = Core.round((vm.offer.measure_price * taxRateValue) / 100);
                }
                vm.taxes_values[+taxRateValue] += value;
                vm.clientTaxValue += value;
            }
            if (vm.offer.transport_cost > 0) {
                if(vm.offer.tax_method === 'offer') {
                    value = (vm.offer.transport_cost * taxRateValue) / 100;
                } else {
                    value = Core.round((vm.offer.transport_cost * taxRateValue) / 100);
                }
                vm.taxes_values[+taxRateValue] += value;
                vm.dealerTaxesValues[+taxRateValue] += value;
                vm.clientTaxValue += value;
                vm.dealerClientTaxValue  += value;
                vm.dealerTaxValue  += value;
            }
            if (vm.offer.client_montages_price_before_discount > 0) {
                value = (Core.roundPrice(
                    vm.offer.client_montages_price_before_discount *
                        ((100 -
                            (!IccConfig.Offer.newSummaryOrder ? vm.offer.client_discount_position : 0) -
                            vm.offer.client_discount_special) /
                            100)
                ) *
                    taxRateValue) /
                100;
                if(vm.offer.tax_method === 'position') {
                    value = Core.round(value);
                }
                vm.taxes_values[+taxRateValue] += value;
                vm.clientTaxValue += value;
            }
            if (vm.offer.dealer_montages_price_before_discount > 0) {
                value = (Core.roundPrice(
                    vm.offer.dealer_montages_price_before_discount *
                        ((100 -
                            vm.offer.dealer_discount_producer -
                            vm.offer.dealer_discount_producer_special) /
                            100)
                ) *
                    taxRateValue) /
                100;
                if(vm.offer.tax_method === 'position') {
                    value = Core.round(value);
                }
                vm.dealerTaxesValues[+taxRateValue] += value;
                vm.dealerClientTaxValue += value;
                vm.dealerTaxValue += value;
            }
        } else {
            if(vm.offer.tax_method === 'offer') {
                vm.clientTaxValue =
                    ((vm.offer.client_price + vm.offer.measure_price) * taxRateValue) / 100;
                vm.dealerClientTaxValue =
                    vm.offer.dealer_client_price * taxRateValue / 100;
            } else {
                vm.clientTaxValue = 0;
                vm.dealerClientTaxValue = 0;
                vm.dealerTaxValue = 0;
                for (const pos of positions) {
                    vm.clientTaxValue +=
                        Core.round(pos.price * (1 - vm.offer.client_discount_special / 100) * taxRateValue / 100) * pos.quantity;
                    vm.dealerClientTaxValue +=
                        Core.round(pos.price * taxRateValue / 100) * pos.quantity;
                }
                vm.clientTaxValue +=
                    Core.round((vm.offer.measure_price * taxRateValue) / 100);
                vm.clientTaxValue +=
                    Core.round((vm.offer.transport_cost * taxRateValue) / 100);
                value = Core.roundPrice(vm.offer.client_montages_price_before_discount *
                    ((100 - ((!IccConfig.Offer.newSummaryOrder ? vm.offer.client_discount_position : 0) - vm.offer.client_discount_special) / 100)));
                vm.clientTaxValue +=
                    Core.round((value * taxRateValue) / 100);

                value = Core.roundPrice( vm.offer.dealer_montages_price_before_discount *
                            ((100 - vm.offer.dealer_discount_producer - vm.offer.dealer_discount_producer_special) / 100));

                vm.dealerClientTaxValue +=
                    Core.round((vm.offer.transport_cost * taxRateValue) / 100);
                vm.dealerClientTaxValue +=
                    Core.round((value * taxRateValue) / 100);

            }
            vm.dealerTaxValue =
                vm.offer.dealer_price * vm.offer.dealer_tax_rate / 100;
        }
    };

    vm.openBatchTaxRatesModal = () => {
        let systems_tax_rates = vm.groups.map(
            positionGroup => positionGroup.rows
        ).reduce(
            (a, b) => a.concat(b), []
        ).map(pos => {
                if (['window', 'hs', 'door', 'folding_door'].indexOf(pos.doc.confType) > -1) {
                    return {
                        id: pos.doc.detailsForView.system.id,
                        name: pos.doc.detailsForView.system.name
                    };
                } else if (pos.doc.confType === 'roller_shutter') {
                    return {
                        id: 'roller_shutter_' + pos.doc.detailsForView.rollerShutter.system.id,
                        name: pos.doc.detailsForView.rollerShutter.system.name
                    };
                } else if (pos.doc.confType === 'additional' || pos.doc.confType === 'other') {
                    return {
                        id: pos.doc.confType,
                        name: pos.doc.confType === 'additional' ? $filter('translate')('OFFER|Pozycje dodatkowe') : $filter('translate')('OFFER|Pozycje inne')
                    };
                } else {
                    return {
                        id: pos.doc.confType,
                        name: pos.doc.name
                    };
                }
        }).filter(
            (system,index,systems_array) => systems_array.findIndex(
                current_system => current_system.id == system.id
            ) === index
        ).map(
            system => {
                system.tax_rate = getTaxValue(vm.offer.tax_rate_id);
                return system;
            }
        );
        TaxRatesService.openModal(systems_tax_rates, vm.setBatchTaxRates, vm.taxes);
    }

    vm.setBatchTaxRates = (tax_rates) => {
        vm.groups.map(
            positionGroup => positionGroup.rows
        ).reduce(
            (a, b) => a.concat(b), []
        ).map(pos => {
            if (['window', 'hs', 'door', 'folding_door'].indexOf(pos.doc.confType) > -1) {
                return {
                    id: pos.id,
                    system: pos.doc.detailsForView.system.id
                };
            } else if (pos.doc.confType === 'roller_shutter') {
                return {
                    id: pos.id,
                    system: 'roller_shutter_' + pos.doc.detailsForView.rollerShutter.system.id
                };
            } else {
                return {
                    id: pos.id,
                    system: pos.doc.confType
                };
            }
        }).forEach(
            pos => vm.positions_tax_rates[pos.id] = tax_rates.find(
                rate => rate.id == pos.system
            ).tax_rate
        );
        vm.updateVatType();
        TaxRatesService.closeModal();
    }

    const subscriptions = [];
    let syncedEvents = [];
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration(['synced'], eventData => {
            if (getOfferOnceRun === null || getOfferOnceRun) {
                syncedHandler(eventData);
            } else {
                syncedEvents.push(eventData);
            }
        })
    );
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('loadedConfiguratorsData', () => {
            vm.taxes = ConfiguratorsDataService.data.taxRates;
            vm.extendedPdf = ConfiguratorsDataService.data.marketExtendedPdf;
        })
    );
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration(
            ['availableConfigs'],
            data => (vm.availableConfigs = data.value)
        )
    );
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Zaokrąglanie liczby do 2 miejsc po przecinku
     * @param  {number} num liczba do zaokrąglenia
     * @return {number}     zaokrąglona liczba
     */
    function round(num) {
        return Core.round(num);
    }

    function editPosition(pos) {
        if (vm.inSystemsComparison.value) {
            SystemsComparisonService.edit.value = true;
            SystemsComparisonService.editId = pos.id;
        }
        EditPositionFactory.editPosition(pos, vm.attachments[pos.id]);
    }

    /**
     * Otwieranie datepickera
     * @param  {object} $event Event
     */
    function openDeadline($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vm.openedDeadline = true;
    }

    /**
     * Otwieranie datepickera
     * @param  {object} $event Event
     */
    function openDeliveryDate($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vm.openedDeliveryDate = true;
    }

    /**
     * Otwieranie datepickera
     * @param  {object} $event Event
     */
    function openAdvance($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vm.openedAdvance = true;
    }

    /**
     * Otwieranie datepickera
     * @param  {object} $event Event
     */
    function openWarranty($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vm.openedWarranty = true;
    }

    function setOfferDrawSymbolOptions(){
        if (theme){
            vm.drawOptions.base.showSymbolsForWindow = theme.show_open_symbols_window;
            vm.drawOptions.base.showSymbolsForFullDoors = theme.show_open_symbols_full_doors;
            vm.drawOptions.base.showSymbolsForProfileDoors = theme.show_open_symbols_profile_doors;
            vm.drawOptions.colors.strokeContrastRatio = theme.theme_stroke_contrast || 30;
            vm.drawOptionsCoupled.base.showSymbolsForWindow = theme.show_open_symbols_window;
            vm.drawOptionsCoupled.base.showSymbolsForFullDoors = theme.show_open_symbols_full_doors;
            vm.drawOptionsCoupled.base.showSymbolsForProfileDoors = theme.show_open_symbols_profile_doors;
            vm.drawOptionsCoupled.colors.strokeContrastRatio = theme.theme_stroke_contrast || 30;
        }
    }

    /**
     * Pobieranie szczegółów oferty
     */
    function getOffer(nextRun = false) {
        getOfferOnceRun = false;
        var id = getOfferId();
        $scope.synced.val = false;
        setOfferDrawSymbolOptions();
        let existingData = null;
        if (nextRun) {
            existingData = {
                offer: vm.offer,
                positions: vm.groups.reduce((prev, group) => {
                    prev.push(...group.rows);
                    return prev;
                }, []),
            };
        }
        return OfferViewFactory.getOffer(id, existingData).then(
            ({
                offer,
                groups,
                transportCost,
                transportCostPos,
                discounts,
                offerLoaded,
                notFoundPositions,
                translatedData,
                emptyOffer,
            }) => {
                loadedOffer = offerLoaded;
                vm.offer = offer;
                vm.drawOptions.dimensions.ratio = vm.offer.dimension_unit == 'mm' ? 1 : IccConfig.Configurators.inchToMilimeter;

                transportCostPosition = transportCostPos;
                vm.transportCost.old = transportCost;
                vm.transportCost.new = transportCost / offer.currency.value;
                vm.discounts = discounts;
                vm.translatedData = translatedData;

                updateOfferDependents();

                if (vm.inSystemsComparison.value) {
                    vm.groups = Core.copy(SystemsComparisonService.groups);
                    vm.colorsPositions = ColorsPositionService.groupPositionsByColors(vm.groups.reduce((prev, curr) => prev = [...prev, ...curr.rows], []), vm.offer);
                } else {
                    vm.groups = groups;
                    vm.groups = vm.groups.map(group => {
                        group.rows = group.rows.map(row => {
                            if(row.doc.configuration.type !== 'coupled_window' ) {
                                row.drawOptions = Core.copy(vm.drawOptions)
                                if (row.doc.configuration?.System?.image_side_on_offer) {
                                    row.drawOptions.base.side = row.doc.configuration?.System?.image_side_on_offer;
                                }
                            } else {
                                row.doc.detailsForView.subPositions = row.doc.detailsForView.subPositions.map(subPos => {
                                    subPos.drawOptions = Core.copy(vm.drawOptions)
                                    if (subPos.doc.configuration?.System?.image_side_on_offer) {
                                        subPos.drawOptions.base.side = subPos.doc.configuration?.System?.image_side_on_offer;
                                    }
                                    return subPos;
                                });
                            }
                            return row;
                        });
                        return group;
                    });
                    vm.coupledPositionsQuantity = vm.groups.reduce((value, group) =>
                        ({
                            positions: value.positions + group.rows.reduce((prev, pos) => prev + (pos.doc.confType === 'coupled_window' ? 1 : 0),0),
                            quantity: value.quantity + group.rows.reduce((prev, pos) => prev + (pos.doc.confType === 'coupled_window' ? pos.doc.quantity : 0),0),
                        })
                    , {
                        positions: 0,
                        quantity: 0
                    });
                }

                vm.notValuatedPositionsList = getNotValuatedPositions(groups);
                activeClient(offer, emptyOffer);
                notFoundInfo(notFoundPositions, offer);
                modalFirstPosition(offer);
                getFirstDealersList();
                getAdditionals();
                if(IccConfig.Offer.commentsToParentOffer && offer.parent_id) {
                    getComments(offer.parent_id);
                } else {
                    getComments(offer.tmp_id);
                }
                getAttachments(offer);

                if (
                    offer.demo_target
                    && (!$rootScope.user
                        || !$rootScope.user.access
                        || $rootScope.user.access === 'klient')
                ) {
                    getTaxRates();
                }

                if (angular.isDefined($rootScope.user) && $rootScope.user.access === 'producent') {
                    getDealers();
                }
                if (IccConfig.Client.types) {
                    getClientsTypes();
                }
                if (
                    $rootScope.user
                    && ($rootScope.user.access == 'producent'
                        || $rootScope.user.access == 'technolog'
                        || $rootScope.user.access == 'dealer'
                        || $rootScope.user.access == 'dealerh')
                ) {
                    getSellers();
                }

                if (
                    vm.offer.order == 0
                    && ($rootScope.user.access == 'producent'
                        || $rootScope.user.access == 'dealer'
                        || $rootScope.user.access == 'dealerh')
                ) {
                    getDealerSellersList(vm.offer.dealer_id);
                    vm.pickDealerSeller = offer.dealer_seller_id > 0 ? offer.dealer_seller_id : -1;
                }

                if (IccConfig.PDF.showCompletion) {
                    vm.completion = new Completion(vm.groups, $rootScope.curLang, vm.offer.currency);
                }
                if (IccConfig.GTM.allowTags && !groups['0'] && !$rootScope.loader) {
                    EventBusService.post({
                        key: 'googleTags',
                        value: 'addOffer',
                    });
                }
                offerElements[offer.tmp_id] = {
                    synced: offer.synced,
                    notStandardPosition: false,
                    mistakeProduct: false,
                    notRectangular: false,
                };

                for (const group of vm.groups) {
                    for (const row of group.rows) {
                        offerElements[row.doc.tmp_id] = {
                            synced: row.doc.synced,
                            notStandardPosition: !row.doc.standard,
                            mistakeProduct: row.doc.configuration.IsMistakeProduct,
                            notRectangular:
                                angular.isDefined(row.doc.configuration.Shape)
                                && angular.isDefined(row.doc.configuration.Shape.shape)
                                && row.doc.configuration.Shape.shape != 'rect',
                        };
                    }
                }
                updateOfferViewProperties();
                getOfferOnceRun = true;

                if (syncedEvents.length > 0) {
                    syncedEvents.forEach(syncedHandler);
                    syncedEvents = [];
                }

                vm.positions_tax_rates = vm.offer.positions_tax_rates;
                vm.vat_type = Object.keys(vm.offer.positions_tax_rates).length > 0 ? "local" : 'global';
                vm.pointOfService = Core.parseJson(vm.offer.point_of_service);
                vm.external_order_information = Core.parseJson(vm.offer.external_order_information);

                vm.updateTaxValues();

                if (!vm.montageToSave) {
                    vm.offerMontagesGroups = Core.copy(vm.offer.montages.groups);
                }

                if(!!vm.offer.tmp_id && IccConfig.Offer.copyOfferByClient) {
                    if(window.location.pathname.match('/client_offers_view/dealer/') && $rootScope.user.access === 'dealer') {
                        vm.addSubOffer(true);
                    } else if(window.location.pathname.match('/client_offers_view/client/')) {
                        vm.addSubOffer();
                    } else if(window.location.pathname.match('/client_offers_view/dealer/') && $rootScope.user.access === 'producent') {
                        $location.path('/app/offers_view/' + vm.offer.tmp_id);
                    }
                }
            }

        );
    }

    async function getTaxRates() {
        vm.taxes = (await OfferViewFactory.getTaxRates()) || [];
        vm.updateTaxValues();
    }

    /**
     * Obsługa komunikatu o ilości pobranych pozycji
     * @param  {array} notFoundPositions Pozycje, ktorych nie udalo sie pobrac
     * @param  {Object} offer            Ofera
     */
    function notFoundInfo(notFoundPositions, offer) {
        if (notFoundPositions) {
            $rootScope.notFoundPositions = notFoundPositions;
            $rootScope.offer_id = offer.tmp_id;
            $rootScope.text = false;
            $rootScope.showFix = true;
            $rootScope.order = !!~~offer.order;
            $rootScope.shopOrder = !!~~offer.shop_order;
        } else {
            if (!$rootScope.addSimilar) {
                $rootScope.loader = false;
            }
            $rootScope.notFoundPositions = undefined;
            $rootScope.offer_id = undefined;
            $rootScope.showFix = false;
            $rootScope.order = undefined;
            $rootScope.shop_order = undefined;
        }
    }

    /**
     * Obsługa okienka po dodaniu pierwszej pozycji
     * @param  {object} offer Oferta aktualna
     */
    function modalFirstPosition(offer) {
        if (
            !~~offer.modal_opened
            && ((IccConfig.Offer.B2C.modalFirstPosition && offer.client_offer)
                || (IccConfig.Offer.B2B.modalFirstPosition && !offer.client_offer))
        ) {
            offer.modal_opened = true;
            OffersService.update(vm.offer._id, angular.extend(vm.offer, { modal_opened: true }));
            InfoService.openInfoModal({
                template: 'offersInfo.html',
                title: $filter('translate')('OFFER|Dodałeś pozycję do oferty'),
                footer: true,
            });
        }
    }

    /**
     * Zwraca Id oferty/zamówienia
     * @return {[type]} [description]
     */
    function getOfferId() {
        return (
            $routeParams.id
            || (window.location.pathname.match('tmp_offer_.*') !== null
                ? window.location.pathname.match('tmp_offer_.*')[0]
                : null)
        );
    }

    /**
     * Pobranie danych obecnego klienta
     * @param  {object} offer Oferta
     */
    function activeClient(offer, emptyOffer) {
        OfferViewFactory.activeClient(offer, emptyOffer, function activeClientSuccess(c, cs) {
            $scope.clientvariable = c;
            vm.Client = Core.copy(c);
            $scope.listOfClients = cs;
        });
    }

    /**
     * Pobieranie załącznikow do oferty
     * @param  {string} offer Id oferty
     */
    function getAttachments(offer) {
        OfferViewFactory.getAttachments(offer)
            .then(attachments => (vm.attachments = attachments))
            .then(() => {
                if (IccConfig.Offer.customPositionsImages) {
                    vm.customPositionsImages = OfferViewFactory
                        .getCustomPositionsImages(vm.groups, vm.attachments);
                }
            });
    }

    /**
     * Pobieranie dealerów dla handlowca
     */
    function getDealers() {
        OfferViewFactory.getDealers().then(dealers => {
            vm.dealers = dealers || [];
            vm.dealer = vm.dealers.find(e => e && vm.offer && e.id == vm.offer.dealer_id);
        });
    }

    /**
     * Pobieranie rodzajów klientów
     */
    function getClientsTypes() {
        OfferViewFactory.getClientsTypes().then(clientsTypes => {
            vm.clientsTypes = clientsTypes || {};
        });
    }

    /**
     * Pobieranie innych handlowców u handlowca
     */
    function getSellers() {
        OfferViewFactory.getSellers().then(sellers => {
            vm.sellers = sellers || [];
        });
    }

    /**
     * Pobieranie produktów dodatkowych
     */
    function getAdditionals() {
        AdditionalsFactory.get().then(additionals => {
            vm.dealerProducts = additionals || [];
        });
    }

    /**
     * Blokowanie odświeżeia pola w momencie przyjscia synchronizacji
     * @param {object} [pos] pozycja
     * @param {string} [field] nazwa edytowanego pola
     */
    function isEdited(pos, field, event) {
        var id = pos.tmp_id || pos.doc.tmp_id;

        if (angular.isUndefined(pos.isEdited)) {
            pos.isEdited = {};
        }
        if (angular.isUndefined($scope.inEdit[id])) {
            $scope.inEdit[id] = {};
        }

        if (event.type === 'focus' || event.type === 'click') {
            pos.isEdited[field] = true;
            $scope.inEdit[id][field] = true;
            $timeout(() => {
                if (document.querySelector("[id='" + id + "'] [ng-model='conf." + field + "']")) {
                    document
                        .querySelector("[id='" + id + "'] [ng-model='conf." + field + "']")
                        .focus();
                }
                if (
                    document.querySelector("[id='" + id + "'] [ng-model='pos.doc." + field + "']")
                ) {
                    document
                        .querySelector("[id='" + id + "'] [ng-model='pos.doc." + field + "']")
                        .focus();
                }
                if (
                    document.querySelector(
                        "[id='" + id + "'] [ng-model='offersView.offer." + field + "']"
                    )
                ) {
                    document
                        .querySelector(
                            "[id='" + id + "'] [ng-model='offersView.offer." + field + "']"
                        )
                        .focus();
                }
            });
        } else if (event.type === 'blur') {
            delete pos.isEdited[field];
            delete $scope.inEdit[id][field];
            if (angular.equals($scope.inEdit[id], {})) {
                delete $scope.inEdit[id];
            }

            if (angular.equals($scope.inEdit, {}) && vm.offerToCheck) {
                vm.offerToCheck = false;
                getOffer().then(() => {});
            }
        }
    }

    /**
     * Sprawdzanie statusu online
     * @returns {boolean} true - online, false - offline
     */
    function isOnline() {
        return OnlineStatusService.getStatus();
    }

    /**
     * Uaktulanienie oferty po przyjściu zmian pozycji lub ofery z zewnątrz
     * @param  {object} event Zdarzenie
     * @param  {object} data  Parametry
     */
    function syncedHandler(eventData) {
        const data = eventData.value;
        if (data.elements.length) {
            if (getOfferOnceRun) {
                const sequenceKeys = OfferSequenceService.keysFromSequence(vm.offer.sequence);
                ManyPositionsService.listById(sequenceKeys, vm.offer.tmp_id).then(allPositions => {
                    if (data.elements.indexOf('Offer') > -1) {
                        const syncedCurrentOffer =
                            angular.isObject(vm.offer)
                            && data.Offer.filter(row => row.tmp_id === vm.offer.tmp_id);
                        if ((syncedCurrentOffer && syncedCurrentOffer.length) || loadedOffer) {
                            checkInEdit(() => changedOfferHandler(syncedCurrentOffer[0]));
                        }
                    }
                    if (data.elements.indexOf('Position') > -1) {
                        const syncedCurrentOfferPositions =
                            angular.isObject(vm.offer)
                            && data.Position.filter(row => row.dealer_offer_id === vm.offer.tmp_id && sequenceKeys.includes(row.tmp_id));
                        if (
                            (syncedCurrentOfferPositions && syncedCurrentOfferPositions.length)
                            || loadedOffer
                        ) {
                            syncedCurrentOfferPositions.sort((a, b) => b.confType !== 'coupled_window' ? 1 : -1);
                            checkInEdit(() =>
                                changedOfferPositionsHandler(
                                    syncedCurrentOfferPositions,
                                    allPositions
                                )
                            );
                        }
                    }
                    if (
                        data.elements.indexOf('PositionAttachment') > -1
                        || data.elements.indexOf('OfferAttachment') > -1
                    ) {
                        const syncedCurrentOfferAttachments =
                            angular.isObject(vm.offer)
                            && data.OfferAttachment
                            && data.OfferAttachment.filter(
                                row => row.dealer_offer_id === vm.offer.tmp_id
                            );
                        const syncedCurrentOfferPositionsAttachments =
                            angular.isObject(vm.offer)
                            && data.PositionAttachment
                            && data.PositionAttachment.filter(
                                row => row.dealer_offer_id === vm.offer.tmp_id
                            );
                        if (
                            (syncedCurrentOfferAttachments && syncedCurrentOfferAttachments.length)
                            || (syncedCurrentOfferPositionsAttachments
                                && syncedCurrentOfferPositionsAttachments.length)
                            || loadedOffer
                        ) {
                            checkInEdit(() => getAttachments(vm.offer));
                        }
                    }
                    updateOfferViewProperties();

                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                });
            } else {
                if (getOfferOnceRun === null) {
                    getOffer()
                        .then(() => vm.offer && OfferViewFactory.updateOfferCurrency(vm.offer))
                }
            }
        }
    }

    function checkInEdit(callback) {
        if (angular.equals($scope.inEdit, {})) {
            callback();
        } else {
            vm.offerToCheck = true;
        }
    }

    function changedOfferHandler(updatedOffer) {
        if (updatedOffer) {
            const dbRevision = StateService.state.offer && JSON.parse(StateService.state.offer.revision);
            const docRevision = JSON.parse(updatedOffer.revision);
            const actual = (
                !docRevision[getMachineId($rootScope.user)]
                || (dbRevision
                    && dbRevision[getMachineId($rootScope.user)]
                    && docRevision[getMachineId($rootScope.user)]
                    && dbRevision[getMachineId($rootScope.user)] === docRevision[getMachineId($rootScope.user)])
            );
            if (actual) {
                Object.assign(vm.offer, OffersServiceStatic.parseOfferValues(updatedOffer));
                updatePositionGroups(vm.offer.positions_groups_data);
                updateOfferDependents();
                loadedOffer = false;
                MontagesDataService.getMontages().then(returned => {
                    MontagesService.setMontagesData(
                        vm.offer,
                        vm.groups,
                        returned.montages,
                        vm.offer.dealer_id,
                        $rootScope.user.marketId
                    );
                });
                vm.groups.forEach(group => {
                    if (group.confType == 'window') {
                        const multiplier = DiscountsAndMultipliersService.getMontageMultiplier();
                        MontagesServiceStatic.calculateProducerMontageCosts(group, vm.offer, multiplier);
                        MontagesServiceStatic.calculateOwnMontageCosts(group, vm.offer, multiplier);
                    }
                });

                if (!$scope.$$phase) {
                    $scope.$apply();
                }
            }
        }
        vm.updateTaxValues();
    }

    function updateOfferDependents() {
        const offer = vm.offer;
        offerElements[offer.tmp_id] = {
            synced: offer.synced,
            notStandardPosition: false,
            mistakeProduct: false,
            notRectangular: false,
        };

        vm.dealerPrices = $rootScope.user.access == 'producent' && offer.dealer_id;
        vm.priceType = OfferPriceService.getPriceType(
            offer,
            offerOptions.orderPreview,
            $rootScope.user.access
        );
        vm.order = !!~~offer.order && offerOptions.orderPreview;
        vm.shopOrder = !!~~offer.shop_order;
        vm.offer.deadline =
            offer.deadline == null ? offer.deadline : format(parseDate(offer.deadline), 'yyyy-MM-dd');
        vm.clientTransportCost = vm.offer.client_transport_cost / (!offer.currency.pricesInCurrency ? offer.currency.value : 1) || 0;
        vm.clientTransportCostVisible =
            vm.clientTransportCostVisible || !!vm.offer.client_transport_cost;
        vm.clientSplitTransportCost = vm.offer.client_split_transport_cost;
        vm.hasClientDiscounts = offer && offer.group_discounts && offer.group_discounts.some(d => d.client);

        if (Object.keys(vm.offerHeader).length == 0) {
            vm.offerHeader = {
                seller_id: offer.seller_id + '',
                deadline: offer.deadline === null ? offer.deadline : new Date(offer.deadline),
                delivery_date:
                    offer.delivery_date === null
                        ? offer.delivery_date
                        : new Date(offer.delivery_date),
                advance_date:
                    offer.advance_date === null ? offer.advance_date : new Date(offer.advance_date),
                advance: Core.round(offer.advance / (!offer.currency.pricesInCurrency ? offer.currency.value : 1)),
                enova: offer.enova,
                status: offer.status,
                warranty_date:
                    offer.warranty_date === null
                        ? offer.warranty_date
                        : new Date(offer.warranty_date),
                transport_cost: Core.round(
                    (offer.order
                        ? offer.transport_cost
                        : OfferTransportCostService.transportM2Cost(
                              UserService.getDealer(),
                              offer
                          ))
                ),
                note: ''
            };
        }

        vm.transport_from_producent = offer.transport_from_producent ? 'producent' : '';
        if (
            vm.offer.order == 1
            && vm.offer.status == '1'
            && $rootScope.user.access == 'producent'
        ) {
            OfferHistoryService.addHistoryEntry(vm.offer, $rootScope.user, [{
                type: 'status',
                oldStatus: offer.status,
                status: 2
            }]);
            vm.offerHeader.status = '2';
            vm.offer.status = '2';
            OffersService.update(vm.offer._id, angular.extend(vm.offer, { synced: false }));
        } else if (
            vm.offer.order == 0
            && vm.offer.status == '2'
            && $rootScope.user.access == 'producent'
        ) {
            vm.offerHeader.status = '5';
            vm.offer.status = '5';
            OfferHistoryService.addHistoryEntry(vm.offer, $rootScope.user, [{
                type: 'status',
                oldStatus: offer.status,
                status: 5
            }]);
            OffersService.update(vm.offer._id, angular.extend(vm.offer, { synced: false }));
        }
    }

    function updatedOfferData(elements, positions = []) {
        const sequenceKeys = OfferSequenceService.keysFromSequence(vm.offer.sequence);
        ManyPositionsService.listById(sequenceKeys, vm.offer.tmp_id).then(allPositions => {
            if (elements.indexOf('Offer') > -1) {
                updateOfferDependents();
            }
            if (elements.indexOf('Position') > -1) {
                positions.forEach(position => updatePositionData(position, allPositions));
                updatePositionsDetails(positions, allPositions).then(() => {
                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                });
            }
            if (elements.length) {
                updateOfferViewProperties();
            }
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        });
    }

    function updatePositionGroups(offerPositionsGroupsData) {
        vm.groups.forEach(group => {
            group.groupData = offerPositionsGroupsData[group.groupCode];
        });
    }

    function changedOfferPositionsHandler(updatedPositions, allPositions) {
        if (updatedPositions) {
            let indexPositions = 0;
            let transportCost = null;
            let transportCostPos = null;
            let discounts = null;
            const currency = Core.copy(vm.offer.currency);
            const updatedGroupPositions = [];
            updatedPositions.forEach(position => {
                let foundedGroup = false;
                let currentPosition = null;
                let currentPositionRow = null;
                for (const group of vm.groups) {
                    if (group.groupCode === position.groupCode) {
                        const groupSequence = vm.offer.sequence.find(
                            g => Object.keys(g)[0] === position.groupCode
                        );
                        currentPositionRow = group.rows.find(
                            row => row.doc.tmp_id === position.tmp_id
                        );
                        if (currentPositionRow) {
                            Object.assign(
                                currentPositionRow.doc,
                                PositionService.parsePositionValues(position)
                            );
                            currentPosition = currentPositionRow.doc;
                        } else if (!position.deleted) {
                            let inGroupIndex = group.rows.length;
                            if (groupSequence) {
                                inGroupIndex = groupSequence[Object.keys(groupSequence)[0]].indexOf(
                                    position.tmp_id
                                );
                            }
                            currentPositionRow = {
                                id: position.tmp_id,
                                key: position.tmp_id,
                                doc: PositionService.parsePositionValues(position),
                            };
                            if (currentPositionRow.doc.configuration.type !== 'coupled_window' ) {
                                currentPositionRow.drawOptions = Core.copy(vm.drawOptions);
                                if (currentPositionRow.doc.configuration?.System?.image_side_on_offer) {
                                    currentPositionRow.drawOptions.base.side = currentPositionRow.doc.configuration?.System?.image_side_on_offer;
                                }
                            }
                            currentPosition = currentPositionRow.doc;
                            group.rows.splice(inGroupIndex, 0, currentPositionRow);
                        }
                        foundedGroup = true;
                        break;
                    }
                }
                if (!foundedGroup && !position.deleted) {
                    currentPositionRow = {
                        id: position.tmp_id,
                        key: position.tmp_id,
                        doc: PositionService.parsePositionValues(position),
                    };
                    if (currentPositionRow.doc.configuration.type !== 'coupled_window' ) {
                        currentPositionRow.drawOptions = Core.copy(vm.drawOptions);
                        if (currentPositionRow.doc.configuration?.System?.image_side_on_offer) {
                            currentPositionRow.drawOptions.base.side = currentPositionRow.doc.configuration?.System?.image_side_on_offer;
                        }
                    }
                    currentPosition = currentPositionRow.doc;
                    const newGroupIndex = vm.offer.sequence.findIndex(
                        g => Object.keys(g)[0] === position.groupCode
                    );
                    vm.groups.splice(newGroupIndex > -1 ? newGroupIndex : vm.groups.length, 0, {
                        groupCode: position.groupCode,
                        confType: position.confType,
                        rows: [currentPositionRow],
                        groupData: vm.offer.positions_groups_data[position.groupCode],
                        groupEdition: 0,
                    });
                }
                if (!position.deleted) {
                    indexPositions = 0;
                    vm.offer.sequence.some(
                        gr =>
                            gr[Object.keys(gr)[0]].some(p => {
                                if (
                                    ['TRS', 'CRS', 'CRW', 'ADD'].every(
                                        c => Object.keys(gr)[0].indexOf(c) === -1
                                    )
                                ) {
                                    indexPositions++;
                                }
                                return p === currentPosition.tmp_id;
                            }),
                        0
                    );
                    ({
                        indexPositions,
                        transportCost,
                        transportCostPosition: transportCostPos,
                        discounts,
                    } = PositionsFactory.parsePosition(
                        { doc: currentPosition },
                        indexPositions - 1,
                        (!IccConfig.Offer.valueAndPercentDiscount && !IccConfig.Offer.offerDiscountToPosition || discounts)
                            ? discounts
                            : vm.discounts,
                        currency
                    ));
                    allPositions.pos.forEach(pos => {
                        if (!pos.doc.summary) {
                            pos.doc.summary = PositionDetailedSummaryService.detailedSummary(
                                pos.doc,
                                IccConfig,
                                vm.offer.currency,
                            );
                        }
                    });
                    updatePositionData(currentPosition, allPositions);

                    // Koszt transportu
                    if (transportCostPos) {
                        transportCostPosition = transportCostPos;
                        vm.transportCost.old = transportCost;
                        vm.transportCost.new = transportCost / vm.offer.currency.value;
                    }

                    // Rabaty
                    if (
                        !vm.discounts
                        || vm.discounts.dealerDiscount !== discounts.dealerDiscount
                        || vm.discounts.clientDiscount !== discounts.clientDiscount
                    ) {
                        vm.discounts = discounts;
                    }

                    vm.notValuatedPositionsList = getNotValuatedPositions(vm.groups);

                    if (IccConfig.PDF.showCompletion) {
                        vm.completion = new Completion(vm.groups, $rootScope.curLang, vm.offer.currency);
                    }

                    offerElements[currentPosition.tmp_id] = {
                        synced: currentPosition.synced,
                        notStandardPosition: !currentPosition.standard,
                        mistakeProduct: currentPosition.configuration.IsMistakeProduct,
                        notRectangular:
                            angular.isDefined(currentPosition.configuration.Shape)
                            && angular.isDefined(currentPosition.configuration.Shape.shape)
                            && currentPosition.configuration.Shape.shape != 'rect',
                    };
                    updatedGroupPositions.push(currentPosition);
                }
            });
            indexPositions = 0;
            updatePositionsDetails(updatedGroupPositions, allPositions).then(() => {
                vm.groups.forEach(group => {
                    group.rows = group.rows.filter(row => {
                        if (
                            [
                                'transport_cost',
                                'colors_cost',
                                'colors_waste_cost',
                                'other',
                                'additional',
                            ].indexOf(row.doc.confType) === -1
                            && !row.doc.coupled_position_id
                            && !row.doc.deleted
                        ) {
                            row.doc.listNumber = ++indexPositions;
                            if (row.doc.detailsForView) {
                                row.doc.detailsForView.index = row.doc.listNumber;
                                if (row.doc.detailsForView.subPositions) {
                                    row.doc.detailsForView.subPositions.forEach(subPosition => {
                                        subPosition.doc.detailsForView.index = subPosition.doc.detailsForView.index.replace(
                                            /^\d+\./,
                                            row.doc.listNumber + '.'
                                        );
                                        subPosition.drawOptions = Core.copy(vm.drawOptions)
                                        if (subPosition.doc.configuration?.System?.image_side_on_offer) {
                                            subPosition.drawOptions.base.side = subPosition.doc.configuration?.System?.image_side_on_offer;
                                        }
                                    });
                                }
                            }
                        }
                        return !row.doc.deleted && !row.doc.coupled_position_id;
                    });
                });

                if (!$scope.$$phase) {
                    $scope.$apply();
                }
                MontagesDataService.getMontages().then(returned => {
                    MontagesService.setMontagesData(
                        vm.offer,
                        vm.groups,
                        returned.montages,
                        vm.offer.dealer_id,
                        $rootScope.user.marketId
                    );
                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                });

                for (let i = vm.groups.length - 1; i >= 0; i--) {
                    const group = vm.groups[i];
                    if (
                        group.rows.length === 0
                        || group.rows.every(row => row.doc.deleted || row.doc.coupled_position_id)
                    ) {
                        vm.groups.splice(i, 1);
                    }
                }

                vm.coupledPositionsQuantity = vm.groups.reduce((value, group) =>
                    ({
                        positions: value.positions + group.rows.reduce((prev, pos) => prev + (pos.doc.confType === 'coupled_window' ? 1 : 0),0),
                        quantity: value.quantity + group.rows.reduce((prev, pos) => prev + (pos.doc.confType === 'coupled_window' ? pos.doc.quantity : 0),0),
                    })
                , {
                    positions: 0,
                    quantity: 0
                });
            });

            const sequenceKeys = OfferSequenceService.keysFromSequence(vm.offer.sequence);
            ManyPositionsService.listById(sequenceKeys, vm.offer.tmp_id).then(positions => {
                const {
                    offerLoaded,
                    notFoundPositions,
                } = OfferViewFactory.checkIsLoadedAllPositions(vm.offer, positions.pos.length);
                loadedOffer = offerLoaded;
                notFoundInfo(notFoundPositions, vm.offer);
                if (!$scope.$$phase) {
                    $scope.$apply();
                }
            });
        }
        vm.updateTaxValues();
    }

    // eslint-disable-next-line max-statements
    function updatePositionData(currentPosition, allOfferPositions) {
        const dataRequiredToUpdate = {
            profiles: ConfiguratorsDataService.data.profiles,
            windowAccessories: ConfiguratorsDataService.data.windowAccessories,
            windowAccessoriesCategories: ConfiguratorsDataService.data.windowAccessoriesCategories,
            profilesIdMap: ConfiguratorsDataService.data.profilesIdMap,
            garageGuides: ConfiguratorsDataService.data.garageGuides,
            rollerShutterProfilesMap: ConfiguratorsDataService.data.rollerShutterProfilesMap,
            rollerShutterColorGroupsMap: ConfiguratorsDataService.data.rollerShutterColorGroupsMap,
            rollerShutterColorsMap: ConfiguratorsDataService.data.rollerShutterColorsMap,
            windowColorRalsMap: ConfiguratorsDataService.data.windowColorRalsMap,
            colors: ConfiguratorsDataService.data.colors,
            windowColorsAll: ConfiguratorsDataService.data.windowColorsAll,
        };
        const position = allOfferPositions.pos.find(el => el.id === currentPosition.tmp_id);
        if (
            [
                'custom',
                'transport_cost',
                'colors_cost',
                'colors_waste_cost',
                'other',
                'additional',
                'static',
            ].indexOf(currentPosition.confType) === -1
        ) {
            if (angular.isString(currentPosition.details) && currentPosition.details != '') {
                currentPosition.details = Core.parseJson(currentPosition.details);
            }
            if (currentPosition.details === null || !currentPosition.details.$version) {
                currentPosition.configuration.$version = 3;
                currentPosition.details = ConfigurationsService.createSimpleConfiguration(
                    Core.parseJson(currentPosition.configuration),
                    dataRequiredToUpdate
                );
            } else {
                currentPosition.details = ConfigurationsService.createSimpleConfiguration(
                    Core.parseJson(currentPosition.details),
                    dataRequiredToUpdate
                );
            }
            if (currentPosition.details && !currentPosition.details.priceParts) {
                currentPosition.invalid = true;
                offerOptions.discounts = false;
                offerOptions.oldVersion = true;
            } else {
                offerOptions.discounts = true;
                offerOptions.oldVersion = false;
            }
            if (currentPosition.details) {
                currentPosition.details.timestamp = Date.now();
            }
            if (!currentPosition.detailsForView) {
                currentPosition.detailsForView = {};
            }
            if (!currentPosition.configuration.drawData && currentPosition.details) {
                currentPosition.configuration.drawData = DrawService.getData(
                    currentPosition.details
                );
            }
            if (currentPosition.coupled_position_id) {
                const coupledPosition = allOfferPositions.pos.find(el => el.id === currentPosition.coupled_position_id);
                if (coupledPosition.doc.detailsForView) {
                    const subPosition = coupledPosition.doc.detailsForView.subPositions.find(el => el.id === currentPosition.tmp_id);
                    subPosition.doc.synced = currentPosition.synced;
                }
            }

            const dimensionUnits = {
                dimensionUnit: UserService?.user?.dimension_unit || 'mm',
                numberOfSpacesAfterDecimalSeparator: UserService?.user?.number_of_places_after_decimal_separator || 0
            };

            const { area, glazingArea, circuit, doorCircuit, windowCircuit, shutterCircuit, size } = PositionService.getDimensions(currentPosition, IccConfig, dimensionUnits);
            currentPosition.area = area;
            currentPosition.glazing_area = glazingArea;
            currentPosition.circuit = circuit;
            currentPosition.shutter_circuit = shutterCircuit;
            currentPosition.door_circuit = doorCircuit;
            currentPosition.window_circuit = windowCircuit;
            currentPosition.size = size;
            const changePosition = document.getElementById(position.id);
            if(changePosition) {
                changePosition.getElementsByClassName('icc-draw-svg')[0]?.classList.remove("edited");
            }
            Object.assign(position.doc, currentPosition);
        }
        if (!position.doc.summary) {
            position.doc.summary = PositionDetailedSummaryService.detailedSummary(
                position.doc,
                IccConfig,
                vm.offer.currency,
            );
        }
    }

    function updatePositionsDetails(currentPositions, allOfferPositions) {
        return OfferViewFactory.getTranslatedData(currentPositions, LangService.getLang())
        .then(translatedData => {
            new PositionsDetailsService(
                IccConfig,
                currentPositions,
                vm.offer,
                $rootScope.user.access,
                vm.priceType && IccConfig.Offer.showPricesInPositionDetails && offerOptions.discounts,
                vm.priceType,
                $rootScope.curLang,
                $filter('translate'),
                ['1', '3', '10'].indexOf(vm.offer.status) >= 0
                    && !vm.offer.client_offer
                    && ['2', '3', '4'].indexOf(vm.offer.dealer_status) == -1
                    && !vm.offer.confirm
                    && vm.shopOfferPositionsEdit(),
                $rootScope.user.market_time_limits_offer_positions,
                false,
                false,
                OfferViewFactory.mergeTranslatedData(vm.translatedData, translatedData),
                $rootScope.showInfo,
                allOfferPositions.pos,
                false,
                $rootScope.user
            );
            currentPositions.forEach(currentPosition => {
                if (currentPosition.coupled_position_id) {
                    const coupledPosition = allOfferPositions.pos.find(el => el.id === currentPosition.coupled_position_id);
                    const subPosition = coupledPosition.doc.detailsForView.subPositions.find(el => el.id === currentPosition.tmp_id);
                    subPosition.doc.synced = currentPosition.synced;
                }
            });
        });

    }

    function updateOfferViewProperties() {
        $scope.synced.val = Object.keys(offerElements).every(key => offerElements[key].synced);
        vm.showMistakeProductInfo = Object.keys(offerElements).some(
            key => offerElements[key].mistakeProduct
        );
        vm.isNonRectangularPosition = Object.keys(offerElements).some(
            key => offerElements[key].notRectangular
        );
        vm.hasNotStandardPosition = Object.keys(offerElements).some(
            key => offerElements[key].notStandardPosition
        );
        if (
            vm.offer.order == 0
            && ($rootScope.user.access == 'producent'
                || $rootScope.user.access == 'dealer'
                || $rootScope.user.access == 'dealerh')
        ) {
            vm.pickDealerSeller = vm.offer.dealer_seller_id > 0 ? vm.offer.dealer_seller_id : -1;
        }
        updateLegend();
    }

    /**
     * Otwieranie widoku pdf, tylko gdy oferta jest zsynchronizowana
     */
    function openPdf(
        standard = true,
        {
            completion = IccConfig.PDF.printWithCompletionDefault,
            extended = false,
            discounts = true,
            prices = true,
            extendedDrawDimensions,
            notValuatedPositionsList,
            client = true,
            measurements = false,
            hideDimensions = false,
        } = {}
    ) {
        if ($scope.synced.val || (isElectron && !isOnline())) {
            if (standard) {
                PdfService.openPdf(
                    vm.offer.tmp_id,
                    {
                        prices,
                        discounts,
                        completion,
                        extended,
                        extendedDrawDimensions,
                        notValuatedPositionsList,
                        client,
                        measurements,
                        hideDimensions,
                    },
                    vm.offer.order
                );
            } else {
                PdfService.openPdfOptions(vm.offer, vm.extendedPdf);
            }
        }
    }

     /**
     * Otwieranie widoku wysyłki maila do klienta
     */
    function sendCustomEmailToClient() {
        OfferViewFactory.sendCustomEmailToClient(vm.offer,  $scope.clientvariable, vm.notValuatedPositionsList);
    }

    /**
     * Pobieranie kodów QR
     */
    function openQr() {
        const url = `${EnvConfig.remoteHost || window.location.origin}/${
            $rootScope.curLang
        }/offer_generator/generator/generate_qrcodes/${vm.offer.tmp_id}`;
        if (isElectron) {
            window.ipc.send('download-file', url);
        } else {
            window.open(url, '_blank');
        }
    }

    /**
     * Pobieranie DWU
     */
    function openDWU() {
        const url = `${EnvConfig.remoteHost || window.location.origin}/${
            $rootScope.curLang
        }/offer_generator/generator/generate_dwu/${vm.offer.tmp_id}`;
        if (isElectron) {
            window.ipc.send('download-file', url);
        } else {
            window.open(url, '_blank');
        }
    }

    /**
     * Pobieranie CE
     */
    function openCE() {
        const url = `${EnvConfig.remoteHost || window.location.origin}/${
            $rootScope.curLang
        }/offer_generator/generator/generate_ce/${vm.offer.tmp_id}`;
        if (isElectron) {
            window.ipc.send('download-file', url);
        } else {
            window.open(url, '_blank');
        }
    }

    /**
     * Dodawanie kopii pozycji do oferty
     * @param {object} org pozycja oryginalna
     */
    function addCopy(org, verticalOffer = false) {
        $rootScope.completelyNewPosition = false;
        if (verticalOffer) {
            OfferViewFactory.addCopy(org, vm.offer, () => {});
        } else {
            EditPositionFactory.addSimilar(org, vm.offer);
        }
    }

    /**
     * Tworzy konstrukcję złożoną
     * @param {*} basePosition
     */
    function createCoupledWindow(basePosition) {
        CoupledWindowService.addCoupledWindow(basePosition, vm.offer);
    }

    /**
     * Usuwanie pozycji z oferty
     * @param {object} [object] pod.doc z pozycji dokumentu
     */
    function removePosition(object) {
        OfferViewFactory.removePosition(object, vm.offer, () => {
            getOffer().then(() => vm.updateMontages());
        });
    }

    /**
     * Sprawdzenie pliku
     * @param {File} [element] pliki z kontrolki
     */
    function validFile(element) {
        if (element.some(e => e.size > 2 * 1024 * 1024)) {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Maksymalny rozmiar pojedynczego pliku wynosi 2MB.')
            );
            return false;
        }
        if (
            element.some(
                e => !IccConfig.Offer.fileExtensions.some(f => e.name.toLowerCase().endsWith(f))
            )
        ) {
            const extensions = IccConfig.Offer.fileExtensions.join(', ');
            $rootScope.showInfo(
                $filter('translate')('OFFER|Dopuszczalne rozszerzenia plików') + ': ' + extensions
            );
            return false;
        }
        return true;
    }

    /**
     * Dodawanie plików do pozycji
     * @param {File} [element] pliki z kontrolki
     * @param {object} [position] pozycja
     */
    function addFile(element, position) {
        if (!validFile(element)) {
            return;
        }

        $rootScope.loader = true;
        PositionAttachmentsFactory.addMulti(element, position.doc).then(function afterAddFile() {
            PositionsFactory.update(position.doc.tmp_id, position.doc, vm.offer).then(
                function updatePositionAfterAddFile() {
                    getOffer().then(() => {});
                }
            );
        });
    }

    /**
     * Usuwanie pliku z bazy
     * @param {object}[doc] obiekt zalacznika
     * @param {object} [position] pozycja
     */
    function removeFile(doc, position) {
        $rootScope.loader = true;
        PositionAttachmentsFactory.remove(doc.tmp_id, doc).then(function afterRemoveFile() {
            if (angular.isObject(position)) {
                position.doc.synced = false;
                PositionsFactory.update(position.doc.tmp_id, position.doc, vm.offer).then(
                    function updatePositionAfterRemoveFile() {
                        getOffer().then(() => {});
                    }
                );
            } else {
                getOffer().then(() => {});
            }
        });
    }

    /**
     * Pobieranie pliku załącznika
     * @param {object} [doc] obiekt zalacznika
     */
    function downloadFile(doc) {
        OfferViewFactory.downloadFile(doc, PositionAttachmentsFactory, vm.offer);
    }

    /**
     * Dodawanie plików do oferty
     * @param {File} [element] pliki z kontrolki
     * @param {object} [id] id pozycji
     */
    function addOfferFile(element) {
        if (!validFile(element)) {
            return;
        }
        $rootScope.loader = true;
        OfferAttachmentsFactory.addMulti(element, vm.offer).then(function afterAddFile() {
            getOffer().then(() => {});
            element = undefined;
        });
    }

    /**
     * Dodawanie plików do oferty
     * @param {File} [element] pliki z kontrolki
     * @param {object} [id] id pozycji
     */
    function addOfferInternalFile(element) {
        if (!validFile(element)) {
            return;
        }
        $rootScope.loader = true;
        OfferAttachmentsFactory.addMulti(element, vm.offer, true).then(function afterAddFile() {
            getOffer().then(() => {});
        });
    }

    /**
     * Usuwanie pliku z bazy
     * @param {object}[doc] obiekt zalacznika
     */
    function removeOfferFile(doc) {
        $rootScope.loader = true;
        OfferAttachmentsFactory.remove(doc.tmp_id, doc).then(function afterRemoveFile() {
            getOffer().then(() => {});
        });
    }

    /**
     * Pobieranie pliku załącznika
     * @param {object} [doc] obiekt zalacznika
     */
    function downloadOfferFile(doc) {
        OfferViewFactory.downloadFile(
            doc,
            OfferAttachmentsFactory,
            vm.offer,
            vm.attachments[vm.offer.tmp_id]
        );
    }

    /**
     * Otwiera modal dodawania nowej pozycji
     */
    function addNewPosition() {
        if (IccConfig.Offer.completelyNewPosition && !$rootScope.coupledPosition) {
            $rootScope.completelyNewPosition = true;
        }
        NewPositionService.openModal(vm.offer, getOffer);
    }

    function goToConfigurator(configurator) {
        if (IccConfig.Configurators.modernConfigurators) {
            $location.url(`/app/new_configurator/${configurator}`);
        } else {
            $location.url(`/app/${configurator}`);
        }
    }

    /**
     * Dodaje pozycję dodatkową do oferty
     * @param {object} pos Pozycja dodatkowa
     */
    function addAdditionalPosition(pos) {
        OfferViewFactory.addAdditionalPosition(pos, vm.offer, function afterAddAdditional() {
            vm.newAdditionalPossition = { quantity: 1, type: 'product', price: 0 };
            vm.additionalPositionForm = 0;
            getOffer().then(() => {});
        });
    }

    /**
     * Dodanie pozycji niestandardowej
     */
    function addCustomPosition() {
        OfferViewFactory.addCustomPosition(vm.offer, () => getOffer().then(() => {}));
    }

    /**
     * Dodaje klienta do oferty
     * @param {String} clientDATA dane klienta
     */
    function setClientToOffer(clientDATA) {
        clientDATA = Core.parseJson(clientDATA);
        OfferViewFactory.setClientToOffer(clientDATA, vm.offer, $scope.synced);
        $scope.clientvariable = clientDATA.doc;
    }

    function confirmSetDealerSellerToOffer(dealerSellerId) {
        if (dealerSellerId === vm.offer.dealer_seller_id) {
            return;
        }
        if (IccConfig.Offer.confirmDealerSellerChange && $rootScope.user.access === 'dealerh' && !$rootScope.user.see_all_documents) {
            InfoService.openConfirmModal(
                $filter('translate')('OFFER|Potwierdzenie przypisania oferty'),
                $filter('translate')('OFFER|Czy napewno chcesz przypisać ofertę? Po przypisaniu utracisz dostęp do oferty.'),
                [
                    {
                        name: $filter('translate')('INTERFACE|Tak'),
                        callback: () => setDealerSellerToOffer(dealerSellerId),
                    },
                    {
                        name: $filter('translate')('INTERFACE|Nie'),
                        callback: () => {},
                        accent: true,
                    },
                ]
            );
        } else {
            setDealerSellerToOffer(dealerSellerId);
        }
    }

    /**
     * Przypisywanie handlowca dealera do oferty
     * @param {String} dealerSellerId dane klienta
     */
    function setDealerSellerToOffer(dealerSellerId) {
        OfferViewFactory.setDealerSellerToOffer(dealerSellerId, vm.offer, () => {
            if ($rootScope.user.access == 'dealer' || $rootScope.user.access == 'producent') {
                getDealerSellersList(vm.offer.dealer_id);
            } else {
                $location.path('/app/offers');
            }
        });
    }

    /**
     * Wybór rodzaju transportu
     */
    function selectTransportCost() {
        OfferViewFactory.selectTransportCost(!!vm.transport_from_producent, vm.offer, () => {});
    }

    /**
     * Aktualizuje koszt transportu do klienta
     */
    function updateClientTransportCost(clientTransportCost) {
        OfferViewFactory.updateClientTransportCost(
            vm.offer,
            clientTransportCost * (!vm.offer.currency.pricesInCurrency ? vm.offer.currency.value : 1),
            () => {}
        );
    }

    /**
     * Przełącza rozbijanie kosztu transportu do klienta na pozycje
     */
    function toggleClientSplitTransportCost(clientSplitTransportCost) {
        OfferViewFactory.toggleClientSplitTransportCost(
            vm.offer,
            clientSplitTransportCost,
            () => {}
        );
    }

    /**
     * Usuwa koszt transportu do klienta
     */
    function removeClientTransportCost() {
        OfferViewFactory.removeClientTransportCost(vm.offer, () => {
            vm.clientTransportCostVisible = false;
        });
    }

    /**
     * Funckcja przypisuje dealera do oferty, ustawia w ofercie rabaty i ceny
     * @param {object} [dealer] dealera z pola wyboru
     * @param {string} [clientRefer] referencja klienta
     */
    function setDealerAndRefferToOffer(dealer, client, clientRefer) {
        if (angular.isDefined(dealer) && angular.isDefined(dealer.Dealer)) {
            dealer = dealer.Dealer;
        }
        if (angular.isDefined(client)) {
            client = Core.parseJson(client);
        }

        OfferViewFactory.setDealerAndRefferToOffer(
            dealer,
            client,
            clientRefer,
            vm.offer,
            $scope.synced,
            () => {
                vm.discounts = null;
                getDealers();
                getDealerSellersList(dealer.id);
            }
        );

        if (angular.isDefined(client)) {
            $scope.clientvariable = client.doc;
        }
    }

    /**
     * Anulowanie oferty
     * @param {object}[doc] obiekt zalacznika
     */
    function cancelOffer() {
        OfferViewFactory.cancelOffer(vm.offer, () => {});
    }

    /**
     * Anulowanie zamówienia ze sklepu
     * @param {object}[doc] obiekt zalacznika
     */
    function cancelShopOrder() {
        OfferViewFactory.cancelShopOrder(vm.offer, () => {});
    }

    /**
     * Aktualizowanie vatu dealera calej oferty
     * @param {type} dealerTaxRate Nowa wartośc vat do oferty
     */
    function updateOfferDealerTaxRate(dealerTaxRate) {
        OfferViewFactory.updateOfferDealerTaxRate(dealerTaxRate, vm.offer, () =>
            getOffer().then(() => {})
        );
    }

    /**
     * Aktualizowanie rabatu dodatkowego calej oferty
     * @param {type} clientDiscountSpecial Nowa wartośc rabatu do oferty
     */
    function updateOfferClientSpecialDiscount(clientDiscountSpecial) {
        OfferViewFactory.updateOfferClientSpecialDiscount(clientDiscountSpecial, vm.offer).then(
            () => {
                updatedOfferData(['Offer']);
            }
        );
    }

    /**
     * Aktualizowanie danych pozycji oferty po zmianie ilości
     * @param {object} positionDATA Dane pozycji
     * @param {number} newquantity  Nowa wartość rabatu
     */
    function updatePostitionQuantity(positionDATA, newquantity) {
        newquantity = newquantity > 0 ? newquantity : 1;
        OfferViewFactory.updatePostitionQuantity(
            positionDATA,
            newquantity,
            () => {
                updatedOfferData(['Position'], [positionDATA]);
            },
            vm.offer
        );
        vm.updateMontages();
        vm.updateTaxValues()
    }

    /**
     * Uaktualnienie VAT-u oferty
     */
    function updateTaxRate() {
        var offer = vm.offer;
        OffersService.update(
            offer._id,
            angular.extend(offer, { tax_rate_id: parseFloat(vm.offer.tax_rate_id) })
        ).then(() => {
            updatedOfferData(['Offer']);
        });
    }

    /**
     * Wyciąganie wartości stawki VAT
     * @param {number} id  Id podatku
     */
    function getTaxValue(id) {
        if (angular.isArray(vm.taxes)) {
            for (var i = 0; i < vm.taxes.length; i++) {
                if (vm.taxes[i].id == id) {
                    return Number(vm.taxes[i].value);
                }
            }
        }
    }

    /**
     * Funkcja wylicza wartość rabatu oraz uaktualnia ceny
     * @param {object} [pos] objekt pozycji
     */
    function recalcDiscountValue(pos, i) {
        OfferViewFactory.recalcDiscountValue(pos, i, vm.offer, () => {
            updatedOfferData(['Position'], [pos]);
        });
    }

    /**
     * Funkcja wylicza stawkę rabatu oraz uaktualnia ceny
     * @param {object} [pos] objekt pozycji
     * @param {object} [i]   index rabatu
     */
    function recalcDiscountRate(pos, i) {
        OfferViewFactory.recalcDiscountRate(pos, i, vm.offer, () => {
            updatedOfferData(['Position'], [pos]);
        });
    }

    /**
     * Pobieranie listy dealerów do zapytania klienta niezalogowanego
     */
    function getDealersList() {
        if (!angular.isObject(vm.Client)) {
            vm.Client = {};
        }
        OfferViewFactory.getDealersList(vm.address, (dealers, address) => {
            $scope.dealersList = dealers;
            vm.address = address;
        });
    }

    /**
     * Pobieranie pierwszej całej listy dealerów do zapytania klienta niezalogowanego
     */
    function getFirstDealersList() {
        OfferViewFactory.getFirstDealersList((dealers, isSelectedDealer) => {
            if (vm.offer.dealer_id !== null) {
                vm.dealerVariable = (
                    dealers.find(el => el.Dealer.id == vm.offer.dealer_id) || {}
                ).Dealer;
            }
            $scope.dealersList = dealers;

            vm.address = '';
            vm.isSelectedDealer = isSelectedDealer;
            if (isSelectedDealer) {
                vm.Client.Dealer = dealers[0].Dealer;
            }

            if (
                !isSelectedDealer
                && angular.isObject(vm.Client)
                && angular.isObject(vm.dealerVariable)
            ) {
                vm.Client.Dealer = vm.dealerVariable;
            }
        });
    }

    /**
     * Pobieranie listy handlowców dealerów
     * @param  {number} Id dealera
     */
    function getDealerSellersList(dealerId) {
        OfferViewFactory.getDealerSellersList(dealerId, dealersSellers => {
            vm.dealerSellersList = dealersSellers;
            if (vm.offer.dealer_seller_id != null) {
                vm.dealerSellerVariable = Core.fIdO(dealersSellers, vm.offer.dealer_seller_id);
            }
        });
    }

    /**
     * Wysyłanie zapytania z pobraniem danych klienta
     * @param  {object} Client Dane klienta
     */
    function sendClientInquiry(Client) {
        OfferViewFactory.sendClientInquiry(
            Client,
            vm.offer,
            () => {
                vm.showSendInquiry = false;
            },
            offer => activeClient(offer)
        );
    }

    /**
     * Wysłanie zapytania ofertowego przez klienta zalogowanego
     */
    function quickSendInquiry() {
        OfferViewFactory.quickSendInquiry(vm.offer, () => {});
    }

    /**
     * Wysłanie zapytania ofertowego przez klienta zalogowanego
     */
    function sendInquiry() {
        vm.showSendInquiry = true;
        if (!angular.isObject(vm.Client)) {
            vm.Client = {};
        }
        const dealer = $scope.dealersList.find(
            el => el.Dealer.id == $rootScope.user.marketDealerId
        );
        vm.Client.Dealer = dealer ? dealer.Dealer : null;
        if ($scope.clientvariable && !$scope.clientvariable.old_email) {
            $scope.clientvariable.old_email = $scope.clientvariable.email;
        }
        if (IccConfig.GTM.allowTags) {
            EventBusService.post({
                key: 'googleTags',
                value: 'sendInquiry',
            });
        }
    }

    /**
     * Wysłanie zamówienia przez klienta zalogowanego na podstawie oferty
     */
    function quickSendOrder() {
        OfferViewFactory.quickSendOrder(vm.offer, () => {});
    }

    /**
     * Wysłanie emaila do klienta
     */
    function sendEmailToClient() {
        OfferViewFactory.sendEmailToClient(vm.offer, () => {});
    }

    /**
     * Wysłanie oferty od dealera do klienta
     */
    function sentToClient() {
        if(vm.montageToSave) {
            InfoService.openConfirmModal(
                $filter('translate')('OFFER|Edycja montaży nie została zapisana'),
                $filter('translate')('OFFER|Czy zatwierdzić oferte bez zapisania montaży?'),
                [
                    {
                        name: $filter('translate')('INTERFACE|Tak'),
                        callback: () => {
                            vm.offerMontagesGroups = vm.offer.montages.groups;
                            OfferViewFactory.sentToClient(vm.offer, () => {});
                        },
                    },
                    {
                        name: $filter('translate')('INTERFACE|Nie'),
                        accent: true,
                    },
                ]
            );
        } else {
            OfferViewFactory.sentToClient(vm.offer, () => {});
        }
    }

    /**
     * Zatwierdzenie oferty przez klient b2c
     */
    function clientSubmitOffer() {
        OfferViewFactory.clientSubmitOffer(vm.offer, $scope.clientvariable);

    }

    /**
     * Tworzenie oferty dla klienta na podstwie zapytania
     */
    function createOfferForClient() {
        OfferViewFactory.createOfferForClient(vm.offer, $scope.clientvariable);
    }

    /**
     * Tworzenie kolejnej oferty dla klienta niezalogowanego
     */
    function anotherClientOffer() {
        OfferViewFactory.anotherClientOffer(vm.offer, $scope.clientvariable);
    }

    /**
     * Dodanie zwykłago opisu do pozycji
     * @param {type} [positionDATA] Dane pozycji
     * @param {type} [description]  Opis
     */
    function updatePositionDescription(positionDATA, description) {
        OfferViewFactory.updatePositionDescription(
            positionDATA,
            description,
            $scope.synced,
            vm.offer
        );
    }

    /**
     * Dodanie tytułu własnego do pozycji
     * @param {type} [positionDATA] Dane pozycji
     * @param {type} [description]  Opis
     */
    function updatePositionCustomTitle(positionDATA, description) {
        OfferViewFactory.updatePositionCustomTitle(
            positionDATA,
            description,
            $scope.synced,
            vm.offer
        );
    }

    /**
     * Zapisuje ofertę na szybko
     */
    function saveOffer() {
        $scope.synced.val = false;
        OffersService.update(vm.offer._id, vm.offer);
    }

    /**
     * Zapisuje ofertę na szybko
     */
    function openAlterModal() {
        var sequenceKeys = vm.offer.sequence[0][Object.keys(vm.offer.sequence[0])[0]];
        ManyPositionsService.listById(sequenceKeys, vm.offer.tmp_id).then(posRes => {
            var positions = posRes.pos;
            var oneSystem = true;
            var system;
            for (var i = positions.length - 1; i >= 0; i--) {
                if (angular.isUndefined(system)) {
                    system = positions[i].doc.configuration.System.id;
                }
                if (system != positions[i].doc.configuration.System.id) {
                    oneSystem = false;
                    break;
                }
            }
            if (oneSystem) {
                AlternativeLayoutsFactory.openModal(
                    positions,
                    Object.keys(vm.offer.sequence[0])[0]
                );
            }
        });
    }

    /**
     * Funkcja czyści zała oferte
     */
    function clearOffer() {
        OfferViewFactory.clearOffer(vm.offer, () => {
            getOffer().then(() => {});
        });
    }

    /**
     * Przesuwanie pozycji
     * @param  {string}   direction  Kierunek przesuwania pozycji
     * @param  {number}   positionId Id pozycji
     * @param  {string}   groupCode  Kod grupy
     * @param  {number}   groupKey   Indeks grupy
     */
    function movePosition(direction, positionId, groupCode, groupKey) {
        if (vm.offer.status == 1 || vm.offer.status == 10 || vm.offer.status == 3) {
            OffersFactory.movePosition(
                vm.offer,
                vm.groups.find(el => el.groupCode === groupCode).rows.map(el => el.id),
                direction,
                positionId,
                groupCode,
                groupKey,
                () => {
                    getOffer().then(() => {});
                },
                vm.offer
            );
        }
    }

    /**
     * Zaznacazenie dealer w B2c
     * @param  {object} dealer Dane dealera
     */
    function selectDealer(dealer) {
        if (!angular.isObject(vm.Client)) {
            vm.Client = {};
        }
        vm.Client.Dealer = dealer;
        ScrollbarService.update();
    }

    /**
     * Odświeżenie stawek rabatowych
     */
    function refreshDiscounts() {
        return OfferViewFactory.refreshDiscounts(vm.offer, () => {});
    }

    /**
     * Odświeżenie stawek rabatowych
     */
    function updateOfferMargin() {
        $scope.synced.val = false;
        OfferViewFactory.updateOfferMargin(vm.offer);
    }

    /**
     * Dodanie nowago rabatu do oferty
     */
    function addNewOfferDiscount() {
        OfferViewFactory.addNewOfferDiscount(vm.offer, vm.newDiscount, () => {
            vm.showNewDiscountForm = false;
            vm.newDiscount = {};
            $scope.$apply();
        });
    }

    /**
     * Edycja rabatu do oferty
     * @param  {number} key      Indeks rabatu
     * @param  {object} discount Rabat edytowany
     */
    function editNewDiscount(key, discount) {
        if (
            $scope.user.access == 'producent'
            && discount.custom
            && ['1', '3'].indexOf(vm.offer.status) > -1
            && !vm.offer.client_offer
            && !vm.offer.confirm
            && (vm.offer.dealer_status != 2
                && vm.offer.dealer_status != 3
                && vm.offer.dealer_status != 4)
        ) {
            vm.newDiscount = angular.extend(discount, { key });
            vm.showNewDiscountForm = true;
        }
    }

    function showNewOfferDiscountForm() {
        vm.showNewDiscountForm = true;
        vm.newDiscount = {};
    }

    function hideNewOfferDiscountForm() {
        vm.showNewDiscountForm = false;
    }

    /**
     * Usuwanie rabatu do oferty
     * @param  {number} key      Indeks rabatu
     */
    function deleteOfferDiscount(key) {
        OfferViewFactory.deleteOfferDiscount(vm.offer, key, () => {
            vm.newDiscount = {};
            vm.showNewDiscountForm = false;
            $scope.$apply();
        });
    }

    /**
     * Uaktualnienie oferty
     * @param  {number} transportCost    Koszt transportu
     * @param  {object} offerHeader      Dane z nagłówka zamówienia
     */
    function updateOfferHeader(transportCost, offerHeader) {
        vm.offerHeaderUpdating = true;
        OfferViewFactory.updateOfferHeader(
            vm.offer,
            offerHeader,
            transportCostPosition,
            transportCost,
            function afterUpdateOfferHeader() {
                vm.offerHeaderUpdating = false;
                $rootScope.showInfo($filter('translate')('OFFER|Zaktualizowano ofertę'), null);
            }
        );
    }

    /**
     * Uaktualnienie rabatu dla wszystkich pozycji
     * @param  {number} discount Rabat
     * @param  {object} offer    Oferta
     * @param  {string} type     Typ
     */
    function updateOfferPositionsDiscount(discount, offer, type) {
        OfferViewFactory.updateOfferPositionsDiscount(
            discount,
            offer,
            type,
            function afterUpdateOfferHeader() {
                getOffer().then(() => {});
            }
        );
    }

    /**
     * Pobieranie komentarzy do oferty
     * @param  {string} id Id oferty
     */
    function getComments(id) {
        OfferViewFactory.getComments(id, function afterGetComments(comments) {
            vm.internalComment = '';
            vm.externalComment = '';
            vm.comments = comments;
        });
    }

    /**
     * Dodawanie komentarza do oferty
     * @param {string} comment Komentarz
     * @param {string} userId  Id użytkownika dodającego komentarz
     * @param {number} flag    Typ komentarza
     */
    function addComment(comment, userId, flag) {
        if (comment.length) {
            OfferViewFactory.addComment(
                comment,
                userId,
                flag,
                vm.offer,
                function afterAddComments() {
                    if(IccConfig.Offer.commentsToParentOffer && vm.offer.parent_id) {
                        getComments(vm.offer.parent_id);
                    } else {
                        getComments(vm.offer.tmp_id);
                    }
                    $rootScope.showInfo($filter('translate')('OFFER|Dodano komentarz.'));
                }
            );
        } else {
            $rootScope.showInfo($filter('translate')('OFFER|Komentarz nie może być pusty.'));
        }
    }

    /**
     * Wycena pozycji przez producenta
     * @param  {object} valuatedPositions Wycenione pozycje
     */
    function valuateProducent(valuatedPositions) {
        $rootScope.loader = true;
        OfferViewFactory.valuate(
            valuatedPositions,
            vm.offer,
            null,
            $rootScope.user.market,
            function afterValuateProducent() {
                $rootScope.showInfo(
                    $filter('translate')('OFFER|Oferta zaktualizowana.'),
                    null,
                    () => {
                        PositionsFactory.whenAllSynced(vm.offer.tmp_id).then(() => {
                            window.location = $scope.curLang + '/dealer/sellers/offers';
                        });
                    }
                );
            }
        );
    }

    /**
     * Wycena pozycji przez technologa
     * @param  {object} valuatedPositions Wycenione pozycje
     * @param  {number} status            Status
     */
    function valuateTechnologist(valuatedPositions, status) {
        let market = null;
        let seller = null;
        $rootScope.loader = true;
        if (vm.sellers.length) {
            seller = vm.sellers.filter(e => e.Seller.id == vm.offer.seller_id);
            if (angular.isDefined(seller[0]) && angular.isDefined(seller[0].Market.code)) {
                market = seller[0].Market.code;
            }
        }
        OfferViewFactory.valuate(
            valuatedPositions,
            vm.offer,
            status,
            market,
            function afterValuateTechnologist() {
                $rootScope.showInfo(
                    $filter('translate')('OFFER|Oferta zaktualizowana.'),
                    null,
                    () => {
                        PositionsFactory.whenAllSynced(vm.offer.tmp_id).then(() => {
                            window.location = $scope.curLang + '/dealer/technologists/offers';
                        });
                    }
                );
            }
        );
    }

    /**
     * Wyliczenie ceny przed rabatem z ceny po rabacie
     * @param  {object} pos         Pozycja
     * @param  {number} clientPrice Cena
     */
    function countPricesFromClient(pos, clientPrice) {
        $rootScope.loader = true;
        OfferViewFactory.countPricesFromClient(
            pos,
            vm.offer,
            clientPrice,
            function afterCountPrice() {
                getOffer().then(() => {});
            }
        );
    }

    /**
     * Wyliczenie ceny przed rabatem z ceny po rabacie
     * @param  {object} pos         Pozycja
     * @param  {number} clientPrice Cena
     */
    function countPricesFromDealer(pos, dealerPrice) {
        $rootScope.loader = true;
        OfferViewFactory.countPricesFromDealer(
            pos,
            vm.offer,
            dealerPrice,
            function afterCountPrice() {
                getOffer();
            }
        );
    }

    /**
     * Potwierdzenie zamówienia
     */
    function confirmOrderEdit() {
        if ($rootScope.user.access == 'producent') {
            InfoService.openConfirmModal(
                $filter('translate')('OFFER|Potwierdzenie zmian w zamówieniu'),
                $filter('translate')(
                    'OFFER|Czy posiadasz dokument poświadczający, że Twój klient potwierdził wykonane zmiany'
                ),
                [
                    {
                        name: $filter('translate')('INTERFACE|Tak'),
                        callback: function afterConfirm() {
                            $rootScope.loader = true;
                            OfferViewFactory.setOrderStatus(
                                OfferViewFactory.confirmOrderEdit(vm.offer),
                                2,
                                () => getOffer()
                            );
                        },
                    },
                    {
                        name: $filter('translate')('INTERFACE|Nie'),
                        callback: function afterDiscard() {
                            $rootScope.loader = true;
                            OfferViewFactory.setOrderStatus(
                                OfferViewFactory.confirmOrderEdit(vm.offer),
                                9,
                                () => getOffer()
                            );
                        },
                        accent: true,
                    },
                ]
            );
        } else {
            $rootScope.loader = true;
            OfferViewFactory.setOrderStatus(
                OfferViewFactory.confirmOrderEdit(vm.offer),
                2,
                () => getOffer()
            );
        }
    }

    /**
     * Potwierdzenie zmian w zamówieniu ze sklepu
     */
    function confirmShopOrderEdit() {
        getPreviousOrderStatus().then(oldStatus => {
            if (vm.offer.status == 9) {
                $rootScope.loader = true;
                OfferViewFactory.setOrderStatus(
                    OfferViewFactory.confirmOrderEdit(vm.offer),
                    oldStatus,
                    () => getOffer()
                );
            } else {
                InfoService.openConfirmModal(
                    $filter('translate')('OFFER|Potwierdzenie zmian w zamówieniu'),
                    $filter('translate')(
                        'OFFER|Czy posiadasz dokument poświadczający, że Twój klient potwierdził wykonane zmiany'
                    ),
                    [
                        {
                            name: $filter('translate')('INTERFACE|Tak'),
                            callback: function afterConfirm() {
                                $rootScope.loader = true;
                                OfferViewFactory.setOrderStatus(
                                    OfferViewFactory.confirmOrderEdit(vm.offer),
                                    oldStatus,
                                    () => getOffer()
                                );
                            },
                        },
                        {
                            name: $filter('translate')('INTERFACE|Nie'),
                            callback: function afterDiscard() {
                                $rootScope.loader = true;
                                OfferViewFactory.setOrderStatus(
                                    OfferViewFactory.confirmOrderEdit(vm.offer),
                                    9,
                                    () => getOffer()
                                );
                            },
                            accent: true,
                        },
                    ]
                );
            }
        });
    }

    function getPreviousOrderStatus() {
        return new Promise((resolve, reject) => {
            OffersService.get(vm.offer.copied_offer || vm.offer.tmp_id).then(offer => {
                let lastStatus = 1;
                if (angular.isArray(offer.history)) {
                    const reversedHistory = [...offer.history].reverse();
                    for (const el of reversedHistory) {
                        if (el.changes?.length) {
                            const statusChange = el.changes.find(change => change.type === 'status');
                            if (statusChange && statusChange.status && statusChange.status != 11) {
                                lastStatus = statusChange.status;
                            }
                        }
                    }
                }
                resolve(lastStatus);
            }).catch(err => reject(err));
        });
    }

    /**
     * Zmiana statusu zamowienia z zapisem historii
     * @param {number} status Numer statusu
     */
    function setOrderStatus(status) {
        OfferViewFactory.setOrderStatus(
            vm.offer,
            status,
            function afterChangeStatus() {}
        );
    }

    /**
     * Zmiana statusu oferty
     * @param {number} status Numer statusu
     */
    function setOfferStatus(status) {
        OfferViewFactory.setOfferStatus(vm.offer, status, function afterChangeStatus() {});
    }

    /**
     * Tworzenie zamowiania
     */
    function addSubOrder() {
        OfferViewFactory.addSubOrder(vm.offer.tmp_id, vm.sellers, vm.offer);
    }

    /**
     * Tworzenie korekty zamówienia ze sklepu
     */
    function addSubShopOrder() {
        OfferViewFactory.addSubShopOrder(vm.offer.tmp_id, vm.offer);
    }

    /**
     * Tworzenie oferty demo
     * @param {boolean} status Kopiowanie oferty demo
     */
    function copyDemoOffer() {
        OfferViewFactory.copyDemoOffer(vm.offer.tmp_id);
    }

    /**
     * Tworzenie zamowiania
     */
    function addSubOffer(checkSubOfferExist = false) {
        if(checkSubOfferExist) {
            OfferViewFactory.getSubOffers(vm.offer.tmp_id).then(offerList => {
                if(offerList.length > 0) {
                    $location.path('/app/offers_view/' + offerList[0].id);
                } else {
                    OfferViewFactory.addSubOffer(vm.offer.tmp_id);
                }
            });
        } else {
            OfferViewFactory.addSubOffer(vm.offer.tmp_id);
        }
    }

    /**
     * Dodawanie klienta na ofercie
     */
    function addClient() {
        ClientsFactory.openClientModal(null, () => {
            activeClient(vm.offer);
        });
    }

    /**
     * Lista pozycji bez wyceny
     * @param {array} groups Tablica pozycji
     */
    function getNotValuatedPositions(groups) {
        let notValuatedPositions = [];
        let positionsCount = 0;
        groups.forEach(group => {
            if (group.confType !== 'additional') {
                group.rows.forEach(position => {
                    if (!position.coupled_position_id) {
                        positionsCount++;
                        if (
                            position.doc.dealer_price === 0
                            || position.doc.dealer_price === null
                            || isNaN(position.doc.dealer_price)
                        ) {
                            notValuatedPositions.push(positionsCount);
                        }
                    }
                });
            }
        });

        if (notValuatedPositions.length == positionsCount) {
            notValuatedPositions = 'all';
            return notValuatedPositions;
        }

        return notValuatedPositions.join(', ');
    }

    function openTransportCostInfo() {
        const modalContent = $rootScope.Elements['transport-cost'];
        InfoService.openInfoModal({
            ownTemplateUrl: 'transportCostInfo.html',
            title: modalContent.name,
            message: $sce.getTrustedHtml(modalContent.content),
            data: {
                logisticMinimum: $rootScope.user.transport_logistic_min,
                minimalTransportCost: $rootScope.user.transport_min_cost,
                transportCostM2: $rootScope.user.transport_m2_cost,
                transportCostType: IccConfig.Offer.transportCostType,
                currency: vm.offer.currency,
            },
        });
    }

    function saveCustomKeyValue() {
        vm.inEditCustomKeyValue = false;
        OfferViewFactory.saveCustomKeyValue(vm.offer, () => {});
    }

    function focusInHandler(event, element) {
        if (event.target && event.target.nodeName === 'INPUT' && event.target.type !== 'file') {
            OffersService.pauseEmitModifiedOffer();
        }
    }

    function focusOutHandler(event, element) {
        if (
            !event.relatedTarget
            || ['INPUT', 'DIV', 'TEXTAREA'].indexOf(event.relatedTarget.nodeName) === -1
            || !element[0].contains(event.relatedTarget)
        ) {
            OffersService.resumeEmitModifiedOffer();
        }
    }

    function changeMontageAndMeasure() {
        OfferViewFactory.changeMontageAndMeasure(vm.offer, vm.groups);
    }

    function openPayments() {
        OfferViewFactory.openPayments(vm.offer);
    }

    function toggleSystemsComparison() {
        if (vm.inSystemsComparison.value) {
            SystemsComparisonService.inSystemsComparison.value = false;
            SystemsComparisonService.onOffer = false;
            SystemsComparisonService.changedGroups = [];
            SystemsComparisonService.invalidPositions = [];
            vm.changedGroups = [];
            vm.invalidPositions = [];
            vm.invalidPositionIndex = null;
            vm.groups = SystemsComparisonService.originalGroups;
        } else {
            SystemsComparisonService.inSystemsComparison.value = true;
            SystemsComparisonService.onOffer = true;
            SystemsComparisonService.originalGroups = Core.copy(vm.groups);
            vm.groups = SystemsComparisonService.getPositionsGroups(true);
            vm.colorsPositions = ColorsPositionService.groupPositionsByColors(vm.groups.reduce((prev, curr) => prev = [...prev, ...curr.rows], []), vm.offer);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }


    function selectGroupForSystemsComparison(groupKey) {
        SystemsComparisonService.systemsComparisonInOffer(vm.groups, groupKey, vm.drawOptions, false)
            .then((groups, configs) => {
                if (groups) {
                    Object.assign(vm.groups, groups);
                    SystemsComparisonService.fixCoupledWindow(vm.groups, configs);
                    vm.colorsPositions = ColorsPositionService.groupPositionsByColors(vm.groups.reduce((prev, curr) => prev = [...prev, ...curr.rows], []), vm.offer);
                }
                vm.changedGroups = SystemsComparisonService.changedGroups;
                vm.invalidPositions = SystemsComparisonService.invalidPositions;
                vm.invalidPositionIndex = null;
            });
    }

    function getSystemsComparisonColorPosition(pos) {
        return SystemsComparisonService.getSystemsComparisonColorPosition(pos, vm.colorsPositions);
    }

    function selectSystemsComparisonOption(option) {
        SystemsComparisonService.selectOption(option);
        vm.selectGroupForSystemsComparison(0);
    }

    function prevInvalidPosition() {
        vm.invalidPositionIndex -= 1;
        if (vm.invalidPositionIndex < 0) {
            vm.invalidPositionIndex = 0;
        }
        $rootScope.goTo(vm.invalidPositions[vm.invalidPositionIndex]);
    }

    function nextInvalidPosition() {
        if (vm.invalidPositionIndex === null ){
            vm.invalidPositionIndex = 0;
        } else {
            vm.invalidPositionIndex += 1;
        }
        if (vm.invalidPositionIndex > vm.invalidPositions.length - 1) {
            vm.invalidPositionIndex = vm.invalidPositions.length - 1;
        }
        $rootScope.goTo(vm.invalidPositions[vm.invalidPositionIndex]);
    }

    function systemComparisonSubOffer(){
        const draws = {};
        document.querySelectorAll('icc-draw').forEach(el => {
            if (el.closest('[id]') && angular.isUndefined(draws[el.closest('[id]').id])) {
                const div = document.createElement('div');
                div.className = 'smallWindowPreview smallWindowPreviewX';
                div.appendChild(el.cloneNode(true))
                draws[el.closest('[id]').id] = div;
            }
        });

        SystemsComparisonService.createSubOffer(vm.groups, draws);
    }

    function isSyncedAndOnline() {
        return $scope.synced.val && vm.offer.synced && vm.isOnline();
    }

    function isSyncedAndOnlineOrElectron() {
        return vm.isSyncedAndOnline() || (isElectron && !vm.isOnline());
    }

    function isEmpty(o) {
        return !angular.isObject(o) || !Object.keys(o).length;
    }

    function montagesAvailable() {
        let isAvailable = false;
        for (const group in vm.offer.montages.groups) {
            if (
                vm.offer.montages.groups[group].own_montages?.length
                || vm.offer.montages.groups[group].producer_montages?.length
                    && vm.producer_montages_allowed
            ) {
                isAvailable = true;
                break;
            }
        }
        return isAvailable;
    }


    function updateChanceId(chanceId) {
        var offer = vm.offer;
        OffersService.update(
            offer._id,
            angular.extend(offer, { chance_id: chanceId })
        ).then(() => {
            updatedOfferData(['Offer']);
        });
    }

    function updateLegend() {
        if ($rootScope.user.flip_symbols) {
            OfferViewFactory.getUsedSashTypes(vm.groups)
                .then(usedSashTypes => vm.usedSashTypes = usedSashTypes);
        }
    }

    function recalcDrawDimension() {
        const svgDraw = document.querySelectorAll(".icc-draw-svg:not(.edited)");
        if (svgDraw.length === 0) {
            setTimeout(() => {
                recalcDrawDimension();
              }, 2000);
          }
        for (let i = 0; i < svgDraw.length; i++) {
            let viewBox = svgDraw.item(i).getAttribute('viewBox')
            viewBox = viewBox.split(" ");
            svgDraw.item(i).classList.add('edited');
            const outer = svgDraw.item(i).parentElement.parentElement.classList.contains('draw-outer')
            const containerWidth = svgDraw.item(i).getBoundingClientRect().width;
            const containerHeight = svgDraw.item(i).getBoundingClientRect().height;
            if(!containerWidth || !containerHeight) {
                continue;
            }
            const scaleWidth = viewBox[2] / containerWidth;
            const scaleHeight = viewBox[3] / containerHeight;
            const scale = scaleWidth < scaleHeight ? scaleHeight : scaleWidth;
            let fontSize = 0;
            let newFontSize = 0;
            Array.from(svgDraw.item(i).getElementsByClassName('DimensionNode')).forEach((element) => {
                let x = null;
                let y = null;
                Array.from(element.getElementsByTagName('text')).forEach((textElement) => {
                    fontSize = textElement.getAttribute('font-size');
                    newFontSize = scale * 14;
                    textElement.setAttribute('font-size', newFontSize);
                    const xToChange = parseFloat(textElement.getAttribute('x'));
                    const yToChange = parseFloat(textElement.getAttribute('y'));
                    y = y ? y : yToChange;
                    x = x ? x : xToChange;

                    if(y === yToChange && x === xToChange) {
                        textElement.setAttribute('y', yToChange + (outer ? - (newFontSize - fontSize) / 2 : (newFontSize - fontSize) / 2));
                    } else {
                        textElement.setAttribute('y', yToChange + (outer ? (newFontSize - fontSize) / 2 : - (newFontSize - fontSize) / 2));
                    }
                });
            });
            Array.from(svgDraw.item(i).getElementsByClassName('IndexesNode')).forEach((element) => {
                Array.from(element.getElementsByTagName('text')).forEach((textElement) => {
                    textElement.setAttribute('font-size',  scale * 14)
                });
            });
            const xINdex = outer ? (parseFloat(viewBox[0]) + 20)  : viewBox[0]
            const yIndex =  viewBox[1] * 2;
            const width = parseFloat(viewBox[2]) + (newFontSize - fontSize);
            const height = parseFloat(viewBox[3]) + (newFontSize - fontSize) - yIndex;

            svgDraw.item(i).setAttribute('viewBox', `${xINdex} ${yIndex} ${width} ${height}`)
        }
    }

    function getCustomPositionImage(pos) {
        return vm.customPositionsImages?.[pos?.doc?.tmp_id];
    }

    function updatePositionMainImage(pos, attachment) {
        OfferViewFactory.updatePositionMainImage(
            pos.doc,
            attachment?.tmp_id,
            $scope.synced,
            vm.offer
        ).then(() => getAttachments(vm.offer));
    }

    function updatePositionExtendedImage(pos, attachment) {
        OfferViewFactory.updatePositionExtendedImage(
            pos.doc,
            attachment?.tmp_id,
            $scope.synced,
            vm.offer
        ).then(() => getAttachments(vm.offer));
    }

    function getMachineId(user) {
        return `${user.id|| ''}:${machine}`;
    }

    /**
     * Zmiana statusu dealera oferty
     * @param  {string} id        Id oferty/zamwienia
     * @param  {string} offerdata Rekord oferty
     */
    function statusChange(id, offerData) {
        const newOffer = offerData;
        OffersService.update(id, newOffer).then(() => {
            $rootScope.showInfo($filter('translate')('OFFER|Status został zaktualizowany'), null, null, 5000);
        });
    }

    function offerInTax(taxRate) {
        const tax = this.taxes.find(t => Number(t.id) === Number(this.offer.tax_rate_id));
        return tax && Number(tax.value) === taxRate
    }

    function getLastModifiedPosition() {
        const oldestPosition =  vm.groups.reduce((oldest, current) => {
            const position = current.rows.reduce((prev, curr) => {
                if(!prev) {
                    return  curr;
                }
                const modifiedDateCurr = new Date(curr.doc.modified);
                const modifiedDatePrev = new Date(prev.doc.modified);
                return modifiedDateCurr < modifiedDatePrev ? curr : prev;
            }, null);
                if(!oldest) {
                    return position;
                }
                const modifiedDatePosition = new Date(position.doc.modified);
                const modifiedDateOldest = new Date(oldest.doc.modified);
            return modifiedDatePosition < modifiedDateOldest ? position : oldest;
        }, null);

        return oldestPosition;
    }

    function getLastSyncDatePosition(field = 'lastUtcConfiguratorData' | 'lastUtcPrice') {
       const date = vm.groups.reduce((oldest, current) => {
            const currentDate = current.rows.reduce((prev, curr) => {
                if(curr.doc.sync_date) {
                    const syncDate = Core.parseJson(curr.doc.sync_date);
                    if(syncDate[field]) {
                        const dateField = new Date(syncDate[field] * 1000);
                        if(prev) {
                            return dateField < prev ? dateField : prev
                        } else {
                            return dateField;
                        }
                    }
                }
                return prev;
            }, null);
            if(oldest && currentDate) {
                return currentDate < oldest ? currentDate : oldest;
            } else if(currentDate) {
                return currentDate;
            }
            return oldest;
        }, null);

        if(date) {
            return date.toLocaleDateString()
        }
        return date;
    }

    function lastUtcDateFromPosition() {
        const oldestPosition = getLastModifiedPosition();
        if(oldestPosition.doc.sync_date) {
            const syncDate = Core.parseJson(oldestPosition.doc.sync_date);
            const lastUtcConfiguratorData = new Date(syncDate.lastUtcConfiguratorData * 1000);
            const lastUtcPrice = new Date(syncDate.lastUtcPrice * 1000);
            return (lastUtcConfiguratorData < lastUtcPrice ? lastUtcPrice : lastUtcConfiguratorData).toLocaleDateString();
        }
        return null;
    }
}
