import { WindowConfigurationLegacy15 } from '../../legacy/WindowConfigurationLegacy15';
import { IWindowConfigurationLegacy16 } from '../../legacy/WindowConfigurationLegacy16';

// eslint-disable-next-line max-statements
export function changesInVersion16(
    oldConfiguration: WindowConfigurationLegacy15,

): IWindowConfigurationLegacy16 {
    const configuration: IWindowConfigurationLegacy16 = {
        ...oldConfiguration,
        listPrice: 0,
        listPriceNoMargin: 0,
        listPriceAfterDiscountsInCurrency: 0,
        listPriceBeforePromotions: 0,
        listPriceGross: 0,
        listPriceGrossAfterDiscounts: 0,
        listPriceGrossBeforePromotions: 0,
        listPriceSegments: [],
        listPriceSegmentsNoMargin: [],
        listPriceSegmentsBeforePromotions: [],
        listPriceParts: [],
        listPricePartsNoMargin: [],
        listPricePartsBeforePromotions: []
    };

    return configuration;
}
